import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(theme => {
    

    return {
        
        inputFiltros: {
            background: "#f7f7f7",
            borderRadius: "20px !important",

            "&:hover .MuiOutlinedInput-notchedOutline": {
                border: "0"
            },
            "& .MuiOutlinedInput-notchedOutline": {
                border: "0"
            }
        },
        sinEstilo: {

            "&:hover .MuiOutlinedInput-notchedOutline": {
                border: "0"
            },
            "& .MuiOutlinedInput-notchedOutline": {
                border: "0"
            }
        }

  }
});