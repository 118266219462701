export const iconURL = (file) => {

    var ext="";
    var image="";

    ext=file.split(".");
    ext=ext[ext.length-1];
    
    switch(ext){

       case "xls":
       case "xlsx":
       case "csv":
       case "ods":
         image="/media/img/excel.png";
         break;

      case "doc":
      case "docx":
      case "odt":       
        image = "/media/img/word.png";
        break;
     
      case "pdf":       
        image = "/media/img/pdf.png";
        break;

       default:
          image = "/media/img/doc.png";
    }
    
   
    return image;

 }

 export const isImage = (url)=>{

    var img=url.split(".");
    var image=false;
    var ext=["jpeg","jpg","png","gif","bmp"];

    if(ext.indexOf(img[img.length-1])>-1){    
      image=true;
    }

    return image;

  }