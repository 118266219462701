
import { useMutation } from "@tanstack/react-query";
import { ejecutaAPI } from "../../../helpers/libFunciones";

export interface ModificacionParcialEnvioRequest {
    id: string;
    peso?: string;
    precio?: string;
}

export const actualizarParcialEnvio = async (values: ModificacionParcialEnvioRequest) => {

    const {success, respuesta, msg} = await ejecutaAPI<[]>('PUT', `/envios/actualizacionParcial`, values);

    if (success)
    {
        return respuesta;
    }
    else 
    {
        throw new Error(msg.join(", "));
    }

}

export const useApiModificacionParcialEnvio = () => {

    //const queryClient = useQueryClient();


    return useMutation<unknown, Error, ModificacionParcialEnvioRequest>((values) => actualizarParcialEnvio(values),
    {
        onSuccess: () => {

           // queryClient.invalidateQueries(['impuestos']);

        }
    });
}