import { TextField } from '@mui/material';
import { get } from 'lodash';
import React, { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { TooltipG } from '../../../../pages/utils';
import { useStyles } from '../../css/css';

const FormTextInputRHFMemo = React.memo(({tooltip, name, propsDefault, props, control}) => {



    return (

        <Controller
            render={({field}) => { 

                var propiedades = {
                    ...propsDefault,
                    value: field.value ?? "",
                    onChange: (e) => propsDefault.onChange(e, field.onChange),
                    onBlur: (e) => propsDefault.onBlur(e, field.onBlur),
                    ...props

                }

                return (
                    tooltip !== undefined
                    ?
                    (
                        <TooltipG title={tooltip}>
                            <TextField {...propiedades}  />
                        </TooltipG>
                    )
                    :
                    (
                        <TextField {...propiedades} />
                    )
                )
            }}
            name={name}
            control={control}
        />
            

    )
}, (prev, next) => {

    var renderizar = ["control", "propsDefault.error"];

    var devolver = true;

    renderizar.forEach(key => {

        if (get(prev, key) !== get(next, key))
        {
            devolver = false;
        }

    })

    return devolver;

});

const FormTextInputRHF = ({name, tooltip, onBlur, onChange, fast = false, clase = null, ...props }) => {

    const classes = useStyles();

    const { control, formState, register } = useFormContext();

    const {errors} = formState;

    const errores = get(errors, name);

    var propsDefault = {
        name:name,
        variant:"outlined",
        size:"small",
        margin: "normal",
        InputProps:{autoComplete:'new-password', className: classes[clase] || null},
        className:"kt-width-full",
        defaultValue: "",
        onBlur: useCallback((e, onBlurRHF) => {

            onBlurRHF(e);
            
            if (typeof onBlur === "function")
            {
                onBlur(e);
            }

        }, [onBlur]),
        onChange: useCallback((e, onChangeRHF) => {


            onChangeRHF(e);

            if (typeof onChange === "function")
            {
                onChange(e);
            }
        }, [onChange]),
        helperText: (errores && errores.message) || '',
        error: Boolean(errores),
    }


    return ( 
        
            props.type === 'hidden'
            ?
            (
                <input type="hidden" {...register(name)} {...props} />
            )
            :
            (
                <FormTextInputRHFMemo control={control} name={name} propsDefault={propsDefault} props={props} tooltip={tooltip}/>
            )

     );
}
 
export default FormTextInputRHF;