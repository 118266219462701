import { CSSProperties, ReactNode } from 'react';
import { TooltipG } from '../../pages/utils';

export interface EtiquetaProps {
    texto: ReactNode;
    estilos?: CSSProperties;
    clases?: string;
    tooltip?: string | null;
}

const Etiqueta = ({texto, estilos = {}, clases = "", tooltip = null} : EtiquetaProps) => {

    return (
     <TooltipG title={(tooltip!=null)?tooltip:""}>
        <span style={estilos} className={clases}>
            {texto}
        </span>
    </TooltipG>   
    )

};

export default Etiqueta;