import React  from 'react';
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, FormControlLabel,Grid,Icon,Checkbox} from '@mui/material';
import {  TooltipG, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, useStylesTable } from "../../../utils";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { capitalize } from 'lodash';


const PanelPermiso = (props)=>{

    const { checkAll, row,checksPermisos, handleChangeCheck, roles,buscar,expandedPanel,setExpandedPanel}=props;
    const classes = useStylesTable();

    //Creamos el array con las distintas secciones
    row.secciones=[null];
    row.permisos.forEach(n=>{
        if(n.seccion!=null && n.seccion!=""){
            if(row.secciones.indexOf(n.seccion)===-1){
                row.secciones.push(n.seccion);

            }
        }
    })

    

    return(
        <ExpansionPanel defaultExpanded={expandedPanel} expanded={expandedPanel} onChange={(e,expanded)=>{setExpandedPanel(expanded)}}  key={row.id}>
        <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={row.modulo}
            id={row.id}
        >
            <Typography className={classes.heading} variant="h5">
                <Icon style={{ width: 30, cursor: "pointer" }} className={row.ico} />
                {row.nombre}
            </Typography> 

        </ExpansionPanelSummary>


        <ExpansionPanelDetails>
            <Grid container spacing={3} justifyContent="flex-end" >
                <Grid item xs={1} align="right" style={{ marginTop: -65 }}  >                    
                </Grid>
                <Grid item xs={12} >
                    {row.secciones.map(seccionP => (

                        <TableContainer component={Paper} style={{ marginBottom: 20 }} >
                            <Table size="small" aria-label="dense table" className={classes.table}>
                                <TableHead>
                                    {(seccionP !== "") ?
                                        <TableRow>
                                            <TableCell className={classes.cell_titulo} colSpan={(roles)?Object.values(roles).length+3:3}>                                               
                                                {seccionP}
                                            </TableCell>
                                        </TableRow>
                                        :
                                        null
                                    }
                                    <TableRow>
                                        <TableCell className={classes.cell_short}></TableCell>
                                        <TableCell className={classes.cell_long}>Permiso</TableCell>
                                        {
                                          Object.values(roles).map(rol=>(
                                            <TableCell  className={classes.cell_med}>
                                                  {capitalize(rol.rol)}                                            
                                                  &nbsp;
                                                  <TooltipG title={"Select/Deselect All"} style={{ fontSize: 25 }}>
                                                      <FormControlLabel
                                                          control={<Checkbox onChange={e => checkAll(e, rol.id ,seccionP)} name={row.id} color="primary" />}
                                                          label=""
                                                      />
                                                  </TooltipG>
                                                  
                                              
                                            </TableCell>
                                          ))
                                        }                                        
                                        
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        Object.values(row.permisos).filter(item => item.seccion === seccionP).filter(item=> (buscar!==""?item.nombre.toLowerCase().indexOf(buscar.toLowerCase())>=0:true)).map(item => (
                                            <TableRow key={`permiso_${item.id}`}>
                                                <TableCell className={classes.cell_short}>
                                                    <TooltipG title={item.comentario} style={{ fontSize: 25 }}>
                                                        <Icon style={{ width: 30,cursor:'pointer' }} className="fa fa-info-circle" color="primary" />
                                                    </TooltipG>
                                                </TableCell>
                                                <TableCell className={classes.cell_long}>
                                                    {item.nombre}
                                                </TableCell>
                                                {
                                                    Object.values(roles).map(rol => (
                                                        <TableCell className={classes.cell_med}>                                                                                                                        
                                                            <Checkbox checked={checksPermisos[rol.id] ? checksPermisos[rol.id][item.id] : false} onChange={(e) =>{handleChangeCheck(e,rol.id)}}   name={item.id} color="primary" />
                                                        </TableCell>
                                                    ))
                                                }                                             
                                             

                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>

                    ))}


                </Grid>

            </Grid>

        </ExpansionPanelDetails>
    </ExpansionPanel> );
};

export default PanelPermiso;