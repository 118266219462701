
export const roles = {
    CLIENTE: 1,
    COMERCIAL: 2,
    INTERNO: 3,
    MENSAJERO: 4,
    MULTILOGIN: 5,
    ADMIN: 6,
    SUBAGENCIA: 7
}

export const rolesSelect = [
    {
        id: roles.CLIENTE,
        nombre: 'Cliente'
    },
    {
        id: roles.COMERCIAL,
        nombre: 'Comercial'
    },
    {
        id: roles.INTERNO,
        nombre: 'Interno'
    },
    {
        id: roles.MENSAJERO,
        nombre: 'Mensajero'
    },
    {
        id: roles.MULTILOGIN,
        nombre: 'Multilogin'
    },
    {
        id: roles.ADMIN,
        nombre: 'Admin'
    },
    {
        id: roles.SUBAGENCIA,
        nombre: 'Subagencia'
    }
]