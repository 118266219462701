import React from 'react';
import { FormSelectFormik } from './Librerias/Formik/FormSelectFormik';
import FormSelectRHF from './Librerias/ReactHookForm/FormSelectRHF';

export const FormSelect = (props) => {

    const { hooks, ...otherProps } = props;


    return (
        !hooks ? 
        (
            <FormSelectFormik {...otherProps} />
        )
        :
        (
            <FormSelectRHF {...otherProps} />
        )
    )
};
 