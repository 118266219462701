import { Card, CardContent, Container, Grid, Icon } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import TienePermisos from './../../../helpers/TienePermisos';
import accessControl from './../../../helpers/accessControl';
import * as permisos from './../../../helpers/permisos';
import
  {
    Portlet,
    PortletBody,
    PortletHeader
  } from "./../../../partials/content/Portlet";


const useStyles = makeStyles((theme) => (
  {
    card: {
      fontSize: "15px",
      textAlign: "center",
      display: "block",
      boderRadius: "5px 5px 5px 5px",
      "& :hover": {
        backgroundColor: "#fafafa"
      }
      , "& :hover >span": {
        color: theme.palette.primary.light,
        transform: "scale(1.1)",
        transition: "0.2s all"
      },
      "& span": {
        fontSize: "3em",
        width: "100%"
      }
      , "& .label": {
        marginTop: "10px",
        fontWeight: "500"
      }
    }
  }
));

const iconos = [
  { link: "configuracion/categorias", icono: "fa fa-tags", label: "Categorías", permiso: [permisos.categorias.ver] },
  { link: "configuracion/config", icono: "fa fa-cog", label: "Variables", permiso: [permisos.config.ver]}, 
  { link: "configuracion/metodospago", icono: "fa fa-credit-card", label: "Métodos de pago", permiso: [permisos.config.ver] }, 
  { link: "configuracion/logs", icono: "fa fa-server", label: "Logs", permiso: [permisos.config.ver]} 

]


const Configuracion=()=> {

  const classes = useStyles();


  return (

    <Portlet>
      <PortletHeader title={<>
        Configuración </>
      }></PortletHeader>
      <PortletBody>
        <div className="kt-section kt-margin-t-30">
          <div className="kt-section__body">
            <Container maxWidth="xl">
              <Grid container spacing={2}>
                {iconos.map(item => (
                  (item.permiso.length === 0 || TienePermisos(item.permiso)) ?
                    <Grid item xs={6} md={4} lg={2} key={item.label}>
                      <Link to={item.link} >
                        <Card elevation={0} className={classes.card} >
                          <CardContent>
                            <Icon className={item.icono} color={item.link !== "" ? "primary" : "disabled"} ></Icon>
                            <div className="label" >{item.label}</div>
                          </CardContent>

                        </Card>
                      </Link>
                    </Grid>
                    : null
                ))}
              </Grid>

            </Container>
          </div>
        </div>
      </PortletBody>

    </Portlet>


  );
}

export default accessControl([permisos.config.ver])(Configuracion);
