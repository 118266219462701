export const esHoraValidaRecogidas = (horaMaximaRecogidas?: string | null) => {
	let devolver = true;

	if (horaMaximaRecogidas) {
		const [horas, minutos] = horaMaximaRecogidas.split(":").map(Number);

		const horaMaxima = new Date();

		horaMaxima.setHours(horas, minutos, 0);

		devolver = new Date() <= horaMaxima;
	}

	return devolver;
};
