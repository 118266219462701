import { Grid, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import accessControl from "../../../../helpers/accessControl";
import { ejecutaAPI } from '../../../../helpers/libFunciones';
import * as permisos from '../../../../helpers/permisos';
import { useGlobalLoader } from '../../../../hooks/hooksPropios';



const FichaLog= (props) => {
 
  const {id}=props;
  const {cargando,setGlobalCargando}=useGlobalLoader();
  const [log,setLog]=useState([]);
  
  useEffect(()=>{

    setGlobalCargando(true);

    (async()=>{

      const {respuesta,success}= await ejecutaAPI('GET', `logs/${id}`);      

      if (success) {
         
         setLog(respuesta);
         setGlobalCargando(false);
      }

    })();


  },[setGlobalCargando,id]);


  const useStyles = makeStyles({
    table: {
      minWidth: 650,
      width: "100%",
      "& tr th": {
        border: "0px !important"
      }
    },
    paddingPeq: {
      padding: "0px !important"
    },

    filaDato: {
      fontSize:"12px",
      border:"1px solid #ccc"
    },
    tituloRef: {
      fontSize: "14px", padding: "8px !important", fontWeight: "bold",
      backgroundColor: "#d7d4d4" },
    tituloCab: { fontSize: "15px", padding: "8px !important", textAlign: "center" },
    icon: {
      color: "red",
      cursor: "pointer"
    },
   
  });

  const classes = useStyles();

  const campos=[{label:"Acción",campo:"accion"},{label:"Usuario",campo:"usuario"},{label:"Ip",campo:"ip"},{label:"Url",campo:"url"},{label:"Info",campo:"info"},{label:"Fecha",campo:"fecha"}];



  return (   
             <Grid container>
              
              <Grid item xs={12}>    
        
                  {/*!cargando && log &&
                  <ReactJson collapsed={true} src={{datosRecibidos:JSON.parse(log),respuesta:JSON.parse(log.respuesta)}} /> */ } 

              {!cargando && log && 
                    <Table id="table-stats" size="small" className={classes.table} aria-label="dense table" >
                      <TableBody >  
                        {
                          campos && campos.map((item)=>
                            <TableRow >
                              <TableCell className={classes.tituloRef} >
                                {item.label}
                              </TableCell>
                              <TableCell className={classes.filaDato} >
                                {log[item.campo]}
                              </TableCell>
                            </TableRow>
                          )  
                        }                          
                      </TableBody>
                    </Table>
               }
               
              </Grid>        

          </Grid>
      
  );

}

export default accessControl([permisos.config.ver])(FichaLog);



