import { useQuery } from "@tanstack/react-query";
import { useDataArray } from "app/hooks/useDataArray";
import { ejecutaAPI } from "../../../helpers/libFunciones";

interface ListadoClientesResponse
{
    total_rows: number;
    total: string;
    data: DatosCliente[];
    page: string;
    total_pages: number;
    next_page: number;
    prev_page: number;
    next_pages: number[];
    prev_pages: number[];
}

export interface DatosCliente
{
    id: string;
    razonSocial: string;
    nif: string;
    codigoContable?: string;
    direccion: string;
    localidad: string;
    provincia: string;
    codigoPostal: string;
    idPais?: any;
    personaContacto?: string;
    telefonoContacto?: string;
    emailFacturacion?: any;
    idFormaPago: string;
    idMetodoPago: string;
    cuentaBancaria?: any;
    iban?: string;
    banco?: any;
    bic?: any;
    remesa?: string;
    idMandato?: any;
    idMensajeroDefecto?: string;
    recogerDesdeDefecto?: string;
    recogerHastaDefecto?: string;
    idEmpresaDefecto: string;
    idTipoImpuesto: string;
    observaciones?: string;
    notasInternas?: any;
    firma?: any;
    enviarEmail: string;
    enviarSMS: string;
    retasar: string;
    identificador?: any;
    observacionesEnvioDefecto?: any;
    activo: string;
    mimeTypeFicheros?: any;
    comisionComercial?: any;
    idComercial?: any;
    fechaVencComisionComercial?: any;
    fechaCreacion: string;
    fechaActualizacion: string;
    comercial: string;
    departamentos: Departamento[];
    redes: Red[];
    categorias: any[];
}

interface Red
{
    id?: string;
    idCliente?: string;
    idRed: string;
    idServicio?: string;
    portes?: string;
    idTarifaAsociada?: string;
    idTarifaDebidos?: (null | string)[];
    factorVolumetrico?: (null | string)[];
    margenReduccion?: any;
    idDelegacion?: string;
    activo?: string;
    defecto?: string;
    idClienteRedTransporte?: string;
    uid?: (null | string)[];
    reglaMedidas?: any;
    fechaCreacion?: any;
    fechaActualizacion?: (null | string)[];
    tarifa?: string;
    nombreRed: string;
    factorVolumetricoRed: string;
    idRedMensaglobalDatos: string;
    idTipoImpuesto?: string;
    idDepartamentoRedTransporte: string;
}

interface Departamento
{
    id: string;
    idCliente: string;
    nombre: string;
    nombreDireccion: string;
    personaContacto?: string;
    telefonoContacto?: string;
    emailContacto?: any;
    direccion: string;
    codigoPostal: string;
    localidad: string;
    provincia: string;
    idPais: string;
    activo: string;
    codigo?: string;
    codigoCliente?: any;
    idMensajeroDefecto?: string;
    recogerDesdeDefecto?: string;
    recogerHastaDefecto?: string;
    observaciones?: string;
    direcciones: any[];
}



export const getClientes = async (queryParams: Record<string,any> = {}) => {

    let queryString = new URLSearchParams(queryParams).toString();

    if (queryString)
    {
        queryString = `?${queryString}`;
    }

    const { success, respuesta, msg } = await ejecutaAPI<ListadoClientesResponse>("GET", `/clientes${queryString}`);

    if (success)
    {
        return respuesta;
    }
    else
    {
        throw new Error(msg.join(", "));
    }
};

export const useClientes = (queryParams = {}, optionsExtra = {}) => {
    const options = {
        ...optionsExtra,
    };

    const query = useQuery(["clientes", queryParams], () => getClientes(queryParams), options);

    const clientes = useDataArray(query.data) as DatosCliente[];

    return {
        ...query,
        clientes,
    };
};
