import { Box } from "@mui/material";
import Etiqueta from "app/componentes/generales/Etiqueta";
import { TIPOS_CATEGORIAS } from "app/helpers/constants";
import { useRoles } from "app/hooks/hooksPropios";
import CargadorEtiquetas from "app/modules/etiquetas/components/CargadorEtiquetas/CargadorEtiquetas";
import GridCategoriasElemento from "app/modules/etiquetas/components/GridCategoriaElemento/GridCategoriasElemento";
import { useDatosTrafico } from "app/pages/home/trafico/hooks/useDatosTrafico";
import { capitalize } from "lodash";
import { useCallback } from "react";
import BultosSinPuntoControl from "../BultosSinPuntoControl";
import IndicadorPuntoControl from "../IndicadorPuntoControl";
import AccionesEnvioFilaTrafico from "./AccionesEnvioFilaTrafico";
import AccionesRecogidaFilaTrafico from "./AccionesRecogidaFilaTrafico";
import CabeceraFilaTrafico from "./CabeceraFilaTrafico";
import EtiquetasEnvio from "./EtiquetasEnvio";
import EtiquetasRecogida from "./EtiquetasRecogida";
import { useStyles } from "./css";

const FilaTrafico = ({
	datos,
	mensajeros,
	estados,
	setMensajero,
	setComprobacion,
	setTerminado,
	tipoEditable,
	seleccionados,
	seleccionarElemento,
	filtros,
	handleCambioParcial,
}) => {
	const { esCliente, esRolAdministrativo } = useRoles();

	const data = {
		estados: estados,
		datos: datos,
		seleccionados: seleccionados,
	};

	const {
		tipo,
		datosTratados,
		codigoEstado,
		desactivarMensajero,
		mostrarFinalizarRecogida,
		elementoSeleccionado,
		estado,
		estadoTooltip,
		codExpedicion,
		precios,
		tieneValoraciones,
	} = useDatosTrafico(data);

	datos = datosTratados;

	const propsParaStyles = {
		datos: datos,
		codigo: codigoEstado,
	};

	const classes = useStyles(propsParaStyles);

	//Comparaciones.

	const handleComprobacion = () => {
		setComprobacion(datos);
	};

	const handleSetTerminado = (e) => {
		setTerminado(datos);
	};

	const handleSubmitMensajero = useCallback(
		(e) => {
			setMensajero(e.target.value, datos);
		},
		[datos, setMensajero],
	);

	return (
		<>
			<div className={classes.item}>
				<div className={classes.caja}>
					<div className={classes.filaHoras}>
						{datos.estadoPuntoControl && (
							<IndicadorPuntoControl
								sx={{ mb: { xs: "0px", md: "10px" }, mr: { xs: "5px", md: "0px" }, width: "25px", height: "25px", fontSize: "10px" }}
								estadoPuntoControl={datos.estadoPuntoControl}
							/>
						)}
						<div className={classes.fechaEnvio}>{datos.fecha}</div>
						<div className={classes.horaInicio}>{datos.horaDesde}</div>
						<div className={classes.horaFin}>{datos.horaHasta}</div>
					</div>

					<div className={classes.filaDatosTipo}>
						<CabeceraFilaTrafico
							classes={classes}
							datos={datos}
							tipo={tipo}
							tipoEditable={tipoEditable}
							elementoSeleccionado={elementoSeleccionado}
							seleccionarElemento={seleccionarElemento}
						/>
						<div className={classes.datoSecundario}>
							{tipo == "E" ? (
								<EtiquetasEnvio
									datos={datos}
									classes={classes}
									codExpedicion={codExpedicion}
									filtros={filtros}
									precios={precios}
									desactivarMensajero={desactivarMensajero}
									handleSubmitMensajero={handleSubmitMensajero}
									mensajeros={mensajeros}
									handleCambioParcial={handleCambioParcial}
									tieneValoraciones={tieneValoraciones}
								/>
							) : (
								<>
									<EtiquetasRecogida
										datos={datos}
										classes={classes}
										estado={estado}
										estadoTooltip={estadoTooltip}
										precios={precios}
										mensajeros={mensajeros}
										handleSubmitMensajero={handleSubmitMensajero}
									/>
								</>
							)}
						</div>
						<div style={{ marginTop: "10px" }}>
							{(esRolAdministrativo || esCliente) && datos.tipo !== "recogidaFueraPlaza" && estado.estado != "" && (
								<Box>
									<Box display="flex" alignItems="center" mb="10px" gap="15px">
										<Etiqueta
											tooltip={estadoTooltip}
											texto={capitalize(estado.estado)}
											clases={classes.etiqueta}
											estilos={{ backgroundColor: "#000674", color: "white", margin: 0 }}
										/>
										{estado.observaciones && (
											<Box
												sx={{
													fontWeight: "500",
													background: "#fff6aff0",
													color: "#64642e",
													padding: "2px 15px",
													fontSize: "10px",
													display: {
														xs: "none",
														md: "block",
													},
												}}
											>
												Obs: {estado.observaciones}
											</Box>
										)}
									</Box>
								</Box>
							)}
							<Box>
								<CargadorEtiquetas
									enabled={true}
									categoriasIniciales={datos.categorias}
									id={datos.id}
									tipo={tipo === "E" ? TIPOS_CATEGORIAS.ENVIOS : TIPOS_CATEGORIAS.RECOGIDAS}
								>
									{({ etiquetasElemento, isLoading }) => (
										<GridCategoriasElemento etiquetasElemento={etiquetasElemento} isLoading={isLoading} gap="0.5rem" />
									)}
								</CargadorEtiquetas>
							</Box>
							{
								filtros?.estadoPuntoControl?.length > 0 && datos.estadoPuntoControl === "parcial" && (
									<Box mt={1}>
										<BultosSinPuntoControl numBultos={datos.bultosEstadosRed.length} bultosFaltantes={datos.bultosFaltantesPuntoControl} />
									</Box>
								)
							}
						</div>
					</div>

					<div className={classes.filaFooter}>
						{tipo === "R" ? (
							<AccionesRecogidaFilaTrafico
								datos={datos}
								codigoEstado={codigoEstado}
								handleSetTerminado={handleSetTerminado}
								mostrarFinalizarRecogida={mostrarFinalizarRecogida}
								handleComprobacion={handleComprobacion}
							/>
						) : (
							<AccionesEnvioFilaTrafico datos={datos} tipo={tipo} classes={classes} />
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default FilaTrafico;
