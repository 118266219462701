//@ts-nocheck
import { TIPOS_NOTIFICACIONES_ENVIOS } from "app/helpers/constants";
import { base64ToBlob, copiaObjeto, existeValorEnObjeto, gira_fecha, muestraPrecio, sumaColumnaArray } from "app/helpers/libFunciones";
import { EnvioPendienteTransmitirResponse } from "app/hooks/api/envios/useApiGetEnviosPendientesTransmitir";
import { isArray, isEmpty, isNumber } from "lodash";
import moment from "moment";
import { ClienteEnviosPendientes, PreciosTrafico, SeparadorBuscadorTrafico } from "../models/trafico.types";

export const filtrosSQL = ["fechaDesde", "fechaHasta", "distribucionPropia", "facturado", "vista", "anulado", "categorias"];

export const ordenaTraficoFecha = (a, b) => {
    let arr = a.fecha.replace(/\//g, "-");
    arr = arr.split("-");
    let fecha1 = moment(arr[2] + "-" + arr[1] + "-" + arr[0] + " 00:00:00");

    arr = b.fecha.replace(/\//g, "-");
    arr = arr.split("-");
    let fecha2 = moment(arr[2] + "-" + arr[1] + "-" + arr[0] + " 00:00:00");

    if (fecha1 > fecha2) {
        return 1;
    } else if (fecha1 < fecha2) {
        return -1;
    } else {
        //Ordenamos por nombre
        return a.direccion?.nombre?.localeCompare(b.direccion.nombre);
    }
};

export const ordenaTraficoRecogidaEnvio = (arrayFiltrado, filtrosState) => {
    if (filtrosState.tipo.indexOf("recogida") >= 0) {
        //Filtro recogidas
        arrayFiltrado = arrayFiltrado.sort((a, b) => {
            return a.direccion?.nombre?.toLowerCase().localeCompare(b.direccion.nombre.toLowerCase());
        });
    } else if (filtrosState.tipo == "envio") {
        //Filtro envios
        arrayFiltrado = arrayFiltrado.sort((a, b) => {
            return a.direccionRecogida?.nombre?.toLowerCase().localeCompare(b.direccionRecogida.nombre.toLowerCase());
        });
    } else {
        //Sin filtro de tipo
        arrayFiltrado = arrayFiltrado.sort((a, b) => {
            if (a.idRecogida == b.idRecogida) {
                if (a.tipo.indexOf("recogida") >= 0 && b.tipo == "envio") {
                    return -1;
                } else if (b.tipo.indexOf("recogida") >= 0 && a.tipo == "envio") {
                    return 1;
                } else if (a.tipo == "envio" && b.tipo == "envio") {
                    return a.direccionRecogida?.nombre
                        .toLowerCase()
                        .localeCompare(b.direccionRecogida.nombre.toLowerCase());
                }
            } else if (a.idRecogida < b.idRecogida) {
                return -1;
            } else if (a.idRecogida > b.idRecogida) {
                return 1;
            }

            return 0;
        });
    }

    return arrayFiltrado;
};

export const ordenaTrafico = (arrayFiltrado, filtrosState) => {
    if (filtrosState.orden == "fecha") {
        arrayFiltrado = arrayFiltrado.sort((a, b) => ordenaTraficoFecha(a, b));
    } else {
        arrayFiltrado = ordenaTraficoRecogidaEnvio(arrayFiltrado, filtrosState);
    }

    return arrayFiltrado;
};

export const filtraTrafico = (arrayFiltrado, filtrosState) => {
    var arrayDevolver = arrayFiltrado;

    var filtros = copiaObjeto(filtrosState);

    filtrosSQL.forEach(item => {
        delete filtros[item];
    });

    //Prefiltros especificos recogidas
    //Tipo Recogida* - Red* - Delegacion?

    //Calcula campo valorado
    arrayDevolver = arrayDevolver.map(item => ({
        ...item,
        valorado: item.valoraciones.length > 0 ? sumaColumnaArray(item.valoraciones, "precio") : 0
    }));

    var filtroRecogidaAgrupado = false;

    if (filtros["tipo"] == "recogida" && filtros["idRed"] > 0) {
        filtroRecogidaAgrupado = true;

        arrayDevolver = arrayDevolver.filter(item => {
            var delegacion = [];

            if (item?.redes_cliente) {
                if (filtros["idDelegacion"] > 0) {
                    delegacion = item.redes_cliente.filter(
                        i => i.idRed === filtros["idRed"] && i.idDelegacion === filtros["idDelegacion"]
                    );
                } else {
                    delegacion = item.redes_cliente.filter(i => i.idRed === filtros["idRed"]);
                }

                if (delegacion.length > 0) return true;
            }

            return false;
        });
    }

    Object.keys(filtros).forEach(filtro => {
        var valor = filtros[filtro];
        var entraFiltro = false;

        const filtrosIgnorar = ["tipoBeneficio", "fechaReembDesde", "fechaReembHasta", "fecha_opcion", "orden", "separadorBuscador", "mostrarEnviosConEsteMensajeroEnRecogida"]

        if ((valor !== "" && !isArray(valor)) || (isArray(valor) && !isEmpty(valor))) {

            if (filtro === "buscador") 
            {
                arrayDevolver = filtrarBuscadorTexto(arrayDevolver, valor, filtros.separadorBuscador);
            } 
            else 
            {
                arrayDevolver = arrayDevolver.filter(fila => {
                    var devolver = false;

                    if (filtrosIgnorar.includes(filtro))
                    {
                        devolver = true;
                    }
                    else if (filtro === "idPais") 
                    {
                        if (valor === fila.direccion.idPais) 
                        {
                            devolver = true;
                        }
                    } 
                    else if (filtro === "pesoDesde" || filtro === "pesoHasta") 
                    {
                        valor = parseFloat(valor);

                        if (filtro === "pesoDesde") 
                        {
                            if (parseFloat(fila.peso) >= valor) {
                                devolver = true;
                            }
                        } 
                        else 
                        {
                            if (parseFloat(fila.peso) <= valor) 
                            {
                                devolver = true;
                            }
                        }
                    } 
                    else if (filtro === "bultosDesde" || filtro === "bultosHasta") 
                    {
                        valor = parseFloat(valor);

                        if (filtro === "bultosDesde") 
                        {
                            if (parseFloat(fila.bultos) >= valor) 
                            {
                                devolver = true;
                            }
                        } 
                        else 
                        {
                            if (parseFloat(fila.bultos) <= valor) 
                            {
                                devolver = true;
                            }
                        }
                    } 
                    else if (filtro === "precioDesde" || filtro === "precioHasta") 
                    {
                        valor = parseFloat(valor);

                        if (filtro === "precioDesde") 
                        {
                            if (parseFloat(fila.valorado) >= valor) 
                            {
                                devolver = true;
                            }
                        } 
                        else if (filtro === "precioHasta") 
                        {
                            if (parseFloat(fila.valorado) <= valor) 
                            {
                                devolver = true;
                            }
                        }
                    } 
                    else if (filtro === "beneficioDesde" || filtro === "beneficioHasta") 
                    {
                        valor = parseFloat(valor);

                        //Calculamos beneficio

                        if (filtros["tipoBeneficio"] != undefined) 
                        {
                            var importe = 0;
                            var coste = 0;
                            var beneficio = 0;
                            var margen = null;

                            switch (filtros["tipoBeneficio"]) 
                            {
                                //estimado
                                case "1":
                                    importe = parseFloat(fila.valorado);
                                    fila.valoraciones &&
                                        fila.valoraciones.forEach(item => {
                                            coste = parseFloat(coste) + parseFloat(item.coste);
                                        });

                                    beneficio = parseFloat(importe - coste);

                                    if (importe > 0) {
                                        margen = parseFloat((beneficio / importe) * 100);
                                    }

                                    break;

                                //real
                                case "2":
                                    importe = parseFloat(fila.valorado);
                                    coste = fila.importeCostesReales;

                                    beneficio = parseFloat(importe - coste);

                                    if (importe > 0 && coste > 0) {
                                        margen = parseFloat((beneficio / importe) * 100);
                                    }

                                    break;

                                default:
                                    devolver = true;
                            }

                            margen = margen != null ? parseFloat(margen.toFixed(2)) : null;

                            if (isNumber(margen)) 
                            {
                                if (filtro == "beneficioDesde" && margen >= valor) 
                                {
                                    devolver = true;
                                } 
                                else if (filtro == "beneficioHasta" && margen <= valor) 
                                {
                                    devolver = true;
                                }
                            }
                        }
                    } 
                    else if (filtro === "estadoRecogida" || filtro === "estadoEnvio") 
                    {
                        if (valor.indexOf(fila.estado.idEstado) !== -1) 
                        {
                            devolver = true;
                        }

                    } 
                    else if (filtro === "contraReembolso") 
                    {
                        devolver = filtraContrareembolso(fila, valor, filtros);
                        
                    } 
                    else if (filtro === "valorado") 
                    {
                        if (
                            valor == 1 &&
                            (fila.tipo == "envio" || fila.tipo == "recogida" || fila.tipo == "recogidaFueraPlaza") &&
                            fila.valorado != null &&
                            parseFloat(fila.valorado) > 0
                        ) {
                            devolver = true;
                        } else if (
                            valor == 0 &&
                            (fila.tipo == "envio" || fila.tipo == "recogida" || fila.tipo == "recogidaFueraPlaza") &&
                            (fila.valorado == null || parseFloat(fila.valorado) <= 0)
                        ) {
                            devolver = true;
                        }
                    } 
                    else if (filtro === "numRecogida") 
                    {
                        if (fila.idRecogida == valor) 
                        {
                            devolver = true;
                        }
                    } 
                    else if (filtro === "idMensajero") 
                    {
                        if (valor == -1) 
                        {
                            //Sin mensajero
                            if (!(fila["idMensajero"] > 0)) 
                            {
                                devolver = true;
                            }
                        } 
                        else if (fila["idMensajero"] === valor || (filtros["mostrarEnviosConEsteMensajeroEnRecogida"] == "1" && fila["idMensajeroRecogida"] === valor)) 
                        {
                            devolver = true;
                        }
                    }
                    else if (filtro === "cpDesde" || filtro === "cpHasta")
                    {

                        devolver = filtraCP(filtros["cpDesde"], filtros["cpHasta"], fila.cp_origen, fila.cp_destino);

                    }
                    else if (filtro === "variables")
                    {
                        if (fila.variables_envio && fila.variables_envio.length > 0)
                        {
                            devolver = fila.variables_envio.some(variable => filtros["variables"].includes(variable.idVariable));
                        }

                    }
                    else if (filtro === "costesReales")
                    {
                        if (fila.tipo === "envio")
                        {
                            if ((valor === "1" && fila?.importeCostesReales > 0) || (valor === "0" && (!fila?.importeCostesReales || fila?.importeCostesReales <= 0)))
                            {
                                devolver = true;
                            }
                        }
                    }
                    else if (filtro === "emailEnvioTransitoEnviado") 
                    {
                        if (fila.tipo === "envio")
                        {   

                            let emailEnvioTransitoEnviado = fila.notificaciones.some(notificacion => notificacion.idTipoNotificacion == TIPOS_NOTIFICACIONES_ENVIOS.ENVIO_TRANSITO.id);

                            if ((valor === "1" && emailEnvioTransitoEnviado) || (valor === "0" && !emailEnvioTransitoEnviado))
                            {
                                devolver = true;
                            }
                        }
                    }
                    else if (filtro === "estadoPuntoControl")
                    {
                        devolver = filtrarPorPuntoControl(fila, valor);
                    }
                    else if (filtro === "transmitido")
                    {
                        devolver = filtrarPorTransmitido(fila, valor);
                    }
                    else if (filtro === "idRuta")
                    {
                        devolver = valor.includes(fila.idRuta);
                    }
                    else 
                    {
                        if (filtroRecogidaAgrupado && (filtro == "idRed" || filtro == "idDelegacion")) 
                        {
                            entraFiltro = true;
                        }

                        if (entraFiltro || fila[filtro] === valor) {
                            devolver = true;
                        }
                    }

                    return devolver;
                });
            }
        }
    });

    return arrayDevolver;
};

export const agrupaClientes = envios => {
    var clientes = [];

    envios &&
        envios.forEach(item => {
            if (clientes[item.idCliente] == undefined) {
                clientes[item.idCliente] = {
                    idCliente: item.idCliente,
                    cliente: item.cliente,
                    codEtiquetador: item.codEtiquetador,
                    envios: [],
                    recogidas: []
                };
            }

            if (item.tipo == "envio") {
                clientes[item.idCliente].envios.push(item);
            }

            if (item.tipo == "recogida" || item.tipo == "recogidaFueraPlaza") {
                clientes[item.idCliente].recogidas.push(item);
            }
        });

    var aux = [];
    clientes.forEach(item => {
        if (item != null && item.idCliente) aux.push(item);
    });

    clientes = [...aux];

    return clientes;
};

//Genera un array agrupado por clientes, del listado de envios
export const agrupaDelegaciones = envios => {
    var delegaciones = [];

    envios &&
        envios.forEach(item => {
            if (delegaciones[item.idDelegacion] == undefined) {
                delegaciones[item.idDelegacion] = {
                    idDelegacion: item.idDelegacion,
                    nombreDelegacion: item.nombreDelegacion,
                    codEtiquetador: item.codEtiquetador,
                    envios: [],
                    recogidas: []
                };
            }

            if (item.tipo == "envio") {
                delegaciones[item.idDelegacion].envios.push(item);
            }

            if (item.tipo == "recogida" || item.tipo == "recogidaFueraPlaza") {
                delegaciones[item.idDelegacion].recogidas.push(item);
            }
        });

    var aux = [];
    delegaciones.forEach(item => {
        if (item != null && item.idDelegacion) aux.push(item);
    });

    delegaciones = [...aux];

    return delegaciones;
};

export const usaFiltrosAvanzados = () => {

    if (localStorage.getItem("filtrosTrafico") != null) {

        var filtros_avanzados = ["tipo", "estadoRecogida","ficherosRecogida","numRecogida", "estadoEnvio", "idMensajero", "idRed", "idDelegacion", "idServicio", "idServicioTipo", "portes", "idPais", "pesoDesde", "pesoHasta","precioDesde","precioHasta","beneficioDesde","beneficioHasta","tipoBeneficio", "idCliente", "idDepartamento", "importado", "facturado","contraReembolso","tipoBeneficio","beneficioDesde","beneficioHasta"];
        var filtros = JSON.parse(localStorage.getItem("filtrosTrafico"));

        var tiene_filtros_avanzados = false;
        for (var i = 0; i < filtros_avanzados.length && !tiene_filtros_avanzados; i++) {
            if (filtros[filtros_avanzados[i]] && filtros[filtros_avanzados[i]].length > 0) {
                tiene_filtros_avanzados = true;
            }
        }

        return tiene_filtros_avanzados;


    } else {
        return false;
    }

}

export const compruebaTraficoRedux = (data) => {

    const { trafico_time, traficoObj } = data;

    if (trafico_time && (((Date.now() / 1000) - (trafico_time / 1000) < 300)) && traficoObj?.listadoAPI?.length > 0) {
        return true;
    } else {
        return false;
    }
}

export const filtraContrareembolso = (fila, valor, filtros) => {

    let devolver = true;

    const contraReembolso = !isNaN(fila.contraReembolso) && fila.contraReembolso !== null ? parseFloat(fila.contraReembolso) : 0;
    const pagadoReembCliente = parseFloat(fila.pagadoReembCliente);
    const pagadoReembRed = parseFloat(fila.pagadoReembRed);
    const valorNum = parseInt(valor);
    const fechaReembDesde = moment(filtros["fechaReembDesde"], "DD/MM/YYYY");
    const fechaReembHasta = moment(filtros["fechaReembHasta"], "DD/MM/YYYY");
    const fechaReembPagado = valorNum === 2 || valorNum === 5 ? moment(fila.fechaReembPagadoRed, "YYYY-MM-DD") : moment(fila.fechaReembPagadoCliente, "YYYY-MM-DD");
    
    const tieneFiltroFechas = [2,3,5,6].includes(valorNum);

    devolver =
        (valorNum === 1 && contraReembolso > 0) ||
        (valorNum === 0 && (contraReembolso === 0 || contraReembolso === null)) ||
        (valorNum === 2 && contraReembolso > 0 && pagadoReembRed === 1) ||
        (valorNum === 3 && contraReembolso > 0 && pagadoReembCliente === 1) ||
        (valorNum === 4 && contraReembolso > 0 && pagadoReembCliente === 1 && pagadoReembRed === 1) ||
        (valorNum === 5 && contraReembolso > 0 && pagadoReembCliente === 0 && pagadoReembRed === 1) ||
        (valorNum === 6 && contraReembolso > 0 && pagadoReembCliente === 1 && pagadoReembRed === 0) ||
        (valorNum === 7 && contraReembolso > 0 && pagadoReembCliente === 0 && pagadoReembRed === 0);

    if (devolver && tieneFiltroFechas && (fechaReembDesde.isValid() || fechaReembHasta.isValid())) 
    {
        if (fechaReembDesde.isValid() && fechaReembPagado < fechaReembDesde) 
        {
            devolver = false;
        }

        if (fechaReembHasta.isValid() && fechaReembPagado > fechaReembHasta) 
        {
            devolver = false;
        }

        if (!fechaReembPagado.isValid()) 
        {
            devolver = false;
        }
    }

    return devolver;

}

const filtraCP = (cpFiltrarDesde, cpFiltrarHasta, cpOrigen, cpDestino) => {

    //Pueden venir como números o como strings. En caso de que sea número comparamos >= y en caso de que sea string comprobamos que sean idénticos.

    let entreOrigen = true;
    let entreDestino = true;


    if (cpFiltrarDesde || cpFiltrarHasta)
    {

        let cpDesde = null;
        let cpHasta = null;

        if (cpFiltrarDesde)
        {
            cpDesde = trataCPFiltro(cpFiltrarDesde);
        }

        if (cpFiltrarHasta)
        {
            cpHasta = trataCPFiltro(cpFiltrarHasta);
        }

        
        cpOrigen = trataCPFiltro(cpOrigen);

        cpDestino = trataCPFiltro(cpDestino);
        

        if (cpOrigen)
        {
            if (cpDesde)
            {
                entreOrigen = cpOrigen >= cpDesde;
            }

            if (entreOrigen && cpHasta)
            {
                entreOrigen = cpOrigen <= cpHasta;
            }
        }

        if (cpDestino)
        {
            if (cpDesde)
            {
                entreDestino = cpDestino >= cpDesde;
            }

            if (entreDestino && cpHasta)
            {
                entreDestino = cpDestino <= cpHasta;
            }

        }
        else 
        {
            entreDestino = false;
        }

    }


    return entreOrigen || entreDestino;
};

const trataCPFiltro = (cp) => {

    let nuevoCP = cp;

    if (cp)
    {
        if (!isNaN(cp))
        {
            //Es numérico
            nuevoCP = parseInt(cp);
        }
        else 
        {
            //Tiene strings
            nuevoCP = cp.toUpperCase();
        }
    }

    return nuevoCP;
}

export const filtraClientes = (cliente, buscador) => {

    var devolver = false;

    if (buscador !== "")
    {

        devolver = cliente.razonSocial ? cliente.razonSocial.toLowerCase().indexOf(buscador.toLowerCase()) !== -1 : false; 

        //Buscamos en sedes
        if (!devolver)
        {
            //Buscamos por nombre o código de cliente
            var depart = cliente.departamentos.filter(i =>
                                                            (i.nombre.toLowerCase().indexOf(buscador.toLowerCase()) !== -1) || 
                                                            (i.codigoCliente && i.codigoCliente.toLowerCase().indexOf(buscador.toLowerCase()) !== -1)
                                                    );

            devolver = depart?.length > 0;                
        }

        //Buscamos en los códigos de cliente de la red
        if (!devolver)
        {
            let encontrado = cliente?.redes.filter(red => red.idClienteRedTransporte && red.idClienteRedTransporte.indexOf(buscador.toLowerCase()) !== -1);

            devolver = encontrado?.length > 0;
        }

    }
    else 
    {
        devolver = true;
    }

    return devolver;

}

export const calcularTotalesEstadisticas = (arrayCalcular) => {

    let totalesArray = { 
        num_envios: 0, 
        porcentaje_envios: "", 
        num_recogidas: 0, 
        reembolsos: 0, 
        coste: 0, 
        beneficio: 0, 
        margen: 0, 
        coste_real: 0, 
        beneficio_real: 0, 
        margen_real: 0, 
        importe: 0, 
        comisiones: 0, 
        comisiones_mensajero: 0, 
        comisiones_mensajero_envios_recogidos: 0,
        comisiones_comercial: 0, 
        servicio: "", 
        cliente: "", 
        provincia: ""
    };

    arrayCalcular.forEach(fila => {

        if (fila.tipo === "envio")
        {
            totalesArray.num_envios++;
        }
        else if (["recogida", "recogidaFueraPlaza"].includes(fila.tipo))
        {
            totalesArray.num_recogidas++;
        }

        if (fila.valoraciones.length > 0) 
        {

            var val_coste = sumaColumnaArray(fila.valoraciones, "coste");
            var val_precio = sumaColumnaArray(fila.valoraciones, "precio");

            if (!isNaN(val_coste))
            {
                totalesArray.coste += val_coste;
            }

            if (!isNaN(val_precio))
            {
                totalesArray.importe += val_precio;
            }

            //Comisiónes

            if (fila.idMensajero)
            {
                totalesArray.comisiones_mensajero += parseFloat(fila.comisionMensajero);
            }

            if (fila.idComercial)
            {
                totalesArray.comisiones_comercial += parseFloat(fila.comisionComercial);
            }

            if (fila.idMensajeroRecogida)
            {
                totalesArray.comisiones_mensajero_envios_recogidos += parseFloat(fila.comisionMensajeroRecogida);
            }

        }

        

        if (fila.contraReembolso > 0 && !isNaN(fila.contraReembolso)) 
        {
            totalesArray.reembolsos += parseFloat(fila.contraReembolso);
        }

        if (fila.importeCostesReales > 0 && !isNaN(fila.importeCostesReales)) 
        {
            totalesArray.coste_real += parseFloat(fila.importeCostesReales);
        }

    });

    totalesArray.comisiones = totalesArray.comisiones_comercial + totalesArray.comisiones_mensajero + totalesArray.comisiones_mensajero_envios_recogidos;

    if (totalesArray.coste > 0 || totalesArray.importe > 0) 
    {
        totalesArray.beneficio = totalesArray.importe - totalesArray.coste;

        totalesArray.margen = ((totalesArray.beneficio / totalesArray.importe) * 100);
    }

    if ((totalesArray.coste_real > 0 || totalesArray.comisiones > 0) && totalesArray.importe > 0) 
    {
        totalesArray.beneficio_real = totalesArray.importe - totalesArray.coste_real - totalesArray.comisiones;

        totalesArray.margen_real = ((totalesArray.beneficio_real / totalesArray.importe) * 100);
    } 
    else 
    {
        totalesArray.beneficio_real = totalesArray.importe;

        totalesArray.margen_real = 100;
    }


    return totalesArray;
}

export const formateaFechas = (fechaComparar, fechaInicial, fechaFinal) => {

    let partes = fechaComparar.split('/');

    let anyo = parseInt(partes[1]);

    let mes = parseInt(partes[0]) - 1;

    let dateInicioFiltro = new Date(gira_fecha(fechaInicial, "-"));

    let dateFinFiltro = new Date(gira_fecha(fechaFinal, "-"));

    let primerDiaMes = new Date(anyo, mes, 1);

    let ultimoDiaMes = new Date(anyo, mes + 1, 0);

    let inicioUsar = primerDiaMes;

    let finUsar = ultimoDiaMes;

    if (dateInicioFiltro >= primerDiaMes)
    {
        inicioUsar = dateInicioFiltro;
    }

    if (dateFinFiltro <= ultimoDiaMes)
    {
        finUsar = dateFinFiltro
    }

    let optionsDate = { day: '2-digit', month: '2-digit', year: 'numeric' };

    return `${inicioUsar.toLocaleDateString('es-ES', optionsDate)} a ${finUsar.toLocaleDateString('es-ES', optionsDate)}`;

}

export const agruparClienteFecha = (listado, fechaInicioFiltro, fechaFinFiltro) => {

    let devolver = [];

    let agrupado = {};

    listado.forEach(registro => {


        let fecha = new Date(gira_fecha(registro.fecha, '/'));

        let mes = fecha.getMonth() + 1; // Los meses en JavaScript empiezan en 0

        let ano = fecha.getFullYear();

        let keyMesAnyo = `${mes}/${ano}`;

        registro.mesAnyo = keyMesAnyo;

        let keyCliente = registro.idCliente;

        if (agrupado[keyCliente] === undefined)
        {
            agrupado[keyCliente] = {};
        }

        if (agrupado[keyCliente][keyMesAnyo] === undefined)
        {
            agrupado[keyCliente][keyMesAnyo] = [];
        }

        agrupado[keyCliente][keyMesAnyo].push(registro);

    })

    Object.entries(agrupado).forEach(([idCliente, meses]) => {

        let traficoCliente = [];

        //De cada mes del cliente, calculamos los datos.
        Object.entries(meses).forEach(([keyMes, arrayMes]) => {

            let datosFila = calcularTotalesEstadisticas(arrayMes);

            devolver.push({
                ...datosFila,
                cliente: arrayMes[0].cliente,
                mes: formateaFechas(keyMes, fechaInicioFiltro, fechaFinFiltro)
            })

            traficoCliente = [...traficoCliente, ...arrayMes];

        })


        //Calculamos los datos del cliente entero.
        let datosTotalClienteMes = calcularTotalesEstadisticas(traficoCliente);

        datosTotalClienteMes.cliente = traficoCliente[0].cliente;
        datosTotalClienteMes.mes = 'TOTAL';

        devolver.push(datosTotalClienteMes);

    })

    //Calculamos el total de todo tráfico.

    let datosTotalGeneral = calcularTotalesEstadisticas(listado);

    datosTotalGeneral.cliente = '';
    datosTotalGeneral.mes = 'TOTAL GENERAL';

    devolver.push(datosTotalGeneral);

    return devolver;

}


export const resaltaFila = (row) => {

    let devolver = {};

    if (row?.mes === "TOTAL")
    {
        devolver.backgroundColor = "#F4F8FB";
    }

    return devolver;
}

export const getValoresInicialesAccionMasivaSeleccionada = (id, accionMasivaSobre, accionesEnvio, accionesRecogida) => {

    let valoresIniciales = {};

    var accion = "";

    if (accionMasivaSobre === "recogida") {
        accion = accionesRecogida.find(accion => accion.id === id);
    }
    else if (accionMasivaSobre === "envio") {
        accion = accionesEnvio.find(accion => accion.id === id);
    }

    if (accion) 
    {
        valoresIniciales = accion.valoresIniciales;
    }

    return valoresIniciales;
}


export const sumaReembolsos = (reembolsos) => {

    let devolver = 0;

    reembolsos.forEach(reemb => {

        reemb.envios.forEach(envio => {

            devolver+= parseFloat(envio.contraReembolso);

        })

        if (reemb.descuento > 0)
        {
            devolver = devolver - reemb.descuento;
        }

    })

    

    return devolver.toFixed(2);
}

type ComisionesTrafico = {
    comisionComercial: number;
    comisionMensajero: number;
    comisionMensajeroRecogida: number;
}

export const damePreciosParaTrafico = (valoraciones, importeCostesReales, comisiones: ComisionesTrafico): PreciosTrafico => {

    let precio_total = sumaColumnaArray(valoraciones, "precio");

	let coste_total = sumaColumnaArray(valoraciones, "coste");

    let comisiones_total = comisiones.comisionComercial + comisiones.comisionMensajero + comisiones.comisionMensajeroRecogida;

	precio_total = muestraPrecio(precio_total);

	let beneficio_est = null;
	let margen_est = null;

	if (precio_total > 0) {
		beneficio_est = precio_total - coste_total - comisiones_total;
		margen_est = ((beneficio_est / precio_total) * 100).toFixed(2);
	}

	let coste_real = importeCostesReales;

	let beneficio_real = null;

	let margen_real = null;

	if (precio_total > 0 && coste_real > 0) {
		beneficio_real = precio_total - coste_real - comisiones_total;
		margen_real = ((beneficio_real / precio_total) * 100).toFixed(2);
	}

    return {
        precio_total,
        coste_total,
        beneficio_est,
        margen_est,
        coste_real,
        beneficio_real,
        comisiones_total,
        margen_real
    }
}

export const filtrarBuscadorTexto = (arrayFiltrar: any[], valor: string, separador: SeparadorBuscadorTrafico): any[] => {

    
    const separadorMap = {
        COMA: ",",
        ESPACIO: " "
    };

    let valorSplit = separadorMap[separador] || "";

    const terminos = valorSplit ? valor.split(valorSplit).map(term => term.trim().toLowerCase()).filter(term => term !== '') : [valor];

    return arrayFiltrar.filter(fila => {
        return terminos.some(termino => existeValorEnObjeto(termino, fila));
    });
}


export const filtrarPorTransmitido = (fila: any, valor: string) => {
    let devolver = false;

    if (fila.tipo === "envio") {
        // Si valor es "1", devolver true si NO está pendiente de transmitir
        // Si valor es "0", devolver true si está pendiente de transmitir
        devolver = valor === "1" ? !fila.pendienteTransmitir : fila.pendienteTransmitir;
    }

    return devolver;
}

export const filtrarPorPuntoControl = (fila: any, valor: string[]) => {
    let devolver = false;

    if (fila.tipo === "envio")
    {
        devolver = valor.includes(fila.estadoPuntoControl);
    }

    return devolver;
}


export function agruparEnviosPendientesTransmitirPorCliente(datos: EnvioPendienteTransmitirResponse[]): ClienteEnviosPendientes[] {
	return datos.reduce<ClienteEnviosPendientes[]>((acc, envio) => {
		const clienteExistente = acc.find((c) => c.idCliente === envio.idCliente);
        
		if (clienteExistente) {
			clienteExistente.cantidadEnvios++;
		} else {
			acc.push({
				idCliente: envio.idCliente,
				razonSocialCliente: envio.razonSocialCliente,
				cantidadEnvios: 1,
			});
		}
		return acc;
	}, []);
}

export const descargarEtiquetas = (etiquetasBase64: string) => {

    let blob = base64ToBlob(etiquetasBase64);

    let blobUrl = URL.createObjectURL(blob);

    const link = document.createElement("a");

    link.download = `etiquetas-${moment().format('YYMMDDHHmmss')}.pdf`;

    link.href = blobUrl;

    document.body.append(link);

    link.click();

    link.remove();

    setTimeout(() => URL.revokeObjectURL(link.href), 7000);

}

export const dameTextoPuntoControl = (estadoPuntoControl: "total" | "parcial" | "ninguno" | null) => {

    let devolver = "";

    if (estadoPuntoControl)
    {
        devolver = "No";

        if (estadoPuntoControl === "total")
        {
            devolver = "Sí";
        }
        else if (estadoPuntoControl === "parcial")
        {
            devolver = "Parcialmente";
        }
    }

    return devolver;
}
