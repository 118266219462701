import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ejecutaAPI } from "app/helpers/libFunciones";
import { VerbosHTTP } from "app/models/types";
import { PlantillaResponse } from "./useApiPlantilla";

export interface GuardarPlantillaRequest {
	campos: CamposGuardarPlantilla;
	tipoImportacionEnvios: string;
	descripcion: null;
	formato: string;
	inicioDatos: string;
	idTipoPlantilla: string;
	idRed: string;
	nombre: string;
	id: string;
}

interface JSONColumna {
	tipo: string;
	valor: string;
}

interface CamposGuardarPlantilla {
	[key: string]: {
		id: string | null;
		columna: string | null;
		funcion: string;
		idTipoCampo: string;
		valorDefecto: string | null;
		jsonColumnas: JSONColumna[];
		comportamientoEnActualizar: string;
	};
}

export const guardarPlantilla = async (values: GuardarPlantillaRequest) => {
	let method: VerbosHTTP = "POST";

	let rutaId = "";

	if (values.id) {
		method = "PUT";

		rutaId = `/${values.id}`;
	}

	const { success, respuesta, msg } = await ejecutaAPI<PlantillaResponse>(method, `/plantillas${rutaId}`, values);

	if (success) {
		return respuesta;
	} else {
		throw new Error(msg.join(","));
	}
};

export const useApiGuardarPlantilla = (id: string | undefined) => {
	const queryClient = useQueryClient();

	return useMutation<PlantillaResponse, Error, GuardarPlantillaRequest, unknown>((values) => guardarPlantilla(values), {
		onSuccess: () => {
			if (id) {
				queryClient.invalidateQueries(["plantillas", id]);
			}
		},
	});
};
