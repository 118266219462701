import { Grid } from "@mui/material";
import { useFormikContext } from "formik";
import { useState } from "react";
import { SketchPicker } from "react-color";
import reactCSS from "reactcss";
import { FormTextInput } from "./FormTextInput";

export const FormColorpicker = ({color, ...props}) => {
    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    const [colorState, setColor] = useState(color !== null ? color : "#000000");

    const formik = useFormikContext();

    const handleClick = () => {
        setDisplayColorPicker(!displayColorPicker);
    };

    const handleClose = () => {
        setDisplayColorPicker(false);
    };

    const handleChange = (color) => {
        setColor(color.hex);
        formik.setFieldValue(props.name, color.hex);
    };

    const styles = reactCSS({
        default: {
            color: {
                width: "20px",
                height: "20px",
                borderRadius: "2px",
                background: `${colorState}`,
            },
            swatch: {
                padding: "5px",
                background: "#fff",
                borderRadius: "1px",
                boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
                display: "inline-block",
                cursor: "pointer",
            },
            popover: {
                position: "absolute",
                zIndex: "2",
            },
            cover: {
                position: "fixed",
                top: "0px",
                right: "0px",
                bottom: "0px",
                left: "0px",
            },
        },
    });


    return (
        <Grid container direction="row" justifyContent="flex-start">
            <Grid item xs={1}>
                <br></br>
                <div style={styles.swatch} onClick={handleClick}>
                    <div style={styles.color} />
                </div>
            </Grid>
            <Grid item xs={11}>
                <FormTextInput {...props} value={colorState} />
            </Grid>
            {displayColorPicker
                ? (
                    <div style={styles.popover}>
                        <div style={styles.cover} onClick={handleClose} />
                        <SketchPicker color={colorState} onChange={handleChange} />
                    </div>
                )
                : null}
        </Grid>
    );
};
