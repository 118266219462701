import LabelIcon from '@mui/icons-material/Label';
import Mostrar from 'app/componentes/datadisplay/Mostrar';
import BotonCircular from 'app/componentes/generales/BotonCircular';
import { TIPOS_CATEGORIAS } from 'app/helpers/constants';
import { useRoles } from 'app/hooks/hooksPropios';
import EtiquetaModal from 'app/modules/etiquetas/components/EtiquetaModal/EtiquetaModal';
import { TooltipG } from 'app/pages/utils';
import React from 'react';


const BotonTooltipEtiquetas = ({tipo, idElemento, etiquetasElemento = []}) => {

    const {esRolAdministrativo} = useRoles();

    const tipoElemento = tipo === "E" ? TIPOS_CATEGORIAS.ENVIOS : TIPOS_CATEGORIAS.RECOGIDAS;

    const titulo = tipo === "E" ? 'Categorizar envío' : 'Categorizar recogida';

    const tooltip = etiquetasElemento?.map(elemento => elemento.nombre).join(", ") || 'Categorizar';

    const colorBoton = etiquetasElemento.length > 0 ? 'primary' : 'default';

    return (
        <Mostrar cuando={esRolAdministrativo} desmontar={true}>
            <EtiquetaModal tipo={tipoElemento} id={idElemento} titulo={titulo}>
            {
                ({abrirModalEtiquetas}) => (
                
                    <TooltipG title={tooltip}>                                                        
                        <BotonCircular onClick={abrirModalEtiquetas} color={colorBoton} variant="contained" type="button">
                            <LabelIcon />
                        </BotonCircular>
                    </TooltipG>
                )
            }
            </EtiquetaModal> 
        </Mostrar>
      );
}
 
export default BotonTooltipEtiquetas;