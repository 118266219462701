

/* Calcula el precio final, pasandole el valor del servicio, y los valores valor o margen */
export const calculaValores = (valorServicio, valor = null, margen = null, dif = null,tipo_porcentaje=null) => {

    let valorCalculado = 0, margenCalculado = 0, diferencia = 0;

    if (valor !== null && valor >= 0 && valorServicio >= 0) 
    {
        valorCalculado = valor;

        if (valorServicio == 0) 
        {
            margenCalculado = 100;
        } 
        else 
        {
            if (valor == 0)
            {
                margenCalculado = 0;
            }
            else
            {
                margenCalculado = ((100 * (valor - valorServicio)) / valor).toFixed(2);
            }
        }
    } 
    else if (margen !== null && margen >= 0 && valorServicio >= 0) 
    {

        if (valorServicio == 0) 
        {
            valorCalculado = 0;

            margenCalculado = 0;

        } 
        else 
        {
            if (margen == 0)
            {
                valorCalculado=0;
            }
            else
            {
                valorCalculado = (valorServicio / (1 - (margen / 100))).toFixed(2);
            }

            margenCalculado = margen;
        }
    } 
    else if (dif > 0 && valorServicio > 0) 
    {

        //Calculamos el margen
        margenCalculado = (100 * parseFloat(dif) / (parseFloat(dif) + parseFloat(valorServicio))).toFixed(2);

        //Calculamos el valor con el margen
        valorCalculado = (valorServicio / (1 - (margenCalculado / 100))).toFixed(2);

    }

    if (valorCalculado > valorServicio)
    {
        diferencia = (valorCalculado - valorServicio).toFixed(2);
    }


    if (parseFloat(valorCalculado) - parseInt(valorCalculado) === 0) 
    {
        valorCalculado = parseFloat(valorCalculado).toFixed(0);
    }

    if (parseFloat(margenCalculado) - parseInt(margenCalculado) === 0) 
    {
        margenCalculado = parseFloat(margenCalculado).toFixed(0);
    }

    if (parseFloat(diferencia) - parseInt(diferencia) === 0) 
    {
        diferencia = parseFloat(diferencia).toFixed(0);
    }

    return { 
        valorCalculado, 
        margenCalculado, 
        diferencia 
    };

}

/* Dada una referencia de servicio, una ruta y el objeto servicio y tarifa, calcula los precios finales*/
/* 
 idRef:  idReferencia servicio
 margenRed: margen de red
 idRuta: idRuta del servicio
 servicio: objeto servicio
 tarifa: objeto tarifa
 cantidad: peso o bultos

 Devuelve un objeto con el precio de servicio y el precio que se aplica segun tarifa/franja 
*/


export const calculaPrecios = (idRef, margenRed, idRutaParam, servicio, tarifa, cantidad) => {

    var indexFranja = -1;
    var indexFranjaAdc = -1;
    var idFranja = -1;
    var idFranjaAdc = -1;

    var indexRuta = -1;
    var idRuta=0;
    
    var margenTarifa = (tarifa.margen > 0) ? tarifa.margen : (margenRed>0)?margenRed:0;

    var objCalc = {
        franja: "",
        ruta: "",
        valorServicio: 0,
        tipoCalculo: null,
        valorCalculado: 0,
        margenCalculado: 0,
        diferencia: 0,
        valorServicioAdc: 0,
        tipoCalculoAdc: null,
        valorCalculadoAdc: 0,
        margenCalculadoAdc: 0,
        diferenciaAdc: 0,
        error: "",
        errorAdc: "",
        mostrarSoloAdc: false
    };

    let aux = null;

    
    
    if (servicio.referencias && servicio.referencias.length > 0) {

        let servicioRef = servicio.referencias.filter(item => item.id === idRef);

        if (servicioRef.length > 0) {

            servicioRef = servicioRef[0];

            let contabilizarCeroUltimaFranja = servicioRef.contabilizarCeroUltimaFranja === "1";
            //Detectamos la ruta
            servicioRef.rutas.forEach((i, index) => {
                if (i.idRuta === idRutaParam) {                    
                    indexRuta = index;    
                    idRuta=i.id;                
                    if (i.nombre)
                        objCalc.ruta = i.nombre

                }
            });


            //Detectamos la franja a la que corresponde igual o mayor
            let encontradaFranja = false;

            servicioRef.franjas.forEach((i, index) => {

                if (!encontradaFranja && parseFloat(cantidad) <= parseFloat(i.valor) && i.valor !== "+") {
                    indexFranja = index;
                    idFranja = i.id;
                    objCalc.franja = i.valor;
                    encontradaFranja = true;                 
                }

                // Comprobamos que el valor de la tarifa sea mayor que 0, si no cogemos la franja siguiente.
                //MODIFICACION: si  buscando en franjas superiores no encuentra con precio, tiene que ir bajando desde la franja que le corresponde al peso.
                if (encontradaFranja) 
                {
                    encontradaFranja = compruebaValorFranja(servicioRef, tarifa, indexRuta, indexFranja, idFranja, idRuta, idRef,margenRed);
                }

            });  

            if(!encontradaFranja){
                indexFranja=-1;
                idFranja=-1;
            }

        
            //Buscamos hacia atrás en las franjas, desde la franja correspondiente
            
            if (!encontradaFranja) 
            {
                var indexFranja_correspondiente = buscaFranjaCorrespondiente(servicioRef.franjas, cantidad);

                if (indexFranja_correspondiente >= 0) 
                {

                    for (var i = indexFranja_correspondiente; i >= 0 && !encontradaFranja; i--) 
                    {

                        let idFranja_aux = buscaFranja_id(servicioRef.franjas, i);

                        encontradaFranja = compruebaValorFranja(servicioRef, tarifa, indexRuta, i, idFranja_aux, idRuta, idRef, margenRed);

                        if (encontradaFranja) 
                        {
                            indexFranja = i;
                            idFranja = idFranja_aux;
                            
                            //Si la cantidad es mayor que la franja, le cobramos los kilos adicionales
                            let franja_aux = servicioRef.franjas.find(i => i.id == idFranja_aux);

                            objCalc.franja = franja_aux.valor;

                            if (parseFloat(cantidad) > parseFloat(franja_aux.valor)) 
                            {
                                indexFranjaAdc = servicioRef.franjas.length - 1;
                                idFranjaAdc = servicioRef.franjas[servicioRef.franjas.length - 1].id;
                            }

                            
                        }

                    }
                }

                if (!encontradaFranja) 
                {
                    //Si entra aquí es porque no ha encontrado ninguna franja válida
                    //Miramos si solo tiene la franja de adicionales

                    let datosFranja = null;
                    /*
                        En caso de que contabilizarCeroUltimaFranja esté activado, debemos tener en cuenta la última franja con valor para contabilizarla como 0, 
                        y los kilos adicionales partirarán desde el valor de esa franja, por ejemplo, si hay una franja de 10 como max, y hay 12kg en el
                        paquete, se contabilizarán 10kg a 0€ y 2kg adicionales al precio que sea
                    */
                    if (contabilizarCeroUltimaFranja)
                    {
                        
                        servicioRef.franjas.forEach((franja, indice) => {

                            if (datosFranja === null || (!isNaN(franja.valor) && parseInt(franja.valor) > parseInt(datosFranja.valor)))
                            {
                                datosFranja = franja;

                                indexFranja = indice;

                                idFranja = franja.id;

                                objCalc.franja = franja.valor;
                            }
                        })


                    }

                    let indexAdcEncontrado = servicioRef.franjas.findIndex(franja => franja.valor === "+");

                    if (indexAdcEncontrado !== -1 && parseFloat(cantidad) >= 1 && (!contabilizarCeroUltimaFranja || datosFranja === null || (contabilizarCeroUltimaFranja && parseFloat(cantidad) > parseFloat(datosFranja.valor))))
                    {
                        indexFranjaAdc = indexAdcEncontrado;

                        idFranjaAdc = servicioRef.franjas[indexAdcEncontrado].id;

                        if (!contabilizarCeroUltimaFranja)
                        {
                            objCalc.franja = 0;

                            objCalc.mostrarSoloAdc = true;
                        }

                        encontradaFranja = true;
                    }

                }
                
            }

            
            //Si tiene franja adicional, comprobamos que tenga valor
            if(indexFranjaAdc>-1){


                var ref_tarifa = tarifa.referencias.find(i => i.idReferencia === idRef);                               
                var obj_RutaTarifa = ref_tarifa.rutas.filter(item => item.idServicioReferenciaRuta === idRuta)[0];
                
                var val_serv_adc = servicioRef.valores.filter((i) => (i.keyFranja === indexFranjaAdc && i.keyRuta === indexRuta));
                var obj_FranjaTarifa_adc = ref_tarifa.franjas.filter(item => item.idServicioReferenciaFranja === idFranjaAdc)[0];

                var objFranja = servicioRef.franjas.filter((i,index)=>index===indexFranja)[0];

                if (obj_RutaTarifa && obj_FranjaTarifa_adc) {


                    var obj_valores_adc = ref_tarifa.valores.filter(i => i.idTarifaRuta === obj_RutaTarifa.id && i.idTarifaFranja === obj_FranjaTarifa_adc.id)[0];

                    if (obj_valores_adc.id) {
                        if (obj_valores_adc.valor > 0 && obj_valores_adc.tipoModificacion != "inactivo") {

                        } else {

                            if (encontradaFranja && (val_serv_adc[0].valor <= 0 || obj_valores_adc.tipoModificacion == "inactivo" || (parseFloat(obj_valores_adc.valor) <= 0 && obj_valores_adc.tipoModificacion == "valor"))) {                                
                                indexFranjaAdc = -1;
                                if (obj_valores_adc.tipoModificacion != "inactivo") {
                                    objCalc.error = "ERROR: PRECIO A 0 EN TARIFA KILOS/BULTOS ADICIONALES";
                                } else {

                                    let stringBultosMax = '';

                                    if (objFranja)
                                    {
                                        stringBultosMax = `(MAX ${objFranja.valor} Kg)`;
                                    }

                                    objCalc.error = `ERROR: LA TARIFA NO PERMITE KILOS/BULTOS ADICIONALES ${stringBultosMax}`;

                                }
                            }
                        }
                    }

                }

            }

        


            //Sacamos el valor  del servicio
            aux = servicioRef.valores.filter((i) => (i.keyFranja === indexFranja && i.keyRuta === indexRuta));

            if (aux.length > 0) {
                objCalc.valorServicio = aux[0].valor;
            }

            //Sacamos el valor  del servicio de la franja adicional
            if (indexFranjaAdc > -1) {
                aux = servicioRef.valores.filter((i) => (i.keyFranja === indexFranjaAdc && i.keyRuta === indexRuta));

                if (aux.length > 0) {
                    objCalc.valorServicioAdc = aux[0].valor;
                }
            }


            //Ahora sacamos si tiene algun valor cambiado en la tarifa.
            if (objCalc) {

                if (idFranja > 0)
                {
                    aux = getPreciosTarifa(idRef, objCalc.valorServicio, tarifa, idFranja, idRuta, margenTarifa, contabilizarCeroUltimaFranja);

                    if (aux) 
                    {
                        objCalc.valorCalculado = parseFloat(aux.valorCalculado);
                        objCalc.margenCalculado = parseFloat(aux.margenCalculado);
                        objCalc.diferencia = parseFloat(aux.diferencia);
                        objCalc.tipoCalculo = aux.tipoCalculo;
                    }
                }
                //Hay que calcular los kilos/bultos adicionales
                if (idFranjaAdc > 0) 
                {                  
                    aux = getPreciosTarifa(idRef, objCalc.valorServicioAdc, tarifa, idFranjaAdc, idRuta, margenTarifa);

                    if (aux) 
                    {
                        objCalc.valorCalculadoAdc = parseFloat(aux.valorCalculado);
                        objCalc.margenCalculadoAdc = parseFloat(aux.margenCalculado);
                        objCalc.diferenciaAdc = parseFloat(aux.diferencia);
                        objCalc.tipoCalculoAdc = aux.tipoCalculo;
                    }

                }
            } 
            else 
            {
                objCalc = false;
            }


        } else {
            objCalc = false;
        }


    } else {
        objCalc = false;
    }

    if(objCalc!==false && objCalc.error && objCalc.error!="" ){

        objCalc.valorServicio=0;
        objCalc.valorCalculado = 0;
        objCalc.margenCalculado = 0;
        objCalc.diferencia = 0;
        objCalc.tipoCalculo = "margen";

        objCalc.valorCalculadoAdc = 0;
        objCalc.margenCalculadoAdc = 0;
        objCalc.diferenciaAdc = 0;
        objCalc.tipoCalculoAdc = "margen";

    }

    return objCalc;

}

function compruebaValorFranja(servicioRef,tarifa,indexRuta,indexFranja,idFranja,idRuta,idRef,margenRed){

      var margenTarifa = (tarifa.margen > 0) ? tarifa.margen : (margenRed>0)?margenRed:0;

       var encontradaFranja=true;
       var val_serv = servicioRef.valores.filter((i) => (i.keyFranja === indexFranja && i.keyRuta === indexRuta));

        if (val_serv.length > 0) {

            let ref_tarifa = tarifa.referencias.find(i => i.idReferencia === idRef);

            if (idFranja > -1) {
                var obj_FranjaTarifa = ref_tarifa.franjas.filter(item => item.idServicioReferenciaFranja === idFranja)[0];
            }

            if (idRuta > -1) {
                var obj_RutaTarifa = ref_tarifa.rutas.filter(item => item.idServicioReferenciaRuta === idRuta)[0];
            }

            if (obj_RutaTarifa && obj_FranjaTarifa) {

                var obj_valores = ref_tarifa.valores.filter(i => i.idTarifaRuta === obj_RutaTarifa.id && i.idTarifaFranja === obj_FranjaTarifa.id)[0];

                if (obj_valores.id) {


                    if (obj_valores.valor > 0 && obj_valores.tipoModificacion != "inactivo") {
                        // la tarifa tiene valor, esto es por si la de servicio esta a 0, y en tarifa han indicado valor.
                    } else {        
                        
                        //Si el precio final de la tarifa es 0 que pase de franja.
                        var precio_aux=getPreciosTarifa(servicioRef.id,val_serv[0].valor, tarifa, idFranja, idRuta, margenTarifa);                       
                        
                        if (val_serv[0].valor <= 0 || obj_valores.tipoModificacion == "inactivo" || (parseFloat(obj_valores.valor)<=0 && obj_valores.tipoModificacion!=null)  || !parseFloat(precio_aux.valorCalculado)>0) {
                            encontradaFranja = false;                            

                        }
                    }
                }

            }

        }

        return encontradaFranja;
    
}

/* Segun la cantidad busca la franja correspondiente */
function buscaFranjaCorrespondiente(franjas,cantidad){

    var indexFranja=0;
    var encontrado=false;
    
    franjas && franjas.forEach((i, index) => {
        
        if (!encontrado && parseFloat(cantidad) <= parseFloat(i.valor) && i.valor !== "+") {
            indexFranja = index;                
            encontrado=true;
        }
    });

    //la cantidad es mayor que la ultima franja, seleccionamos la ultima
    if(!encontrado){
        indexFranja=franjas.length-2;
    }

    return indexFranja;

}


/* Segun la cantidad busca la franja correspondiente */
function buscaFranja_id(franjas,indexFranja) {

    var idFranja = 0;

    franjas && franjas.forEach((i, index) => {

        if(index==indexFranja){
            idFranja = i.id;
        }
    });

    return idFranja;

}


function getPreciosTarifa(idRef, valorServicio, tarifa, idFranja, idRuta, margenTarifa, contabilizarCeros = false) {

    var obj_FranjaTarifa = null;
    var obj_RutaTarifa = null;
    var obj_valores = null;
    var encontrado = false;
    var objCalc = false;

    var margenAplicado=margenTarifa;


    tarifa.referencias && tarifa.referencias.forEach((reg) => {

        obj_FranjaTarifa = false;
        obj_RutaTarifa = false;

        if (reg.idReferencia === idRef) {

                      

            if (idRuta > -1) {
                obj_RutaTarifa = reg.rutas.filter(item => item.idServicioReferenciaRuta === idRuta)[0];
                if(obj_RutaTarifa && obj_RutaTarifa.margen!=null)
                    margenAplicado=obj_RutaTarifa.margen;
            }

            if (idFranja > -1) {
                obj_FranjaTarifa = reg.franjas.filter(item => item.idServicioReferenciaFranja === idFranja)[0];
                if (obj_FranjaTarifa && obj_FranjaTarifa.margen != null)
                    margenAplicado = obj_FranjaTarifa.margen;
            }

            if (obj_RutaTarifa && obj_FranjaTarifa) {

                obj_valores = reg.valores.filter(i => i.idTarifaRuta === obj_RutaTarifa.id && i.idTarifaFranja === obj_FranjaTarifa.id)[0];

                if (obj_valores.id) {
                    encontrado = true;
                }

            }

        }

    });



    if (encontrado) {

        let tipoModificacion = obj_valores.tipoModificacion;

        if (valorServicio <= 0 && tipoModificacion != "valor")
            tipoModificacion = "inactivo";

        switch (tipoModificacion) {
            case "valor":
                objCalc = calculaValores(valorServicio, obj_valores.valor, margenAplicado, null);
                break;

            case "margen":
                objCalc = calculaValores(valorServicio, null, obj_valores.margen, null);
                break;

            case null:
                objCalc = calculaValores(valorServicio, null, margenAplicado, null);
                break;
            case "inactivo":
                objCalc = {};
                objCalc.valorCalculado = contabilizarCeros ? 0 : valorServicio;
                objCalc.margenCalculado = 0;
                objCalc.diferencia = 0;
                break;

            default:
        }

        objCalc.tipoCalculo = obj_valores.tipoModificacion;

        return objCalc;

    } else {
        return false;
    }

}
