import { Button, FormControl, Grid, InputLabel, MenuItem, Select, Switch, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import accessControl from "../../../../helpers/accessControl";
import { Portlet, PortletBody, PortletHeader } from "../../../../partials/content/Portlet";

import Api from '../../../../crud/Api';
import { PaperLine } from "../../../utils";

import { injectIntl } from "react-intl";
import Swal from 'sweetalert2';
import * as auth from "../../../../store/ducks/auth.duck";
import PanelPermisoRol from './PanelPermisoRol';


 const Permisos =(props)=> {

     
     const [checksPermisos, setChecksPermisos] = useState({});     
     const [filterPermisos, setFilterPermisos] = useState({ modulo: 0, buscar: '' })

     const [expandedPanel, setExpandedPanel] = useState(true);

     const { modulos, roles } = props;


     useEffect(() => {

        
         if (roles.length == 0 || modulos.length==0) {
             props.ActualizaPermisos({});
         }
         
         //Establecemos los checks
         var permisos_roles_check = [];

         
         if (modulos && Object.values(modulos).length > 0) {

             Object.values(roles).forEach((item)=>{

                 permisos_roles_check[item.id]= {};
                
             });

             
             
             //Permisos globales
             permisos_roles_check.forEach((it, index) => {

                 if (modulos[index]){
                        modulos[index].permisos.forEach((item_p) => {
                            if (item_p.id > 0)
                                permisos_roles_check[index][item_p.id] = false;
                        });
                }

             });


             Object.values(roles).forEach(item=>{

                 if(item.permisos && item.permisos.length>0){

                      item.permisos.forEach(row=>{
                         permisos_roles_check[item.id][row]=true;
                      });
                 }

             });


           
         
            setChecksPermisos(permisos_roles_check);          

         }

         return () => {
             // clean up             

         };

     }, [roles,modulos]);


     const handleChangeCheck = (event,rol) => {
         setChecksPermisos({ ...checksPermisos, [rol]:{...checksPermisos[rol],[event.target.name]: event.target.checked }});        
     };

     const checkAll = (event, rol, seccion) => {

         var modulo = event.target.name;
         var check = event.target.checked;

         //Marcamos todos los checks correspondientes al modulo
         Object.values(modulos).forEach((row) => {

             if (row.id === modulo) {
                 row.permisos.forEach(item => {
                     if (seccion === undefined) {
                         checksPermisos[rol][item.id] = check;
                     } else {
                         if (item.seccion == seccion)
                             checksPermisos[rol][item.id] = check;
                     }

                 });
             }
         });

         setChecksPermisos({ ...checksPermisos });
     }

   

    const guardaPermisosRol=()=>{
     

         var res_data={};
         var arr={};
               

         Object.entries(checksPermisos).forEach( (item) => {
              var rol=item[0];
              var tempArr=item[1];
              
              arr[rol]=[];              

              Object.entries(tempArr).forEach((item)=>{
                if(item[1])
                    arr[rol].push(item[0]);
              });             
             
            
         });
                  

            Api("put", `/users/setAllPermisosRol`, arr).then(({data})=>{


                if (data.success === false) {
                    Swal.fire(
                        'Error al guardar!',
                        '',
                        'error'
                    );
                } else {

                    Swal.fire(
                        'Permisos rol actualizados!',
                        '',
                        'success'
                    );

                    res_data = data.data;
                    const roles = Object.values(res_data);
                    props.setRoles({ roles });

                }
            });

    
        
     }


     

    return (
        <Portlet>
            <PortletHeader
                title={<>
                    Permisos Rol </>
                }                
            >
            </PortletHeader>
            <PortletBody>
                <PaperLine elevation={0} style={{
                    marginTop: "10px", marginBottom: "20px"
                }}>

                    <Grid container spacing={3} justifyContent="space-around" >

                    

                        <Grid item xs={12} md={2} >
                            <FormControl style={{ marginTop: 16 }} >
                                <InputLabel id="idModulo" shrink={true}>Módulo</InputLabel>
                                <Select
                                    labelId="idModulo"
                                    id="idModulo"
                                    defaultValue={0}
                                    placeholder="Módulo"
                                    style={{ minWidth: "150px", maxWidth: "150px" }}

                                    onChange={(event) => { setFilterPermisos({ ...filterPermisos, modulo: event.target.value }) }}
                                >
                                    <MenuItem value={0}>
                                        <em>Todos</em>
                                    </MenuItem>
                                    

                                    {Object.values(modulos).map((item) => (
                                        <MenuItem key={item.id} value={item.id}>
                                            {item.nombre}
                                        </MenuItem>
                                    ))}

                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={4}  >
                            <TextField
                                type="text"
                                label="Buscar"
                                onChange={(event) => { setFilterPermisos({ ...filterPermisos, buscar: event.target.value }) }}
                                margin="normal"
                                name="permiso"
                            />
                        </Grid>

                        <Grid item xs={6} md={1}>
                            <br></br><br></br>
                            <Switch
                                checked={expandedPanel}
                                onChange={(e) => { setExpandedPanel(e.target.checked ? 1 : 0) }}
                                name="expandPanel"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                            />
                        </Grid>

                        <Grid xs={6} item md={2} >
                            <br></br>
                            <Button onClick={guardaPermisosRol} variant="contained" color="primary" style={{ float: "right", marginRight: "20" }}>
                                Guardar
                            </Button>

                        </Grid>
                    </Grid>
                </PaperLine>
                <br></br>


                <Grid container spacing={3}>
                    {
                        Object.values(modulos).filter((i) => (filterPermisos.modulo > 0 ? i.id === filterPermisos.modulo : true)).map((row) => (

                            <>
                                <Grid item xs={12} lg={12}>                                   

                                      <PanelPermisoRol key={`modulo_${row.id}`}
                                        row={row}
                                        roles={roles}                                        
                                        buscar={filterPermisos.buscar}
                                        checkAll={checkAll}
                                        checksPermisos={checksPermisos}
                                        handleChangeCheck={handleChangeCheck}
                                        expandedPanel={expandedPanel}
                                        setExpandedPanel={setExpandedPanel} />

                                </Grid>

                                <br></br>
                            </>
                        )) 
                    }
                    <Grid item xs={12}></Grid>
                    <Grid item xs={12} >
                        <Button style={{ float: "right" }} onClick={guardaPermisosRol} variant="contained" color="primary">
                            Guardar
                                    </Button>
                    </Grid>

                </Grid> 
            </PortletBody>           
           <br></br>

        </Portlet>   

    );
}


const mapStateToProps = (state) => {    
    const modulos = state.auth.modulos;
    const roles = state.auth.roles;    
    return {modulos,roles};
}

export default injectIntl(accessControl([72])(connect(mapStateToProps,auth.actions)(Permisos)));
