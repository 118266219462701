import { Button, FormControl, Grid, InputLabel, MenuItem, Select, Switch, TextField } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import Api from "app/crud/Api";
import TienePermisos from "app/helpers/TienePermisos";
import { usuarios as permisosUsuarios } from "app/helpers/permisos";
import { roles as rolesID } from "app/helpers/roles";
import { useGlobalLoader, useRoles } from "app/hooks/hooksPropios";
import PanelPermiso from "app/pages/home/config/users/PanelPermiso";
import { PaperLine } from "app/pages/utils";
import { actions } from "app/store/ducks/auth.duck";
import { intersection } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";

export default function PermisosUsuario({ rol, usuario }) {


	const [filterPermisos, setFilterPermisos] = useState({ modulo: 0, buscar: "" });
	const [modulosRol, setModulosRol] = useState([]);
	const [expandedPanel, setExpandedPanel] = useState(true);
	const { ADMIN, MULTILOGIN } = useRoles();

	const {setGlobalCargando} = useGlobalLoader();

	let { roles, modulos } = useSelector((state) => state.auth);

	const queryClient = useQueryClient();

	const dispatch = useDispatch();

	const { id } = usuario;

	const permisosRoles = [];

	permisosRoles[1] = { permisoEditar: permisosUsuarios.editar_cliente };
	permisosRoles[2] = { permisoEditar: permisosUsuarios.editar_comercial };
	permisosRoles[3] = { permisoEditar: permisosUsuarios.editar_interno };
	permisosRoles[4] = { permisoEditar: permisosUsuarios.editar_mensajero };
	permisosRoles[rolesID.SUBAGENCIA] = { permisoEditar: permisosUsuarios.editar_subagencia };


	const checkAll = (event, rol, seccion) => {
		var modulo = event.target.name;
		var check = event.target.checked;

		//Marcamos todos los checks correspondientes al modulo
		Object.values(modulosRol).forEach((row) => {
			if (row.id === rol) {
				row.modulos.forEach((itemM) => {
					if (itemM.id === modulo) {
						itemM.permisos.forEach((item) => {
							if (seccion === undefined) {
								modulosRol[rol].checkPermisos[item.id] = check;
							} else {
								if (item.seccion === seccion) modulosRol[rol].checkPermisos[item.id] = check;
							}
						});
					}
				});
			}
		});

		setModulosRol({ ...modulosRol });
	};

	const rolesSel = () => {
		var roles = {
			idRol: rol.id,
			permisos: []
		};

		//Ajustamos roles


		var aux = [];
		var checks = Object.values(modulosRol).filter((j) => j.id === rol.id);

		if (checks[0]) {
			checks = checks[0].checkPermisos;

			Object.entries(checks).forEach((n) => {
				if (n[1] === true) aux.push(n[0]);
			});
		}

		roles.permisos = aux;

		return roles;
	};

	const guardaPermisos = () => {
		//set roles seleccionados
		var values = rolesSel();

		if (Object.keys(values).length > 0) {

			setGlobalCargando(true);

			Api("put", `/users/permisosUsuario/${usuario.id}`, values).then(({ data }) => {

				setGlobalCargando(false);
				
				if (data.success) {

					queryClient.invalidateQueries(['usuarios', id]);

					Swal.fire("Permisos actualizados!", "", "success");
				} else {
					if (data.msg) {
						Swal.fire("Error!", data.msg[0], "error");
					}
				}
			});
		}
	};

	const handleChangeCheck = (event, idRol) => {
		setModulosRol({
			...modulosRol,
			[idRol]: {
				...modulosRol[idRol],
				checkPermisos: { ...modulosRol[idRol].checkPermisos, [event.target.name]: event.target.checked },
			},
		});
	};

	useEffect(() => {
		if (roles.length === 0 || modulos.length === 0) {
			dispatch(actions.ActualizaPermisos({}));
		}

		let auxModulosRoles = {};

		if (roles && modulos) {
			//Obtenemos de cada rol, sus modulos

			Object.values(roles).forEach((item) => {
				auxModulosRoles[item.id] = { ...item, modulos: [], checkPermisos: [] };

				Object.values(modulos).forEach((n) => {
					let aux_permisos = [];
					if (item.permisos.length > 0) {
						n.permisos.forEach((item_aux) => {
							aux_permisos.push(item_aux.id);
						});

						let permisosRol = item.permisos.map(permiso => permiso.idPermiso);

						if (intersection(permisosRol, aux_permisos).length > 0) {
							auxModulosRoles[item.id].modulos.push(n);
						}
					}
				});
			});
			
			//Establecemos los permisos básicos del rol para el usuario, por defecto marcados
			Object.values(auxModulosRoles).forEach((n, index, arr) => {
				var obj = Object.values(roles.filter((item) => item.id == n.id))[0];

				if (obj) {
					obj.permisos.forEach((item) => {
						arr[index].checkPermisos[item.idPermiso] = item.activo === '1';
					});
				}
			});

			setModulosRol({ ...auxModulosRoles });
		}

		if (id > 0) {


			//Establecemos los permisos del usuario en cada rol, si tiene el rol
			if (TienePermisos([], [76, 77, 78, 79])) {
				Object.values(auxModulosRoles).forEach((n, index, arr) => {
					var obj = Object.values(roles.filter((item) => item.id == n.id));

					if (obj.length > 0) {
						obj = obj[0];

						var permisosUserRol = Object.values(usuario.roles.filter((j) => j.id == n.id));

						if (permisosUserRol.length > 0) {
							permisosUserRol = permisosUserRol[0].permisos;
						}

						obj.permisos.forEach((item) => {
							//si se le ha añadido un rol nuevo y no tiene permisos seleccionados, seleccionamos todos los del rol
							
							if (permisosUserRol.indexOf(item.idPermiso) > -1) arr[index].checkPermisos[item.idPermiso] = true;
							else arr[index].checkPermisos[item.idPermiso] = false;
							
						});
					}
				});

				setModulosRol({ ...auxModulosRoles });

			}
		}
	}, [id, modulos, roles, ADMIN, MULTILOGIN, usuario.id, usuario.roles, dispatch]);

	return (
		<>
			<PaperLine elevation={0} style={{ marginTop: "10px", marginBottom: "20px" }}>
				<Grid container spacing={3} justifyContent="space-around">
					<Grid item xs={12} md={2}>
						<FormControl style={{ marginTop: 16 }}>
							<InputLabel variant="standard" id="idModulo" shrink={true}>
								Módulo
							</InputLabel>
							<Select
								labelId="idModulo"
								id="idModulo"
								defaultValue={0}
								placeholder="Módulo"
								style={{ minWidth: "150px", maxWidth: "150px" }}
								onChange={(event) => {
									setFilterPermisos({ ...filterPermisos, modulo: event.target.value });
								}}
							>
								<MenuItem value={0}>
									<em>Todos</em>
								</MenuItem>
								{modulosRol[rol.id] &&
									modulosRol[rol.id].modulos.map((item) => (
										<MenuItem key={`modulos_rol_${item.id}`} value={item.id}>
											{item.nombre}
										</MenuItem>
									))}
							</Select>
						</FormControl>
					</Grid>

					<Grid item xs={12} md={4}>
						<TextField
							type="text"
							label="Buscar"
							onChange={(event) => {
								setFilterPermisos({ ...filterPermisos, buscar: event.target.value });
							}}
							margin="normal"
							name="permiso"
						/>
					</Grid>

					<Grid xs={6} item md={1}>
						<br></br>
						<br></br>
						<Switch
							checked={expandedPanel}
							onChange={(e) => {
								setExpandedPanel(e.target.checked ? 1 : 0);
							}}
							name="expandPanel"
							inputProps={{ "aria-label": "secondary checkbox" }}
						/>
					</Grid>

					<Grid xs={6} item md={2}>
						<br></br>

						<Button
							onClick={guardaPermisos}
							disabled={TienePermisos([permisosRoles[rol.id].permisoEditar]) ? false : true}
							variant="contained"
							color="primary"
							style={{ float: "right", marginRight: "20" }}
						>
							Guardar
						</Button>
					</Grid>
				</Grid>
			</PaperLine>
			<br></br>

			<Grid container spacing={3}>
				{modulosRol[rol.id] &&
					modulosRol[rol.id].modulos
						.filter((i) => (filterPermisos.modulo > 0 ? i.id === filterPermisos.modulo : true))
						.map((row) => (
							<>
								<Grid key={`grid_${rol.id}`} item xs={12} lg={12}>
									<PanelPermiso
										key={`modulo_${row.id}`}
										id={`modulo_${row.id}`}
										row={row}
										rol={roles.filter(item => item.id === rol.id)[0]}
										buscar={filterPermisos.buscar}
										checkAll={checkAll}
										checksPermisos={modulosRol[rol.id].checkPermisos}
										handleChangeCheck={(e) => handleChangeCheck(e, rol.id)}
										expandedPanel={expandedPanel}
										setExpandedPanel={setExpandedPanel}
									/>
								</Grid>

								<br></br>
							</>
						))}
				<Grid item xs={12}></Grid>
				<Grid item xs={12}>
					<Button
						style={{ float: "right" }}
						disabled={TienePermisos([permisosRoles[rol.id].permisoEditar]) ? false : true}
						onClick={guardaPermisos}
						variant="contained"
						color="primary"
					>
						Guardar
					</Button>
				</Grid>
			</Grid>
		</>
	);
}
