import { ReactNode, createContext, useCallback, useContext, useReducer } from 'react';
import { ModalAction, ModalActionTypes, ModalContextProps } from './models/useModalContext.types';

export const ModalContext = createContext<ModalContextProps | null>(null);

export const ModalDispatcher = createContext<React.Dispatch<ModalAction> | null>(null);

const initialState: ModalContextProps = {
    open: false,
    data: null,
    vista: null,
    props: {}
}

const modalReducer = (state: ModalContextProps, action: ModalAction) => {
    switch (action.type) {
      case ModalActionTypes.open:
        return {
          ...state,
          vista: action.payload.vista,
          data: action.payload.data,
          open: true,
          props: action.payload.props
        };
      case ModalActionTypes.close:
        return {
          ...state,
          vista: null,
          data: null,
          open: false,
          props: {}
        };
      default:
        throw new Error('Acción de modal desconocida');
    }
}

export const ModalContextProvider = ({children} : {children: ReactNode}) => {


    const [state, dispatch] = useReducer(modalReducer, initialState);

    return (
        <ModalContext.Provider value={state}>
            <ModalDispatcher.Provider value={dispatch}>
                {children}
            </ModalDispatcher.Provider>
        </ModalContext.Provider>
    )

}

export const useModalContext = () => {


    const modalState = useContext(ModalContext);

    if (!modalState)
    {
        throw new Error('No se puede usar useModalContext fuera de su contexto');
    }
    

    return {
        ...modalState

    }
}


export const useModalContextAction = () => {

    const dispatch = useContext(ModalDispatcher);

    if (!dispatch)
    {
        throw new Error('No se puede usar useModalContextAction fuera de su contexto');
    }

    const openModal = useCallback((vista: string, data: any = null, props = {}) => {

        dispatch({type: ModalActionTypes.open, payload: { vista, data, props}});

    }, [dispatch]);

    const closeModal = useCallback(() => {

        dispatch({type: ModalActionTypes.close});

    }, [dispatch]);


    return {
        dispatch,
        openModal,
        closeModal
    };
}

