
import * as permisos from './../../app/helpers/permisos';
import { roles } from './../../app/helpers/roles';


 const Menu=() => { 

    const {CLIENTE, MENSAJERO, INTERNO, ADMIN, MULTILOGIN, SUBAGENCIA} = roles;  

    const c_menu = {
      header: {
        self: {},
        items: []
      },
      aside: {
        self: {},
        items: []
      }
    };


    c_menu.header.items.push({
      title: "Inicio",
      root: true,
      alignment: "left",
      page: "dashboard",
      translate: "MENU.DASHBOARD",
      permisos:[],
      rol:[]
    });

    c_menu.aside.items.push({
      title: "Inicio",
      icon: "flaticon2-architecture-and-city",
      page: "dashboard",
      translate: "MENU.DASHBOARD",
      bullet: "dot",
      permisos:[],
      rol:[]
    });

  c_menu.header.items.push({
    title: "Avisos/Envíos",
    root: true,
    alignment: "left",
    page: "cliente/trafico",
    translate: "MENU.CONFIG",
    permisos: [permisos.recogidas.ver,permisos.envios.ver],
    rol: [CLIENTE]
  });

  
  c_menu.aside.items.push({
    title: "Avisos/Envíos",
    icon: "flaticon2-architecture-and-city",
    page: "cliente/trafico",
    translate: "MENU.CONFIG",
    bullet: "dot",
    permisos: [permisos.recogidas.ver,permisos.envios.ver],
    rol: [CLIENTE]
  });

  c_menu.header.items.push({
    title: "Entregas/Recogidas",
    root: true,
    alignment: "left",
    page: "mensajero/trafico",
    translate: "MENU.CONFIG",
    permisos: [permisos.envios.ver,permisos.recogidas.ver],
    rol: [MENSAJERO]
  });

  c_menu.aside.items.push({
    title: "Entregas/Recogidas",
    icon: "flaticon2-architecture-and-city",
    page: "mensajero/trafico",
    translate: "MENU.CONFIG",
    bullet: "dot",
    permisos: [permisos.envios.ver,permisos.recogidas.ver],
    rol: [MENSAJERO]
  });



  c_menu.header.items.push({
    title: "Tráfico",
    root: true,
    alignment: "left",
    page: "trafico",
    translate: "MENU.CONFIG",
    permisos: [],
    rol: [INTERNO, MULTILOGIN, ADMIN, SUBAGENCIA]
  });

  c_menu.aside.items.push({
    title: "Tráfico",
    icon: "flaticon2-architecture-and-city",
    page: "trafico",
    translate: "MENU.CONFIG",
    bullet: "dot",
    permisos: [],
    rol: [INTERNO, MULTILOGIN, ADMIN, SUBAGENCIA]
  });


  
   c_menu.header.items.push({
     title: "Clientes",
     root: true,
     alignment: "left",
     page: "clientes",
     translate: "MENU.CONFIG",
     rol: [INTERNO, MULTILOGIN, ADMIN],
     permisos: [permisos.clientes.ver]
   });

   c_menu.aside.items.push({
     title: "Clientes",
     icon: "flaticon2-architecture-and-city",
     page: "clientes",
     translate: "MENU.CONFIG",
     bullet: "dot",
     rol: [INTERNO, MULTILOGIN, ADMIN],
     permisos: [permisos.clientes.ver]
   }); 



  c_menu.header.items.push({
    title: "Redes",
    root: true,
    alignment: "left",
    page: "redes",
    translate: "MENU.CONFIG",
    rol:[INTERNO, MULTILOGIN, ADMIN],
    permisos: [permisos.redes.ver]
  });

  c_menu.aside.items.push({
    title: "Redes",
    icon: "flaticon2-architecture-and-city",
    page: "redes",
    translate: "MENU.CONFIG",
    bullet: "dot",
    rol: [INTERNO, MULTILOGIN, ADMIN],
    permisos: [permisos.redes.ver]
  });

  c_menu.header.items.push({
    title: "Tarifas",
    root: true,
    alignment: "left",
    page: "tarifas",
    translate: "MENU.CONFIG",
    rol: [INTERNO, MULTILOGIN, ADMIN],
    permisos: [permisos.tarifas.ver]
  });

  c_menu.aside.items.push({
    title: "Tarifas",
    icon: "flaticon2-architecture-and-city",
    page: "tarifas",
    translate: "MENU.CONFIG",
    bullet: "dot",
    rol: [INTERNO, MULTILOGIN, ADMIN],
    permisos: [permisos.tarifas.ver]
  });



  c_menu.header.items.push({
    title: "Facturación",
    root: true,
    alignment: "left",
    page: "facturacion/facturar",
    translate: "MENU.CONFIG",
    rol: [INTERNO, MULTILOGIN, ADMIN],
    permisos: [permisos.facturacion.facturar]
  });

  c_menu.aside.items.push({
    title: "Facturación",
    icon: "flaticon2-architecture-and-city",
    page: "facturacion/facturar",
    translate: "MENU.CONFIG",
    bullet: "dot",
    rol: [INTERNO, MULTILOGIN, ADMIN],
    permisos: [permisos.facturacion.facturar]
  });





  const sub_menu_conf_t = [
    {
      title: "Transmisión",
      bullet: "dot",
      page: "transmision",
      rol: [INTERNO, MULTILOGIN, ADMIN],
      permisos: [permisos.transmision.ver]
    },
    {
      title: "Importación",
      bullet: "dot",
      page: "importacion",
      rol: [INTERNO, MULTILOGIN, ADMIN],
      permisos: [permisos.importacion.ver]
    }
  ];

  c_menu.header.items.push({
    title: "Acciones",
    root: true,
    alignment: "left",
    page: "",
    translate: "MENU.CONFIG",
    submenu: sub_menu_conf_t,
    rol: [INTERNO, MULTILOGIN, ADMIN],
    permisos: [permisos.transmision.ver,permisos.importacion.ver]
  });

  c_menu.aside.items.push({
    title: "Acciones",
    icon: "flaticon2-architecture-and-city",
    page: "",
    translate: "MENU.CONFIG",
    bullet: "dot",
    submenu: sub_menu_conf_t,
    rol: [INTERNO, MULTILOGIN, ADMIN],
    permisos: [permisos.transmision.ver, permisos.importacion.ver]
  });


  c_menu.header.items.push({
    title: "Presupuestos",
    root: true,
    alignment: "left",
    page: "presupuestos",
    translate: "MENU.CONFIG",
    rol: [INTERNO, MULTILOGIN, ADMIN,CLIENTE, SUBAGENCIA],
    permisos: [permisos.simulador.ver]
  });

  c_menu.aside.items.push({
    title: "Presuspuestos",
    icon: "flaticon2-architecture-and-city",
    page: "presupuestos",
    translate: "MENU.CONFIG",
    bullet: "dot",
    rol: [INTERNO, MULTILOGIN, ADMIN,CLIENTE, SUBAGENCIA],
    permisos: [permisos.simulador.ver]
  });



  const sub_menu_conf = [
    {
      title: "Usuarios",
      bullet: "dot",
      page: "config/users",
      rol: [INTERNO, MULTILOGIN, ADMIN],
      permisos: [permisos.usuarios.ver]
    },
    {
      title: "Categorias",
      bullet: "dot",
      page: "configuracion/categorias",
      rol: [INTERNO, MULTILOGIN, ADMIN],
      permisos: [permisos.config.ver]    
    }
];
  
    c_menu.header.items.push({
      title: "Configuración",
      root: true,
      alignment: "left",
      page: "configuracion",
      translate: "MENU.CONFIG",
      submenu: sub_menu_conf,
      rol: [INTERNO, MULTILOGIN, ADMIN],
      permisos:[permisos.config.ver]
      
    });

    c_menu.aside.items.push({
      title: "Configuración",
      icon: "flaticon2-architecture-and-city",
      page: "configuracion",
      translate: "MENU.CONFIG",
      bullet: "dot",
      submenu:sub_menu_conf,
      rol: [INTERNO, MULTILOGIN, ADMIN],
      permisos:[permisos.config.ver]
    });

   
    return (c_menu);
 
  

}

const menuConfig=Menu();

export default menuConfig;





