import { takeLatest} from "redux-saga/effects";


export const actionTypes = {
  CARGANDO:"[Cargando]",
};

const initialState = { 
    appCargando: false
  } ;

export const reducer = 
    (state = initialState, action) => {
      switch (action.type) {
        case actionTypes.CARGANDO: {
          
          return {...state,appCargando:action.payload};
        }

        default:

          return state;
      }
}
   

export const actions = {
  setCargando: (payload) => ({type: actionTypes.CARGANDO, payload})
};


//Watchers
export function* saga() {
  yield takeLatest(actionTypes.CARGANDO, function* listadoSaga(){});
}

