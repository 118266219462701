export interface ModalContextProps {
    open: boolean;
    data?: any;
    vista: string | null;
    props: any;
}

export const ModalActionTypes = {
    open: '@modal/open',
    close: '@modal/close'
} as const;

export type OpenModalAction = {type: typeof ModalActionTypes.open, payload: {vista: string, data: any, props: any}}

export type CloseModalAction = {type: typeof ModalActionTypes.close}

export type ModalAction = OpenModalAction | CloseModalAction;