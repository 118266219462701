import React from 'react';
import { Route } from 'react-router-dom';
import ControlAcceso from './ControlAcceso';

const RoutePermisos = ({roles, ...props}) => {


    return ( 
        
            <ControlAcceso roles={roles}>
                    <Route {...props}/>
            </ControlAcceso>
        
     );
}
 
export default RoutePermisos;