import { Grid } from "@mui/material";
import { Button, Icon } from "@mui/material/";
import { useEffect, useState } from "react";
import { useRoles } from "../../../hooks/hooksPropios";
import { Portlet, PortletBody, PortletHeader } from "../../../partials/content/Portlet";
import { SelectVideos } from ".././../../helpers/selects";
import VideoAyuda from "./VideoAyuda";

export default function Ayuda() {
	const [videos, setVideos] = useState();

	const { INTERNO, CLIENTE, MENSAJERO, esRolAdministrativo, idRol } = useRoles();

	const ruta = "/media/ayuda";

	const manuales = [
		{ rol: CLIENTE, download: "NOVEDADES.pdf", nombre: "Novedades" },
		{ rol: INTERNO, download: "FORMACION_AGENCIAS.pdf", nombre: "Formación Agencias" },
		{ rol: CLIENTE, download: "FORMACION_CLIENTES.pdf", nombre: "Formación Clientes" },
		{ rol: MENSAJERO, download: "FORMACION_MENSAJEROS.pdf", nombre: "Formación Mensajeros" },
	];

	useEffect(() => {
		SelectVideos().then((data) => {
			setVideos(data);
		});
	}, []);

	return (
		<Portlet>
			<PortletHeader title={<>Ayuda </>} toolbar={null}></PortletHeader>
			<PortletBody>
				<div className="kt-section kt-margin-t-30">
					<div className="kt-section__body">
						<Grid container>
							{manuales.map(
								(i) =>
									(esRolAdministrativo || idRol == i.rol) && (
										<Grid item xs={12} sm={6} md={4} lg={3}>
											<a href={`${ruta}/${i.download}`} download={i.download}>
												<Button style={{ fontSize: "16px" }} type="button" variant="contained" color="primary">
													<Icon className="fa fa-file-pdf"></Icon> &nbsp;&nbsp; {i.nombre}
												</Button>
											</a>
										</Grid>
									),
							)}

							<Grid item xs={12}>
								<br></br>
								<br></br>
							</Grid>
							{esRolAdministrativo
								? videos &&
								  videos.map((item) => (
										<Grid item xs={12} sm={6} md={4} lg={3}>
											<VideoAyuda {...item}></VideoAyuda>
										</Grid>
								  ))
								: null}
						</Grid>
					</div>
				</div>
			</PortletBody>
		</Portlet>
	);
}
