import { Box } from '@mui/material';
import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import Swal from 'sweetalert2';
import { ejecutaAPI } from '../../../helpers/libFunciones';
import FicherosTumbsnail from '../../galeria/FicherosThumbsnail';
import SpinnerCargando from '../../generales/SpinnerCargando';
import { useStyles } from './css';



const Dropzone = ({uploadUrl = "", carpeta = "", aceptar = false, mostrarSoloFicheros = false, ficheros, setFicheros, mensaje = "", multiple = true,mostrarNombre=false}) => {

    const classes = useStyles();

    //const [files, setFiles] = useState(ficheros || []);
    const [cargando, setCargando] = useState(false);

    const opcionesDropzone = {
        multiple: multiple,
        onDrop: async acceptedFiles => {

          /*var ficherosAceptados = acceptedFiles.map(file => Object.assign(file, {
            url: URL.createObjectURL(file)
          }))*/

          if (uploadUrl !== "")
          {

              setCargando(true);
              var errores=[];

              var ficherosSubidos = Promise.all(acceptedFiles.map(async fichero => {

                  const datosForm = new FormData();

                  datosForm.append('fichero', fichero);
                  datosForm.append('carpeta', carpeta);

                  const ficheroSubidoAPI = await ejecutaAPI('POST', uploadUrl, datosForm);

                  var respuesta = ficheroSubidoAPI.respuesta;

                  if (ficheroSubidoAPI.success)
                  {                    
                      return respuesta;
                  }else{                    
                    errores.push({fichero:fichero,error:respuesta.data.msg.join(",")});                    
                  
                  }
              }));

              

              ficherosSubidos.then(ficheroAPI => {

                setCargando(false);

                var nuevoFiles = [...ficheros, ...ficheroAPI];

                setFicheros(nuevoFiles);

                if(errores.length>0){
                  //Unificar
                  var err="";

                  errores.forEach(item=>{
                    err+=`<br>Fichero: ${item.fichero.name}: ${item.error} `;

                  });

                  if(err!=""){

                      Swal.fire({
                        icon: 'error',
                        width:"500",
                        title: 'Error',
                        html: err,                      
                      });                    

                  }

                }


              });

          }
          else
          {
            setFicheros(acceptedFiles);
          }


        }
    }

    if (aceptar)
    {
        opcionesDropzone.accept = aceptar;
    }

    const {getRootProps, getInputProps} = useDropzone(opcionesDropzone);

    const deleteFichero = (fichero) => {

        var indiceFichero = ficheros.findIndex(file => file.id === fichero.id);

        if (indiceFichero !== -1)
        {
          var nuevoArray = [...ficheros];

          nuevoArray.splice(indiceFichero, 1);

          setFicheros(nuevoArray);

        }

    }
  
  

  /*useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach(file => URL.revokeObjectURL(file.url));

  }, [files, getFiles]);*/


  return (
    <div className={classes.dropzoneContainer}>
      {
        !mostrarSoloFicheros ?
        (
          <div {...getRootProps({className: classes.dropzone})}>
            <input {...getInputProps()} />
              <span>{(mensaje !== "") ? mensaje :"Suelta ficheros aquí para subirlos"}</span>
          </div>
        )
        :
        (
          null
        )
      }
      
      {
        cargando ?
        (
          <Box width="100%">
              <SpinnerCargando posicion={"center"} />
          </Box>
        )
        :
        (
          <>
            <FicherosTumbsnail
              ficheros={ficheros}
              mostrarNombre={mostrarNombre}
              onDelete={!mostrarSoloFicheros ? deleteFichero : null}
            />
          </>
        )
      }
      
    </div>
  );
}
 
export default Dropzone;