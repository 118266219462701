import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion as ExpansionPanel, AccordionDetails as ExpansionPanelDetails, AccordionSummary as ExpansionPanelSummary, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { last } from 'lodash';
import moment from 'moment';
import { useState } from 'react';
import { TooltipG } from '../../../../pages/utils';
import { useStyles } from './css';


const BultoEnvio = ({datosSeguimiento, indice}) => {

    const [expanded, setExpanded] = useState(true);

    const handleChange = () => {
        setExpanded(!expanded);
    }

    var seguimientos = datosSeguimiento.seguimiento;


    seguimientos = seguimientos.sort((a, b) => {
        
        var aFechaHora = a?.fecha?.split(' ') || "";
        var bFechaHora = b?.fecha?.split(' ') || "";

    
        let arr = aFechaHora[0];
        arr = arr.replace(/\//g, "-")        
        arr=arr.split("-");
        let fecha1 = moment(arr[2] + "-" + arr[1] + "-" + arr[0]+" "+aFechaHora[1]);
        arr = bFechaHora[0];
        arr = arr.replace(/\//g, "-")
        arr = arr.split("-");
        let fecha2 = moment(arr[2] + "-" + arr[1] + "-" + arr[0]+" "+bFechaHora[1]);
        

        if (fecha1 > fecha2) {
            return 1
        } else if (fecha1 < fecha2) {
            return -1;
        } else {
            return 0;
        }     
    });

    var ultimoSeguimiento = last(seguimientos);

    var ultimoEstado = ultimoSeguimiento?.nombre ? ultimoSeguimiento.nombre : "No se ha podido obtener el seguimiento";

    const classes = useStyles();

    return ( 
        <>
        <Grid container>
            <Grid item lg={12} xs={12}>

                    <ExpansionPanel expanded={expanded} onChange={handleChange}>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            <Grid container>
                                <Grid item>
                                    <strong>Bulto: {indice+1}</strong>
                                </Grid>
                                <Grid item lg xs>
                                    <Grid container justifyContent="center">
                                        <span className={classes.estadoEnvio}>{ultimoEstado}</span>
                                    </Grid>
                                </Grid>

                            </Grid>
                            
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Fecha</TableCell>
                                            <TableCell>Estado</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            seguimientos.map(seguimiento => {

                                                return (
                                                
                                                    <TableRow key={`row_${datosSeguimiento.idBulto}_${seguimiento.fecha}`}>
                                                        <TableCell>{seguimiento.fecha}</TableCell>
                                                        <TableCell>
                                                            <TooltipG title={seguimiento.descripcion}>
                                                                <span>{seguimiento.nombre}</span>
                                                            </TooltipG>
                                                        </TableCell>
                                                    </TableRow>
                                                )

                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </ExpansionPanelDetails>
                    </ExpansionPanel> 
            </Grid>
        </Grid>
        </>
     );
}
 
export default BultoEnvio;