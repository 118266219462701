

 const Header = ()=>{


    return(
             {                  
              /*'Authorization': `Bearer ${token}`*/
              /*'Origin':'*',*/              
              'Content-Type':'application/json',               
             }            
        );

        
}

export default Header;