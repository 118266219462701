import { useQuery } from "@tanstack/react-query";
import { ejecutaAPI } from "app/helpers/libFunciones";
import { useDataArray } from "app/hooks/useDataArray";
import { ListadosResponse } from "app/models/types";

export interface PlantillaTipoResponse {
  id: string;
  nombre: string;
  tipo: string;
  campos: CampoPlantillaTipo[];
}

interface CampoPlantillaTipo {
  idTipoCampo: string;
  campo: string;
  actualizable: string;
}

export const getPlantillasTipos = async (params = {}) => {

    let parametros = new URLSearchParams(params).toString();

    parametros = parametros ? `?${parametros}` : ''

    const {success, respuesta, msg} = await ejecutaAPI<ListadosResponse<PlantillaTipoResponse>>('GET', `/plantillas/tipos${parametros}`);

    if (success)
    {
        return respuesta;
    }
    else 
    {
        throw new Error(msg.join(","));
    }
}


export const useApiPlantillasTipos = (params = {}, optionsExtra = {}) => {

    const options = {
        cacheTime: 1000 * 3600,
        ...optionsExtra
    }

    const query = useQuery(['plantillas_tipos', params], () => getPlantillasTipos(params), options);

    const plantillasTipos = useDataArray<PlantillaTipoResponse[]>(query.data);

    return {
        ...query,
        plantillasTipos
    }

}