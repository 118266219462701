import { Box, Button, Grid, MenuItem } from "@mui/material";
import { Form } from "app/componentes/formularios/form/form";
import { FormSelect } from "app/componentes/formularios/FormSelect";
import { FormTextInput } from "app/componentes/formularios/FormTextInput";
import { TIPOS_PLANTILLA_IMPORTACION_ENVIOS } from "app/helpers/constants";
import { pintaOpcionesSelect } from "app/helpers/libFunciones";
import { PlantillaResponse } from "app/hooks/api/plantillas/useApiPlantilla";
import { PlantillaTipoResponse } from "app/hooks/api/plantillas/useApiPlantillasTipos";
import { useFormPlantilla } from "app/hooks/usePlantillas";
import { useWatch } from "react-hook-form";
import CamposPlantilla from "./CamposPlantilla";


interface FormularioPlantillaProps {
	redes: any[];
	plantillasTipos: PlantillaTipoResponse[];
    plantilla: PlantillaResponse
}

const FormularioPlantilla = ({ redes, plantillasTipos, plantilla }: FormularioPlantillaProps) => {
	const { form, guardarPlantilla, permisoGuardar } = useFormPlantilla(plantilla, plantillasTipos);

    const onChangeTipoPlantilla = () => {

        //Setear valores por defecto
        let values = form.getValues();

        const tipoSeleccionado = plantillasTipos.find(tipo => tipo.id === values.idTipoPlantilla);

        if (!tipoSeleccionado)
        {
            return;
        }

        let arrayCampos = {} as any;

        tipoSeleccionado.campos.forEach((campo) => {

            let valoresDefecto = {
                id: '',
                idTipoCampo: campo.idTipoCampo,
                funcion: 'columna',
                jsonColumnas: [],
                columna: '',
                valorDefecto: '',
                comportamientoEnActualizar: 'defecto'
            }


            arrayCampos[campo.idTipoCampo] = valoresDefecto;
            
        });

        form.setValue('campos', arrayCampos);
    }


	return (
		<Form {...form} onSubmit={guardarPlantilla}>
			<Box mb={1}>
				<Grid container spacing={1}>
					<Grid item lg={2} xs={12}>
						<FormTextInput hooks name="nombre" label="Nombre" />
					</Grid>
					<Grid item lg={2} xs={12}>
						<FormSelect hooks label="Tipo" name="idTipoPlantilla" onChange={onChangeTipoPlantilla}>
							{pintaOpcionesSelect(plantillasTipos, "id", "nombre", "selectTiposPlantilla")}
						</FormSelect>
					</Grid>
                    <OpcionesTipoPlantilla redes={redes} />
					<Grid item lg={2} xs={12}>
						<FormTextInput
							type="number"
							hooks
							name="inicioDatos"
							tooltip="Nº de fila en la que empiezan a haber datos"
							label="Inicio datos"
						/>
					</Grid>
					<Grid item lg={2} xs={12}>
						<FormSelect hooks label="Formato" name="formato">
							<MenuItem value="excel">Excel</MenuItem>
						</FormSelect>
					</Grid>

					<Grid item lg={12} xs={12}>
						<FormTextInput hooks name="descripcion" multiline rows={4} label="Descripción" InputLabelProps={{ shrink: true }} />
					</Grid>
				</Grid>
			</Box>
			<Box mb={1}>
				<CamposPlantilla plantillasTipos={plantillasTipos} />
			</Box>
			<Box mb={1} display="flex" justifyContent="flex-end">
				<Button disabled={!permisoGuardar} type="submit" variant="contained" color="primary">
					Guardar
				</Button>
			</Box>
		</Form>
	);
};

interface OpcionesTipoPlantillaProps {
	redes: any[];
}

function OpcionesTipoPlantilla({ redes }: OpcionesTipoPlantillaProps) {
	const idTipoPlantilla = useWatch({ name: "idTipoPlantilla" });

    let opcionesTipoImportacionEnvios = Object.keys(TIPOS_PLANTILLA_IMPORTACION_ENVIOS).map((key) => {
		return {
			id: TIPOS_PLANTILLA_IMPORTACION_ENVIOS[key as keyof typeof TIPOS_PLANTILLA_IMPORTACION_ENVIOS].id,
			nombre: TIPOS_PLANTILLA_IMPORTACION_ENVIOS[key as keyof typeof TIPOS_PLANTILLA_IMPORTACION_ENVIOS].nombre,
		};
	});

	return (
		<>
			{idTipoPlantilla === "1" && (
				<>
					<Grid item lg={2} xs={12}>
						<FormSelect hooks label="Tipo de P.envíos" name="tipoImportacionEnvios">
							{pintaOpcionesSelect(opcionesTipoImportacionEnvios, "id", "nombre", "selectTipoImportacionEnvios")}
						</FormSelect>
					</Grid>
					<Grid item lg={2} xs={12}>
						<FormSelect hooks label="Red" name="idRed">
							{pintaOpcionesSelect(redes, "id", "nombre", "selectRedesPlantilla")}
						</FormSelect>
					</Grid>
				</>
			)}
		</>
	);
}

export default FormularioPlantilla;
