import { useMemo } from "react";

export const useDataArray = <T> (data: any) => {

    return useMemo<T>(() => {

        var devolver = [];

        if (data && data?.data !== undefined)
        {
            devolver = data.data;
        }
        else if (data)
        {
            devolver = data;
        }

        return devolver;
    }, [data]);

}