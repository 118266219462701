import { format } from 'date-fns';
import { get } from 'lodash';
import { Controller, useFormContext } from 'react-hook-form';
import DatepickerP from '../../../generales/DatepickerP';
import { useStyles } from '../../css/css';

const FormDatepickerRHF = ({name, onAccept, clase, margin = "normal", ...props}) => {

    const { control, setValue, formState } = useFormContext();

    const { errors} = formState;

    const errores = get(errors, name);

    const classes = useStyles();

    var propsDefault = {
        name:name,
        InputProps:{
            autoComplete:'new-password', className: classes[clase] || null,
            helperText: (errores && errores.message) || '',
            error: Boolean(errores),
            margin: margin,
        },
        
    }

    const handleChange = (date, onChangeRHF) => {

        var value = format(date, 'dd/MM/yyyy', new Date());

         setValue(name, value);
 
        if (typeof onAccept === "function")
        {
            onAccept(date, value);
        }
        

    }

    return ( 
        <Controller
            name={name}
            control={control}
            defaultValue={null}
            render={({field}) => (
                <DatepickerP value={field.value} {...propsDefault} {...props} onAccept={(date) => handleChange(date, field.onChange)} />
            )}
        />
        
     )
}
 
export default FormDatepickerRHF;