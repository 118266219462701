export const getPermisos = (store) => {

    var roles = store.auth.user.roles;
    roles = Object.values(roles);   
   

    var rol=roles.filter(item => item.id == store.auth.idRol);


    if(rol && rol.length>0)
       return rol[0].permisos;
    else
       return {};

}
