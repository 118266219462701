import { Box, Button, Grid, Icon, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { ColorPicker } from '../formularios/core';
import Modal from './../../pages/utils/Modal';
import { iconos_mu } from './iconos_material';

function ModalColorIcono(props) {


    const useStyles = makeStyles((theme) => (
        {
            "icono":{             
               fontSize:"30px",             
            },
            "boxIcono":{
                "padding":"10px",
                "cursor":"pointer",
                "&:hover": {
                    backgroundColor: "#dddddd"
                }  
            },
            "boxIconoSel": {
                "padding": "10px",
                "cursor": "pointer",                
                backgroundColor: color,
                "& span":{
                    color:"white"
                }
                
                
            }
        }
    ));
    
    
    const { open, close,color,icono, setColor,setIcono } = props;

    const classes=useStyles(color);

    const [filtroIcono, setFiltroIcono] = useState("");

    const seleccionaIcono=(icono)=>{

        setIcono(icono);   
        setFiltroIcono("");    
        close();

    }

    

    return (
        <Modal maxWidth="lg" titulo={`Color e icono`} open={open} handleClose={() => { close(); setFiltroIcono(""); }}>
     
            <Grid container spacing={2}>
                <Grid item xs={11}>
                    <span>Selecciona color</span>
                    &nbsp;&nbsp;&nbsp;<ColorPicker  color={color} setColor={(c)=>setColor(c)} />  

                </Grid>
                <Grid item xs={1}>
                    <br></br>
                    <Button type="button" color="primary" onClick={(e)=>{seleccionaIcono(icono)}} style={{ float: "right" }} variant="contained">
                         Guardar
                    </Button>
                </Grid>
                <Grid item xs={11}>
                     <TextField style={{marginLeft:"20px"}} name="filtroIcono" value={filtroIcono} label="Buscar icono" onChange={(e)=>{setFiltroIcono(e.target.value)}}></TextField>
                </Grid>    
                <Grid item xs={12}>
                    <Box
                        display="flex"
                        flexWrap="wrap"
                        p={1}
                        m={1}
                    >
                     
                    {
                        iconos_mu.map((item,index)=>{

                            return(
                            (filtroIcono==="" || item.indexOf(filtroIcono)>-1)?                            
                            <Box key={`icono_${index}`} p={2} className={(item===icono)?classes.boxIconoSel:classes.boxIcono} onClick={(e)=>seleccionaIcono(item)}>                                
                                    <Icon className={classes.icono} >{item}</Icon>                              
                            </Box>
                            :null
                            )
                            

                        })

                    }
                    </Box>
                </Grid>
            </Grid>

        </Modal>
    );

}

export default React.memo(ModalColorIcono);