import { useQuery } from "@tanstack/react-query";
import { ejecutaAPI } from "app/helpers/libFunciones";
import { useDataArray } from "app/hooks/useDataArray";
import { ListadosResponse } from "app/models/types";

export interface DepartamentoClienteResponse {
  id: string;
  idCliente: string;
  nombre: string;
  nombreDireccion: string;
  personaContacto: null | string;
  telefonoContacto: null;
  emailContacto: string;
  direccion: string;
  codigoPostal: string;
  localidad: string;
  provincia: string;
  idPais: string;
  activo: string;
  codigo: null | string;
  codigoCliente: null | string;
  idMensajeroDefecto: null;
  recogerDesdeDefecto: null | string;
  recogerHastaDefecto: null | string;
  observaciones: null;
  direcciones: any[];
}

export const getDepartamentosCliente = async (idCliente: string, queryString: Record<string, any> = {}) => {

    let parametros = new URLSearchParams(queryString).toString();

    parametros = parametros ? `?${parametros}` : ''

    const { success, respuesta, msg } = await ejecutaAPI<ListadosResponse<DepartamentoClienteResponse>>("GET", `/clientes/${idCliente}/1/departamentos${parametros}`);

    if (success)
    {
        return respuesta;
    }
    else
    {
        throw new Error(msg.join(", "));
    }
};

export const useApiDepartamentosCliente = (idCliente: string, queryString: Record<string, any> = {}, optionsExtra = {}) => {
    const options = {
        ...optionsExtra,
    };

    const query = useQuery(["departamento", "cliente", idCliente], () => getDepartamentosCliente(idCliente, queryString), options);

    const departamentosCliente = useDataArray<DepartamentoClienteResponse>(query.data);

    return {
        ...query,
        departamentosCliente,
    };
};