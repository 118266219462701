import { yupResolver } from '@hookform/resolvers/yup';
import { swalError } from 'app/librerias/swal';
import { FichaPlantillaMapper } from 'app/modules/plantillas/helpers/plantillas.mapper';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import TienePermisos from '../helpers/TienePermisos';
import { plantillas as permisosPlantillas } from '../helpers/permisos';
import { schemaPlantillas } from '../pages/home/Plantillas/schemas';
import { useApiGuardarPlantilla } from './api/plantillas/useApiGuardarPlantilla';
import { useApiPlantilla } from './api/plantillas/useApiPlantilla';
import { useApiPlantillasTipos } from './api/plantillas/useApiPlantillasTipos';
import { useListadoRedes } from './api/redes/useListadoRedes';
import { useGlobalLoader } from './hooksPropios';

export const usePlantillas = (idPlantilla) => {


    const {redes, isInitialLoading: isLoadingRedes} = useListadoRedes({per_page: -1});

    const {plantillasTipos, isInitialLoading: isLoadingTipos} = useApiPlantillasTipos();

    const {data: plantilla, isInitialLoading: isLoadingPlantilla} = useApiPlantilla(idPlantilla);
    


    return {
        redes,
        plantillasTipos,
        plantilla,
        isLoading: isLoadingRedes || isLoadingTipos || isLoadingPlantilla
    }
}

export const useFormPlantilla = (plantilla, plantillasTipos) => {


    const {setGlobalCargando} = useGlobalLoader();

    const history = useHistory();

    const form = useForm({
		defaultValues: FichaPlantillaMapper.toForm(plantilla, plantillasTipos),
        resolver: yupResolver(schemaPlantillas)
	});

    const {mutate} = useApiGuardarPlantilla(plantilla?.id);

    var permisoUsar = permisosPlantillas.crear;

    if (plantilla?.id)
    {
        permisoUsar = permisosPlantillas.editar;
    }

    var permisoGuardar = TienePermisos([permisoUsar]);

    const guardarPlantilla = async (values) => {


        setGlobalCargando(true);

        let datosEnviar = FichaPlantillaMapper.toRequest(values);

        mutate(datosEnviar, {
            onSuccess: () => history.push(`/plantillas`),
            onError: () => {
                let msgError = 'Se ha producido un error guardando la plantilla';

                swalError(msgError);
            },
            onSettled: () => setGlobalCargando(false)
        })

    }

    return {
        form,
        schemaPlantillas,
        permisoGuardar,
        guardarPlantilla

    }

}