import { swalError } from 'app/librerias/swal';
import { isEmpty, last } from 'lodash';
import React, { useContext, useEffect, useReducer, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import TienePermisos from '../helpers/TienePermisos';
import { copiaObjeto, ejecutaAPI, isCanvasEmpty } from '../helpers/libFunciones';
import { envios } from '../helpers/permisos';
import { schemaRecogidaMensajero, schemaRetasacion } from '../pages/home/mensajero/recogidas/schemas';
import { useGlobalLoader } from './hooksPropios';



export const FichaRecogidaMensajeroContext = React.createContext();

const recogidaReducer = (state, action) => {

    switch (action.type) {
        case 'CARGANDO_INIT': 
            return {...state, cargando: true};
        case 'SET_RECOGIDA':

            var estadoActual = '';

            if (!isEmpty(action.payload.estados))
            {
                var ultimoEstado = last(action.payload.estados);

                if (!isEmpty(ultimoEstado))
                {
                    estadoActual = ultimoEstado.codigo;
                }
            }

            return {...state, cargando: false, datosRecogida: action.payload, imagenes: action.payload.imagenes, estadoRecogida: estadoActual}
        case 'SET_ENVIOS_RECOGIDA':
            return {...state, enviosRecogida: action.payload}
        case 'SET_PAGOS':
            return { ...state, datosRecogida: { ...state.datosRecogida, pagos: action.payload } }
        case 'SET_ENVIO_COMPROBADO':

            var nuevoArray = [...state.enviosRecogida];

            var indiceBuscar = nuevoArray.findIndex(envio => envio.id === action.payload.id);

            if (indiceBuscar !== -1)
            {
                nuevoArray[indiceBuscar].comprobado = "1";
            }


            return {...state, enviosRecogida: nuevoArray}
        case 'SET_ENVIO_SELECCIONADO': 
            return {...state, envioSeleccionado: action.payload, cargandoModal: false}
        case 'SET_FICHEROS_RECOGIDA':
            return {...state, imagenes: action.payload}
        case 'SET_ERROR': 
            return {...state, cargando: false, errores: {...state.errores, error: true, msg: action.payload}}
        case 'SET_TIPOS_INCIDENCIA':
            return {...state, tiposIncidencia: action.payload}
        case 'CARGANDO_MODAL':
            return {...state, cargandoModal: true}
        case 'SET_ERROR_MODAL':
            return {...state, cargandoModal: false, erroresModal: {...state.erroresModal, error: true, msg: action.payload}}
        default:
            throw new Error();
      }
}


export const FichaRecogidaMensajeroProvider = ({children}) => {

    const [recogida, dispatch] = useReducer(recogidaReducer, {

        datosRecogida: {},
        enviosRecogida: {},
        envioSeleccionado: {},
        imagenes: [],
        tiposIncidencia: [],
        estadoRecogida: '',
        cargando: true,
        errores: {error: false, msg: ""},
        cargandoModal: true,
        erroresModal: {error: false, msg: ""}
        
    });

    const refFirma = useRef();

    return (
        <FichaRecogidaMensajeroContext.Provider value={[recogida, dispatch, refFirma]}>
            {children}
        </FichaRecogidaMensajeroContext.Provider>
    )
}

export const useFichaRecogidaMensajero = (id) => {

    const [recogida, dispatch] = useContext(FichaRecogidaMensajeroContext);



    useEffect(() => {

        (async () => {


            dispatch({type: 'CARGANDO_INIT'});

            const datosRecogidaAPI = await ejecutaAPI('GET', `/recogidas/${id}`);

            if (datosRecogidaAPI.success)
            {
                if (TienePermisos([envios.ver]))
                {
                    const datosEnviosRecogidaAPI = await ejecutaAPI('GET', `/envios?per_page=-1&idRecogida=${id}&anulado=0`);

                    if (datosEnviosRecogidaAPI.success)
                    {
                        dispatch({type: 'SET_ENVIOS_RECOGIDA', payload: datosEnviosRecogidaAPI.respuesta.data});
                    }
                }

                dispatch({type: 'SET_RECOGIDA', payload: datosRecogidaAPI.respuesta});
            }
            else 
            {
                dispatch({type: 'SET_ERROR', payload: 'Se ha producido un error obteniendo los datos de la recogida'});
            }

        })(); 

  
        (async () => {
            
            const tiposIncidenciaAPI = await ejecutaAPI('GET', '/recogidas/tipos_incidencia');

            if (tiposIncidenciaAPI.success)
            {

                dispatch({type: 'SET_TIPOS_INCIDENCIA', payload: tiposIncidenciaAPI.respuesta});

            }



        })();

    }, [id, dispatch]);

    return {
        ...recogida,
        dispatch,

    }

}

export const useFichaRecogidaMensajeroContext = () => {

    var [recogida, dispatch, refFirma] = useContext(FichaRecogidaMensajeroContext);

    const {setGlobalCargando} = useGlobalLoader();

    const history = useHistory();

    const subirFirma = async (canvas) => {


        return new Promise((resolve, reject) => {

            let isEmpty = isCanvasEmpty(canvas);

            if (isEmpty)
            {
                resolve(null);
            }
            else 
            {
                canvas.toBlob(async (blob) => {

                    const datosForm = new FormData();

                    datosForm.append('fichero', blob, 'firma.png')

                    var resultado = await ejecutaAPI('POST', '/ficheros', datosForm);

                    if (resultado.success)
                    {
                        resolve(resultado.respuesta.id);
                    }
                    else 
                    {
                        reject(new Error('Se ha producido un error subiendo la firma'));
                    }

                });
            }
        });
    }
    
    const handleSubmit =  (values) => {

        //Revisar si todos los envíos están comprobados

        const guardarRecogida = async () => {

            var canvas = refFirma.current.getCanvas();

            setGlobalCargando(true);

            try 
            {
        
                const idFicheroFirma = await subirFirma(canvas);
            
                var datosEnviar = copiaObjeto(values);

                datosEnviar.idFicheroFirma = idFicheroFirma;

                datosEnviar.imagenes = recogida.imagenes.map(imagen => imagen.id);

                var resultadoAPIRecogida = await ejecutaAPI('PUT', `/recogidas/${recogida.datosRecogida.id}`, datosEnviar);
                
                if (resultadoAPIRecogida.success)
                {

                    var datosEstado = {
                        estado: "recogido"
                    }
        
                    var resultadoEstado = await ejecutaAPI('POST', `/recogidas/${recogida.datosRecogida.id}/estados`, datosEstado);

                    if (resultadoEstado.success)
                    {
                        
                        resultadoEstado.respuesta.codigo = datosEstado.estado;

                        resultadoAPIRecogida.respuesta.estados.push(resultadoEstado.respuesta);



                        //dispatch({type: 'SET_RECOGIDA', payload: resultadoAPIRecogida.respuesta});

                        history.push('/mensajero/trafico');

                        
                    }
                    else 
                    {
                        Swal.fire({
                            title: 'Error',
                            text: 'Se ha producido un error',
                            icon: 'error'
                        });
                    }

                }
                else 
                {
                    Swal.fire({
                        title: 'Error',
                        text: 'No se ha podido guardar los datos de la recogida',
                        icon: 'error'
                    });
                }

            }
            catch(error)
            {
                swalError('Error', error.message);
            }

            setGlobalCargando(false);
        }

        var enviosPorComprobar = recogida.enviosRecogida.some(envio => envio.comprobado !== "1");

        if (enviosPorComprobar)
        {
            Swal.fire({
                title: 'Hay envios sin comprobar',
                text: 'Hay envíos sin comprobar, ¿Continuar igualmente?',
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Continuar'

            }).then((result) =>{

                if (result.value)
                {
                    guardarRecogida();
                }

            });
        }
        else 
        {
            guardarRecogida();
        }

    }

    const handleSubmitRetasacion = async (values) => {

        
        var datosEnviar = copiaObjeto(values);

        datosEnviar.comprobado = true;

        datosEnviar.transmitir = "0";

        setGlobalCargando(true);

        var resultado = await ejecutaAPI('PUT', `/envios/${recogida.envioSeleccionado.id}`, datosEnviar);
        

        if (resultado.success)
        {

            setGlobalCargando(false);


            dispatch({type: 'SET_ENVIO_COMPROBADO', payload: {id: recogida.envioSeleccionado.id}});
        }
        else 
        {
            Swal.fire({
                title: 'Error',
                text: 'Se ha producido un error durante la comprobación',
                icon: 'error',
                confirmButtonText: 'Aceptar'

            });

            setGlobalCargando(false);
        }

        

    }

    const cargaEnvio = async (idEnvio) => {

        dispatch({type: 'CARGANDO_MODAL'});

        var datosEnvioAPI = await ejecutaAPI('GET', `/envios/${idEnvio}`);

        if (datosEnvioAPI.success)
        {
            var datosEnvio = datosEnvioAPI.respuesta;

            dispatch({type: 'SET_ENVIO_SELECCIONADO', payload: datosEnvio});

        }
        else 
        {
            dispatch({type: 'SET_ERROR_MODAL', payload: 'Se ha producido un error obteniendo los datos del envío'});
        }

    }

    const handleSetFicheros = (ficheros) => {

        dispatch({type: 'SET_FICHEROS_RECOGIDA', payload: ficheros});

    }




    return {
        ...recogida,
        dispatch,
        refFirma,
        schemaRecogidaMensajero,
        schemaRetasacion,
        cargaEnvio,
        handleSetFicheros,
        handleSubmitRetasacion,
        handleSubmit
    }
}