import { Button } from "@mui/material";

const BotonCancelar = ({children, ...props}) => {

    return (

            <Button type="button" variant="contained" color="default" {...props}>
                {children}
            </Button>
    )
}

export default BotonCancelar;