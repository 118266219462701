import { FicheroLecturas } from "./FicheroLecturas.class";

export class FicheroLecturasGeneral extends FicheroLecturas {

    generarContenido() {
      
      let devolver = '';

      let envios = this.envios;

      if (envios.length === 0) 
      {
          throw new Error('No se han filtrado envíos');
      }

      envios.forEach(envio => {

          envio.bultosEstadosRed.forEach(bulto => {

              let codigoSeguimiento = this.procesarCodigoBulto(envio, bulto);
              
              devolver += codigoSeguimiento + "\r\n";
          })
      });

      return devolver;

    }


}

