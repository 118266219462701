import TimepickerP from "app/componentes/generales/TimepickerP";
import { format, parse } from "date-fns";
import { useField } from "formik";
import { useState } from "react";

export const FormTimepickerFormik = ({ name, onChange = null, margin = 'normal', ...props }) => {
    const [campo, infoCampo, eventos] = useField(name);
    const [error, setError] = useState(false);

    const handleError = () => {
        if (error)
        {
            return true;
        }
        else
        {
            return Boolean(infoCampo.touched && infoCampo.error);
        }
    };

    const handleHelperText = () => {
        if (error)
        {
            return "Formato incorrecto";
        }
        else
        {
            return infoCampo.touched && infoCampo.error;
        }
    };

    var value = null;

    if (campo.value)
    {
        var valorParsear = campo.value.split(":");

        valorParsear = `${valorParsear[0]}:${valorParsear[1]}`;

        value = parse(valorParsear, "HH:mm", new Date());
    }

    var propsDefault = {
        name: name,
        value: value ?? null,
        onChange: (date, error) => {

            if (error.validationError === null)
            {
                setError(false);
                
                let string = null;

                if (date !== null)
                {
                    string = format(date, "HH:mm");
                }

                eventos.setValue(string);

                if (typeof onChange === "function")
                {
                    onChange(date, campo.value);
                }
            }
            else 
            {
                setError(true);
            }
        },
        InputProps: {
            helperText: handleHelperText(),
            error: handleError(),
            margin: margin
        },
    };

    return <TimepickerP {...propsDefault} {...props} />;
};
