import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "./ducks/auth.duck";
import * as users from "./ducks/users.duck";
import * as redes from "./ducks/redes.duck";
import * as clientes from "./ducks/clientes.duck";
import * as tarifas from "./ducks/tarifas.duck";
import * as loader from "./ducks/loader.duck";
import * as trafico from "./ducks/trafico.duck";

import { metronic } from "../../_metronic";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  users:users.reducer,
  redes: redes.reducer,
  clientes: clientes.reducer,
  tarifas: tarifas.reducer,
  trafico:trafico.reducer,
  loader: loader.reducer,
  i18n: metronic.i18n.reducer,
  builder: metronic.builder.reducer
});

export function* rootSaga() {
  yield all([auth.saga(),users.saga(),redes.saga(),clientes.saga()]);
}
