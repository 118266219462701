export const setObjetoParaObtenerDatosComisiones = (listadoTrafico) => {

    var devolver = [];

    if (listadoTrafico.length > 0)
    {
        devolver = listadoTrafico.map(item => ({id: item.id, tipo: item.tipo}));
    }

    return devolver;
}

export const calcularPorcentajeComision = (precioTotal = 0, comisionEuros = 0) => {

    return precioTotal > 0 ? ((comisionEuros * 100) / precioTotal).toFixed(1) : 0;

}

export const calcularComisionDesdePorcentaje  = (precioTotal = 0, comisionPorcentaje = 0) => {


    return ((comisionPorcentaje * precioTotal) / 100).toFixed(2);
}