import { format } from "date-fns";
import { useField } from "formik";
import DatepickerP from "../../../generales/DatepickerP";
import { useStyles } from "../../css/css";

const FormDatepickerFormik = ({ hooks, name, onAccept, clase, margin = "normal", ...props }) => {
    const [campo, infoCampo, eventos] = useField(name);

    const classes = useStyles();

    var propsDefault = {
        name: name,
        value: campo.value || null,
        InputProps: {
            autoComplete: "new-password",
            className: classes[clase] || null,
            helperText: infoCampo.touched && infoCampo.error,
            error: Boolean(infoCampo.touched && infoCampo.error),
            margin: margin,
        },
        onAccept: (date) => {
            eventos.setValue(format(date, "dd/MM/yyyy", new Date()));

            if (typeof onAccept === "function")
            {
                onAccept(date, campo.value);
            }
        },
    };

    return <DatepickerP {...propsDefault} {...props} />;
};

export default FormDatepickerFormik;
