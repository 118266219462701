
import { useMutation } from "@tanstack/react-query";
import { ejecutaAPI } from "../../../helpers/libFunciones";

export const recalcularComisiones = async (values) => {

    const {success, respuesta, error} = await ejecutaAPI('PUT', `/trafico/comisiones`, values);

    if (success)
    {
        return respuesta;
    }
    else 
    {
        throw new Error(error);
    }

}

export const useRecalcularComisiones = () => {

    //const queryClient = useQueryClient();


    return useMutation((values) => recalcularComisiones(values),
    {
        onSuccess: () => {

           // queryClient.invalidateQueries(['impuestos']);

        }
    });
}