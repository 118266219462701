import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ejecutaAPI } from "app/helpers/libFunciones";

async function borrarRegistro(url: string) {
	const { success, msg } = await ejecutaAPI("DELETE", url);

	if (success) {
		return true;
	} else {
		let mensaje = Array.isArray(msg) ? msg.join(", ") : "Se ha producido un error borrando el registro";

		throw new Error(mensaje);
	}
}

export const useEliminarRegistro = (url: string, invalidationKeys: string[][] | string[]) => {


    const queryClient = useQueryClient();

	return useMutation<any, Error, any, unknown>(() => borrarRegistro(url), {
		onSuccess: () => {

			if (Array.isArray(invalidationKeys[0]))
			{
				(invalidationKeys as string[][]).forEach((keys) => {
					queryClient.invalidateQueries(keys);
				});
			}
			else 
			{
				queryClient.invalidateQueries(invalidationKeys as string[]);
			}

		},
	});


}