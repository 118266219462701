import React from 'react';
import { FormTextInputFormik } from './Librerias/Formik/FormTextInputFormik';
import FormTextInputRHF from './Librerias/ReactHookForm/FormTextInputRHF';

export const FormTextInput = (props) => {

    const { hooks, ...otherProps } = props;


    return (
        !hooks ? 
        (
            <FormTextInputFormik {...otherProps} />
        )
        :
        (
            <FormTextInputRHF {...otherProps} />
        )
    )
    
};

