import { capitalize } from 'lodash';
import moment from 'moment';
import 'moment/locale/es';
import Api from './../crud/Api';


 export const SelectPaises=async ()=>{

    let arr={};


    if (localStorage.getItem("paises")) {
        arr = JSON.parse(localStorage.getItem("paises"));

        return new Promise((resolve)=>{
            resolve(arr);
        });

    } else {

            let response= await Api("get", "localizaciones/paises");

            return new Promise((resolve) => {

                if(response.data){
                    let data=response.data;
                    
                    if (data.success === true) {

                        let dat = Object.values(data.data);
                        localStorage.setItem("paises", JSON.stringify(dat));
                        arr = dat;

                        resolve(arr);
                       

                    }else{
                        resolve([]);
                    }

                }

          
            });

    }   

}


export const SelectProvincias = async () => {

    let arr = {};


    if (localStorage.getItem("provincias")) {
        arr = JSON.parse(localStorage.getItem("provincias"));

        return new Promise((resolve) => {
            resolve(arr);
        });

    } else {

        let response = await Api("get", "localizaciones/provincias");

        return new Promise((resolve) => {

            if (response.data) {
                let data = response.data;

                if (data.success === true) {

                    let dat = Object.values(data.data);
                    localStorage.setItem("provincias", JSON.stringify(dat));
                    arr = dat;

                    resolve(arr);


                } else {
                    resolve([]);
                }

            }


        });

    }

}


export const paisDefecto = { "id": "214", "pais": "España", "alpha2Code": "", "alpha3Code": "", "codigoNumerico": "", "bandera": "" };


/* Categorias / Configuracion */
export const SelectTiposCategorias = async () => {

    let arr = {};

    if (localStorage.getItem("categorias_config")) {
        arr = JSON.parse(localStorage.getItem("categorias_config"));

        return new Promise((resolve) => {
            resolve(arr);
        });

    } else {

        let response = await Api("get", "categorias/tipos");

        return new Promise((resolve) => {

            if (response.data) {
                let data = response.data;

                if (data.success === true) {
                

                    let dat = Object.values(data.data);
                    localStorage.setItem("categorias_config", JSON.stringify(dat));
                    arr = dat;

                    resolve(arr);


                } else {
                    resolve([]);
                }

            }


        });

    }

}


export const SelectRedes= async () => {

    let arr = [];

    let save = moment(JSON.parse(sessionStorage.getItem("redes_time")));

    if (sessionStorage.getItem("redes") && parseInt(moment().format('X') - save.format('X')) < 120) {
        arr = JSON.parse(sessionStorage.getItem("redes"));

        return new Promise((resolve) => {
            resolve(arr);
        });

    } else {

        let response = await Api("get", "redes?per_page=-1");

        return new Promise((resolve) => {


            if (response.data) {
                let data = response.data;

                if (data.success === true) {

                    let dat = Object.values(data.data.data);
                    sessionStorage.setItem("redes", JSON.stringify(dat));
                    sessionStorage.setItem("redes_time", JSON.stringify(new Date()));
                    arr = dat;

                    resolve(arr);


                } else {
                    resolve([]);
                }

            }


        });

    }

}





export const SelectRedesServiciosTipo = async() => {

    let arr = {};


    if (sessionStorage.getItem("servicios_tipos")!=null) {
        arr = JSON.parse(sessionStorage.getItem("servicios_tipos"));
        
        return new Promise((resolve) => {
            resolve(arr);
        });

    } else {

    
            let response = await Api("get", "servicios/tipos");

            return new Promise((resolve) => {

                if (response.data) {
                    let data = response.data;

                    if (data.success === true) {

                        let dat = Object.values(data.data);
                        sessionStorage.setItem("servicios_tipos", JSON.stringify(dat));
                        arr = dat;

                        resolve(arr);


                    }else{
                        resolve([]);
                    }

                }


            });         
            
        
    }

    

}





export const SelectRedesServiciosModalidad= async() => {

    let arr = [];


    if (sessionStorage.getItem("servicios_modalidad")) {
        arr = JSON.parse(sessionStorage.getItem("servicios_modalidad"));
        
        return new Promise((resolve) => {
            resolve(arr);
        });

    } else {

      
            let response = await Api("get", "servicios/modalidades");

            return new Promise((resolve) => {

                if (response.data) {
                    let data = response.data;

                    if (data.success === true) {

                        let dat = Object.values(data.data);
                        sessionStorage.setItem("servicios_modalidad", JSON.stringify(dat));
                        arr = dat;

                        resolve(arr);


                    }else{
                        resolve([]);
                    }

                }


            });   
            
        

        
    }


}



export const SelectRedesServiciosReferenciasTipos = async () => {

    let arr = [];


    if (localStorage.getItem("servicios_referencias_tipos")) {
        arr = JSON.parse(localStorage.getItem("servicios_referencias_tipos"));

        return new Promise((resolve) => {
            resolve(arr);
        });

    } else {


        let response = await Api("get", "servicios/referencias/tipos");

        return new Promise((resolve) => {

            if (response.data) {
                let data = response.data;

                if (data.success === true) {

                    let dat = Object.values(data.data);
                    localStorage.setItem("servicios_referencias_tipos", JSON.stringify(dat));
                    arr = dat;

                    resolve(arr);


                } else {
                    resolve([]);
                }

            }


        });




    }

  

}



export const SelectRedesMaestros= async (idRed) => {

    let arr = {};

    if(idRed>0){

    
            let response = await Api("get", `redes/${idRed}/serviciosMaestros?per_page=-1`);

            return new Promise((resolve) => {

                if (response.data) {
                    let data = response.data;

                    if (data.success === true) {

                        arr = Object.values(data.data.data); 

                        resolve(arr);


                    } else {
                        resolve([]);
                    }

                }


            });   

        
    } 
    

   
}


export const SelectRedesRutas = async (idRed) => {

    let arr = {};

    if (idRed > 0) {


        let response = await Api("get", `redes/${idRed}/rutas?per_page=-1`);

        return new Promise((resolve) => {

            if (response.data) {
                let data = response.data;

                if (data.success === true) {

                    arr = Object.values(data.data.data);

                    resolve(arr);


                } else {
                    resolve([]);
                }

            }


        });


    }


}




export const SelectRedesServicios = async (idRed) => {

    let arr = {};

    if (idRed > 0) {


        let response = await Api("get", `redes/${idRed}/servicios?per_page=-1`);

        return new Promise((resolve) => {

            if (response.data) {
                let data = response.data;

                if (data.success === true) {

                    arr = Object.values(data.data.data);

                    resolve(arr);


                } else {
                    resolve([]);
                }

            }


        });


    }


}



export const SelectRedesVariables = async (idRed) => {

    let arr = {};


    if (idRed > 0) {


        let response = await Api("get", `redes/${idRed}/variables?per_page=-1`);

        return new Promise((resolve) => {

            if (response.data) {
                let data = response.data;

                if (data.success === true) {

                    arr = Object.values(data.data.data);                    
                    resolve(arr);


                } else {
                    resolve([]);
                }

            }


        });


    }




}



/* select de clientes */
export const SelectClientes = async () => {

    let arr = [];

    
        let response = await Api("get", "clientes?per_page=-1&simplificado=1&activo=1");

        return new Promise((resolve) => {

            if (response.data) {
                let data = response.data;

                if (data.success === true) {

                    let dat = Object.values(data.data.data);
                    arr = dat;

                    resolve(arr);


                } else {
                    resolve([]);
                }

            }


        });

    

}



/* select de empresas (API FACTURACION) */
export const SelectEmpresas = async () => {

    let arr = [];

    let save = moment(JSON.parse(sessionStorage.getItem("empresas_time")));


    if (sessionStorage.getItem("empresas") && parseInt(moment().format('X') - save.format('X')) < 120) {
        arr = JSON.parse(sessionStorage.getItem("empresas"));

        return new Promise((resolve) => {
            resolve(arr);
        });

    } else {


        let response = await Api("get", "empresas?per_page=-1");

        return new Promise((resolve) => {

            if (response.data) {
                let data = response.data;

                if (data.success === true) {
                  
                    let dat = Object.values(data.data);
                    sessionStorage.setItem("empresas", JSON.stringify(dat));
                    sessionStorage.setItem("empresas_time", JSON.stringify(new Date()));
                    arr = dat;

                    resolve(arr);


                } else {
                    resolve([]);
                }

            }


        });




    }


}


/* tipos servicio envio */
export const SelectMensajeros= async () => {

    let arr = [];

    let save = moment(JSON.parse(sessionStorage.getItem("mensajeros_time")));

    if (sessionStorage.getItem("mensajeros") && parseInt(moment().format('X') - save.format('X')) < 120) {
        arr = JSON.parse(sessionStorage.getItem("mensajeros"));

        return new Promise((resolve) => {
            resolve(arr);
        });

    } else {


        
     
        let response = await Api("get", "users?activo=1&per_page=-1&roles[]=4");

        return new Promise((resolve) => {

            if (response.data) {
                let data = response.data;

                if (data.success === true) {
                                        
                    let dat = Object.values(data.data.data);
                    
                    //Ordenamos por nombre
                    dat.sort((a, b) => a.nombre?.localeCompare(b.nombre));

                    sessionStorage.setItem("mensajeros", JSON.stringify(dat));
                    sessionStorage.setItem("mensajeros_time", JSON.stringify(new Date())); 
                    arr = dat;

                    resolve(arr);


                } else {
                    resolve([]);
                }

            }


        });


    }


}



/* tipos servicio envio */
export const SelectServiciosTipo = async () => {

    let arr = [];

    let save = moment(JSON.parse(sessionStorage.getItem("servicios_tipos_time")));

    if (sessionStorage.getItem("servicios_tipos") && parseInt(moment().format('X') - save.format('X')) < 3600) {
        arr = JSON.parse(sessionStorage.getItem("servicios_tipos"));

        return new Promise((resolve) => {
            resolve(arr);
        });

    } else {


        let response = await Api("get", "servicios/tipos");

        return new Promise((resolve) => {

            if (response.data) {
                let data = response.data;

                if (data.success === true) {

                    let dat = Object.values(data.data);
                    sessionStorage.setItem("servicios_tipos", JSON.stringify(dat));
                    sessionStorage.setItem("servicios_tipos_time", JSON.stringify(new Date()));
                    arr = dat;

                    resolve(arr);


                } else {
                    resolve([]);
                }

            }


        });


    }


}



/* tipos servicio envio */
export const SelectDelegacionesRed = async (red) => {

    let arr = [];

      
    let save = moment(JSON.parse(sessionStorage.getItem("delegaciones_red_time_"+red)));

    if (sessionStorage.getItem("delegaciones_red_"+red) && parseInt(moment().format('X') - save.format('X')) < 120) {
        arr = JSON.parse(sessionStorage.getItem("delegaciones_red_"+red));

        return new Promise((resolve) => {
            resolve(arr);
        });

    } else {


        let response = await Api("get", `redes/${red}/delegaciones?per_page=-1`);

        return new Promise((resolve) => {

            if (response.data) {
                let data = response.data;

                if (data.success === true) {

                    let dat = Object.values(data.data.data);
                    sessionStorage.setItem("delegaciones_red_"+red, JSON.stringify(dat));
                    sessionStorage.setItem("delegaciones_red_time_"+red, JSON.stringify(new Date()));
                    arr = dat;

                    resolve(arr);


                } else {
                    resolve([]);
                }

            }


        });


    }


}



/* tipos servicio envio */
export const SelectServiciosRed_Cache = async (red) => {

    let arr = [];


   
        let response = await Api("get", `redes/${red}/servicios?per_page=-1`);

        return new Promise((resolve) => {

            if (response.data) {
                let data = response.data;

                if (data.success === true) {

                    let dat = Object.values(data.data.data);
                    arr=dat;
                    resolve(arr);


                } else {
                    resolve([]);
                }

            }


        });


    


}




/* tipos servicio envio */
export const SelectEstadosTrafico = async () => {

    let arr = [];


    let save = moment(JSON.parse(sessionStorage.getItem("estados_trafico_time")));

    if (sessionStorage.getItem("estados_trafico") && parseInt(moment().format('X') - save.format('X')) < 300) {
        arr = JSON.parse(sessionStorage.getItem("estados_trafico"));

        return new Promise((resolve) => {
            resolve(arr);
        });

    } else {
        

        let response = await Api("GET", `trafico/estados?per_page=-1`);

        return new Promise((resolve) => {

            if (response.data) {
                let data = response.data;

                if (data.success === true) {

                    let dat = Object.values(data.data.data);
                    sessionStorage.setItem("estados_trafico", JSON.stringify(dat));
                    sessionStorage.setItem("estados_trafico_time", JSON.stringify(new Date()));
                    arr = dat;

                    resolve(arr);


                } else {
                    resolve([]);
                }

            }


        });


    }


}




/*Para el select de las fechas */
export const options_FECHAS = () => {

    moment.locale('es');
    let hoy = moment();
    let opc = [];

    let mes = capitalize(moment().format("MMMM"));
    let mes_pasado = capitalize(moment().subtract(1, 'month').format("MMMM"));

    opc.push({ nombre: `Hoy (${hoy.format("L")})`, value:"hoy"  });
    opc.push({ nombre: `Ayer (${hoy.subtract(1, 'days').format("L")})`, value: `ayer` });
    opc.push({ nombre: `Esta semana`, value: `semana` });
    opc.push({ nombre: `Semana pasada`, value: `semana_pasada` });
    opc.push({ nombre: `Este mes (${mes})`, value: `mes` });
    opc.push({ nombre: `Mes pasado (${mes_pasado})`, value: `mes_pasado` });


    return opc;
}

export const calculaFechas=(opcion)=>{

    let fec=moment();
    let fechaDesde='';
    let fechaHasta='';

    switch (opcion) {

        default:
        case "hoy":
            fechaDesde = fec.format("YYYY-MM-DD");
            fechaHasta = fechaDesde;
            break;            

        case "ayer":
            fechaDesde=fec.subtract(1, "days").format("YYYY-MM-DD");
            fechaHasta = fechaDesde
            break;

        case "semana":
            fechaDesde = fec.format("YYYY") + "-" + fec.format("MM") + "-"+fec.startOf('week').format("DD");
            fechaHasta = fec.format("YYYY") + "-" + fec.format("MM") + "-" + fec.endOf('week').format("DD");
        break;

        case "semana_pasada":
            fechaDesde = fec.subtract(1,"weeks").format("YYYY") + "-" + fec.format("MM") + "-" + fec.startOf('week').format("DD");
            fechaHasta = fec.format("YYYY") + "-" + fec.format("MM") + "-" + fec.endOf('week').format("DD");
        break;

        case "mes":
            fechaDesde = fec.format("YYYY") + "-" + fec.format("MM")+"-01";
            fechaHasta = fec.format("YYYY") + "-" + fec.format("MM") + "-" + fec.endOf('month').format("DD");
        break;

        case "mes_pasado":
            fechaDesde = fec.subtract(1, "months").format("YYYY") + "-" + fec.format("MM") + "-01";
            fechaHasta = fec.format("YYYY") + "-" + fec.format("MM") + "-" + fec.endOf('month').format("DD");
         break;

            
        
    }

    return { fechaDesde, fechaHasta }             


}




/* Videos de ayuda */
export const SelectVideos = async () => {

    let arr = [];

    let save = moment(JSON.parse(localStorage.getItem("videos_time")));

    if (localStorage.getItem("videos") && parseInt(moment().format('X') - save.format('X')) < 300) {
        arr = JSON.parse(localStorage.getItem("videos"));

        return new Promise((resolve) => {
            resolve(arr);
        });

    } else {

        let response = await Api("get", "videos?per_page=-1");

        return new Promise((resolve) => {

     
            if (response.data) {
                let data = response.data;

                if (data.success === true) {

                    let dat = Object.values(data.data);
                    localStorage.setItem("videos", JSON.stringify(dat));
                    localStorage.setItem("videos_time", JSON.stringify(new Date()));
                    arr = dat;

                    resolve(arr);


                } else {
                    resolve([]);
                }

            }


        });

    }

}




/* Tipos IVA */
export const SelectTiposIVA = async () => {

    let arr = [];

    let save = moment(JSON.parse(localStorage.getItem("tiposIVA_time")));

    if (localStorage.getItem("tiposIVA") && parseInt(moment().format('X') - save.format('X')) < 3600) {
        arr = JSON.parse(localStorage.getItem("tiposIVA"));

        return new Promise((resolve) => {
            resolve(arr);
        });

    } else {

        let response = await Api("GET", "tiposIVA");

        return new Promise((resolve) => {


            if (response.data) {
                let data = response.data;

                if (data.success === true) {

                    let dat = Object.values(data.data);
                    localStorage.setItem("tiposIVA", JSON.stringify(dat));
                    localStorage.setItem("tiposIVA_time", JSON.stringify(new Date()));
                    arr = dat;

                    resolve(arr);


                } else {
                    resolve([]);
                }

            }


        });

    }

}




const selects = [];

selects["activo"] = [];
selects["activo"][1] = "Activo";
selects["activo"][0] = "Desactivado";

selects["activoSINO"] = [];
selects["activoSINO"][1] = "Si";
selects["activoSINO"][0] = "No";


selects["habilitado"] = [];
selects["habilitado"][1] = "Habilitado";
selects["habilitado"][0] = "Deshabilitado";

export  default selects;