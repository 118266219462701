import { Box } from "@mui/material";
import Cargando from "app/componentes/avisos/Cargando";
import TabMaterial, { TabPanelMaterial } from "app/componentes/tabs/TabMaterial";
import { roles } from "app/helpers/roles";
import FormularioFichaUsuario from "app/modules/usuarios/components/ficha/FormularioFichaUsuario";
import PermisosUsuario from "app/modules/usuarios/components/ficha/PermisosUsuario";
import { useFichaUsuario } from "app/modules/usuarios/hooks/useFichaUsuario";
import { Portlet, PortletBody, PortletHeader } from "app/partials/content/Portlet";
import { useParams } from "react-router-dom";

interface FichaUsuarioPageParams {
	id: string;
}

export default function FichaUsuarioPage() {
	let tabs = [{ label: "Usuario", value: "usuario" }];

	const { id } = useParams<FichaUsuarioPageParams>();

	const { isLoading, guardarUsuario, usuarioForm, paises, clientes, datosUsuario } = useFichaUsuario(id);

	datosUsuario?.roles.forEach((rol) => {
		if (![roles.ADMIN, roles.MULTILOGIN].includes(parseInt(rol.id))) {
			tabs.push({ label: rol.nombre, value: `rol_${rol.id}` });
		}
	});

	return (
		<Portlet>
			<PortletHeader title="Ficha Usuario" goBack="/config/users"></PortletHeader>
			<PortletBody>
				<Box flexGrow="1" width="100%" bgcolor={"theme.palette.background.paper"}>
					<Cargando activo={isLoading}>
						<TabMaterial tabs={tabs}>
							<TabPanelMaterial value="usuario">
								<FormularioFichaUsuario
									id={id}
									formValues={usuarioForm}
									guardarUsuario={guardarUsuario}
									paises={paises}
									clientes={clientes}
								/>
							</TabPanelMaterial>
							{datosUsuario && datosUsuario.roles.length > 0
								? datosUsuario.roles.map((rol) => (
										<TabPanelMaterial value={`rol_${rol.id}`}>
											<PermisosUsuario usuario={datosUsuario} rol={rol} />
										</TabPanelMaterial>
								  ))
								: null}
						</TabMaterial>
					</Cargando>
				</Box>
			</PortletBody>
		</Portlet>
	);
}
