import { Icon } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { useRef, useState } from "react";
import ReactPlayer from 'react-player/lazy';
import Modal from '../../utils/Modal';



const useStyles = makeStyles((theme) => (
    {
        card: {
            fontSize: "15px",
            textAlign: "center",
            display: "block",
            padding:"10px 10px 10px 10px",
            boderRadius: "5px 5px 5px 5px",    
            marginBottom:"10px",                      
            "& .label": {
                marginTop: "10px",
                marginBottom: "10px",
                fontWeight: "500",
                textAlign:"left",
                fontSize:"16px"
            },
            "& span.material-icons":{
                cursor:"pointer",
                marginRight:"20px"
            },
            "& span.material-icons:hover": {
                color:"black"
            }
            
        }
    }
));


export default function VideoAyuda(props) {

    const classes = useStyles();

    const { titulo, codigo, plataforma } = props;

    const player=useRef();

    var url="";

    const [playing,setPlaying]=useState(false);
    const [open,setOpen]=useState(false);


    const handlePlayPause = () => {
       setPlaying(!playing);
    }
    
    switch(plataforma){

        case "vimeo":
            url = `https://vimeo.com/${codigo}`;
        break;

        case "youtube":
            url = `https://www.youtube.com/watch?v=${codigo}`;
            break;


        default: break;
    }

        return (

               <div className={classes.card}>
                <div className="label" >{titulo}</div>    
                  <div >
                    <ReactPlayer 
                        ref={player}
                        url={url}  
                        playing={playing}   
                        controls={false}                             
                        width="300px"                                       
                        height="200px"
                                           
                     />
                     <div style={{textAlign:"left",marginTop:"20px"}}>
                        {playing ? <Icon onClick={handlePlayPause} style={{color:'green'}} className="fa fa-pause"></Icon> : <Icon onClick={handlePlayPause} className="fa fa-play"></Icon>}                        
                        <Icon onClick={() => { setOpen(true); setPlaying(false); }} className="fa fa-expand"></Icon>
                    </div>  
                 </div>                

                <Modal titulo={titulo} open={open} style={{height:"600px"}} handleClose={() => { setOpen(false) }}>
                    <ReactPlayer                        
                        url={url}
                        playing={true}
                        controls={true}
                        width="100%"
                        height="600px"
                    />
                </Modal>

            </div> 

            
    );
}

