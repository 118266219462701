import { useModalContext, useModalContextAction } from 'app/contexts/useModalContext';
import Modal from 'app/pages/utils/Modal';
import { Suspense, lazy } from 'react';

const EtiquetarElemento = lazy(() => import('app/modules/etiquetas/components/modales/EtiquetarElemento/EtiquetarElemento'));
const FichaGenerarManifiesto = lazy(() => import('app/modules/trafico/components/manifiesto/FichaGenerarManifiesto/FichaGenerarManifiesto'));
const FichaGenerarExcelFactura = lazy(() => import('app/modules/trafico/components/excel_factura/FichaGenerarExcelFactura/FichaGenerarExcelFactura'));
const FichaRecalcularFactura = lazy(() => import('app/modules/clientes/components/facturacion/FichaRecalcularFactura/FichaRecalcularFactura'));
const FichaMetodoPago = lazy(() => import('app/modules/metodospago/components/FichaMetodoPago/FichaMetodoPago'));
const FichaModalFacturacion = lazy(() => import('app/modules/facturacion/components/FichaModalFacturacion/FichaModalFacturacion'));
const FichaModalAddDescuento = lazy(() => import('app/modules/clientes/components/facturacion/FichaModalAddDescuento/FichaModalAddDescuento'));
const FichaModalConfigHoraMaximaRecogidas = lazy(() => import('app/modules/shared/components/FichaModalConfigHoraMaximaRecogidas/FichaModalConfigHoraMaximaRecogidas'));
const FichaModalEnviosPendientesTransmitir = lazy(() => import('app/modules/trafico/components/FichaModalEnviosPendientesTransmitir/FichaModalEnviosPendientesTransmitir'));
const FichaModalImportacionTarifas = lazy(() => import('app/modules/tarifas/components/FichaModalImportacionTarifas/FichaModalImportacionTarifas'));

export const VISTAS_MODALES = {
    ADD_ETIQUETAS: 'ADD_ETIQUETAS',
    GENERAR_MANIFIESTO: 'GENERAR_MANIFIESTO',
    GENERAR_EXCEL_FACTURA: 'GENERAR_EXCEL_FACTURA',
    RECALCULAR_FACTURA: 'RECALCULAR_FACTURA',
    ADD_METODO_PAGO: 'ADD_METODO_PAGO',
    FACTURAR: 'FACTURAR',
    ADD_RANGO_DESCUENTO_CLIENTE: 'ADD_RANGO_DESCUENTO_CLIENTE',
    HORA_MAXIMA_RECOGIDAS: 'HORA_MAXIMA_RECOGIDAS',
    ENVIOS_PENDIENTES_TRANSMITIR: 'ENVIOS_PENDIENTES_TRANSMITIR',
    IMPORTAR_TARIFAS: 'IMPORTAR_TARIFAS',
}

const ModalesApp = () => {

    const {open, vista, props} = useModalContext();

    const {closeModal} = useModalContextAction();

    return ( 
   
        <Modal open={open} handleClose={closeModal} {...props}>
            <Suspense fallback={<div>Cargando...</div>}>
                {vista === VISTAS_MODALES.ADD_ETIQUETAS && <EtiquetarElemento />}
                {vista === VISTAS_MODALES.GENERAR_MANIFIESTO && <FichaGenerarManifiesto />}
                {vista === VISTAS_MODALES.GENERAR_EXCEL_FACTURA && <FichaGenerarExcelFactura />}
                {vista === VISTAS_MODALES.RECALCULAR_FACTURA && <FichaRecalcularFactura />}
                {vista === VISTAS_MODALES.ADD_METODO_PAGO && <FichaMetodoPago />}
                {vista === VISTAS_MODALES.FACTURAR && <FichaModalFacturacion />}
                {vista === VISTAS_MODALES.ADD_RANGO_DESCUENTO_CLIENTE && <FichaModalAddDescuento />}
                {vista === VISTAS_MODALES.HORA_MAXIMA_RECOGIDAS && <FichaModalConfigHoraMaximaRecogidas />}
                {vista === VISTAS_MODALES.ENVIOS_PENDIENTES_TRANSMITIR && <FichaModalEnviosPendientesTransmitir />}
                {vista === VISTAS_MODALES.IMPORTAR_TARIFAS && <FichaModalImportacionTarifas />}
            </Suspense>
        </Modal>
     );
}
 
export default ModalesApp;