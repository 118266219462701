import { useQuery } from "@tanstack/react-query";
import { ejecutaAPI } from "app/helpers/libFunciones";

export interface PlantillaResponse {
  id: string;
  nombre: string;
  idTipoPlantilla: string;
  idRed: string;
  inicioDatos: string;
  formato: string;
  descripcion: string | null;
  tipoImportacionEnvios: string;
  fechaCreacion: string;
  fechaActualizacion: null;
  idRedMensaglobalDatos: string;
  campos: CampoPlantilla[];
}

export interface CampoPlantilla {
  id: string;
  idPlantilla: string;
  idTipoCampo: string;
  columna: null | string;
  valorDefecto: null | string;
  fechaCreacion: string;
  fechaActualizacion: null;
  funcion: string;
  jsonColumnas: any[];
  comportamientoEnActualizar: string;
  nombreCampoForm: null | string;
}

export const getPlantillaImportacion = async (idPlantilla: string) => {

    const {success, respuesta, msg} = await ejecutaAPI<PlantillaResponse>('GET', `/plantillas/${idPlantilla}`);

    if (success)
    {
        return respuesta;
    }
    else 
    {
        throw new Error(msg.join(", "));
    }
}

export const useApiPlantilla = (idPlantilla: string, optionsExtra: Record<string, any> = {}) => {

    const options = {
        ...optionsExtra,
        enabled: !isNaN(parseInt(idPlantilla)) && Boolean(idPlantilla)
    }

    const data = useQuery(['plantillas', idPlantilla], () => getPlantillaImportacion(idPlantilla), options);

    let errorMsg = data.error instanceof Error ? data.error.message : '';

    return {
        ...data,
        errorMsg
    }
}