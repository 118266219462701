import { FormControl } from "@mui/material";
import { FormSelect } from "app/componentes/formularios/FormSelect";
import { FormTextInput } from "app/componentes/formularios/FormTextInput";
import { pintaOpcionesSelect } from "app/helpers/libFunciones";
import { roles } from "app/helpers/roles";
import { ordenarListadoTarifasPorNombre } from "app/hooks/api/tarifas/listadoTarifas.helper";
import { useListadoTarifas } from "app/hooks/api/tarifas/useListadoTarifas";
import { useWatch } from "react-hook-form";

const OpcionesUserMensajero = () => {
	const { listadoTarifas: tarifasMensajero } = useListadoTarifas({ tipo: "mensajero" });

	const idTarifaMensajero = useWatch({ name: "idTarifaMensajero" });

	const idTarifaMensajeroRecogida = useWatch({ name: "idTarifaMensajeroRecogida" });

	const rolesUsuario = useWatch({ name: "idRol" });

	const redesTarifaSeleccionadasEntrega = tarifasMensajero
		.filter((tarifa) => idTarifaMensajero?.includes(tarifa.id))
		.map((tarifa) => tarifa.idRed);

	const redesTarifaSeleccionadasRecogida = tarifasMensajero
		.filter((tarifa) => idTarifaMensajeroRecogida?.includes(tarifa.id))
		.map((tarifa) => tarifa.idRed);

	const tarifasOrdenadas = ordenarListadoTarifasPorNombre(tarifasMensajero);

	const tarifasSelectMensajeroEntrega = tarifasOrdenadas.map((tarifa) => ({
		id: tarifa.id,
		value: `[${tarifa.nombreRed}] ${tarifa.nombre}`,
		disabled: redesTarifaSeleccionadasEntrega.includes(tarifa.idRed) && !idTarifaMensajero?.includes(tarifa.id),
	}));

	const tarifasSelectMensajeroRecogida = tarifasOrdenadas.map((tarifa) => ({
		id: tarifa.id,
		value: `[${tarifa.nombreRed}] ${tarifa.nombre}`,
		disabled: redesTarifaSeleccionadasRecogida.includes(tarifa.idRed) && !idTarifaMensajeroRecogida?.includes(tarifa.id),
	}));

	return rolesUsuario?.includes(roles.MENSAJERO) ? (
		<>
			<FormControl style={{ width: "100%" }}>
				<FormTextInput
					hooks
					type="number"
					inputProps={{ min: 0, max: 100, step: "0.01" }}
					name="comisionMensajero"
					label="Comisión mensajero entrega (%)"
					variant="standard"
				/>
			</FormControl>

			<FormControl style={{ width: "100%" }}>
				<FormTextInput
					hooks
					type="number"
					inputProps={{ min: 0, max: 100, step: "0.01" }}
					name="comisionMensajeroRecogida"
					label="Comisión mensajero recogida (%)"
					variant="standard"
				/>
			</FormControl>

			<FormControl style={{ width: "100%" }}>
				<FormSelect hooks name="idTarifaMensajero" multiple label="Tarifas de mensajero entrega" variant="standard">
					{pintaOpcionesSelect(tarifasSelectMensajeroEntrega, "id", "value", "tarifas_mensajero", 0)}
				</FormSelect>
			</FormControl>

			<FormControl style={{ width: "100%" }}>
				<FormSelect hooks name="idTarifaMensajeroRecogida" multiple label="Tarifas de mensajero recogida" variant="standard">
					{pintaOpcionesSelect(tarifasSelectMensajeroRecogida, "id", "value", "tarifas_mensajero_recogida", 0)}
				</FormSelect>
			</FormControl>
		</>
	) : null;
};

export default OpcionesUserMensajero;
