import { ordenaTrafico } from "app/modules/trafico/helpers/trafico.helper";
import autobahn from "autobahn";
import moment from 'moment';
import React, { useContext, useEffect, useReducer } from 'react';
import Swal from 'sweetalert2';
import TienePermisos from '../helpers/TienePermisos';
import { getHostname } from '../helpers/libFunciones';
import { envios, recogidas, usuarios } from '../helpers/permisos';
import { SelectEstadosTrafico, SelectMensajeros } from '../helpers/selects';
import { ejecutaAPI } from './../helpers/libFunciones';
import { useGlobalLoader, useRoles, useUsuarioConectado } from './hooksPropios';


export const WebSocketContext = React.createContext();


const websocketReducer = (state, action) => {


    var ordenado=[];
    var aux=[];
    
    switch (action.type) {

        case 'SET_OPEN':
            return { ...state, open: action.payload };

        case 'SET_PAGINA':
            return { ...state, pag: action.payload };


        case 'SET_ESTADOS':           

            return { ...state, estados: action.payload }

        case 'SET_MENSAJEROS':
            var mensajeros = action.payload.map((mensajero) => {
                mensajero.nombreCompleto = `${mensajero.nombre} ${mensajero.apellidos}`;
                return mensajero;
            })
            return { ...state, mensajeros: mensajeros };

        case 'RESET':
            return({...state,listadoTrafico:[]});

        case 'SET_FILTROS':
            
             ordenado = ordenaTrafico(state.listadoTrafico, action.payload);

            return { ...state,listadoTrafico:ordenado,filtrosTrafico: action.payload };

        case 'SET_VISTA':
            return { ...state, filtrosTrafico: {...state.filtrosTrafico,vista:action.payload} };

        case 'SET_CONNECTION':
            return { ...state, connection: action.payload };

        case 'SET_SESSION':
            return { ...state, session: action.payload };

        case 'SET_NOTIFICACION':
            return { ...state, notificaciones: action.payload };

        case 'SET_OPEN_TRAFICO':
            return { ...state, openTrafico: action.payload };

        case 'ADD_TRAFICO':                            
            //Comprobamos si existe ya , para evitar duplicados y si existe nos quedamos con el ultimo                        
            var listado = state.listadoTrafico.map(item=>{
                if(item.idUnico==action.payload.idUnico){
                    return action.payload;
                }else{
                    return item;
                }
            });

            var existe=listado.find(item=>item.idUnico===action.payload.idUnico);

            if(existe==undefined){
                listado.push(action.payload);
            }

            ordenado = ordenaTrafico(listado, state.filtrosTrafico);
            return { ...state, listadoTrafico: ordenado };


        case 'RECARGAR_MENSAJERO':

             aux= state.listadoTrafico && state.listadoTrafico.map(item=>{
                if(item.idUnico==action.payload.idUnico){
                    return {...item,idMensajero:action.payload.idMensajero};
                }else{
                    return item;
                }
            })

            return{...state,listadoTrafico:aux};      
            
        case 'RECARGAR_ESTADO':

            aux = state.listadoTrafico && state.listadoTrafico.map(item => {
                if (item.idUnico == action.payload.idUnico) {                    
                    return { ...item, estado: action.payload.estado };
                } else {
                    return item;
                }
            });            

            return { ...state, listadoTrafico: aux };      

        default:
            throw new Error();
    }
}

export const WebSocketProvider = ({ children }) => {

    
    const vista_defecto = (localStorage.getItem("vistaTrafico") != null && localStorage.getItem("vistaTrafico") != "estadisticas") ? localStorage.getItem("vistaTrafico") : "recogidaEnvios";
    

    const filtrosInitTrafico = {
        vista:vista_defecto,
        orden:'fecha',
        tipo:''

    };

    const dataInit = { 
        connection:null,    
        session:null,      
        mensajeros: [],        
        estados:[],        
        listadoTrafico:[],
        filtrosTrafico:filtrosInitTrafico,
        open:false,
        pag:1,
           
    }

    const [dataWS, dispatch] = useReducer(websocketReducer, dataInit);

    return (
        <WebSocketContext.Provider value={[dataWS, dispatch]}>
            {children}
        </WebSocketContext.Provider>
    )
}


export const useWSContext = () =>{

    const [dataWS, dispatch] = useContext(WebSocketContext);

    const { MENSAJERO, esRol, esRolAdministrativo } = useRoles();

    const { datosUser } = useUsuarioConectado();

    const { setGlobalCargando } = useGlobalLoader();

        
    const esMensajero = esRol(MENSAJERO);

    const isConnected=()=>{
        return (dataWS.connection !== null && dataWS.connection.isOpen ? true : false);
    }

    const disconnect = () => {
        if(isConnected())
            dataWS.connection.close();
    }

    const addTrafico= async (data)=>{
                
        const aux = data[0]?.data[0] || data[0];       
             
        //El usuario que lo crea que no le saga el aviso        
        if(datosUser.user.id!=aux.idUsuarioCreacion)
            dispatch({ type: "ADD_TRAFICO", payload: aux });
    }

    

    const subscribe=(key,func)=>{
        
        if (isConnected()){            
            dataWS.connection.session.subscribe(key, (data) => func(data));                
        }
         

    }
    

    const numAlerts=()=>{

         if(isConnected()){
            
            if(dataWS.listadoTrafico.length>0){
                return dataWS.listadoTrafico.length;
            }
        }

    }

    const setOpen=(open)=>{
        if((dataWS.listadoTrafico.length>0 && open) || open===false)
            dispatch({ type: "SET_OPEN", payload: open });
    }

    const connect=()=>{

         if(!isConnected()){

            var protocol=window.location.protocol;
            var protocolWS="ws";
            if(protocol=="https:"){
                protocolWS="wss";
            }

            var conn = new autobahn.Connection({
                url: `${protocolWS}://${getHostname()}/ws`,
                realm: 'realm1',
                max_retries:5
            });

            conn.onopen = (session) => {

                console.log("Conectado con el websocket");
                
                dispatch({ type: "SET_SESSION", payload: session.id });
                dispatch({ type: "RESET"});

                //Nos identificamos
                session.call('mensaglobal.setdominio', [getHostname(), session.id]);

                if (esRolAdministrativo) {

                    session.subscribe(`nueva_recogida`, (data) => addTrafico(data));
                    session.subscribe(`nuevo_envio`, (data) => addTrafico(data));
                }
                else if(esMensajero){

                    session.subscribe(`nueva_recogida_mensajero_${datosUser.user.id}`, (data) => addTrafico(data));
                    session.subscribe(`nuevo_envio_mensajero_${datosUser.user.id}`, (data) => addTrafico(data));

                    if (TienePermisos([envios.ver_todos])) {
                        session.subscribe(`nuevo_envio`, (data) => addTrafico(data));
                    }
                    
                    if (TienePermisos([recogidas.ver_todos])) {
                        session.subscribe(`nueva_recogida`, (data) => addTrafico(data));
                    }

                }                


            };

            conn.onclose = () => {
                dispatch({ type: "SET_SESSION", payload: null });
            }

            conn.open();

            dispatch({ type: "SET_CONNECTION", payload: conn });    

        }
    }

    

    //Funciones especificas


    const handleSetMensajero = async (nuevoValor, objeto) => {

        setGlobalCargando(true);

        var url = `recogidas/${objeto.id}`;

        if (objeto.tipo === "envio") {
            url = `envios/${objeto.id}`;
        }

        var respuesta = await ejecutaAPI('PUT', url, { idMensajero: nuevoValor || null });

        if (respuesta.success) {

            dispatch({ type: 'RECARGAR_MENSAJERO', payload: {idMensajero:nuevoValor,idUnico:objeto.idUnico} });

        }

        setGlobalCargando(false);


    }

    const handleSetTerminado = async (objeto) => {

        if (objeto.tipo.indexOf("recogida") >= 0) {

            setGlobalCargando(true);

            var url = `recogidas/${objeto.id}/estados`;

            var datosEnviar = {
                estado: "terminado"
            }

            var respuesta = await ejecutaAPI('POST', url, datosEnviar);

            if (respuesta.success) {

                var estado = { id: "1", idEstado: "5", idUsuario: datosUser.user.id, observaciones: null, fecha: moment().format("DD/MM/YYYY"),codigo:'terminado',estado:'Terminado',icono:'done_outline',usuario:datosUser.user.user};

                dispatch({ type: 'RECARGAR_ESTADO', payload: { estado:estado, idUnico: objeto.idUnico } });

            }

            setGlobalCargando(false);

        }
    }


    const handleSetComprobado = async (objeto) => {

        const enviarEstadoComprobado = async () => {

            setGlobalCargando(true);

            var url = `recogidas/${objeto.id}/estados`;

            var datosEnviar = {
                estado: "comprobado"
            }

            var respuesta = await ejecutaAPI('POST', url, datosEnviar);

            setGlobalCargando(false);

            if (respuesta.success) {

                var estado = { id: "1", idEstado: "3", idUsuario: datosUser.user.id, observaciones: null, fecha: moment().format("DD/MM/YYYY"), codigo: 'comprobado', estado: 'Comprobado', icono: 'verified', usuario: datosUser.user.user };

                dispatch({ type: 'RECARGAR_ESTADO', payload: { estado: estado, idUnico: objeto.idUnico } });
                

            }
            else {
                Swal.fire({
                    title: "Error",
                    text: 'Se ha producido un error',
                    icon: 'error'
                });
            }
        }

        if (objeto.tipo === "recogida") {

            setGlobalCargando(true);

            var datosRecogidaAPI = await ejecutaAPI('GET', `recogidas/${objeto.id}`);

            if (datosRecogidaAPI.success) {
                var datosRecogida = datosRecogidaAPI.respuesta;

                if (datosRecogida.notasMensajero) {
                    setGlobalCargando(false);

                    Swal.fire({
                        title: 'Aviso',
                        html: `La recogida tiene la siguiente nota: <br> ${datosRecogida.notasMensajero}`,
                        icon: 'info',
                        showCancelButton: false,
                        confirmButtonText: 'Aceptar',
                    }).then(async (result) => {

                        if (result.value) {

                            enviarEstadoComprobado();

                        }
                    });
                }
                else {
                    enviarEstadoComprobado();
                }


            }
            else {
                Swal.fire({
                    title: "Error",
                    text: 'Se ha producido un error',
                    icon: 'error'
                });
            }
        }
    }

    const handleSetVista = (vista) => {

        dispatch({ type: 'SET_VISTA', payload: vista });
    }


    const handleSetOrden = (orden) => {
        dispatch({ type: 'SET_FILTROS', payload: { ...dataWS.filtrosTrafico, orden: orden } });
    }

    const handleReset = ()=>{

        dispatch({ type: 'RESET' });

        setOpen(false);
    }



    return {
        ...dataWS,
        dispatch,        
        setOpen,
        isConnected,
        disconnect,
        connect,
        subscribe,
        numAlerts,
        handleSetMensajero,
        handleSetTerminado,
        handleSetComprobado,
        handleSetVista,
        handleSetOrden,
        handleReset

    }

}


export const useWS = () => {
    
    const {isConnected, disconnect, connect, open, setOpen, listadoTrafico, filtrosTrafico, pag, mensajeros, estados, handleSetMensajero, handleSetTerminado, handleSetComprobado, handleSetVista, handleSetOrden,handleReset,dispatch } = useWSContext();
            
    useEffect(() => {
  
        
        if (TienePermisos([], [usuarios.ver, envios.ver_todos, recogidas.ver_todos])) {

            (async () => {

                var mensajeros = await SelectMensajeros();


                if (mensajeros.length) {

                    dispatch({ type: "SET_MENSAJEROS", payload: [{ id: "-1", nombre: "Sin mensajero", apellidos: "" }, ...mensajeros] });
                }
               

            })();
        }

        (async () => {            

            var estadosTrafico = await SelectEstadosTrafico();

            if (estadosTrafico) {
                dispatch({ type: "SET_ESTADOS", payload: estadosTrafico })
            }
            

        })();

    }, [dispatch]);


    return { isConnected, disconnect, connect, open, setOpen, listadoTrafico, filtrosTrafico, pag, mensajeros, estados, handleSetMensajero, handleSetTerminado, handleSetComprobado, handleSetVista, handleSetOrden,handleReset,dispatch };


    
} 



