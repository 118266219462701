import { useQuery } from "@tanstack/react-query";
import { ejecutaAPI } from "app/helpers/libFunciones";
import { useDataArray } from "app/hooks/useDataArray";


export const getRedes = async (params = {}) => {

    let queryString = new URLSearchParams(params).toString();

    if (queryString)
    {
        queryString = `?${queryString}`;
    }

    const {success, respuesta, error} = await ejecutaAPI('GET', `/redes${queryString}`);

    if (success)
    {
        return respuesta;
    }
    else 
    {
        throw new Error(error);
    }
}

export const useListadoRedes = (params = {}, optionsExtra = {}) => {

    let options = {
        staleTime: 1000 * 3600,
        cacheTime: 1000 * 10800,
        ...optionsExtra
    }

    const query = useQuery(['redes', params], () => getRedes(params), options);

    const redes = useDataArray(query.data);

    return {
        ...query,
        redes
    }
}