import { TextareaAutosize } from '@mui/material';
import { TooltipG } from 'app/pages/utils';
import { useField } from 'formik';
import React from 'react';


const styleTextArea = { width: "100%", height: "100", padding: "20px" };

export const FormTextAreaFormik = ({name, tooltip, onChange, ...props}) => {

    const [campo] = useField(name);

    var propsDefault = {
        name:name,
        value: campo.value || '',
        variant:"outlined",
        size:"small",
        margin:"normal",
        className:"kt-width-full",       
        onChange:(e) => {

            campo.onChange(e);

            if (typeof onChange === "function")
            {
                onChange(e, campo.value);
            }
        },      
    }

      
    return ( 

        tooltip !== undefined 
        ? 
        (
            <TooltipG title={tooltip}>
                <label>{props.label}</label><br></br>
                <TextareaAutosize 
                    style={styleTextArea}
                    {...propsDefault}
                    {...props}
                />

            </TooltipG>
        )
        :
        (   <>
            <label>{props.label}</label><br></br>
            <TextareaAutosize
                style={styleTextArea}
                {...propsDefault}
                {...props}
            />
            </>
        )
     );
}

