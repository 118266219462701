import React from 'react';
import { FormTextAreaFormik } from './Librerias/Formik/FormTextAreaFormik';
import FormTextAreaRHF from './Librerias/ReactHookForm/FormTextAreaRHF';

export const FormTextArea = (props) => {

    const { hooks, ...otherProps } = props;


    return (
        !hooks ? 
        (
            <FormTextAreaFormik {...otherProps} />
        )
        :
        (
            <FormTextAreaRHF {...otherProps} />
        )
    )
}

