import { TIPOS_PLANTILLA_IMPORTACION_ENVIOS } from "app/helpers/constants";
import { PlantillaResponse } from "app/hooks/api/plantillas/useApiPlantilla";
import { PlantillaTipoResponse } from "app/hooks/api/plantillas/useApiPlantillasTipos";

export const FichaPlantillaMapper = {
    toForm: (plantilla: PlantillaResponse | undefined, plantillasTipos: PlantillaTipoResponse[]) => {

        let devolver = {
            id: plantilla?.id || "",
            nombre: plantilla?.nombre || "",
            idTipoPlantilla: plantilla?.idTipoPlantilla || "",
            idRed: plantilla?.idRed || "",
            inicioDatos: plantilla?.inicioDatos || "1",
            formato: plantilla?.formato || "excel",
            descripcion: plantilla?.descripcion || "",
            tipoImportacionEnvios: plantilla?.tipoImportacionEnvios || TIPOS_PLANTILLA_IMPORTACION_ENVIOS.CREAR.id,
            campos: {}
        }

        if (plantilla)
        {
            let campos = {} as any;

            let tipoSeleccionado = plantillasTipos.find(tipo => tipo.id === plantilla.idTipoPlantilla);

            tipoSeleccionado?.campos.forEach((campo) => {

                let valoresDefecto = {
                    id: '',
                    idTipoCampo: campo.idTipoCampo,
                    funcion: 'columna',
                    jsonColumnas: [],
                    columna: '',
                    valorDefecto: '',
                    comportamientoEnActualizar: 'defecto'
                }
    
                //Buscamos si tenemos este campo lo tenemos en la DB
                let campoEnDB = plantilla.campos.find(campoDB => campoDB.idTipoCampo === campo.idTipoCampo);

                if (campoEnDB)
                {
                    campos[campo.idTipoCampo] = {
                        id: campoEnDB.id,
                        idTipoCampo: campoEnDB.idTipoCampo,
                        funcion: campoEnDB.funcion,
                        jsonColumnas: campoEnDB.jsonColumnas,
                        columna: campoEnDB.columna,
                        valorDefecto: campoEnDB.valorDefecto,
                        comportamientoEnActualizar: campoEnDB.comportamientoEnActualizar
                    };
                }
                else
                {
                    campos[campo.idTipoCampo] = valoresDefecto;
                }

            });

            devolver.campos = campos;
        }

        return devolver;
    },

    toRequest: (plantilla: any) => {


        return {
            id: plantilla.id || null,
            nombre: plantilla.nombre,
            formato: plantilla.formato,
            idRed: plantilla.idRed || null,
            idTipoPlantilla: plantilla.idTipoPlantilla,
            inicioDatos: plantilla.inicioDatos,
            descripcion: plantilla.descripcion || null,
            tipoImportacionEnvios: plantilla.tipoImportacionEnvios || null,
            campos: plantilla.campos

        }
    }
}