import { useQuery } from "@tanstack/react-query";
import { SortingState } from "@tanstack/react-table";
import { ListadosQueryParams, ListadosResponse } from "app/models/types";
import { getListado } from "../datatable.helper";

interface useDatatableQueryProps
{
    url: string;
    datosPaginacion: {
        pageIndex: number;
        pageSize: number;
    };
    sorting: SortingState;
    queryKey: string[];
    filtros: any;
}

export const useDatatableQuery = ({ url, datosPaginacion, sorting, queryKey, filtros }: useDatatableQueryProps) => {
    let filtersQuery: ListadosQueryParams = {
        page: datosPaginacion.pageIndex + 1,
        per_page: datosPaginacion.pageSize,
    };

    if (sorting.length > 0)
    {
        filtersQuery.orderby = `${sorting[0].id},${sorting[0].desc ? "desc" : "asc"}`;
    }

    filtersQuery = { ...filtersQuery, ...filtros };

    const { data, isFetching } = useQuery([...queryKey, filtersQuery], () => getListado<ListadosResponse<any>>(url, filtersQuery), {
        keepPreviousData: true,
        enabled: Boolean(url),
    });

    return {
        data,
        isFetching,
    };
};
