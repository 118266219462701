import { PaginationState } from "@tanstack/react-table";
import { useCallback, useState } from "react";

export interface DatatablePaginationState {
    datosPaginacion: PaginationState;
    setPagination: React.Dispatch<React.SetStateAction<PaginationState>>;
    onChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
    onChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    goToFirstPage: () => void;
}

export const useDatatablePagination = (initialPage: number = 0, initialRowsPerPage: number = 10) : DatatablePaginationState => {
    const [datosPaginacion, setPagination] = useState<PaginationState>({
        pageIndex: initialPage,
        pageSize: initialRowsPerPage,
    });

    const onChangePage = useCallback((event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPagination((state) => ({ ...state, pageIndex: newPage }));
    }, []);

    const onChangeRowsPerPage = useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setPagination((state) => ({ ...state, pageSize: parseInt(event.target.value) }));
    }, []);

    const goToFirstPage = useCallback(() => {
        setPagination((state) => ({ ...state, pageIndex: 0 }));
    }, []);

    return {
        datosPaginacion,
        setPagination,
        onChangePage,
        onChangeRowsPerPage,
        goToFirstPage
    };
};