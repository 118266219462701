import { useEliminarRegistro } from "app/hooks/api/shared/useEliminarRegistro";
import { useGlobalLoader } from "app/hooks/hooksPropios";
import { swalAviso, swalError } from "app/librerias/swal";
import { ReactNode, useCallback } from "react";

interface BotonDatatableBorrarProps {
	to: string;
	invalidationKeys?: string[][] | string[];
    children: ReactNode;
}



export default function BotonDatatableBorrar({to, invalidationKeys = [], children} : BotonDatatableBorrarProps) {

    const { setGlobalCargando } = useGlobalLoader();

    const {mutate} = useEliminarRegistro(to, invalidationKeys);

	const onClickBorrar = useCallback((e: React.MouseEvent<HTMLDivElement>) => {

		e.stopPropagation();

		swalAviso("Borrar registro", "Se borrará el registro. ¿Continuar?", () => {
			setGlobalCargando(true);

			mutate(to, {
				onError: (error) => {
					swalError("error", error.message ?? "Se ha producido un error");
				},
				onSettled: () => setGlobalCargando(false),
			});
		});
	}, [mutate, setGlobalCargando, to]);

    return (
        <div onClick={onClickBorrar}>
            {children}
        </div>
    )

}