import { Button, Grid, Typography } from '@mui/material';
import SnackbarAlert from 'app/componentes/avisos/SnackbarAlert';
import { useFormikContext } from 'formik';
import { useContext } from 'react';
import { FormTextInput } from '../../../../componentes/formularios/FormTextInput';
import Dropzone from './../../../../componentes/formularios/Dropzone/Dropzone';
import { useGlobalLoader } from './../../../../hooks/hooksPropios';
import { ConfigContext } from './index';



const FormConfig = (props) => {

   
    const [config, dispatch] =useContext(ConfigContext);

    const { cargando } = useGlobalLoader();
    const formik = useFormikContext();

    const handleSubmit = () => {        
        formik.handleSubmit();
    }

    const handleSetLogoTarifa=(ficheros)=>{
       
        const imagenes=ficheros.filter(imagen => imagen != undefined);
        
           if (imagenes.length>0 && imagenes[imagenes.length-1]?.id) {               
                dispatch({ type: "SET_LOGO_TARIFA", payload: imagenes[imagenes.length - 1] });                                             
            } else {
               dispatch({ type: "SET_LOGO_TARIFA", payload: {} });
            }        

            formik.handleSubmit();  
    }

        const handleSetLogoManifiesto = (ficheros) => {

            const imagenes = ficheros.filter(imagen => imagen != undefined);

            if (imagenes.length > 0 && imagenes[imagenes.length - 1]?.id) {
                dispatch({ type: "SET_LOGO_MANIFIESTO", payload: imagenes[imagenes.length - 1] });
            } else {
                dispatch({ type: "SET_LOGO_MANIFIESTO", payload: {} });
            }

            formik.handleSubmit();
        }


    


    return (   <>
            
                            {(formik.status) ? (
                                <>
                                    <SnackbarAlert variant="outlined" elevation={0} severity={"warning"}  >
                                        {formik.status}
                                    </SnackbarAlert>
                                    <br></br>
                                </>
                            ) : null}                          

                            <Grid container spacing={3}>                              

                                <Grid item xs={12} lg={3}>
                                    <Typography variant="h6">Logo tarifas</Typography>
                                    <Dropzone aceptar="image/*" carpeta="logos" ficheros={(config.logo_tarifa?.url != undefined) ? [config.logo_tarifa] : []} mensaje="Sube aqui el logotipo para las tarifas" setFicheros={handleSetLogoTarifa} uploadUrl="/ficheros" multiple={false} />                                    
                                </Grid> 
                                <Grid item xs={12} lg={3}>
                                    <Typography variant="h6">Logo manifiesto</Typography>
                                    <Dropzone aceptar="image/*" carpeta="logos" ficheros={(config.logo_manifiesto?.url != undefined) ? [config.logo_manifiesto] : []} mensaje="Sube aqui el logotipo para el manifiesto" setFicheros={handleSetLogoManifiesto} uploadUrl="/ficheros" multiple={false} />
                                </Grid>

                            </Grid>

                            <Grid container spacing={3} style={{marginTop:"20px"}}>

                                <Grid item xs={12} > 
                                <h4>Configuración Email</h4>
                                </Grid>

                                <Grid item xs={12} lg={3}>                                  
                                    <FormTextInput fast name="hostSMTP" label="Host SMTP" type="text" />
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <FormTextInput fast name="portSMTP" label="Puerto SMTP" type="number" />
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <FormTextInput fast name="usuarioSMTP" label="Usuario SMTP" type="text" />
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <FormTextInput fast name="passSMTP" label="Contraseña SMTP" type="text" />
                                </Grid>

                                <Grid item xs={12} lg={3}>
                                    <FormTextInput fast name="replyTo" label="Respuesta a (replyto) " type="text" />
                                </Grid>

                                <Grid item xs={12} lg={3}>
                                    <FormTextInput fast name="nombreRemitente" label="Nombre remitente" type="text" />
                                </Grid>

                            </Grid>


                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Button type="submit" disabled={!cargando ? false : true} style={{ float: "right" }} variant="contained" color="primary">
                                        Guardar
                                    </Button>
                                </Grid>
                            </Grid>
</>
    );
}

export default FormConfig;