import CloseIcon from '@mui/icons-material/Close';
import { Dialog, Icon, IconButton, DialogActions as MuiDialogActions, DialogContent as MuiDialogContent, DialogTitle as MuiDialogTitle, Paper, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import { useState } from 'react';
import { isMobile } from "react-device-detect";
import Draggable from 'react-draggable';


const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2)
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey.main,
    },
    maxButton: {
        position: 'absolute',
        right: theme.spacing(5),
        top: theme.spacing(1),
        color: theme.palette.grey.main,
    },
    saveButton: {
        position: 'absolute',
        right: theme.spacing(10),
        top: theme.spacing(1),
        color: theme.palette.grey.main,
    }
});

function PaperComponent(props) {
    if(isMobile){
        return (            
                <Paper {...props} />            
        );
    }else{
        return (
            <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
                <Paper {...props} />
            </Draggable>
        );  
    }
}

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);


const Modal = (props) => {

  

    const [maximizar, setMax] = useState((props.fullscreen === true) ? true : false);

    const {
        titulo,
        open,
        handleClose,
        maxWidth = "lg",
        fullScreen = maximizar,
        save,
        disableEscapeKeyDown = false,
        keepMounted = false,
        ...otherModalProps
    } = props;



    const DialogTitle = withStyles(styles)((props) => {
        const { children, classes, onClose, save, fullScreen, ...other } = props;
        return (
            <MuiDialogTitle className={classes.root} {...other}>
                <Typography variant="h6">{children}</Typography>
                {onClose ? (
                    <>
                       
                            {(typeof save === "function") ?
                                (<IconButton className={classes.saveButton} onClick={save}>
                                    <Icon className="fa fa-save"  ></Icon>
                                </IconButton>) : null
                            }
                            <IconButton aria-label="close" className={classes.maxButton} onClick={() => { (maximizar === true) ? setMax(false) : setMax(true) }}>
                                <Icon className={`fa ${clsx({ "fa-expand": !maximizar }, { "fa-compress": maximizar })}`}></Icon>
                            </IconButton>
                            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                                <CloseIcon />
                            </IconButton>  

                    </>
                ) : null}
            </MuiDialogTitle>
        );
    });


    return (
        <div>

            <Dialog scroll="body" keepMounted={keepMounted} disableEscapeKeyDown={disableEscapeKeyDown} fullWidth={true} PaperComponent={PaperComponent} maxWidth={maxWidth} fullScreen={(maximizar !== false) ? maximizar :fullScreen} onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} {...otherModalProps}>

                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title" onClose={handleClose} save={save}>
                    {titulo}
                </DialogTitle>

                <DialogContent dividers>
                    {props.children}
                </DialogContent>

                <DialogActions>

                </DialogActions>


            </Dialog>
        </div>
    );
}

export default Modal;