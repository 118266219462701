import IBAN from 'iban';
import * as Yup from 'yup';

export const emptyStringToNull = (value, originalValue) => {
    if (typeof originalValue === 'string' && originalValue === '') {
      return null;
    }
    return value;
}

export const transformarComas = (value, originalValue) => {

    if (typeof originalValue === 'string' && originalValue !== '') {
        return parseFloat(originalValue.replace(',', '.'));
    }
    return value;

}

Yup.addMethod(Yup.string, 'dni', function(mensaje) {

    return this.test('test-name', mensaje, function(value) {
        const { path, createError } = this;
        //const { some, more, args } = anyArgsYouNeed;
        var valido = true;

        if (value !== undefined && value !== null && value !== "")
        {
            const expr = /^([a-z]|[A-Z]|[0-9])[0-9]{7}([a-z]|[A-Z]|[0-9])$/;

            valido = expr.test(value);
        }

        // [value] - value of the property being tested
        // [path]  - property name,
        // ...
        return valido || createError({path, mensaje});
    });
});

Yup.addMethod(Yup.string, 'cif', function(mensaje) {

    return this.test('test-cif', mensaje, function(value) {
        const { path, createError } = this;
        //const { some, more, args } = anyArgsYouNeed;
        var valido = true;

        if (value !== undefined && value !== null && value !== "")
        {
            const expr = /^[a-zA-Z]{1}\d{7}[a-zA-Z0-9]{1}$/;

            valido = expr.test(value);
        }

        // [value] - value of the property being tested
        // [path]  - property name,
        // ...
        return valido || createError({path, mensaje});
    });
});

Yup.addMethod(Yup.string, 'nifOrCif', function(mensaje) {

    return this.test('test-nifOrCif', mensaje, function(value) {
        const { path, createError } = this;
        //const { some, more, args } = anyArgsYouNeed;
        var valido = true;

        if (value !== undefined && value !== null && value !== "")
        {
            const cifExpr = /^[a-zA-Z]{1}\d{7}[a-zA-Z0-9]{1}$/;

            const nifExpr = /^([a-z]|[A-Z]|[0-9])[0-9]{7}([a-z]|[A-Z]|[0-9])$/;

            valido = cifExpr.test(value) || nifExpr.test(value);


        }

        // [value] - value of the property being tested
        // [path]  - property name,
        // ...
        return valido || createError({path, mensaje});
    });
});

Yup.addMethod(Yup.string, 'iban', function(mensaje) {

    return this.test('test-iban', mensaje, function(value) {
        const { path, createError } = this;
        //const { some, more, args } = anyArgsYouNeed;
        var valido = true;

        if (value !== undefined && value !== null && value !== "")
        {

            valido = IBAN.isValid(value);
        }

        // [value] - value of the property being tested
        // [path]  - property name,
        // ...
        return valido || createError({path, mensaje});
    });
});

Yup.addMethod(Yup.object, "optional", function (
    isOptional = true,
    defaultValue = undefined
) {
    return this.transform(function (value) {
        // If false is passed, skip the transform
        if (!isOptional) return value;

        // If any child property has a value, skip the transform
        if (
            value &&
            Object.values(value).some(v => !(v === null || v === undefined || v === ""))
        ) {
            return value;
        }

        return defaultValue;
    })
        // Remember, since we're dealing with the `object` 
        // type, we have to change the default value
        .default(defaultValue);
});

export default Yup;