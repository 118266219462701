export const bancos = [
{
    "pais": "ES",
    "bancos": [
        {entidad:'0003', banco: 'BANCO DE DEPOSITOS, S.A.', bic: 'BDEPESM1XXX'},
        {entidad:'0004', banco: 'BANCO DE ANDALUCIA, S.A.', bic: 'BANDESSSXXX'},
        {entidad:'0008', banco: 'BANCO ATLANTICO, S.A.', bic: 'BSABESBBXXX'},
        {entidad:'0011', banco: 'ALLFUNDS BANK, S.A.', bic: 'ALLFESMMXXX'},
        {entidad:'0013', banco: 'SOLBANK SBD, S.A.', bic: 'SLBKESBBXXX'},
        {entidad:'0015', banco: 'BANCA CATALANA, S.A.', bic: 'CATAESBBXXX'},
        {entidad:'0019', banco: 'DEUTSCHE BANK, S.A.E.', bic: 'DEUTESBBASS'},
        {entidad:'0021', banco: 'BANCO CONDAL, S.A.', bic: 'BCNDESM1XXX'},
        {entidad:'0024', banco: 'BANCO DE CREDITO BALEAR, S.A.', bic: 'BALEES22XXX'},
        {entidad:'0030', banco: 'BANCO ESPAÑOL DE CREDITO, S.A.', bic: 'ESPCESMMXXX'},
        {entidad:'0031', banco: 'BANCO ETCHEVERRIA, S.A.', bic: 'ETCHES2GXXX'},
        {entidad:'0036', banco: 'SANTANDER INVESTIMENT, S.A.', bic: 'SABNESMMXXX'},
        {entidad:'0042', banco: 'BANCO GUIPUZCOANO, S.A.', bic: 'BGUIES22XXX'},
        {entidad:'0043', banco: 'BANCO HERRERO, S.A.', bic: 'BHROESM2XXX'},
        {entidad:'0046', banco: 'BANCO GALLEGO, S.A.', bic: 'GALEES2GXXX'},
        {entidad:'0048', banco: 'BANCO SIMEON, S.A.', bic: 'CGDIES2VXXX'},
        {entidad:'0049', banco: 'BANCO SANTANDER, S.A.', bic: 'BSCHESMMXXX'},
        {entidad:'0050', banco: 'BANCO DEL COMERCIO, S.A.', bic: 'BCIOESMMXXX'},
        {entidad:'0056', banco: 'BANCA JOVER, S.A.', bic: 'JOVEESBBXXX'},
        {entidad:'0057', banco: 'BANCO DEPOSITARIO BBVA, S.A.', bic: 'BVADESMMXXX'},
        {entidad:'0058', banco: 'BNP PARIBAS ESPAÑA, S.A.', bic: 'BNPAESMZXXX'},
        {entidad:'0059', banco: 'BANCO DE MADRID, S.A.', bic: 'MADRESMMXXX'},
        {entidad:'0061', banco: 'BANCA MARCH, S.A.', bic: 'BMARES2MXXX'},
        {entidad:'0063', banco: 'BANCO SERVICIOS FINANCIEROS CAJA MADRID', bic: 'BMAPESM1XXX'},
        {entidad:'0065', banco: 'BARCLAYS BANK, S.A.', bic: 'BARCESMMXXX'},
        {entidad:'0072', banco: 'BANCO PASTOR, S.A.', bic: 'PSTRESMMXXX'},
        {entidad:'0073', banco: 'OPEN BANK, S.A.', bic: 'OPENESMMXXX'},
        {entidad:'0075', banco: 'BANCO POPULAR ESPAÑOL, S.A.', bic: 'POPUESMMXXX'},
        {entidad:'0078', banco: 'BANCA PUEYO, S.A.', bic: 'BAPUES22XXX'},
        {entidad:'0081', banco: 'BANCO DE SABADELL, S.A.', bic: 'BSABESBBXXX'},
        {entidad:'0082', banco: 'BANCO DE CASTILLA, S.A.', bic: 'CASTES2SXXX'},
        {entidad:'0083', banco: 'RENTA 4 BANCO, S.A.', bic: 'RENBESMMXXX'},
        {entidad:'0085', banco: 'BANCO SANTANDER CENTRAL HISPANO, S.A.', bic: 'BDERESMMXXX'},
        {entidad:'0086', banco: 'BANCO BANIF, S.A.', bic: 'NORTESMMXXX'},
        {entidad:'0087', banco: 'SINDICATO DE BANQUEROS DE BARCELONA, S.A', bic: 'SIBBESBBXXX'},
        {entidad:'0089', banco: 'BANCO DE EXTREMADURA, S.A.', bic: 'CGDIES2CXXX'},
        {entidad:'0091', banco: 'BANCO DE ALBACETE, S.A.', bic: 'ALACESM1XXX'},
        {entidad:'0093', banco: 'BANCO DE VALENCIA, S.A.', bic: 'VALEESVVXXX'},
        {entidad:'0094', banco: 'RBC INVESTOR SERVICES ESPAÑA, S.A.', bic: 'BVALESMMXXX'},
        {entidad:'0095', banco: 'BANCO DE VASCONIA, S.A.', bic: 'VASCES2PXXX'},
        {entidad:'0097', banco: 'BANCO DE GALICIA, S.A.', bic: 'GALIES2VXXX'},
        {entidad:'0099', banco: 'BANKIA BANCA PRIVADA, S.A.', bic: 'AHCRESVVXXX'},
        {entidad:'0100', banco: 'BANCO DE VITORIA, S.A.', bic: 'BVICES21XXX'},
        {entidad:'0103', banco: 'BANCO ZARAGOZANO, S.A.', bic: 'BARCESMMXXX'},
        {entidad:'0104', banco: 'BANCO EXTERIOR DE ESPAÑA, S.A.', bic: 'EXTEESMMXXX'},
        {entidad:'0106', banco: 'LLOYDS TSB BANK PLC, S.E.', bic: 'LOYDESMMXXX'},
        {entidad:'0107', banco: 'BANCA NAZIONALE DEL LAVORO, S.P.A., S.E.', bic: 'BNLIESM1XXX'},
        {entidad:'0108', banco: 'SOCIETE GENERALE', bic: 'SOGEESMMAGM'},
        {entidad:'0112', banco: 'BANCO URQUIJO, S.A.', bic: 'PROGESMMXXX'},
        {entidad:'0113', banco: 'BANCO INDUSTRIAL DE BILBAO, S.A.', bic: 'INBBESM1XXX'},
        {entidad:'0118', banco: 'BANCO DE ASTURIAS, S.A.', bic: 'ASTUESMMXXX'},
        {entidad:'0121', banco: 'BANCO OCCIDENTAL, S.A.', bic: 'OCBAESM1XXX'},
        {entidad:'0122', banco: 'CITIBANK ESPAÑA, S.A.', bic: 'CITIES2XXXX'},
        {entidad:'0125', banco: 'BANCOFAR, S.A.', bic: 'BAOFESM1XXX'},
        {entidad:'0127', banco: 'BANCO DE ALICANTE, S.A.', bic: 'BBVAESMMXXX'},
        {entidad:'0128', banco: 'BANKINTER, S.A.', bic: 'BKBKESMMXXX'},
        {entidad:'0129', banco: 'BBVA BANCO DE FINANCIACION, S.A.', bic: 'INALESM1XXX'},
        {entidad:'0130', banco: 'BANCO CAIXA GERAL, S.A.', bic: 'CGDIESMMXXX'},
        {entidad:'0131', banco: 'BANCO ESPIRITO SANTO, S.A.', bic: 'BESMESMMXXX'},
        {entidad:'0132', banco: 'BANCO DE PROMOCION DE NEGOCIOS, S.A.(PROMOBANC)', bic: 'PRNEESM1XXX'},
        {entidad:'0133', banco: 'NUEVO MICRO BANK, S.A.', bic: 'MIKBESB1XXX'},
        {entidad:'0136', banco: 'ARESBANK, S.A.', bic: 'AREBESMMXXX'},
        {entidad:'0138', banco: 'BANKOA, S.A.', bic: 'BKOAES22XXX'},
        {entidad:'0142', banco: 'BANCO DE LA PEQUEÑA Y MEDIANA EMPRESA', bic: 'BPMEESBBXXX'},
        {entidad:'0144', banco: 'BNP PARIBAS SECURITIES SERVICES', bic: 'PARBESMHXXX'},
        {entidad:'0145', banco: 'DEUTSCHE BANK A.G., S.E.', bic: 'DEUTESM1XXX'},
        {entidad:'0149', banco: 'BNP PARIBAS', bic: 'BNPAESMHXXX'},
        {entidad:'0151', banco: 'JPMORGAN CHASE BANK NATIONAL ASSOCIATION, S.E.', bic: 'CHASESM3XXX'},
        {entidad:'0152', banco: 'BARCLAYS BANK PLC', bic: 'BPLCESMMXXX'},
        {entidad:'0154', banco: 'CREDIT AGRICOLE CORPORATE AND INVESTMENT BANK', bic: 'BSUIESMMXXX'},
        {entidad:'0155', banco: 'BANCO DO BRASIL, S.A.', bic: 'BRASESMMXXX'},
        {entidad:'0156', banco: 'THE ROYAL BANK OF SCOTLAND PLC', bic: 'ABNAESMMXXX'},
        {entidad:'0159', banco: 'COMMERZBANK AKTIENGESELLSCHAFT', bic: 'COBAESMXTMA'},
        {entidad:'0160', banco: 'THE BANK OF TOKYO-MITSUBISHI UFJ, LTD', bic: 'BOTKESMXXXX'},
        {entidad:'0161', banco: 'DEUTSCHE BANK TRUST COMPANY AMERICAS, S.E.', bic: 'BKTRESM1XXX'},
        {entidad:'0162', banco: 'HSBC BANK PLC', bic: 'MIDLESMXXXX'},
        {entidad:'0167', banco: 'BNP PARIBAS FORTIS, S.A., N.V.', bic: 'GEBAESMMBIL'},
        {entidad:'0168', banco: 'ING BELGIUM, S.A.', bic: 'BBRUESMXXXX'},
        {entidad:'0169', banco: 'BANCO DE LA NACION ARGENTINA', bic: 'NACNESMMXXX'},
        {entidad:'0182', banco: 'BANCO BILBAO VIZCAYA ARGENTARIA, S.A.', bic: 'BBVAESMMXXX'},
        {entidad:'0184', banco: 'BANCO EUROPEO DE FINANZAS, S.A.', bic: 'BEDFESM1XXX'},
        {entidad:'0185', banco: 'BANCO URQUIJO SABADELL BANCA PRIVADA, S.A.', bic: 'UIJOESMMXXX'},
        {entidad:'0186', banco: 'BANCO MEDIOLANUM, S.A.', bic: 'BFIVESBBXXX'},
        {entidad:'0188', banco: 'BANCO ALCALA, S.A.', bic: 'ALCLESMMXXX'},
        {entidad:'0190', banco: 'BANCO BPI, S.A.', bic: 'BBPIESMMXXX'},
        {entidad:'0196', banco: 'PORTIGON AG', bic: 'WELAESMMFUN'},
        {entidad:'0198', banco: 'BANCO COOPERATIVO ESPAÑOL, S.A.', bic: 'BCOEESMMXXX'},
        {entidad:'0200', banco: 'PRIVAT BANK DEGROOF, S.A.', bic: 'PRVBESB1XXX'},
        {entidad:'0205', banco: 'DEUTSCHE BANK CREDIT, S.A.', bic: 'DECRESM1XXX'},
        {entidad:'0211', banco: 'EBN BANCO DE NEGOCIOS, S.A.', bic: 'PROAESMMXXX'},
        {entidad:'0216', banco: 'TARGOBANK, S.A.', bic: 'POHIESMMXXX'},
        {entidad:'0217', banco: 'LLOYDS BANK INTERNATIONAL, S.A.', bic: 'HLFXESMMXXX'},
        {entidad:'0218', banco: 'FCE BANK PLC S.E.', bic: 'FCEFESM1XXX'},
        {entidad:'0219', banco: 'BANQUE MAROCAINE COMMERCE EXTERIEUR INTERNATIONAL, S.A.', bic: 'BMCEESMMXXX'},
        {entidad:'0220', banco: 'BANCO FINANTIA SOFINLOC, S.A.', bic: 'FIOFESM1XXX'},
        {entidad:'0223', banco: 'GENERAL ELECTRIC CAPITAL BANK, S.A.', bic: 'GEECESB1XXX'},
        {entidad:'0224', banco: 'SANTANDER CONSUMER FINANCE, S.A.', bic: 'SCFBESMMXXX'},
        {entidad:'0225', banco: 'BANCO CETELEM, S.A.', bic: 'FIEIESM1XXX'},
        {entidad:'0226', banco: 'UBS BANK, S.A.', bic: 'UBSWESMMNPB'},
        {entidad:'0227', banco: 'UNOE BANK, S.A.', bic: 'UNOEESM1XXX'},
        {entidad:'0228', banco: 'BANCO EXELBANK, S.A.', bic: 'IXIUESM1XXX'},
        {entidad:'0229', banco: 'BANCOPOPULAR-E, S.A.', bic: 'POPLESMMXXX'},
        {entidad:'0231', banco: 'DEIXA SABADELL, S.A.', bic: 'DSBLESMMXXX'},
        {entidad:'0232', banco: 'BANCO INVERSIS, S.A.', bic: 'INVLESMMXXX'},
        {entidad:'0233', banco: 'POPULAR BAMCA PRIVADA, S.A.', bic: 'POPIESMMXXX'},
        {entidad:'0234', banco: 'BANCO CAMINOS, S.A.', bic: 'CCOCESMMXXX'},
        {entidad:'0235', banco: 'BANCO PICHINCHA ESPAÑA, S.A.', bic: 'PIESESM1XXX'},
        {entidad:'0236', banco: 'LLOYDS BANK INTERNATIONAL, S.A.U.', bic: 'LOYIESMMXXX'},
        {entidad:'0237', banco: 'CAJASUR BANCO, S.A.', bic: 'CSURES2CXXX'},
        {entidad:'0238', banco: 'BANCO PASTOR, S.A.', bic: 'PSTRESMMXXX'},
        {entidad:'0239', banco: 'EVO BANCO, S.A.', bic: 'EVOBESMMXXX'},
        {entidad:'0486', banco: 'BANCO GRUPO CAJATRES, S.A.', bic: 'TRESES2BXXX'},
        {entidad:'0487', banco: 'BANCO MARE NOSTRUM, S.A.', bic: 'GBMNESMMXXX'},
        {entidad:'0488', banco: 'BANCO FINANCIERO Y DE AHORROS, S.A.', bic: 'BFASESMMXXX'},
        {entidad:'1000', banco: 'INSTITUTO DE CREDITO OFICIAL', bic: 'ICROESMMXXX'},
        {entidad:'1113', banco: 'BANQUE SUDAMERIS', bic: 'BSUDESM1XXX'},
        {entidad:'1116', banco: 'BANQUE SCANDINAVE EN SUISSE', bic: 'SCSIESM1XXX'},
        {entidad:'1127', banco: 'STANDARD CHARTERED BANK', bic: 'SCBLESM1XXX'},
        {entidad:'1156', banco: 'THE BANK OF NEW YORK MELLON', bic: 'IRVTESM1XXX'},
        {entidad:'1164', banco: 'ESPIRITO SANTO BANK OF FLORIDA', bic: 'ESBFESM1XXX'},
        {entidad:'1168', banco: 'BANCO NACIONAL DE CUBA', bic: 'BNACESM1XXX'},
        {entidad:'1173', banco: 'BANQUE COURTOIS', bic: 'COURESB1XXX'},
        {entidad:'1182', banco: 'UNICREDIT BANK AG', bic: 'HYVEESM1XXX'},
        {entidad:'1191', banco: 'SVENSKA HANDELSBANKEN', bic: 'HANDES21XXX'},
        {entidad:'1193', banco: 'PKB PRIVATBANK AG.', bic: 'PKBSES21XXX'},
        {entidad:'1196', banco: 'BANCO DE LAS ACTIVIDADES ECONOMICAS EXTERIORES', bic: 'AEEVESM1XXX'},
        {entidad:'1197', banco: 'BANQUE INTERNATIONALE A LUXEMBOURG', bic: 'BILLESB1XXX'},
        {entidad:'1199', banco: 'BANCA CARIGE SPA-CASSA RISPARMIO GENOVA E IMPERIA', bic: 'CRGEESM1XXX'},
        {entidad:'1209', banco: 'ALLIED BANKING CORPORATION', bic: 'ABCMESM1XXX'},
        {entidad:'1210', banco: 'BANQUE REGIONALE D\'ESCOMPTE ET DE DEPOTS', bic: 'REDEESM1XXX'},
        {entidad:'1221', banco: 'PHILIPPINE NATIONAL BANK, S.A.', bic: 'PNBMESM1XXX'},
        {entidad:'1224', banco: 'RHEINHYP RHEINISCHE HYPOTHEKENBANK A.G.', bic: 'RHRHESM1XXX'},
        {entidad:'1227', banco: 'BANQUE SCS ALLIANCE', bic: 'BSSAESB1XXX'},
        {entidad:'1231', banco: 'BANCO CANARIAS DE VENEZUELA, CA', bic: 'BOCAES21XXX'},
        {entidad:'1233', banco: 'ATTIJARIWAFA BANK', bic: 'BCMAESM1XXX'},
        {entidad:'1234', banco: 'BANCO DE LA PROVINCIA DE BUENOS AIRES', bic: 'PRBAESM1XXX'},
        {entidad:'1236', banco: 'LANDESBANK HESSEN-THUERINGEN GIROZENTRALE', bic: 'HELAESM1XXX'},
        {entidad:'1238', banco: 'BANCO INTERNACIONAL, S.A.', bic: 'BIMEESM1XXX'},
        {entidad:'1240', banco: 'LONDON FORFAITING A PARIS, S.A.', bic: 'LOFPESB1XXX'},
        {entidad:'1241', banco: 'BANCO SBS-AGRO', bic: 'STOLESM1XXX'},
        {entidad:'1242', banco: 'LANDESBANK BADEN-WURTTEMBERG', bic: 'SOLAESB1XXX'},
        {entidad:'1245', banco: 'BES INVESTIMENTO SA', bic: 'BEIVESM1XXX'},
        {entidad:'1248', banco: 'WAFABANK', bic: 'WAFAESM1XXX'},
        {entidad:'1249', banco: 'NORWICH AND PETERBOROUGH BUILDING SOCIETY', bic: 'NPBSES21XXX'},
        {entidad:'1251', banco: 'PRIVATBANK IHAG ZURICH', bic: 'IHZUES21XXX'},
        {entidad:'1255', banco: 'AAREAL BANK AG', bic: 'AARBESM1XXX'},
        {entidad:'1302', banco: 'ARGENTARIA, CAJA POSTAL Y BANCO HIPOTECA', bic: 'BBVAESMMXXX'},
        {entidad:'1451', banco: 'CAISSE REGIONALE CREDIT AGRICOLE MUTUEL SUD MED,SE', bic: 'CRCGESB1XXX'},
        {entidad:'1454', banco: 'NEWEDGE GROUP, S.E.', bic: 'NEWGESM1XXX'},
        {entidad:'1457', banco: 'DE LAGE LANDEN INTERNATIONAL B.V., S.E.', bic: 'LLISESM1XXX'},
        {entidad:'1459', banco: 'COOPERATIEVE CENTRALE RAIFFEISEN-BOERENLEENBANK B.A.(R.N.)', bic: 'PRABESMMXXX'},
        {entidad:'1460', banco: 'CREDIT SUISSE AG', bic: 'CRESESMMXXX'},
        {entidad:'1462', banco: 'ASSOCIATES CAPITAL CORPORATION, PLC. S.E.', bic: 'ASSCESM1XXX'},
        {entidad:'1463', banco: 'BANQUE PSA FINANCE, S.E.', bic: 'PSABESM1XXX'},
        {entidad:'1464', banco: 'NEWCOURT FINANCE (FRANCE) S.N.C., S.E.', bic: 'NFFSESM1XXX'},
        {entidad:'1465', banco: 'ING DIRECT, N.V.', bic: 'INGDESMMXXX'},
        {entidad:'1466', banco: 'FRANFINANCE, S.E.', bic: 'FRANESM1XXX'},
        {entidad:'1467', banco: 'HYPOTHEKENBANK FRANKFURT AG', bic: 'EHYPESMMXXX'},
        {entidad:'1469', banco: 'BANCO SYGMA HISPANIA, S.E.', bic: 'SHSAESM1XXX'},
        {entidad:'1470', banco: 'BANCO PORTUGUES DE INVESTIMENTO, S.A. S.E.', bic: 'BPIPESM1XXX'},
        {entidad:'1472', banco: 'CREDIT AGRICOLE LEASING & FACTORING, S.E.', bic: 'UCSSESM1XXX'},
        {entidad:'1473', banco: 'BANQUE PRIVEE EDMOND DE ROTHSCHILD EUROPE, S.E', bic: 'PRIBESMXXXX'},
        {entidad:'1474', banco: 'CITIBANK INTERNATIONAL PLC', bic: 'CITIESMXSEC'},
        {entidad:'1475', banco: 'CORTAL CONSORS, S.E.', bic: 'CCSEESM1XXX'},
        {entidad:'1478', banco: 'MERRILL LYNCH INTERNATIONAL BANK LTD., S.E.', bic: 'MLIBESM1XXX'},
        {entidad:'1479', banco: 'NATIXIS, S.A.', bic: 'NATXESMMXXX'},
        {entidad:'1480', banco: 'VOLKSWAGEN BANK GMBH, S.E.', bic: 'VOWAES21XXX'},
        {entidad:'1485', banco: 'BANK OF AMERICA, NATIONAL ASSOCIATION, S.E.', bic: 'BOFAES2XXXX'},
        {entidad:'1488', banco: 'PICTET & CIE (EUROPE), S.A., S.E.', bic: 'PICTESMMXXX'},
        {entidad:'1490', banco: 'SELF TRADE BANK, S.A.', bic: 'SELFESMMXXX'},
        {entidad:'1491', banco: 'TRIODOS BANK, N.V., S.E.', bic: 'TRIOESMMXXX'},
        {entidad:'1494', banco: 'INTESA SANPAOLO S.P.A., S.E.', bic: 'BCITESMMXXX'},
        {entidad:'1497', banco: 'BANCO ESPIRITO SANTO DE INVESTIMENTO, S.A.', bic: 'ESSIESMMXXX'},
        {entidad:'1501', banco: 'DEUTSCHE PFANDBRIEFBANK AG, S.E.', bic: 'DPBBESM1XXX'},
        {entidad:'1502', banco: 'IKB DEUTSCHE INDUSTRIEBANK AG, S.E.', bic: 'IKBDESM1XXX'},
        {entidad:'1505', banco: 'EUROPE ARAB BANK PLC, S.E.', bic: 'ARABESMMXXX'},
        {entidad:'1506', banco: 'MERRILL LYNCH INTERNATIONAL BANK LTD., S.E.', bic: 'MLCBESM1XXX'},
        {entidad:'1522', banco: 'EFG BANK (LUXEMBOURG) S.A., S.E.', bic: 'EFGBESMMXXX'},
        {entidad:'1524', banco: 'UBI BANCA INTERNATIONAL, S.A.', bic: 'UBIBESMMXXX'},
        {entidad:'1525', banco: 'BANQUE CHAABI DU MAROC, S.E.', bic: 'BCDMESMMXXX'},
        {entidad:'1534', banco: 'KBL EUROPEAN PRIVATE BANKERS, S.A. S.E.', bic: 'KBLXESMMXXX'},
        {entidad:'1538', banco: 'INDUSTRIAL & COMMERCIAL BANK OF CHINA(EUROPE)SA,SE', bic: 'ICBKESMMXXX'},
        {entidad:'1544', banco: 'ANDBANK ESPAÑA, S.A.', bic: 'BACAESMMXXX'},
        {entidad:'1545', banco: 'CREDIT AGRICOLE LUXEMBOURG, S.E.', bic: 'AGRIESMMXXX'},
        {entidad:'1583', banco: 'REVOLUT BANK UAB', bic: 'REVOESM2XXX'},
        {entidad:'2000', banco: 'CECABANK, S.A.', bic: 'CECAESMMXXX'},
        {entidad:'2010', banco: 'M.P. Y CAJA GENERAL AHORROS DE BADAJOZ', bic: 'CECAESMM010'},
        {entidad:'2013', banco: 'CATALUNYA BANC, S.A.', bic: 'CESCESBBXXX'},
        {entidad:'2017', banco: 'C.A.M.P. CIRCULO CATOLICO DE OBREROS DE BURGOS', bic: 'CECAESMM017'},
        {entidad:'2018', banco: 'CAJA DE AHORROS MUNICIPAL DE BURGOS', bic: 'CECAESMM018'},
        {entidad:'2024', banco: 'CAJA DE AHORROS Y M.P. DE CORDOBA', bic: 'CECAESMM024'},
        {entidad:'2030', banco: 'CAIXA D\'ESTALVIS DE GIRONA', bic: 'CAIXESBBXXX'},
        {entidad:'2031', banco: 'CAJA GENERAL DE AHORROS DE GRANADA', bic: 'CECAESMM031'},
        {entidad:'2032', banco: 'CAJA DE AHORRO PROVINCIAL DE GUADALAJARA', bic: 'CAIXESBBXXX'},
        {entidad:'2037', banco: 'CAJA DE AHORROS DE LA RIOJA', bic: 'CAHMESMMXXX'},
        {entidad:'2038', banco: 'BANKIA, S.A.', bic: 'CAHMESMMXXX'},
        {entidad:'2040', banco: 'CAIXA D\'ESTALVIS COMARCAL DE MANLLEU', bic: 'BBVAESMMXXX'},
        {entidad:'2041', banco: 'CAIXA D\'ESTALVIS DE MANRESA', bic: 'CESCESBBXXX'},
        {entidad:'2042', banco: 'CAIXA D\'ESTALVIS LAIETANA', bic: 'CAHMESMMXXX'},
        {entidad:'2043', banco: 'CAJA DE AHORROS DE MURCIA', bic: 'CECAESMM043'},
        {entidad:'2045', banco: 'CAJA DE AHORROS Y M.P. DE ONTINYENT', bic: 'CECAESMM045'},
        {entidad:'2048', banco: 'LIBERBANK, S.A.', bic: 'CECAESMM048'},
        {entidad:'2051', banco: 'CAJA DE AHORROS Y M.P. DE LAS BALEARES', bic: 'CECAESMM051'},
        {entidad:'2052', banco: 'CAJA INSULAR DE AHORROS DE CANARIAS', bic: 'CECAESMM052'},
        {entidad:'2054', banco: 'CAJA DE AHORROS Y M.P. DE NAVARRA', bic: 'CAIXESBBXXX'},
        {entidad:'2056', banco: 'COLONYA-CAIXA D\'ESTALVIS DE POLLENSA', bic: 'CECAESMM056'},
        {entidad:'2059', banco: 'CAIXA D\'ESTALVIS DE SABADELL', bic: 'BBVAESMMXXX'},
        {entidad:'2065', banco: 'CAJA GENERAL DE AHORROS DE CANARIAS', bic: 'CAIXESBBXXX'},
        {entidad:'2066', banco: 'CAJA DE AHORROS DE SANTANDER Y CANTABRIA', bic: 'CECAESMM066'},
        {entidad:'2069', banco: 'CAJA DE AHORROS Y M.P. DE SEGOVIA', bic: 'CAHMESMMXXX'},
        {entidad:'2071', banco: 'CAJA DE AH. PROV. SAN FERNANDO DE SEVILLA', bic: 'CECAESMM071'},
        {entidad:'2073', banco: 'CAIXA D\'ESTALVIS DE TARRAGONA', bic: 'CESCESBBXXX'},
        {entidad:'2074', banco: 'CAIXA D\'ESTALVIS DE TERRASSA', bic: 'BBVAESMMXXX'},
        {entidad:'2077', banco: 'CAJA DE AHORROS DE. VALENCIA, CASTELLON Y ALICANTE', bic: 'CAHMESMMXXX'},
        {entidad:'2080', banco: 'NCG BANCO, S.A.', bic: 'CAGLESMMXXX'},
        {entidad:'2081', banco: 'CAIXA D\'ESTALVIS DEL PENEDES', bic: 'CECAESMM081'},
        {entidad:'2085', banco: 'IBERCAJA BANCO, S.A.', bic: 'CAZRES2ZXXX'},
        {entidad:'2086', banco: 'BANCO GRUPO CAJATRES, S.A.', bic: 'CECAESMM086'},
        {entidad:'2090', banco: 'CAJA DE AHORROS DEL MEDITERRANEO', bic: 'BSABESBBXXX'},
        {entidad:'2091', banco: 'CAJA DE AHORROS DE GALICIA', bic: 'CAGLESMMXXX'},
        {entidad:'2092', banco: 'CAJA PROVINCIAL DE AHORROS DE JAEN', bic: 'UCJAES2MXXX'},
        {entidad:'2094', banco: 'CAJA DE AHORROS Y M.P. DE AVILA', bic: 'CAHMESMMXXX'},
        {entidad:'2095', banco: 'KUTXABANK, S.A.', bic: 'BASKES2BXXX'},
        {entidad:'2096', banco: 'CAJA ESPAÑA DE INVERSIONES, CAJA DE AHORROS Y M.P.', bic: 'CSPAES2LXXX'},
        {entidad:'2099', banco: 'CAJA DE AHORROS Y M.P. DE EXTREMADURA', bic: 'CECAESMM099'},
        {entidad:'2100', banco: 'CAIXABANK, S.A.', bic: 'CAIXESBBXXX'},
        {entidad:'2101', banco: 'CAJA DE AHORROS Y M.P. DE GIPUZKOA Y SAN SEBASTIAN', bic: 'CGGKES22XXX'},
        {entidad:'2103', banco: 'UNICAJA BANCO, S.A.', bic: 'UCJAES2MXXX'},
        {entidad:'2104', banco: 'CAJA DE AHORROS DE SALAMANCA Y SORIA', bic: 'CSSOES2SXXX'},
        {entidad:'2105', banco: 'CAJA DE AHORROS DE CASTILLA-LA MANCHA', bic: 'CECAESMM105'},
        {entidad:'2106', banco: 'M.P. Y C.A. SAN FERNANDO DE HUELVA JEREZ', bic: 'CAIXESBBXXX'},
        {entidad:'2107', banco: 'UNNIM BANC, S.A', bic: 'BBVAESMM107'},
        {entidad:'2108', banco: 'BANCO DE CAJA ESPAÑA DE INVERSIONES,SALAMANCA Y SORIA, S.A.', bic: 'CSPAES2L108'},
        {entidad:'3001', banco: 'CAJA R. DE ALMENDRALEJO, S.C.C.', bic: 'BCOEESMM001'},
        {entidad:'3005', banco: 'CAJA R. CENTRAL, S.C.C.', bic: 'BCOEESMM005'},
        {entidad:'3007', banco: 'CAJA R. DE GIJON, C.C', bic: 'BCOEESMM007'},
        {entidad:'3008', banco: 'CAJA R. DE NAVARRA, S.C.C.', bic: 'BCOEESMM008'},
        {entidad:'3009', banco: 'CAJA R. DE EXTREMADURA, S.C.C.', bic: 'BCOEESMM009'},
        {entidad:'3016', banco: 'CAJA R. DE SALAMANCA, S.C.C.', bic: 'BCOEESMM016'},
        {entidad:'3017', banco: 'CAJA R. DE SORIA, S.C.C.', bic: 'BCOEESMM017'},
        {entidad:'3018', banco: 'CAJA R.R.S.AGUSTIN DE FUENTE ALAMO M., S.C.C.', bic: 'BCOEESMM018'},
        {entidad:'3020', banco: 'CAJA R. DE UTRERA, S.C.A.C.', bic: 'BCOEESMM020'},
        {entidad:'3021', banco: 'CAJA R. DE ARAGON, S.C.C.', bic: 'BCOEESMM021'},
        {entidad:'3023', banco: 'CAJA R. DE GRANADA, S.C.C.', bic: 'BCOEESMM023'},
        {entidad:'3025', banco: 'CAIXA DE CREDIT DELS ENGINYERS, S.C.C.', bic: 'CDENESBBXXX'},
        {entidad:'3029', banco: 'CAJA DE CREDITO DE PETREL, CAJA RURAL, C.C.V.', bic: 'CCRIES2A029'},
        {entidad:'3035', banco: 'CAJA LABORAL POPULAR, C.C.', bic: 'CLPEES2MXXX'},
        {entidad:'3045', banco: 'CAIXA R. ALTEA, C.C.V.', bic: 'CCRIES2A045'},
        {entidad:'3056', banco: 'CAJA R. DE ALBACETE, S.C.C.', bic: 'BCOEESMM056'},
        {entidad:'3058', banco: 'CAJAS RURALES UNIDAS, S.C.C.', bic: 'CCRIES2AXXX'},
        {entidad:'3059', banco: 'CAJA R. DE ASTURIAS, S.C.C.', bic: 'BCOEESMM059'},
        {entidad:'3060', banco: 'CAJA R. DE BURGOS, SEGOVIA Y CASTELLDANS, S.C.C.', bic: 'BCOEESMM060'},
        {entidad:'3062', banco: 'CAJA R. DE CIUDAD REAL, S.C.C.', bic: 'BCOEESMM062'},
        {entidad:'3063', banco: 'CAJA R. DE CORDOBA, S.C.C.', bic: 'BCOEESMM063'},
        {entidad:'3066', banco: 'CAJA R. DE HUESCA, S.C.C.', bic: 'BCOEESMM066'},
        {entidad:'3067', banco: 'CAJA R. DE JAEN, BARCELONA Y MADRID, S.C.C.', bic: 'BCOEESMM067'},
        {entidad:'3070', banco: 'CAIXA R. GALEGA, S.C.C.L.G.', bic: 'BCOEESMM070'},
        {entidad:'3076', banco: 'CAJASIETE, CAJA RURAL, S.C.C.', bic: 'BCOEESMM076'},
        {entidad:'3078', banco: 'CAJA R. DE SEGOVIA, C.C.', bic: 'BCOEESMM078'},
        {entidad:'3080', banco: 'CAJA R. DE TERUEL, S.C.C.', bic: 'BCOEESMM080'},
        {entidad:'3081', banco: 'CAJA RURAL DE CASTILLA-LA MANCHA, S.C.C.', bic: 'BCOEESMM081'},
        {entidad:'3082', banco: 'CAJA R. DEL MEDITERRANEO, RURALCAJA, S.C', bic: 'BCOEESMM082'},
        {entidad:'3083', banco: 'CAJA R. DEL DUERO, S.C.C.L.', bic: 'BCOEESMM083'},
        {entidad:'3084', banco: 'IPAR KUTXA RURAL, S.C.C.', bic: 'CVRVES2BXXX'},
        {entidad:'3085', banco: 'CAJA R. DE ZAMORA, C.C.', bic: 'BCOEESMM085'},
        {entidad:'3089', banco: 'CAJA R. BAENA NTRA. SRA. GUADALUPE S.C.C.A.', bic: 'BCOEESMM089'},
        {entidad:'3092', banco: 'CAJA R. DE ZARAGOZA, S.C.C.', bic: 'BCOEESMM092'},
        {entidad:'3094', banco: 'CAJA CAMPO, CAJA RURAL, S.C.C.', bic: 'BCOEESMM094'},
        {entidad:'3095', banco: 'CAJA R. S. ROQUE DE ALMENARA S.C.C.V.', bic: 'CCRIES2A095'},
        {entidad:'3096', banco: 'CAIXA R. DE L\'ALCUDIA, S.C.V.C.', bic: 'BCOEESMM096'},
        {entidad:'3098', banco: 'CAJA R. NTRA. SRA. DEL ROSARIO, S.C.A.C.', bic: 'BCOEESMM098'},
        {entidad:'3102', banco: 'CAIXA R. S. VICENT FERRER DE LA VALL D\'UIXO,C.C.V.', bic: 'BCOEESMM102'},
        {entidad:'3104', banco: 'CAJA R. DE CAÑETE TORRES NTRA.SRA.DEL CAMPO,S.C.A.', bic: 'BCOEESMM104'},
        {entidad:'3105', banco: 'CAIXA R. DE CALLOSA D\'EN SARRIA, C.C.V.', bic: 'CCRIES2A105'},
        {entidad:'3110', banco: 'CAJA R. CATOLICO AGRARIA, S.C.C.V.', bic: 'BCOEESMM110'},
        {entidad:'3111', banco: 'CAIXA R. LA VALL \'S. ISIDRO\', S.C.C.V.', bic: 'BCOEESMM111'},
        {entidad:'3112', banco: 'CAJA R. S. JOSE DE BURRIANA, S.C.C.V.', bic: 'CCRIES2A112'},
        {entidad:'3113', banco: 'CAJA R. S. JOSE DE ALCORA S.C.C.V.', bic: 'BCOEESMM113'},
        {entidad:'3114', banco: 'CAJA R. CASTELLON S. ISIDRO, S.C.C.V.', bic: 'BCOEESMM114'},
        {entidad:'3115', banco: 'CAJA R. NUESTRA MADRE DEL SOL, S.C.A.C.', bic: 'BCOEESMM115'},
        {entidad:'3116', banco: 'CAJA R. COMARCAL DE MOTA DEL CUERVO, S.C.L.C.A.', bic: 'BCOEESMM116'},
        {entidad:'3117', banco: 'CAIXA R. D\'ALGEMESI, S.C.V.C.', bic: 'BCOEESMM117'},
        {entidad:'3118', banco: 'CAIXA RURAL TORRENT C.C.V.', bic: 'CCRIES2A118'},
        {entidad:'3119', banco: 'CAJA R. S. JAIME ALQUERIAS NIÑO PERDIDO S.C.C.V.', bic: 'CCRIES2A119'},
        {entidad:'3121', banco: 'CAJA R. DE CHESTE, S.C.C.', bic: 'CCRIES2A121'},
        {entidad:'3123', banco: 'CAIXA R. DE TURIS, C.C.V.', bic: 'CCRIES2A123'},
        {entidad:'3127', banco: 'CAJA R. DE CASAS IBAÑEZ, S.C.C.CASTILLA-LA MANCHA', bic: 'BCOEESMM127'},
        {entidad:'3130', banco: 'CAJA R. S. JOSE DE ALMASSORA, S.C.C.V.', bic: 'BCOEESMM130'},
        {entidad:'3134', banco: 'CAJA R. NTRA. SRA. LA ESPERANZA DE ONDA, S.C.C.V.', bic: 'BCOEESMM134'},
        {entidad:'3135', banco: 'CAJA R. S. JOSE DE NULES S.C.C.V.', bic: 'CCRIES2A135'},
        {entidad:'3137', banco: 'CAJA R. DE CASINOS S.C.C.V.', bic: 'CCRIES2A137'},
        {entidad:'3138', banco: 'CAJA R. DE BETXI, S.C.C.V.', bic: 'BCOEESMM138'},
        {entidad:'3140', banco: 'CAJA R. DE GUISSONA, S.C.C.', bic: 'BCOEESMM140'},
        {entidad:'3144', banco: 'CAJA R. DE VILLAMALEA, S.C.C.A. CASTILLA-LA MANCHA', bic: 'BCOEESMM144'},
        {entidad:'3146', banco: 'CAJA DE CREDITO COOPERATIVO, S.C.C.', bic: 'CCCVESM1XXX'},
        {entidad:'3150', banco: 'CAJA R. DE ALBAL, C.C.V.', bic: 'BCOEESMM150'},
        {entidad:'3152', banco: 'CAJA R. DE VILLAR C.C.V.', bic: 'CCRIES2A152'},
        {entidad:'3157', banco: 'CAJA R. LA JUNQUERA DE CHILCHES, S.C.C.V.', bic: 'CCRIES2A157'},
        {entidad:'3159', banco: 'CAIXA POPULAR-CAIXA RURAL, S.C.C.V.', bic: 'BCOEESMM159'},
        {entidad:'3160', banco: 'CAIXA R.S.JOSEP DE VILAVELLA, S.C.C.V.', bic: 'CCRIES2A160'},
        {entidad:'3162', banco: 'CAIXA R. BENICARLO, S.C.C.V.', bic: 'BCOEESMM162'},
        {entidad:'3165', banco: 'CAJA R. S. ISIDRO DE VILAFAMES, S.C.C.V.', bic: 'CCRIES2A165'},
        {entidad:'3166', banco: 'CAIXA RURAL LES COVES DE VINROMA, S.C.C.V.', bic: 'BCOEESMM166'},
        {entidad:'3171', banco: 'CAIXA DELS ADVOCATS-CAJA DE LOS ABOGADOS, S.C.C.', bic: 'CXAVESB1XXX'},
        {entidad:'3172', banco: 'CAJA CAMINOS, S.C.C.', bic: 'CCOCESMMXXX'},
        {entidad:'3174', banco: 'CAIXA R. VINAROS, S.C.C.V.', bic: 'BCOEESMM174'},
        {entidad:'3177', banco: 'CAJA R. DE CANARIAS, S.C.C.', bic: 'BCOEESMM177'},
        {entidad:'3179', banco: 'CAJA R. DE ALGINET, S.C.C.V.', bic: 'CCRIES2A179'},
        {entidad:'3183', banco: 'CAJA DE ARQUITECTOS, S.C.C.', bic: 'CASDESBBXXX'},
        {entidad:'3186', banco: 'CAIXA R. ALBALAT DELS SORELLS, C.C.V.', bic: 'CCRIES2A186'},
        {entidad:'3187', banco: 'CAJA R. DEL SUR, S. COOP. DE CREDITO', bic: 'BCOEESMM187'},
        {entidad:'3188', banco: 'CREDIT VALENCIA, C.R.C.C.V.', bic: 'CCRIES2A188'},
        {entidad:'3190', banco: 'C.R. DE ALBACETE, CIUDAD REAL Y CUENCA, S.C.C.', bic: 'BCOEESMM190'},
        {entidad:'3191', banco: 'CAJA RURAL DE ARAGON SOC. COOP. DE CREDITO', bic: 'BCOEESMM191'},
        {entidad:'3501', banco: 'RENTA 4 SOCIEDAD DE VALORES, S.A.', bic: 'RENTESMMXXX'},
        {entidad:'3524', banco: 'AHORRO CORPORACION FINANCIERA, S.A.,SOCIEDAD DE VALORES', bic: 'AHCFESMMXXX'},
        {entidad:'3563', banco: 'MAPFRE INVERSION, SOCIEDAD DE VALORES, S.A.', bic: 'MISVESMMXXX'},
        {entidad:'3575', banco: 'INVERSEGUROS, SOCIEDAD DE VALORES, S.A.', bic: 'INSGESMMXXX'},
        {entidad:'3604', banco: 'CM CAPITAL MARKETS BOLSA, SOCIEDAD DE VALORES, S.A.', bic: 'CAPIESMMXXX'},
        {entidad:'3641', banco: 'LINK SECURITIES, SOCIEDAD DE VALORES, S.A.', bic: 'LISEESMMXXX'},
        {entidad:'3656', banco: 'FINANDUERO, SOCIEDAD DE VALORES, S.A.', bic: 'CSSOES2SFIN'},
        {entidad:'3661', banco: 'MERRILL LYNCH CAPITAL MARJETS ESPAÑA, S.A.', bic: 'MLCEESMMXXX'},
        {entidad:'3669', banco: 'INTERMONEY VALORES, SOCIEDAD DE VALORES, S.A.', bic: 'IVALESMMXXX'},
        {entidad:'3682', banco: 'GVC GAESCO VALORES, SOCIEDAD DE VALORES, S.A.', bic: 'GVCBESBBETB'},
        {entidad:'6814', banco: 'MONTY GLOBAL PAYMENTS, S.A.', bic: 'MNTYESMMXXX'},
        {entidad:'6852', banco: 'BMCE EUROSERVICES, S.A.', bic: 'BMEUESM1XXX'},
        {entidad:'8233', banco: 'CELERIS SERVICIOS FINANCIEROS, S.A., E.F.C.', bic: 'CSFAESM1XXX'},
        {entidad:'8512', banco: 'UNION DE CREDITOS INMOBILIARIOS, S.A., E.F.C.', bic: 'UCINESMMXXX'},
        {entidad:'8835', banco: 'SANTANDER BRASIL, E.F.C., S.A.', bic: 'SBFCESMMXXX'},
        {entidad:'9000', banco: 'BANCO DE ESPAÑA', bic: 'ESPBESMMXXX'},
        {entidad:'9020', banco: 'SOCIEDAD ESPAÑOLA DE SISTEMAS DE PAGO, S.A.', bic: 'IPAYESMMXXX'},
        {entidad:'9091', banco: 'SOCIEDAD RECTORA BOLSA DE VALORES DE BARCELONA, S.A.', bic: 'XBCNESBBXXX'},
        {entidad:'9092', banco: 'SOCIEDAD RECTORA BOLSA DE VALORES DE BILBAO, S.A.', bic: 'XRBVES2BXXX'},
        {entidad:'9093', banco: 'SOCIEDAD RECTORA BOLSA DE VALORES DE VALENCIA, S.A.', bic: 'XRVVESVVXXX'},
        {entidad:'9094', banco: 'MEFF SOCIEDAD RECTORA DE PRODUCTOS DERIVADOS, S.A.U.', bic: 'MEFFESBBXXX'},
        {entidad:'9096', banco: 'SOCIEDAD DE GESTION DE LOS SISTEMAS DE REGISTRO, S.A.U.', bic: 'IBRCESMMXXX'}
    ]
}];