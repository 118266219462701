import { yupResolver } from '@hookform/resolvers/yup';
//import { useToast } from 'app/hooks/useToast';
import { isArray, isEmpty } from 'lodash';
import { useEffect, useMemo, useRef } from "react";
import { FormProvider, useForm } from "react-hook-form";
import AutoSubmit from "./AutoSubmit";

export default function FormRHF({ defaultValues, useToastErrors = false, schema = null, validate = null, children, onSubmit, autoReset = true, autoSubmit = false, ...props}) {

  const methods = useForm(
  { 
    defaultValues, 
    mode: "onBlur",
    resolver: schema ? yupResolver(schema) : validate ? validate : null,
    shouldUnregister: true,
    ...props
  });

  const { handleSubmit, reset, formState } = methods;

  const {errors} = formState;

  const formRef = useRef(null);

  //const {toast} = useToast();

  //defaultValues debe venir siempre de un estado o una variable memorizada. Si no, puede producir que se resetee el formulario a cada rerender del componente que contenga la variable.
  useEffect(() => {

    if (autoReset)
    {
        reset(defaultValues);
    }
 
  },[defaultValues, autoReset, reset]);

  useEffect(() => {

    if (useToastErrors && !isEmpty(errors))
    {
        var msg = [];

        Object.keys(errors).forEach(keyError => {

            if (!isArray(errors[keyError]) && errors[keyError].message)
            {
                msg.push(`${errors[keyError].message}`);
                msg.push(<br key={`br_${keyError}`}/>);
            }
        })


        //toast('error', msg);

    }


  }, [errors, useToastErrors]);

  const childrenProps = useMemo(() => ({
    formRef,
    ...methods
  }), [formRef, methods])

  return (
    <FormProvider {...methods}>
      <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
        <>
        {
          typeof children !== 'function' ? children : children(childrenProps)
        }
        {
          autoSubmit && (
            <AutoSubmit formRef={formRef} />
          )
        }
        </>
      </form>
    </FormProvider>
  );
}