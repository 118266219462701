import { Button, Grid, Icon } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { useFormikContext } from "formik";
import { useCallback, useState } from "react";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { FormSelect, FormTextInput } from '../../../../componentes/formularios/core';
import { pintaOpcionesSelect } from "../../../../helpers/libFunciones";
import * as permisos from '../../../../helpers/permisos';
import ModalColorIcono from './../../../../componentes/modalesComunes/ModalColorIcono';
import Api from './../../../../crud/Api';
import TienePermisos from './../../../../helpers/TienePermisos';
import { useGlobalLoader } from './../../../../hooks/hooksPropios';

const ElementoCategoria = (props) => {

  const { index, setCategorias, tiposCategorias } = props;


  const [open, setOpen] = useState(false);

  const { values, setFieldValue } = useFormikContext();

  const close = () => {
    setOpen(false);
  }


  const MySwal = withReactContent(Swal);
  const { setGlobalCargando } = useGlobalLoader();


  const useStyles = makeStyles((theme) => (
    {
      "iconoColor": {
        width: "70%",
        cursor: "pointer",
        marginTop: "10px",
        padding: "5px",
        textAlign: "center",
        borderRadius: "5px 5px 5px 5px",
        backgroundColor: colorIcon,
        "& span": {
          color: "white",
          fontSize: "30px"
        },
        "&:hover": {
          filter: "saturate(90%)"
        }
      }
    }
  ));

  const colorIcon = (values.categorias[index]) ? values.categorias[index].color : "#000000";

  const classes = useStyles(colorIcon);

  const eliminar = useCallback((index) => {

    MySwal.fire({
      title: '¿Estás seguro de eliminar la categoria?',
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {

        setGlobalCargando(true);

        Api("delete", `categorias/${values.categorias[index].id}`).then((data) => {

          if (data.data.success === true) {
            Swal.fire(
              'Borrado!',
              'Se ha borrado la categoria',
              'success'
            );

            let cat = [];

            values.categorias.forEach((item, indexCat) => {
              if (indexCat === index) {

              } else {
                cat.push(item);
              }
            });

            setCategorias({ categorias: cat });

          } else {

            Swal.fire(
              'Error!',
              data.data.msg,
              'error'
            )
          }

          setGlobalCargando(false);

        }).catch(() => {

          Swal.fire(
            'Error!',
            'No se ha podido eliminar',
            'error'
          )

          setGlobalCargando(false);

        });


        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {

      }
    })

  }, [setCategorias, values, MySwal, setGlobalCargando]);

  const eliminarNew = (index) => {

    let aux = [];

    values.categorias.forEach((item, indexCat) => {
      if (indexCat !== index)
        aux.push(item);
    })

    setCategorias({ categorias: aux });


  }


  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} lg={2} >
          <div className={classes.iconoColor} onClick={e => { setOpen(true) }}>
            <Icon>
              {(values.categorias[index]) ? values.categorias[index].icono : null}
            </Icon>
          </div>
        </Grid>

        <Grid item xs={12} lg={2}>
          <FormTextInput name={`categorias[${index}].nombre`} label="Nombre *"></FormTextInput>
        </Grid>

        <Grid item xs={12} lg={3}>
          <FormTextInput name={`categorias[${index}].descripcion`} fast label="Descripción"></FormTextInput>
        </Grid>

        <Grid item xs={12} lg={2}>
          <FormTextInput name={`categorias[${index}].referencia`} fast label="Referencia"></FormTextInput>
        </Grid>

        <Grid item xs={12} lg={2}>
          <FormSelect name={`categorias[${index}].idTipo`} label="Tipo">
            {pintaOpcionesSelect(tiposCategorias, "id", "nombre", `tiposCategoria_`, 0)}
          </FormSelect>
        </Grid>

        <Grid item xs={12} lg={1}>
          <br></br>
          {
          (TienePermisos([permisos.categorias.eliminar]))?
          <Button variant="contained" onClick={(e) => { (values.categorias[index].id > 0) ? eliminar(index) : eliminarNew(index) }}>
            Eliminar
          </Button>
          :null
            }

        </Grid>

      </Grid>
      {values.categorias[index] ?
        <ModalColorIcono open={open} close={close}
          icono={values.categorias[index].icono} color={values.categorias[index].color}
          setColor={(c) => { setFieldValue(`categorias[${index}].color`, c) }}
          setIcono={(c) => { setFieldValue(`categorias[${index}].icono`, c) }} />
        : null}
    </>

  );



}

export default ElementoCategoria;