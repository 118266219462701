import { TextField, useTheme } from "@mui/material";
import Etiqueta from "app/componentes/generales/Etiqueta";
import TienePermisos from "app/helpers/TienePermisos";
import { muestraPrecio, pintaOpcionesSelect } from "app/helpers/libFunciones";
import { recogidas } from "app/helpers/permisos";
import { useRoles } from "app/hooks/hooksPropios";
import { PreciosTrafico } from "app/modules/trafico/models/trafico.types";
import { TooltipG } from "app/pages/utils";
import { capitalize } from "lodash";
import { ChangeEventHandler } from "react";

interface EtiquetasEnvioProps {
	classes: any;
	datos: any;
	desactivarMensajero: boolean;
	handleSubmitMensajero: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> | undefined;
	mensajeros: any[];
    estado: any;
    estadoTooltip: any;
    precios: PreciosTrafico;
}

export default function EtiquetasRecogida(props: EtiquetasEnvioProps) {

    const theme = useTheme() as any;

    const {esRolAdministrativo, esMensajero} = useRoles();

    const {classes, datos, estado, estadoTooltip, precios, mensajeros = [], handleSubmitMensajero} = props;

	return (
		<>
			<Etiqueta
				texto={datos.tipo === "recogidaFueraPlaza" ? "RF" : "R"}
				clases={classes.etiqueta}
				estilos={{
					backgroundColor:
						datos.tipo === "recogidaFueraPlaza"
							? theme.palette.trafico.recogidasFueraPlaza.main
							: theme.palette.trafico.recogidas.main,
					color: "white",
				}}
			/>

			{esRolAdministrativo && <Etiqueta texto={datos.id} clases={classes.etiqueta} />}

			{datos.tipo === "recogidaFueraPlaza" ? (
				<>
					<Etiqueta texto={datos.nombreRed} clases={classes.etiqueta} estilos={{ backgroundColor: "#000674", color: "white" }} />
					<Etiqueta texto={datos.codRecogida} clases={classes.etiqueta} />
					{datos?.seguimientoRecogida.length > 0 && (
						<Etiqueta
							estilos={{ backgroundColor: "#000674", color: "white" }}
							texto={datos.seguimientoRecogida[0]["descripcion"]}
							tooltip={datos.seguimientoRecogida[0]["fecha"]}
							clases={classes.etiqueta}
						/>
					)}
				</>
			) : (
				<Etiqueta texto={capitalize(estado.estado)} tooltip={estadoTooltip} clases={classes.etiqueta} />
			)}

			{datos.numEnvios > 0 && <Etiqueta texto={`ENVIOS: ${datos.numEnvios}`} clases={classes.etiqueta} />}
			{datos.numBultos > 0 && <Etiqueta texto={`BULTOS: ${datos.numBultos}`} clases={classes.etiqueta} />}

			{esRolAdministrativo &&
				(precios.precio_total > 0 ? (
					<>
						<Etiqueta
							tooltip="Coste estimado"
							texto={muestraPrecio(precios.coste_total) + " €"}
							clases={classes.etiqueta}
							estilos={{ backgroundColor: "#63c086", color: "white" }}
						/>
						<Etiqueta
							tooltip="Precio total sin IVA"
							texto={`${muestraPrecio(precios.precio_total)} € (${muestraPrecio(precios.margen_est)}%)`}
							clases={classes.etiqueta}
							estilos={{ backgroundColor: "#43ac6a", color: "white" }}
						/>
					</>
				) : null)}

			{esRolAdministrativo || (esMensajero && TienePermisos([recogidas.ver_todos])) ? (
				<div className={classes.opcionesMensajero}>
					<TooltipG title="Seleccionar Mensajero">
						<TextField select name="idMensajero" onChange={handleSubmitMensajero} label="" value={datos.idMensajero || ""}>
							{pintaOpcionesSelect(mensajeros, "id", "nombreCompleto", "selectMensajerosTrafico")}
						</TextField>
					</TooltipG>
				</div>
			) : datos.idMensajero > 0 && esMensajero ? (
				<Etiqueta texto={`${datos.nombreMensajero}`} clases={classes.etiqueta} />
			) : null}
		</>
	);
}
