import { makeStyles } from "@mui/styles";



export const useStyles = makeStyles(theme => {
    

    return {
        dropzoneContainer: {
            display: "flex",
            flexDirection: "column",
            width: "100%"
        },
        dropzone: {
            "flex": "1",
            "background": "#f9f9f9",
            "display": "flex",
            "alignItems": "center",
            "justifyContent": "center",
            "padding": "45px",
            "cursor": "pointer",
            "color": "grey",
            "border": "3px dashed #e6e6e6"
        },
        thumbsContainer: {
            display:"flex",
            marginTop: "15px",
            flexWrap: "wrap"
        },
        thumb: {
            "display": "flex",
            "flexDirection": "column",
            "width": "125px",
            "minWidth": "125px",
            "margin": "5px",
            "boxShadow": "1px 0 3px #00000040"
        },
        thumbImageContainer: {
            "width": "100%",
            "height": "100px",
            "flex": "1",
            "padding": "5px"
        },
        image: {
            width: "100%",
            height: "100%"
        },
        actionsContainer: {
            display: "flex"
        },
        action: {
            
            "flex": "1",
            "display": "flex",
            "alignItems": "center",
            "justifyContent": "center",
            "padding": "3px",
            "background": "#444444",
            cursor: "pointer",
            "color": "white",
            transition: "0.2s"
              
        },
        actionVer: {
            '&:hover': {
                background: "#3b96e4",
                transition: "0.2s",
            }
        },
        actionBorrar: {
            '&:hover': {
                background: "#e43b3b",
                transition: "0.2s"
            }
        },
        enlaceAction: {
            "width": "100%",
            "display": "flex",
            "justifyContent": "center",
            "color": "white",
            "&:hover": {
                color:"white"
            }
        }
        ,nombreFichero: {
            float: "left", 
            whiteSpace: "nowrap",
            textOverflow: "ellipsis", 
            overflow: "hidden",
            padding:"5px"
        }
    }
});