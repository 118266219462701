import { toPairs } from 'lodash';
import { muestraPrecio } from '../../../helpers/libFunciones';
import { IVA_REF } from './../../../helpers/constants';
import { calculaValores } from './../../../pages/home/tarifas/FuncionesTarifas';




const calculaTotal = (valoraciones) => {

    let coste_total = 0;
    let precio_total = 0;
    let margen_beneficio = 0;
    let beneficio = 0;
    let precio_iva=0;

    valoraciones && valoraciones.forEach(item => {
        coste_total = parseFloat(coste_total) + parseFloat(item.coste);
        precio_total = parseFloat(precio_total) + parseFloat(item.precio);    
    });

    beneficio = (precio_total - coste_total);

    if (beneficio > 0 && precio_total > 0)
        margen_beneficio = ((beneficio / precio_total) * 100);
    else
        margen_beneficio = 0;

    precio_iva = ((precio_total * 1.21));


    return { coste_total, precio_total, margen_beneficio, beneficio, precio_iva };

}


export const filtraServiciosVinculados = (variables, idServicio, idRuta) => {

    var variablesRedes = [];

    //Servicios vinculados
    if (idServicio > 0 && idRuta > 0) {

        variables && variables.forEach((aux_var) => {


            //Filtramos si hay restricciones de servicios/rutas
            if (aux_var?.servicios_vinculados.id != undefined) {

                var indexServicio = aux_var.servicios_vinculados.servicios.map(item => item.id).indexOf(idServicio);

                //opcion habilitar
                if (aux_var.servicios_vinculados?.opcion == "1") {

                    if (indexServicio >= 0) {
                        if (aux_var.servicios_vinculados.servicios[indexServicio].rutas.indexOf(idRuta) >= 0) {
                            variablesRedes.push(aux_var);
                        }
                    }

                    //opcion deshabilitar
                } else if (aux_var.servicios_vinculados?.opcion == "2") {

                    if (indexServicio >= 0) {
                        if (aux_var.servicios_vinculados.servicios[indexServicio].rutas.indexOf(idRuta) == -1) {
                            variablesRedes.push(aux_var);
                        }
                    } else {
                        variablesRedes.push(aux_var);
                    }

                } else {
                    variablesRedes.push(aux_var);
                }
            } else {
                variablesRedes.push(aux_var);
            }
        }
        );

    } else {
        variablesRedes = variables;
    }

    return variablesRedes;

};


export const FuncionesVariables = () => {


    const setVariablesFormik = (tarifaVariables, variablesRed, variablesOpcionales) => {

        var aux = [];

        if (tarifaVariables && variablesRed.length > 0) {

            variablesRed.forEach(item => {
                if (item.opcional === "1" || item.depende === "variable") {

                    //Filtramos que esten activas en las variables de la tarifa.    
                    if (tarifaVariables.filter(i => i.idVariable === item.id).length > 0) {

                        //Buscamos en variables iniciales                     
                        let var_aux = variablesOpcionales && variablesOpcionales.filter(i => i.idVariable === item.id);

                        let checked_aux = false;

                        let valor_aux = null;
                        let activo_aux = "0";

                        if (var_aux.length === 1) {
                            checked_aux = true;
                            if (parseInt(var_aux[0].valor) > 0) {
                                valor_aux = var_aux[0].valor;
                                activo_aux = "1";
                                checked_aux = false;
                            }

                        }

                        aux.push({ id: item.id, nombre: item.nombre, depende: item.depende, checked: checked_aux, valor: valor_aux, activo: activo_aux, interno: item.interno });

                    }
                }
            });

        }

        return aux;
    }


    const calculaPrecios = (item, margenDefecto, factor, factor2 = 0) => {

        let coste = 0;
        let precio = 0;
        let objCalc = {};



        if (item.tipo === "€") {
            coste = factor * item.valor;

        } else {
            if (item.tipo === "%") {                
                coste = factor * (1 + (item.valor / 100));

            } else {
                coste = factor * ((item.valor / 100));

            }
        }


        let objImporte = {};
        let objImporte2 = {};
        let objValor={};
        let objImporteMinimo = {};
        let aplicaMinimoVariable=false; 
        let prefijoValor=item.descuento=="1"?"-":"";
  
        //Calculamos el precio final
        if (item.tarifa.tipoModificacion === null || item.tarifa.tipoModificacion == undefined) {

           
            if (item.tipo === "€") {
                objImporte = calculaValores(coste, null, margenDefecto);

                if (factor2 > 0) {
                    objValor = calculaValores(item.valor, null, margenDefecto);
                    objImporte2={valorCalculado:factor2 * objValor.valorCalculado,margenCalculado:objValor.margenCalculado};
                }

            } else {
                //Calculamos el nuevo porcentaje 
                objImporte = calculaValores(item.valor, null, margenDefecto);

            }
        } else {
          

            if (item.tarifa.tipoModificacion === "inactivo") {
                objImporte = { coste: "0", precio: "0", margen: "0" }

                if (factor2 > 0) {
                    objImporte2 = { coste: "0", precio: "0", margen: "0" }
                }
            } else {

                if (item.tarifa.valor >= 0 && item.tarifa.valor!=null) {
                    if(item.tarifa.valor==0){

                        if(coste>0){
                            objImporte = { coste: coste, precio: "0", margen: "0", valorCalculado: "0" }                            
                        }else{

                            objImporte = { coste: "0", precio: "0", margen: "0",valorCalculado:"0" }                       
                            coste=0;
                        }
                    }else{
                        if (item.tipo === "€") {
                            objImporte = calculaValores(coste, item.tarifa.valor * factor, null);                        

                            if (factor2 > 0) {
                                objValor = calculaValores(item.tarifa.valor, null, margenDefecto);
                                objImporte2={valorCalculado:factor2 * objValor.valorCalculado,margenCalculado:objValor.margenCalculado};
                            }

                        } else {
                            //Se calcula el tanto % de nuevo, segun tarifa                                                
                                objImporte = calculaValores(item.valor, item.tarifa.valor, null);
                        }
                  }
                } else {
                    if (item.tipo === "€") {
                      
                            objImporte = calculaValores(coste, null, item.tarifa.margen);                        

                        if (factor2 > 0) {
                            objValor = calculaValores(item.valor, null, item.tarifa.margen);
                            objImporte2 = { valorCalculado: factor2 * objValor.valorCalculado, margenCalculado: objValor.margenCalculado };
                        }
                        
                    } else {
                        //Se calcula el tanto % de nuevo, segun tarifa      
                        if(item.tarifa.margen==0){                    
                            coste=0;
                        }

                        objImporte = calculaValores(item.valor, null, item.tarifa.margen);                        
                       
                    }

                }

            }


        }

      

        //si es por margen hay que calcular el margen y luego calcular el valor de nuevo
        if (item.tipo === "%" || item.tipo === "solo_%") {
        
            var aux = 0;
            aux=coste;

            objImporte2=objImporte;             

            var obj = { margenCalculado: item.valor, valorCalculado: aux }
            objImporte = obj;

            if (factor2 > 0 ) {
                            
                aux = 0;
                if (objImporte2.valorCalculado > 0) {
                    if (item.tipo === "%") {
                        aux = factor2 * (1 + (objImporte2.valorCalculado / 100));
                    } else {
                        aux = factor2 * ((objImporte2.valorCalculado / 100));
                    }

                }else{
                    aux = objImporte.valorCalculado;
                }

                var obj2 = { margenCalculado: objImporte2.valorCalculado, valorCalculado: aux }
                objImporte2 = obj2;

                if(item.tarifa.valor==0){
                     objImporte2 = { margenCalculado: 0, valorCalculado: 0 };
                }

            }else{

                if(item.tipo=="solo_%"){
                    objImporte = { valorCalculado: (objImporte2.valorCalculado/100)*factor, margenCalculado: objImporte2.valorCalculado }
                }else if(item.tipo=="%"){
                    objImporte = { valorCalculado: (1+(objImporte2.valorCalculado / 100)) * factor, margenCalculado: objImporte2.valorCalculado }
                }

            }

        }

        
        if (item.tarifa.minimoTipoModificacion === null || item.tarifa.minimoTipoModificacion === undefined) {
            
            objImporteMinimo = calculaValores(item.minimo, null, margenDefecto);            

        } else {
     
            objImporteMinimo = calculaValores(item.minimo, item.tarifa.minimoValor, item.tarifa.minimoMargen);
        }

        if(item.tipo=="%"){

                       
            //Para llegar al minimo se tiene en cuenta solo el porcentajeCalculado a la variable no el importe de la variable en si.
            if ((parseFloat(objImporte2.valorCalculado) - parseFloat(factor2)) < parseFloat(objImporteMinimo.valorCalculado) && factor2 > 0) {
                aplicaMinimoVariable = true;
                objImporte.valorCalculado = factor2;
                objImporte2.valorCalculado = parseFloat(factor2) + parseFloat(objImporteMinimo.valorCalculado);

            }
            else if((parseFloat(objImporte.valorCalculado)-parseFloat(factor))<parseFloat(objImporteMinimo.valorCalculado) && factor>0){                   
                aplicaMinimoVariable=true;                              
                coste = factor;
                objImporte.valorCalculado=parseFloat(factor) + parseFloat(objImporteMinimo.valorCalculado);                
            } 
        }

        if (factor2 > 0) {

            objCalc.coste = objImporte.valorCalculado;
            objCalc.precio = (parseFloat(objImporte2.valorCalculado) > parseFloat(objImporteMinimo.valorCalculado)) ? objImporte2.valorCalculado : objImporteMinimo.valorCalculado;
            objCalc.margen = (parseFloat(objImporte2.valorCalculado) > parseFloat(objImporteMinimo.valorCalculado)) ? objImporte2.margenCalculado : objImporteMinimo.margenCalculado;

        } else {
            
            objCalc.coste = coste;
            objCalc.precio = (parseFloat(objImporte.valorCalculado) >= parseFloat(objImporteMinimo.valorCalculado)) ? objImporte.valorCalculado : objImporteMinimo.valorCalculado;
            objCalc.margen = (parseFloat(objImporte.valorCalculado) >= parseFloat(objImporteMinimo.valorCalculado)) ? objImporte.margenCalculado : objImporteMinimo.margenCalculado;

        }

        if(objImporte.margenCalculado==undefined)
         objImporte.margenCalculado=objImporte.margen;

         
        if (parseFloat(objImporteMinimo.valorCalculado) > parseFloat(objImporte.valorCalculado) || aplicaMinimoVariable ) {
                               
            if(aplicaMinimoVariable){                       

                if(factor2>0){
                    //objCalc.descripcion = `Precio Min: ${muestraPrecio(objImporteMinimo.valorCalculado)} €  &nbsp;&nbsp;&nbsp;&nbsp; Precio:  ${muestraPrecio(objImporte2.valorCalculado)} €`;
                    objCalc.descripcion = `Coste Min: ${prefijoValor}${muestraPrecio(item.minimo)} €  &nbsp;&nbsp;&nbsp;&nbsp; Precio: ${muestraPrecio(factor2)} + ${muestraPrecio(item.minimo)} (${muestraPrecio(objImporteMinimo.margenCalculado)} %): ${prefijoValor}${muestraPrecio(objImporte2.valorCalculado)} €`;                                                                        
                    objCalc.descripcionSinCoste = `Precio: ${muestraPrecio(factor2)} + ${muestraPrecio(item.minimo)} (${muestraPrecio(objImporteMinimo.margenCalculado)} %): ${prefijoValor}${muestraPrecio(objImporte2.valorCalculado)} €`;                                                                        
                    objCalc.coste=parseFloat(factor)+parseFloat(item.minimo);
                    
                }else{  
                    objCalc.coste = parseFloat(factor)+parseFloat(item.minimo);
                    objCalc.descripcion = `Coste Min: ${prefijoValor}${muestraPrecio(item.minimo)} €  &nbsp;&nbsp;&nbsp;&nbsp; Precio: ${muestraPrecio(factor)} + ${muestraPrecio(item.minimo)} (${muestraPrecio(objImporteMinimo.margenCalculado)} %): ${prefijoValor}${muestraPrecio(objImporte.valorCalculado)} €`;
                    objCalc.descripcionSinCoste = `Precio: ${muestraPrecio(factor)} + ${muestraPrecio(item.minimo)} (${muestraPrecio(objImporteMinimo.margenCalculado)} %): ${prefijoValor}${muestraPrecio(objImporte.valorCalculado)} €`;
                    //objCalc.descripcion = `Precio Min: ${muestraPrecio(objImporteMinimo.valorCalculado)} €  &nbsp;&nbsp;&nbsp;&nbsp; Precio:  ${muestraPrecio(objImporte.valorCalculado)} €`;                
                }

            }else{

                    objCalc.coste = item.minimo;
                objCalc.descripcion = `Coste Min: ${prefijoValor}${muestraPrecio(item.minimo)} €  &nbsp;&nbsp;&nbsp;&nbsp; Precio: ${muestraPrecio(item.minimo)} (${muestraPrecio(objImporteMinimo.margenCalculado)} %) ${prefijoValor}${muestraPrecio(objImporteMinimo.valorCalculado)} €`;
                objCalc.descripcionSinCoste = `Precio: ${muestraPrecio(item.minimo)} (${muestraPrecio(objImporteMinimo.margenCalculado)} %) ${prefijoValor}${muestraPrecio(objImporteMinimo.valorCalculado)} €`;
                
            }

        } else {

            if (item.tipo === "%" || item.tipo === "solo_%") {

                if (factor2 > 0) {

                    if (item.tipo === "%") {
                        objCalc.descripcion = `Coste: ${muestraPrecio(factor)} + (${muestraPrecio(objImporte.margenCalculado)} %): ${prefijoValor}${muestraPrecio(objCalc.coste)} €  &nbsp;&nbsp;&nbsp;&nbsp; Precio: ${muestraPrecio(factor2)} + (${muestraPrecio(objImporte2.margenCalculado)} %): ${prefijoValor}${muestraPrecio(objCalc.precio)} € `;
                        objCalc.descripcionSinCoste = `Precio: ${muestraPrecio(factor2)} + (${muestraPrecio(objImporte2.margenCalculado)} %): ${prefijoValor}${muestraPrecio(objCalc.precio)} € `;
                    } else {
                        objCalc.descripcion = `Coste: ${muestraPrecio(factor)} (${muestraPrecio(objImporte.margenCalculado)} %): ${prefijoValor}${muestraPrecio(objCalc.coste)} €  &nbsp;&nbsp;&nbsp;&nbsp; Precio: ${muestraPrecio(factor2)} (${muestraPrecio(objImporte2.margenCalculado)} %): ${prefijoValor}${muestraPrecio(objCalc.precio)} € `;
                        objCalc.descripcionSinCoste = `Precio: ${muestraPrecio(factor2)} (${muestraPrecio(objImporte2.margenCalculado)} %): ${prefijoValor}${muestraPrecio(objCalc.precio)} € `;
                    }

                } else {


                    if (item.tipo === "%") {
                        objCalc.descripcion = `Coste: ${muestraPrecio(factor)} + (${muestraPrecio(item.valor)} %): ${prefijoValor}${muestraPrecio(objCalc.coste)} €  &nbsp;&nbsp;&nbsp;&nbsp; Precio: ${muestraPrecio(factor)} + (${muestraPrecio(objImporte.margenCalculado)} %): ${prefijoValor}${muestraPrecio(objCalc.precio)} € `;
                        objCalc.descripcionSinCoste = `Precio: ${muestraPrecio(factor)} + (${muestraPrecio(objImporte.margenCalculado)} %): ${prefijoValor}${muestraPrecio(objCalc.precio)} € `;
                    } else {
                        objCalc.descripcion = `Coste: ${muestraPrecio(factor)} (${muestraPrecio(item.valor)} %): ${prefijoValor}${muestraPrecio(objCalc.coste)} €  &nbsp;&nbsp;&nbsp;&nbsp; Precio: ${muestraPrecio(factor)} (${muestraPrecio(objImporte.margenCalculado)} %): ${prefijoValor}${muestraPrecio(objCalc.precio)} € `;
                        objCalc.descripcionSinCoste = `Precio: ${muestraPrecio(factor)} (${muestraPrecio(objImporte.margenCalculado)} %): ${prefijoValor}${muestraPrecio(objCalc.precio)} € `;
                    }
                }
            } else {

                if (factor2 > 0) {


                    objCalc.descripcion = `Coste: ${muestraPrecio(factor)} x ${muestraPrecio(item.valor)}: ${prefijoValor}${muestraPrecio(objCalc.coste)} €  &nbsp;&nbsp;&nbsp;&nbsp; Precio: ${muestraPrecio(factor2)} x ${muestraPrecio(objValor.valorCalculado)} : ${prefijoValor}${muestraPrecio(objCalc.precio)} €  &nbsp;&nbsp;&nbsp;`;
                    objCalc.descripcionSinCoste = `Precio: ${muestraPrecio(factor2)} x ${muestraPrecio(objValor.valorCalculado)} : ${prefijoValor}${muestraPrecio(objCalc.precio)} €  &nbsp;&nbsp;&nbsp;`;
                    

                } else {

                    if (factor > 1) {
                        objCalc.descripcion = `Coste: ${muestraPrecio(factor)} x ${muestraPrecio(item.valor)}: ${prefijoValor}${muestraPrecio(objCalc.coste)} €  &nbsp;&nbsp;&nbsp;&nbsp; Precio: ${muestraPrecio(objCalc.coste)} € (${muestraPrecio(objImporte.margenCalculado)} %) : ${prefijoValor}${muestraPrecio(objCalc.precio)} €  &nbsp;&nbsp;&nbsp;`;
                        objCalc.descripcionSinCoste = `Precio: ${muestraPrecio(objCalc.coste)} € (${muestraPrecio(objImporte.margenCalculado)} %) : ${prefijoValor}${muestraPrecio(objCalc.precio)} €  &nbsp;&nbsp;&nbsp;`;
                    } else {
                        objCalc.descripcion = `Coste: ${prefijoValor}${muestraPrecio(objCalc.coste)} €  &nbsp;&nbsp;&nbsp;&nbsp; Precio: ${muestraPrecio(objCalc.coste)} € (${muestraPrecio(objImporte.margenCalculado)} %) : ${prefijoValor}${muestraPrecio(objCalc.precio)} €  &nbsp;&nbsp;&nbsp;`;
                        objCalc.descripcionSinCoste = `Precio: ${muestraPrecio(objCalc.coste)} € (${muestraPrecio(objImporte.margenCalculado)} %) : ${prefijoValor}${muestraPrecio(objCalc.precio)} €  &nbsp;&nbsp;&nbsp;`;
                    }
                }


            }

        }

        if(item.descuento=="1"){
                objCalc.coste=objCalc.coste*-1;
                objCalc.precio=objCalc.precio*-1;
        }

        return objCalc;


    }




    const calculaVariables = (item, variables, margenDefecto, valoraciones, impuestos, final = false) => {

        let aux = false;
        var val_aux = {};


        const { variablesCampos, variablesOpcionales } = variables;

        if (item.tarifa.tipoModificacion != "inactivo" && item.tarifa.tipoModificacion != "inactivo" && item.tarifa.activa != "0") {
                    
            var objIva=impuestos && impuestos.find(i=>i.referencia==item.tipoIVA);
            var tipoIVA=(objIva?.id)?objIva.referencia:IVA_REF;
                        
            if (final) {

                const valoraciones_filter = valoraciones.filter(i => i.final == undefined);

                switch (item.depende) {
                    //Variable dependiente de campo
                    case "portes":

     
                        let { coste_total, precio_total } = calculaTotal(valoraciones_filter.filter(i => i.idRef > 0));

                        let obj = calculaPrecios(item, margenDefecto, coste_total, precio_total);

                        //Buscamos en valoraciones por si tenia campos de texto editados                        
                        val_aux = valoraciones.filter(i => i.idRef === 0 && i.idVariable === item.id);
                        
                                            
                        if (val_aux.length > 0) {
                            val_aux = val_aux[0];
                            aux = { idRef: 0, idVariable: item.id, idServicio: 0, ref: val_aux.ref, servicio: val_aux.servicio, ruta: val_aux.ruta, coste: muestraPrecio(obj.coste), precio: muestraPrecio(obj.precio), info: `${obj.descripcion} ${(item.descripcion != "") ? `-&nbsp;&nbsp;&nbsp;${item.descripcion}` : ""}`, infoSinCoste: `${obj.descripcionSinCoste} ${(item.descripcion != "") ? `-&nbsp;&nbsp;&nbsp;${item.descripcion}` : ""}`, adicional: 0, final: 1,tipoIVA,valor_variable_coste:coste_total,valor_variable:precio_total};
                        } else {
                            aux = { idRef: 0, idVariable: item.id, idServicio: 0, ref: `V${item.id}`, servicio: item.nombre, ruta: "", coste: muestraPrecio(obj.coste), precio: muestraPrecio(obj.precio), info: `${obj.descripcion} ${(item.descripcion != "") ? `-&nbsp;&nbsp;&nbsp;${item.descripcion}` : ""}`, infoSinCoste: `${obj.descripcionSinCoste} ${(item.descripcion != "") ? `-&nbsp;&nbsp;&nbsp;${item.descripcion}` : ""}`, adicional: 0, final: 1, tipoIVA, valor_variable_coste:coste_total,valor_variable:precio_total};
                        }

                            
                        

                        break;

                    case "total":

                        if (true) {

                            let { coste_total, precio_total } = calculaTotal(valoraciones.filter(i => i.idVariable!== item.id));

                            let obj = calculaPrecios(item, margenDefecto, coste_total, precio_total);

                      
                            //Buscamos en valoraciones por si tenia campos de texto editados                        
                            val_aux = valoraciones.filter(i => i.idRef === 0 && i.idVariable === item.id);

                            if (val_aux.length > 0) {
                                val_aux = val_aux[0];
                                aux = { idRef: 0, idVariable: item.id, idServicio: 0, ref: val_aux.ref, servicio: val_aux.servicio, ruta: val_aux.ruta, coste: muestraPrecio(obj.coste), precio: muestraPrecio(obj.precio), info: `${obj.descripcion} ${(item.descripcion != "") ? `-&nbsp;&nbsp;&nbsp;${item.descripcion}` : ""}`, infoSinCoste: `${obj.descripcionSinCoste} ${(item.descripcion != "") ? `-&nbsp;&nbsp;&nbsp;${item.descripcion}` : ""}`, adicional: 0, final: 1, tipoIVA, valor_variable_coste:coste_total,valor_variable:precio_total };
                            } else {
                                aux = { idRef: 0, idVariable: item.id, idServicio: 0, ref: `V${item.id}`, servicio: item.nombre, ruta: "", coste: muestraPrecio(obj.coste), precio: muestraPrecio(obj.precio), info: `${obj.descripcion} ${(item.descripcion != "") ? `-&nbsp;&nbsp;&nbsp;${item.descripcion}` : ""}`, infoSinCoste: `${obj.descripcionSinCoste} ${(item.descripcion != "") ? `-&nbsp;&nbsp;&nbsp;${item.descripcion}` : ""}`, adicional: 0, final: 1, tipoIVA, valor_variable_coste:coste_total,valor_variable:precio_total };
                            }

                        }

                        break;

                    default:

                        break;
                }

            } else {


                switch (item.depende) {
                    //Variable dependiente de campo
                    case "bultos":
                    case "kg":
                    case "reembolso":
                    case "declarado":
                    case "kilometros":
                    case "es_recogida":
                    case "es_intercity":
                    case "tiene_dni":
                    case "es_debidos":
                    case "exceso_medidas":

                        //Buscamos el campo
                        let var_campos = toPairs(variablesCampos);
                        let campo_encontrado = false;

                        var_campos.forEach(campo => {
                            if (campo[0] === item.depende && campo[1] !== undefined && campo[1]!==null) {
                                campo_encontrado = campo[1];

                                //Filtramos valores, numericos mayor que 0
                                switch(item.depende){
                                    case "bultos":
                                    case "kg":
                                    case "reembolso":
                                    case "declarado":
                                    case "kilometros":                                                                         
                                     if(parseFloat(campo[1])<=0)
                                        campo_encontrado=false;

                                    break;
                                    default: 
                                }

                                
                            }
                        });
                    

                        if (campo_encontrado !== false && campo_encontrado !== "") {

                           
                            let obj = calculaPrecios(item, margenDefecto, campo_encontrado);


                            //Buscamos en valoraciones por si tenia campos de texto editados
                            val_aux = valoraciones.filter(i => i.idRef === 0 && i.idVariable === item.id);

                            if (val_aux.length > 0) {
                                val_aux = val_aux[0];
                                aux = { idRef: 0, idVariable: item.id, idServicio: 0, ref: val_aux.ref, servicio: val_aux.servicio, ruta: val_aux.ruta, coste: muestraPrecio(obj.coste), precio: muestraPrecio(obj.precio), info: `${obj.descripcion} ${(item.descripcion != "") ? `-&nbsp;&nbsp;&nbsp;${item.descripcion}` : ""}`, infoSinCoste: `${obj.descripcionSinCoste} ${(item.descripcion != "") ? `-&nbsp;&nbsp;&nbsp;${item.descripcion}` : ""}` , adicional: 0, tipoIVA, valor_variable:campo_encontrado };
                            } else {

                                aux = { idRef: 0, idVariable: item.id, idServicio: 0, ref: `V${item.id}`, servicio: item.nombre, ruta: "", coste: muestraPrecio(obj.coste), precio: muestraPrecio(obj.precio), info: `${obj.descripcion} ${(item.descripcion != "") ? `-&nbsp;&nbsp;&nbsp;${item.descripcion}` : ""}`, infoSinCoste: `${obj.descripcionSinCoste} ${(item.descripcion != "") ? `-&nbsp;&nbsp;&nbsp;${item.descripcion}` : ""}`, adicional: 0, tipoIVA, valor_variable:campo_encontrado };
                            }
                        } else {
                            //Si no se ha encontrado el campo no se aplica la variable. 

                        }

                        break;

                    case "variable":

                        //Buscamos el campo                
                        campo_encontrado = false;
                        let valor_variable = 0;

                        variablesOpcionales && variablesOpcionales.forEach(var_opc => {
                            if (var_opc.idVariable === item.id) {
                                valor_variable = var_opc.valor;
                            }
                        });

                        if (item.valor > 0 && valor_variable > 0) {

                            let obj = calculaPrecios(item, margenDefecto, valor_variable);

                      
                            //Buscamos en valoraciones por si tenia campos de texto editados
                            val_aux = valoraciones.filter(i => i.idRef === 0 && i.idVariable === item.id);

                            if (val_aux.length > 0) {
                                val_aux = val_aux[0];
                                aux = { idRef: 0, idVariable: item.id, idServicio: 0, ref: val_aux.ref, servicio: val_aux.servicio, ruta: val_aux.ruta, coste: muestraPrecio(obj.coste), precio: muestraPrecio(obj.precio), info: `${obj.descripcion} ${(item.descripcion != "") ? `-&nbsp;&nbsp;&nbsp;${item.descripcion}` : ""}`, infoSinCoste: `${obj.descripcionSinCoste} ${(item.descripcion != "") ? `-&nbsp;&nbsp;&nbsp;${item.descripcion}` : ""}`, adicional: 0, tipoIVA, valor_variable:valor_variable };
                            } else {

                                aux = { idRef: 0, idVariable: item.id, idServicio: 0, ref: `V${item.id}`, servicio: item.nombre, ruta: "", coste: muestraPrecio(obj.coste), precio: muestraPrecio(obj.precio), info: `${obj.descripcion} ${(item.descripcion != "") ? `-&nbsp;&nbsp;&nbsp;${item.descripcion}` : ""}`, infoSinCoste: `${obj.descripcionSinCoste} ${(item.descripcion != "") ? `-&nbsp;&nbsp;&nbsp;${item.descripcion}` : ""}`, adicional: 0, tipoIVA, valor_variable: valor_variable};
                            }


                        } else {
                            //Si no se ha encontrado el campo no se aplica la variable. 

                        }

                        break;


                    case "fijo":
                        let obj = calculaPrecios(item, margenDefecto, 1);

                        //Buscamos en valoraciones por si tenia campos de texto editados
                        val_aux = valoraciones.filter(i => i.idRef === 0 && i.idVariable === item.id);

                  
                        if (val_aux.length > 0) {
                            val_aux = val_aux[0];
                            aux = { idRef: 0, idVariable: item.id, idServicio: 0, ref: val_aux.ref, servicio: val_aux.servicio, ruta: val_aux.ruta, coste: muestraPrecio(obj.coste), precio: muestraPrecio(obj.precio), info: `${obj.descripcion} ${(item.descripcion != "") ? `-&nbsp;&nbsp;&nbsp;${item.descripcion}` : ""}`, infoSinCoste: `${obj.descripcionSinCoste} ${(item.descripcion != "") ? `-&nbsp;&nbsp;&nbsp;${item.descripcion}` : ""}`, adicional: 0, tipoIVA };
                        } else {
                            aux = { idRef: 0, idVariable: item.id, idServicio: 0, ref: `V${item.id}`, servicio: item.nombre, ruta: "", coste: muestraPrecio(obj.coste), precio: muestraPrecio(obj.precio), info: `${obj.descripcion} ${(item.descripcion != "") ? `-&nbsp;&nbsp;&nbsp;${item.descripcion}` : ""}`, infoCoste: `${obj.descripcionSinCoste} ${(item.descripcion != "") ? `-&nbsp;&nbsp;&nbsp;${item.descripcion}` : ""}` , adicional: 0, tipoIVA };
                        }

                        break;

                    default:

                        break;

                }

            }
        }

        return aux;

    }

    



    return {
        calculaVariables,
        setVariablesFormik
    }

}

