import { Box } from '@mui/material';
import Cargando from 'app/componentes/avisos/Cargando';
import Mostrar from 'app/componentes/datadisplay/Mostrar';
import { useRoles } from 'app/hooks/hooksPropios';
import React from 'react';
import CategoriaElemento from '../CategoriaElemento/CategoriaElemento';



const GridCategoriasElemento = React.memo(({etiquetasElemento, isLoading, showLoader = false, gridMinSize = '140px', gap = "1rem"}) => {

    const {esRolAdministrativo, esMensajero} = useRoles();

    const GridCategoriasElementoSX = {

        display: "grid",
        width: '100%',
        gridTemplateColumns: `repeat(auto-fill, minmax(${gridMinSize}, 1fr))`,
        gap: gap,
        justifyContent: "center"
    
    }

    if (!showLoader && isLoading)
    {
        return null;
    }


    return (  
        <Mostrar cuando={esRolAdministrativo || esMensajero} desmontar={true}>
            <Cargando activo={isLoading}>
                <Box sx={GridCategoriasElementoSX}>
                {
                    etiquetasElemento.map(etiqueta => <CategoriaElemento 
                                                        key={`etiqueta_${etiqueta.id}`} 
                                                        data={etiqueta}
                                                    />)
                }
                </Box>
            </Cargando>
        </Mostrar>
    );
})
 
export default GridCategoriasElemento;