import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, Checkbox } from "@mui/material";
import { direccionString } from "app/helpers/libFunciones";
import { useRoles } from "app/hooks/hooksPropios";
import transformaDireccionTrafico from "app/modules/trafico/helpers/trafico.mapper";
import { DireccionTrafico } from "app/modules/trafico/models/trafico.types";
import { TooltipG } from "app/pages/utils";
import { Link } from "react-router-dom";

const sxCabecera = {
	display: "flex",
	mb: "4px",
	flexDirection: { xs: "column", md: "row" },
	alignItems: { xs: "inherit", md: "center" },
	gap: { xs: "5px", md: "0px" },
};

interface CabeceraFilaTraficoProps {
	classes: any;
	datos: any;
	tipo: "E" | "R" | "RF";
	tipoEditable: any;
	elementoSeleccionado: any;
	seleccionarElemento: any;
}

export default function CabeceraFilaTrafico({
	classes,
	datos,
	tipo,
	tipoEditable,
	elementoSeleccionado,
	seleccionarElemento,
}: CabeceraFilaTraficoProps) {
	const { esRolAdministrativo, esCliente, esMensajero } = useRoles();

	let direccionRecogida = {} as DireccionTrafico;

	if (datos.direccionRecogida) {
		direccionRecogida = transformaDireccionTrafico(datos.direccionRecogida);
	}

	let direccionObjeto = transformaDireccionTrafico(datos.direccion);

	let enlaceRecogida = esMensajero ? `/mensajero/recogidas/${datos.idRecogida}` : `/recogidas/${datos.idRecogida}`;

	let enlaceEnvio = esMensajero ? `/mensajero/entregas/${datos.id}` : `/envios/${datos.id}`;

	let direccionInicioUsar = tipo == "E" ? direccionRecogida : direccionObjeto;

	return (
		<Box sx={sxCabecera} className={classes.datoPrincipal}>
			{datos.tipo === tipoEditable && (esRolAdministrativo || esCliente) && (tipoEditable != "envio" || tipoEditable == "envio") && (
				<Checkbox
					checked={elementoSeleccionado}
					onChange={seleccionarElemento}
					value={datos.id}
					name="elementoSeleccionado"
					color="primary"
				/>
			)}
			<TooltipG title={direccionString(direccionInicioUsar)}>
				<Link to={enlaceRecogida} className={classes.enlace}>
					{direccionInicioUsar.nombre}
				</Link>
			</TooltipG>
			<div className={classes.direccion}>{direccionString(direccionInicioUsar, true)}</div>
			{tipo == "E" && (
				<>
					<ArrowForwardIcon className={classes.iconoFlecha}></ArrowForwardIcon>

					<TooltipG title={direccionString(direccionObjeto)}>
						<Link to={enlaceEnvio} className={classes.enlace}>
							{datos.direccion.nombre}
						</Link>
					</TooltipG>
					<div className={classes.direccion}>{direccionString(direccionObjeto, true)}</div>
				</>
			)}
		</Box>
	);
}
