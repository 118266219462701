import { Box } from "@mui/material";
import BotonAdd from "app/componentes/botones/BotonAdd";
import ListadoPaginado from "app/componentes/generales/ListadoPaginado";
import AccionesPagina from "app/componentes/layout/AccionesPagina/AccionesPagina";
import { useListadoMetodosPago } from "../../hooks/useListadoMetodosPago";

interface ListadoMetodosPagoProps {
    idDepartamento?: string;
}

export default function ListadoMetodosPago({idDepartamento = ""} : ListadoMetodosPagoProps) {


    const {columns, abrirModalMetodoPago, recargarTabla, setRecargarTabla} = useListadoMetodosPago({idDepartamento});

    let urlPeticion = idDepartamento ? `configuracion/metodospago?idDepartamento=${idDepartamento}` : `configuracion/metodospago?general=1`;

    return (
        <>
        <AccionesPagina>
            <BotonAdd onClick={() => abrirModalMetodoPago()}>
                Añadir
            </BotonAdd>
        </AccionesPagina>
        <Box>
            <ListadoPaginado 
                url={urlPeticion}
                columns={columns}
                title="Métodos de pago"
                recargarTabla={recargarTabla}
                setRecargarTabla={setRecargarTabla}
            />
        </Box>
        </>
    )

}