import { TextareaAutosize } from '@mui/material';
import { TooltipG } from 'app/pages/utils';
import { get } from 'lodash';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useStyles } from '../../css/css';

const styleTextArea = { width: "100%", height: "100", padding: "20px" };

const FormTextAreaRHF = ({name, onChange, tooltip, clase, label = "", ...props}) => {

    const { control, formState } = useFormContext();

    const { errors} = formState;

    const errores = get(errors, name);

    const classes = useStyles();

    var propsDefault = {
        name:name,
        InputProps:{autoComplete:'new-password', className: classes[clase] || null},
        helperText: (errores && errores.message) || '',
        error: Boolean(errores)
    }


    return ( 
        <Controller
            name={name}
            control={control}
            defaultValue={null}
            render={({field}) => (

                tooltip !== undefined 
                ? 
                (
                    <TooltipG title={tooltip}>
                        {
                            label && (
                                <label>{label}</label>
                            )
                        }
                        <TextareaAutosize 
                            style={styleTextArea}
                            {...propsDefault}
                            {...props}
                        />

                    </TooltipG>
                )
                :
                (   <>
                    {
                        label && (
                            <label>{label}</label>
                        )
                    }
                    <TextareaAutosize
                        style={styleTextArea}
                        {...propsDefault}
                        {...props}
                    />
                    </>
                )
            )}
        />
        
     )
}
 
export default FormTextAreaRHF;