import { Box, Chip } from '@mui/material';
import { TooltipG } from 'app/pages/utils';

interface BultosSinPuntoControlProps {
    numBultos: number;
    bultosFaltantes: {
        id: string;
        numBulto: string;
        codSeguimiento: string;
    }[];
}

export default function BultosSinPuntoControl({ numBultos, bultosFaltantes }: BultosSinPuntoControlProps) {
    return (
        <Box display="flex" flexWrap="wrap" gap={1}>
            {bultosFaltantes.map((bulto) => (
                <TooltipG key={bulto.id} title={`Código de bulto: ${bulto.codSeguimiento}`}>
                    <Chip
                        label={`Falta P.C: ${bulto.numBulto} de ${numBultos}`}
                        size="small"
                        sx={{
                            backgroundColor: '#FFCCCB', // Un tono de rojo claro
                            color: '#8B0000', // Un tono de rojo oscuro para el texto
                            fontWeight: '600',
                            '&:hover': {
                                backgroundColor: '#FFB6B6', // Un tono ligeramente más oscuro para el hover
                            },
                        }}
                    />
                </TooltipG>
            ))}
        </Box>
    );
}
