import { FieldErrors, FieldValues, FormProvider, UseFormReturn } from "react-hook-form";

interface FormProps<TFieldValues extends FieldValues = FieldValues> extends UseFormReturn<TFieldValues> {
	children: React.ReactNode;
	onSubmit: (data: any) => void;
	onInvalidSubmit?: (errors: FieldErrors<TFieldValues>) => void;
	className?: string;
	id?: string;
}

const Form = <TFieldValues extends FieldValues = FieldValues>({children, onSubmit, onInvalidSubmit, className, id, ...props} : FormProps<TFieldValues>) => {

	return (
		<FormProvider {...props}>
			<form id={id} onSubmit={props.handleSubmit(onSubmit, onInvalidSubmit)} className={className}>
				{children}
			</form>
		</FormProvider>
	)
}

export { Form };

