import { Autocomplete, Checkbox, TextField } from '@mui/material';
import { useField } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useDelta } from 'react-delta';
import uuid4 from "uuid4";
import { getValueAutocomplete } from '../../../../helpers/libFunciones';
import { useStyles } from '../../css/css';

export const FormAutocompleteFormik = ({hooks, fast, name, opciones, label, placeholder, clase, onChangeCallback, margin, propsTextField = {}, ...props}) => {


    const [campo, infoCampo, funciones] = useField(name);

    const [local, setEstadoLocal] = useState(campo.value || '');

    const valorFormik = useDelta(campo.value);

    const classes = useStyles();

    var propMultiple = {};

    var valueUsar = campo.value || '';

    if (fast)
    {
        valueUsar = local;
    }

    var propChange = {

        getOptionLabel: (option) => option?.value ? option.value : '',
        onChange: (e, item) => {
            if (item !== undefined && item !== null)
            {
                
                if (props.multiple)
                {

                    var arrayItems = item.map(i => i.id);

                    funciones.setValue(arrayItems);
                }
                else 
                {
                    funciones.setValue(item.id);
                }

            }
            else
            {

                props.multiple ? funciones.setValue([]) : funciones.setValue("");
            }

            if (typeof onChangeCallback === "function")
            {

                onChangeCallback(e, item);
            }

        },
        value: getValueAutocomplete(opciones, campo.value || '', props.multiple)
    }

    if (props.freeSolo)
    {
        propChange = {
            onInputChange:(e, item) =>  {

                if (e !== null)
                {
                    if (!fast)
                    {
                        funciones.setValue(item);
                    }
                    else 
                    {
                        setEstadoLocal(item);
                    }
                }
            },
            value: null,
            inputValue: valueUsar,
            onBlur: () => {
                if (fast)
                {
                    funciones.setValue(local);
                }
            }
        }

    }

    if (props.multiple)
    {
        propMultiple = {
            renderOption: (props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                style={{ marginRight: 4 }}
                checked={selected}
              />
              {option.value}
            </li>
          ),
          disableCloseOnSelect: true,

        }
    }

    useEffect(() => {

        if (fast)
        {
            
            if (valorFormik !== null)
            {
                if (valorFormik.curr !== local)
                {
                    
                    setEstadoLocal(valorFormik.curr || "");
                }
            }

        }

    }, [valorFormik, fast, local]);

    const getOptionKey = useCallback((option) => {
        
        return `option-${option?.id || uuid4()}`;
    }, [])

    return (
        <Autocomplete
            options={opciones}
            renderInput={(params) => (
                <TextField
                    {...params}
                    inputProps={{
                    ...params.inputProps,
                    autoComplete:'new-password',
                    }}
                    className={classes[clase] || null}
                    InputLabelProps={{shrink: true}}
                    margin={margin || "normal"}
                    label={label}
                    variant={"outlined"}
                    placeholder={props.multiple ? (campo.value.length > 0 ? null : (placeholder ? placeholder : null)) : null}
                    helperText={infoCampo.touched && infoCampo.error}
                    error={Boolean(infoCampo.touched && infoCampo.error)}
                    {...propsTextField}
                    
                />
            )}
            getOptionKey={getOptionKey}
            {...propChange}
            {...propMultiple}
            {...props}
        />
    )
     
}
