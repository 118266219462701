import { Card, CardContent, Container, Grid, Icon } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { isObject, toPairs } from 'lodash';
import { Link } from "react-router-dom";
import { useRoles, useUsuarioConectado } from "../../hooks/hooksPropios";
import
  {
    Portlet,
    PortletBody,
  } from "../../partials/content/Portlet";
import TienePermisos from './../../helpers/TienePermisos';
import * as permisos from './../../helpers/permisos';



const useStyles = makeStyles((theme)=>(
  {
  card:{
    fontSize:"15px",    
    textAlign:"center",
    display:"block",
    boderRadius:"5px 5px 5px 5px",
    "& :hover":{
      backgroundColor:"#fafafa"
    }    
    ,"& :hover >span": {
      color:theme.palette.primary.light, 
      transform: "scale(1.1)",
      transition: "0.2s all"
    },
    "& span":{
      fontSize:"3em",
      width:"100%"
    }
     ,"& .label": {
      marginTop: "10px",
      fontWeight:"500"
    }
  }  
}
));

/*Roles
1	CLIENTE
2	COMERCIAL
3	INTERNO
4	MENSAJERO
*/



export default function Dashboard() {

const classes = useStyles();
 
const {CLIENTE, MENSAJERO, INTERNO, MULTILOGIN, SUBAGENCIA, ADMIN} = useRoles();

const {idRol, idCliente,datosUser} = useUsuarioConectado();

const iconos= [
  { link: "/trafico", icono: "fa fa-clock", label: "Tráfico", permiso:[], roles: [INTERNO, MULTILOGIN, ADMIN, SUBAGENCIA]  }, 
  { link: "/clientes", icono: "fa fa-users", label: "Clientes", permiso: [permisos.clientes.ver], roles: [INTERNO, MULTILOGIN, ADMIN]},
  { link:`/clientes/${idCliente}`, icono: "fa fa-user", label: "Mis datos", permiso: [permisos.clientes.ver], roles: [CLIENTE] },
  { link: `/cliente/trafico`, icono: "fa fa-envelope", label: "Avisos/Envíos", permiso: [permisos.envios.ver,permisos.recogidas.ver], roles: [CLIENTE]},
  { link: "/redes", icono: "fa fa-project-diagram", label: "Redes", permiso: [permisos.redes.ver], roles: [INTERNO, MULTILOGIN, ADMIN] }, 
  { link: "/tarifas", icono: "fa fa-funnel-dollar", label: "Tarifas", permiso: [permisos.tarifas.ver], roles: [INTERNO, MULTILOGIN, ADMIN] }, 
  { link: "/facturacion/facturar", icono: "fa fa-file-invoice-dollar", label: "Facturar Envios", permiso: [permisos.facturacion.facturar], roles: [INTERNO, MULTILOGIN, ADMIN] }, 
  { link: "/config/users", icono: "fa fa-users", label: "Usuarios", permiso: [permisos.usuarios.ver], roles: [INTERNO, MULTILOGIN, ADMIN] }, 
  { link: "/transmision", icono: "fa fa-rss", label: "Transmisión", permiso: [permisos.transmision.ver], roles: [INTERNO, MULTILOGIN, ADMIN] },   
  { link: "/importacion", icono: "fa fa-upload", label: "Importación", permiso: [permisos.importacion.ver], roles: [INTERNO, MULTILOGIN, ADMIN] }, 
  { link: "/plantillas", icono: "fa fa-file-alt", label: "Plantillas", permiso: [permisos.plantillas.ver], roles: [INTERNO, MULTILOGIN, ADMIN] }, 
  { link: "/presupuestos", icono: "fa fa-desktop", label: "Presupuestos", permiso: [permisos.simulador.ver], roles: [INTERNO, MULTILOGIN, ADMIN, CLIENTE, SUBAGENCIA] }, 
  { link: "/utilidades", icono: "fa fa-tools", label: "Utilidades", permiso: [permisos.utilidades.ver], roles: [INTERNO, MULTILOGIN, ADMIN] }, 
  { link: "/configuracion", icono: "fa fa-cog", label: "Configuración", permiso: [permisos.config.ver], roles: [INTERNO, MULTILOGIN, ADMIN]}, 
  { link: "/soporte", icono: "fa fa-headset", label: "Soporte Técnico", permiso: [], roles: [] },                                                                                                                                                                                                                                                                                                                                                           
  { link: "/ayuda", icono: "fa fa-question-circle", label: "Ayuda", permiso: [], roles: [INTERNO, MULTILOGIN, ADMIN, MENSAJERO, CLIENTE] },
  { link: "/mensajero/trafico", icono: "fas fa-box", label: "Entregas/Recogidas", permiso: [permisos.envios.ver,permisos.recogidas.ver], roles: [MENSAJERO] },  
];

 //Comprobamos si tiene asociados proyectos para hacer login

 if (isObject(datosUser.user.proyectos))
 {
    let proyects = toPairs(datosUser.user.proyectos);

    if (proyects && proyects.length > 0)
    {

      proyects.forEach(item => {
        
        if (item[0] === "VIVO")
        {
            iconos.push({link: `/proyecto/${item[0]}`, icono: "fa fa-money-bill-alt", label: "Gestión Facturas",permiso: [permisos.facturacion.ver],roles:[INTERNO, MULTILOGIN, ADMIN], target: "_blank"});

            iconos.push({link: `/proyecto/${item[0]}`, icono: "fas fa-warehouse", label: "Almacén", permiso: [permisos.facturacion.ver], roles:[INTERNO, MULTILOGIN, ADMIN], target: "_blank"});
        }
        else if (item[0] === "CRM")
        {
            iconos.push({link: `/proyecto/${item[0]}`, icono: "fa fa-tasks", label: "CRM", permiso: [], roles: [INTERNO, MULTILOGIN, ADMIN], target: "_blank"});
        }
        else if (item[0] === "GestionTime")
        {
            iconos.push({link: `/proyecto/${item[0]}`, icono: "far fa-clock", label: "RRHH", permiso: [], roles: [INTERNO, MULTILOGIN, ADMIN], target: "_blank"});
        }


      });

    }         
  }
  
  return (
   
    <Portlet>
  

      <PortletBody>
        <div className="kt-section kt-margin-t-30">
          <div className="kt-section__body">
              <Container maxWidth={false}>
                <Grid container spacing={2}>              
                {iconos.map(item=>(
                  
                  ((item.roles.indexOf(idRol) !== -1 || item.roles.length===0)) && (item.permiso.length===0 || TienePermisos(item.permiso))?
                  <Grid item xs={6} md={4} lg={2} key={item.label}>
                      <Link to={item.link} target={(item.target)?item.target:""}>
                      <Card elevation={0} className={classes.card} >                        
                        <CardContent>
                          <Icon className={item.icono} color={item.link!==""?"primary":"disabled"} ></Icon>
                          <div className="label" >{item.label}</div>
                        </CardContent>

                      </Card>
                    </Link>
                  </Grid>
                  :null
                ))}
                </Grid>
            </Container>
          </div> 
        </div>
      </PortletBody>  

    </Portlet> 

    
  );
}

