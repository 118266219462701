
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Button, IconButton, Accordion as MuiExpansionPanel, AccordionDetails as MuiExpansionPanelDetails, AccordionSummary as MuiExpansionPanelSummary, Paper, Tooltip } from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import { Link } from "react-router-dom";





const fontSize = {fontSize:22};

export const BackButton = ({ handleBack }) => (    
    <div style={{height:"20"}}>
    <IconButton onClick={handleBack}  >
        <ArrowBackIcon style={fontSize} /> 
    </IconButton> 
    </div>   
);


export const BotonAdd=({name,link})=>{
    return(
    <Link to={link}>      
        <Button variant="outlined" color="primary">
            <AddCircleOutlineIcon />
            &nbsp;&nbsp;
            {name}
        </Button>
    </Link>
    );
}

export const BotonAdd2 = (props) => {

    const {name,icon}=props;

    return (        
            <Button {...props} color="primary"  variant="outlined" >
            {(icon !== undefined) ? icon : <AddCircleOutlineIcon  />}
            &nbsp;&nbsp;
            {name}
            </Button>           
    );
}





export const BotonAnt = ({ name, link }) => {
    return (
        <Link to={link}>

            <Button  color="primary">
                <ArrowBackIosIcon /> 
                &nbsp;&nbsp;
                {name}
            </Button>
        </Link>
    );
}


export const BotonSig = ({ name, link }) => {
    return (
        <Link to={link}>

            <Button  color="primary">
                {name}
                &nbsp;&nbsp;
                <ArrowForwardIosIcon/>                            
            </Button>
        </Link>
    );
}


export const BotonAntClick = ({ name, func }) => {
    return (
    
        <Button color="primary" onClick={func}>
                <ArrowBackIosIcon />
                &nbsp;&nbsp;
                {name}
            </Button>
        
    );
}


export const BotonSigClick = ({ name, func }) => {
    return (
             <Button color="primary" onClick={func}>
                {name}
                &nbsp;&nbsp;
                <ArrowForwardIosIcon />
            </Button>
     
    );
}






export const TooltipG = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.gray,
        color: 'rgba(255, 255, 255, 1)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}))(Tooltip);




export const ExpansionPanel = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none !important',      
        '&:before': {
            display: 'none',
        },
        '&.expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiExpansionPanel);

export const ExpansionPanelSummary = withStyles({
    root: {
        backgroundColor: 'rgba(0, 0, 0, .03)',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 56,
        '&.expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&.expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiExpansionPanelSummary);

export const ExpansionPanelSummaryBlanco = withStyles({
    root: {
        backgroundColor: 'rgba(0, 0, 0, 0)',
        border: '0px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 56,
        '&.expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&.expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiExpansionPanelSummary);

export const ExpansionPanelDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiExpansionPanelDetails);




export const useStylesTable = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    table: {},
    tableL:{
        "& th":{
            fontSize:"20px"
        },
        "& td": {
            fontSize: "18px"
        }
    }
    ,table_sinbordes: {        
        width: "100%",
        "& tr th": {
            border: "0px !important"
        }
    },
    row: {
        width: 700,
        backgroundColor: 'grey'

    },
    cell_long: {
        fontSize: "12px",
        width: 600,
        textAlign: "left"

    },
    cell_short: {
        fontSize: "12px",
        width: 50,
        textAlign: "center",

    },
    cell_short_left: {
        fontSize: "12px",
        width: 50,
        textAlign: "left",

    },
    cell_med: {
        fontSize: "12px",
        width: 150,
        textAlign: "center"

    }
    ,cell_titulo: {
        fontSize: "18px !important",       
        width: 100,
        textAlign: "center",
        backgroundColor: theme.palette.background.default,

    },
    titulo:{
        fontSize:"16px !important"
    }    
}));

export const PaperLine = ({style,...props}) => (<Paper {...props} style={
    {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none !important', padding: "20px",
    ...style
   }

}></Paper>);


