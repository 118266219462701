import CloseIcon from '@mui/icons-material/Close';
import { Box, ButtonBase, TextField } from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { parse } from 'date-fns';
import { useCallback } from 'react';
import { replaceAll } from '../../helpers/libFunciones';

const DatepickerP = ({value = null, inputVariant = "outlined", removableValue = false, ...props}) => {


    if (value)
    {

        value = replaceAll(value, '-', '/');

        var arrayValue = value.split('/');

        var formato = 'dd/MM/yyyy';

        if (arrayValue[0].length === 4)
        {
            formato = 'yyyy/MM/dd';
        }

        value = parse(value, formato, new Date());

    }


    const removeDate = useCallback(() => {

        if (typeof props?.onChange === "function")
        {
            props.onChange(null);
        }

    }, [props])

    var inputProps = {

        size:"small",
        margin:"normal",
        className:"kt-width-full"
        
    }


    if (props.InputProps)
    {
        inputProps = {...inputProps, ...props.InputProps}
    }

    return ( 

            <Box className='input-wrapper'>
                <MobileDatePicker
                    format="dd/MM/yyyy"
                    value={value}
                    views={["year", "month", "day"]}
                    slots={{
                        textField: TextField
                    }}
                    slotProps={{ 
                        textField: { 
                            variant: inputVariant,
                            autoComplete: 'new-password',
                            ...inputProps
                        } 
                    }}
                    {...props}

                />
                {
                    removableValue && (
                        <ButtonBase className='botonRemoveValueInput' type="button" onClick={removeDate}>
                            <CloseIcon />
                        </ButtonBase>
                    )
                }
                
            </Box>
     );
}

export default DatepickerP;
 
