import { Box, BoxProps } from '@mui/material';
import BotonCancelar from './BotonCancelar';
import BotonGuardar from './BotonGuardar';

interface BotonesFormularioProps extends BoxProps {
    onCancelClick?: (() => void) | null;
    textCancel?: string;
    textSave?: string;
}

const BotonesFormulario = ({onCancelClick = null, textCancel = "Cancelar", textSave = "Guardar", ...props}: BotonesFormularioProps) => {
    return ( 
        <>
            <Box mt={"40px"} display="flex" justifyContent="flex-end" sx={{gap: '5px'}} {...props}>
                {
                    typeof onCancelClick === "function" && (
                        <BotonCancelar onClick={onCancelClick}>
                            {textCancel}
                        </BotonCancelar>
                    )
                }
                
                <BotonGuardar>
                    {textSave}
                </BotonGuardar>
            </Box>
        </>
     );
}
 
export default BotonesFormulario;