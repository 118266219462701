export const config = {
    defaultOptions: {
        queries: {
          retry: 2, //Número de intentos a realizar si la query falla.
          refetchOnMount: true, //true/false/always. true -> Recarga si los datos se consideran stale (desactualizados), false no los vuelve a cargar nunca y always se los trae siempre.
          refetchOnWindowFocus: false, //Lo mismo que lo de arriba pero para cuando el usuario hace focus en la pantalla de la aplicación
          refetchOnReconnect: true, //Lo mismo pero por si el usuario pierde internet. Cuando le vuelve hace de nuevo la petición si procede.
          cacheTime: 1000*300, //Tiempo que el dato permanece en caché.
          refetchInterval: false, 
          refetchIntervalInBackground: false,
          suspense: false,
          staleTime: 1000 * 30, //Tiempo para que el dato se considere desactualizado
        },
        mutations: {
          retry: false,
        }
    }
}