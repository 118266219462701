
export const actionTypes = {  
  TRAFICO:"SET_TRAFICO",  
  INIT_TRAFICO: "INIT_TRAFICO",
};

const initialState = { 
    traficoObj: {  },
    trafico_time:0    
  } ;

export const reducer = 
    (state = initialState, action) => {
      switch (action.type) {
        case actionTypes.TRAFICO: {
          
          return { ...state, traficoObj: action.payload, trafico_time: Date.now() };
        }

        case actionTypes.INIT_TRAFICO: {

          return { ...state, traficoObj: {}, trafico_time: 0 };
        }
    
        default:

          return state;
      }
}


export const actions = {
  init_trafico: data => ({ type: actionTypes.INIT_TRAFICO, payload: data }),
 
};
   


