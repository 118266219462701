import { isObject } from "lodash";
import React, { useEffect, useRef,useState } from "react";
import { connect } from "react-redux";
import  sha1  from 'sha1';
import { useUsuarioConectado } from "../../hooks/hooksPropios";
import SpinnerCargando from "../generales/SpinnerCargando";



const Multilogin=(props)=>{
 
     const {proyecto,destino,proyectos}=props;
     const formRef=useRef(null);
     const [datos,setDatos]=useState({});

     const {datosUser} = useUsuarioConectado();

    useEffect(() => {
    
        const auxDatos={};

        if (isObject(proyectos))
        {
          
            auxDatos.idUsuarioMultilogin= datosUser.user.idUsuarioMultilogin;

            if(proyectos[proyecto]){
                auxDatos.action = `${proyectos[proyecto][0].dominio}${proyectos[proyecto][0].apiLogin}`;
                auxDatos.idUsuarioInterno=proyectos[proyecto][0].idUsuario;                          
                auxDatos.codificado=sha1(`${proyectos[proyecto][0].idUsuario}${datosUser.user.semilla}`);                                    
            }

            setDatos(auxDatos);                        
        }


    }, [formRef,proyecto,destino,proyectos, datosUser.user.idUsuarioMultilogin, datosUser.user.semilla]);

    useEffect(()=>{
        if(datos.codificado!=""){            
            formRef.current.submit();
        }

    },[datos]);

    return (
         
            <form
            method="POST"
            action={datos.action}
            ref={formRef}            
            autoComplete="off"
            className="kt-form">                         
                <input type="hidden" name="codificado" value={datos.codificado}/>
                <input type="hidden" name="idUsuarioInterno" value={datos.idUsuarioInterno}/>
                <input type="hidden" name="idUsuarioMultilogin" value={datos.idUsuarioMultilogin}/>                                      

            <SpinnerCargando style={{marginTop:"18%"}} posicion={"center"} />      
          </form>
    
    );

}


const mapStateToProps=(state)=>{
    return state.auth.user;
}

export default connect(mapStateToProps,null)(Multilogin);

