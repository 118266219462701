
import { makeStyles } from '@mui/styles';
import moment from 'moment';


export const useStyles = makeStyles(theme => {
    

    return {
        item: {
            width: "100%",
            padding: "5px"
        },      
        caja: {
            padding: "11px",
            boxShadow: "2px 2px 8px #00000024",
            display: "flex",   
            alignItems:"center",         
            fontSize: "12px",
            position:"relative",
            backgroundColor:  ({datos, codigo}) => {
                
                var color = "white";

                if ((datos.tipo === "recogida" && (codigo === "terminado" || codigo === "recogido")) || (datos.tipo === "envio" && codigo === "entregado"))
                {
                    color = theme.palette.success.light;
                }

                if (datos.tipo == "recogida" && (datos.observacionesRecogida != null || datos.notasMensajero != null)) {
                    color = "#efefef";
                }

                if (datos.anulado === "1")
                {
                    color = theme.palette.red[100];
                } else if (!(datos.valorado > 0) && datos.tipo == "envio") {
                    color = theme.palette.orange[100];
                }

                return color;
            }
            ,
            "&:hover": {
                backgroundColor: ({ datos, codigo }) => {

                    var color = "#f7f7f7";

                    if (datos.tipo == "recogida" && (datos.observacionesRecogida != null || datos.notasMensajero != null)) {
                        color = "#e4e4e4";
                    }

                    if ((datos.tipo === "recogida" && (codigo === "terminado" || codigo === "recogido")) || (datos.tipo === "envio" && codigo === "entregado")) {
                        color = theme.palette.success.darkLight;
                    }

                    if (datos.anulado === "1") {
                        color = theme.palette.red[200];
                    } else if (!(datos.valorado > 0) && datos.tipo == "envio") {
                        color = theme.palette.orange[200];
                    }

                    return color;
                }
            }
        },
        filaTitulo: {
            display: "flex",
            alignItems: "center",
            marginBottom: "5px"
        },
        mensajero: {
            display: "flex",
            alignItems: "center",
            marginBottom: "5px"
        },
        opciones: {
            marginTop: "0px",
            width:"200px",
            display:"inline-block",
            float:"left",
            marginLeft:"15px"

        },
        opcionesMensajero: {
            marginTop: "-20px",
            width: "200px",
            display: "inline-block",
            float: "left",
            marginLeft: "15px"

        }
        ,tipo: {
            padding: "5px",
            backgroundColor: ({ datos }) => datos.tipo === "recogida" ? theme.palette.trafico.recogidas.main : (datos.tipo === "recogidaFueraPlaza") ? theme.palette.trafico.recogidasFueraPlaza.main : theme.palette.trafico.envios.main,
            color: "white",            
            fontWeight: "bold",
            display:'flex'
        },
        textoArriba: {
            paddingLeft: "6px",
            flex: "1",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            paddingRight: "7px",
            fontSize: "10px",
            color: "#848484"
        },
        filaDatosTipo: {
            display: "flex",
            flexDirection: "column",
            paddingLeft: "15px",
            flex: "1",                  
        },
        datoPrincipal: {
            fontSize: "14px",
            position: "relative",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            color: ({ datos }) => datos.tipo === "recogida" ? theme.palette.trafico.recogidas.dark : (datos.tipo === "recogidaFueraPlaza") ? theme.palette.trafico.recogidasFueraPlaza.dark : theme.palette.trafico.envios.dark,
            fontWeight: "600",
            maxWidth:"85%"
        },
        datoSecundario: {
            overflow: "hidden",
            fontSize: "12px",
            color: "#848484",
            marginTop:"5px",     
            maxWidth: "85%"                   
        },
        datosEnvio: {          
            fontSize: "12px",
            color: "#848484",
            marginLeft:"5px",            
            float: "left"
        },
        direccion:{
            fontSize:"11px",
            color: "#848484",
            marginLeft:"20px",
            display:"inline-flex",
            fontWeight:"normal",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
        }
        ,filaHoras: {
            display: "flex",
            flexFlow: "row wrap",
            width:"108px",
            alignItems: "center",
            justifyContent: "center",
            flexShrink:"0"
        },
        fechaEnvio: {
            padding: "5px",
            display: "inline",                
            minWidth:"100%",
            textAlign: "center",
            backgroundColor: ({ datos, codigo }) => {


                var color = theme.palette.trafico.recogidas.light;

                if (datos.tipo === "envio") {
                    color = theme.palette.trafico.envios.light;
                }

                var desde = moment(datos.horaDesde, "HH:mm");

                var actual = moment();

                if (actual.isAfter(desde)) {
                    color = "#f08a24";
                }

                if ((datos.tipo === "recogida" && (codigo === "terminado" || codigo === "recogido")) || (datos.tipo === "envio" && codigo === "entregado")) {
                    color = theme.palette.success.main;
                }

                if (datos.anulado === "1") {
                    color = theme.palette.red.main;
                } else if (!(datos.valorado > 0) && datos.tipo == "envio") {
                    color = theme.palette.orange.main;
                }

                return color;
            },
            color: "white",
            margin: "2px"
        },
        horaInicio: {
            padding: "5px",
            display: "inline",
            minWidth: "50px",
            textAlign:"center",
            backgroundColor: ({datos,codigo}) => {
                

                var color = theme.palette.trafico.recogidas.light;

                if (datos.tipo === "envio")
                {
                    color = theme.palette.trafico.envios.light;
                }

                var desde = moment(datos.horaDesde, "HH:mm");

                var actual = moment();

                if (actual.isAfter(desde))
                {
                    color = "#f08a24";
                }

                if ((datos.tipo === "recogida" && (codigo === "terminado" || codigo === "recogido")) || (datos.tipo === "envio" && codigo === "entregado"))
                {
                    color = theme.palette.success.main;
                }

                if (datos.anulado === "1")
                {
                    color = theme.palette.red.main;
                } else if (!(datos.valorado > 0) && datos.tipo == "envio") {
                    color = theme.palette.orange.main;
                }

                return color;
            },
            color: "white",
            margin: "2px"
        },
        horaFin: {
            padding: "5px",
            display: "inline",
            minWidth:"50px",                 
            textAlign: "center",
            backgroundColor: ({datos, codigo}) => {
                
                var color = theme.palette.trafico.recogidas.light;

                if (datos.tipo === "envio")
                {
                    color = theme.palette.trafico.envios.light;
                }

                var desde = moment(datos.horaHasta, "HH:mm");

                var actual = moment();

                if (actual.isAfter(desde))
                {

                    color = "#f44336";
                }

                if ((datos.tipo === "recogida" && (codigo === "terminado" || codigo === "recogido")) || (datos.tipo === "envio" && codigo === "entregado"))
                {
                    color = theme.palette.success.main;
                }

                if (datos.anulado === "1")
                {
                    color = theme.palette.red.main;
                } else if (!(datos.valorado > 0) && datos.tipo == "envio") {
                    color = theme.palette.orange.main;
                }

                return color;
            },
            color: "white",
            margin: "2px"
        },
        filaFooter: {
            display: "flex",
            alignItems: "center",
            flexShrink:"0",
            position:"absolute",
            right:"5px",
        },
        footer1: {
            flex: "1"
        },
        footer2: {
            alignSelf: "flex-end",
            flex: "1",
            fontSize: "10px",
            textAlign: "right",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            paddingRight: "5px",
            paddingLeft: "5px"
        },
        footer3: {
            alignSelf: "flex-end"
        },
        enlace: {
            color: "inherit",
            '&:hover': {
                color: "inherit"
            }
            
        },
        iconoEnvios: {
            color: ({datos}) => datos.numEnvios && datos.numEnvios > 0 ? theme.palette.success.main : "#bdbdbd"
        },
        iconoTerminar: {
            cursor: "pointer"
        },      
        etiqueta:{
            borderRadius:"5px 5px 5px 5px",
            color:"black",
            backgroundColor:"#f2f2f2",
            marginRight:"5px",
            padding:"2px 5px 2px 5px",
            fontSize:"12px",
            marginBottom:"10px"
            
        }
        ,botonEstado: {

            minWidth: "130px", 
            display: "flex",
            '& span': {
                marginRight: "6px"
            }

        },   
        iconoFlecha:{
            marginLeft:"20px",
            marginRight:"20px"
        },           
        '@media (max-width: 768px)': {
            datoPrincipal: {
                fontSize: "14px",
                whiteSpace: "inherit !important",                
                textOverflow:"inherit !important",
                color: ({ datos }) => datos.tipo === "recogida" ? theme.palette.trafico.recogidas.dark : (datos.tipo === "recogidaFueraPlaza") ? theme.palette.trafico.recogidasFueraPlaza.dark: theme.palette.trafico.envios.dark,
                fontWeight: "600",
                maxWidth: "95%",
                width:"100%"
            }
            ,datoSecundario: {                
                marginTop: "20px",
                maxWidth: "95%"
            },
            caja: {
                flexDirection: 'column',
            },
            filaHoras: {
                display: "flex",
                flexFlow: "row nowrap",                
                alignItems: "center",
                justifyContent: "flex-start",
                width:"100%"                
            },
            fechaEnvio: {
                padding: "5px",
                display: "inline",
                minWidth: "inherit",
                textAlign: "center",
            }
            ,filaDatosTipo: {
                paddingLeft: 0,
                flexFlow: "row wrap",
                justifyContent: 'center',
                textAlign: 'left',
                marginTop: '10px', 
                maxWidth:"100%"              
            },
            filaFooter: {
                justifyContent: 'center',
                flexFlow: "row wrap",
                textAlign:"left",
                marginTop: '10px',
                position:"relative"
            }, 
            iconoFlecha:{
                display:"none"
            },
            direccion: {
                fontSize: "11px",
                color: "#848484",
                marginLeft: "20px",
                display: "inline-flex",
                fontWeight: "normal",
                whiteSpace: "normal",
                overflow: "hidden",
                
            }

        }


  }
});