import { Box, SxProps } from "@mui/material";
import { TooltipG } from "app/pages/utils";
import { dameTextoPuntoControl } from "../../helpers/trafico.helper";


interface IndicadorPuntoControlProps {
	estadoPuntoControl: "total" | "parcial" | "ninguno" | null;
	sx?: SxProps;
}

export default function IndicadorPuntoControl({estadoPuntoControl, sx} : IndicadorPuntoControlProps) {

    let textoPuntoControl = dameTextoPuntoControl(estadoPuntoControl);

    const baseSx: SxProps = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "20px",
        height: "20px",
        borderRadius: "50%",
        fontWeight: "bold",
        fontSize: "8px",
        color: estadoPuntoControl === "total" ? "#006400" : estadoPuntoControl === "parcial" ? "#8B4513" : "#8B0000",
        bgcolor: estadoPuntoControl === "total" ? "#90EE90" : estadoPuntoControl === "parcial" ? "#FFD700" : "#FFA07A"
    };


	return (
		<TooltipG title={`Punto de control: ${textoPuntoControl}`}>
			<Box sx={{...baseSx, ...sx}}>P.C</Box>
		</TooltipG>
	);
}
