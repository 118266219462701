import Yup from '../../../../librerias/yup';


export const schemaRecogidaMensajero = Yup.object({
    referenciaAlbaran: Yup.string().nullable(),
    nombreFirma: Yup.string().nullable(),
    DNIFirma: Yup.string().nullable(),
    idTipoIncidencia: Yup.string().nullable(),
    textoIncidencia: Yup.string().nullable()
});

export const schemaRetasacion = Yup.object({
    referencias: Yup.array().of(Yup.object({
        id: Yup.string().nullable(),
        peso: Yup.number().positive('Tiene que ser un número positivo').nullable()
    }))

});