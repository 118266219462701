import { ejecutaAPI } from "app/helpers/libFunciones";

export async function getListado<ListadoData>(url: string, queryParams : any) {

    let queryString = new URLSearchParams(queryParams).toString();

    if (queryString)
    {

        let separadorInicial = url.includes('?') ? '&' : '?';

        queryString = `${separadorInicial}${queryString}`;
    }

    const {success, respuesta, msg} = await ejecutaAPI<ListadoData>('GET', `/${url}${queryString}`);

    if (success)
    {
        return respuesta;
    }
    else 
    {
        throw new Error(msg.join(', '));
    }
}