import { toAbsoluteUrl } from "../utils/utils";

export default [
  {
    id: 1,
    username: "admin",
    password: "demo",
    email: "admin@demo.com",
    accessToken: "access-token-8f3ae836da744329a6f93bf20594b5cc",
    refreshToken: "access-token-8f3ae836da744329a6f93bf20594b5cc",
    roles: [1], // Administrator
    permisos:[1,2,3],
    pic: toAbsoluteUrl("/media/users/300_25.jpg"),
    fullname: "David",
    occupation: "CEO",
    companyName: "Keenthemes",
    phone: "456669067890",
    address: {
      addressLine: "L-12-20 Vertex, Cybersquare",
      city: "San Francisco",
      state: "California",
      postCode: "45000"
    }
  },
  
];
