import { useEffect } from 'react';
import { useFormikContext } from 'formik';


export const AutoSave = (props) => {
    const { values, handleSubmit } = useFormikContext();
    const {submit}=props;
    useEffect(() => {
        if(submit===undefined ||submit===true)
            handleSubmit();
    }, [values,submit, handleSubmit])
    
    return null;
}
