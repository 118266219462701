import { useCallback, useState } from "react";

export const useImageViewer = () => {

    const [open, setOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState(0);

    const nextImage = useCallback(() => {

        setCurrentImage(index => index + 1);

    }, [])

    const previousImage = useCallback(() => {

        setCurrentImage(index => index - 1);

    }, [])

    const openVisor = useCallback(() => setOpen(true), []);

    const closeVisor = useCallback(() => setOpen(false), []);

    const onClickThumbnail = useCallback((imgIndex) => setCurrentImage(imgIndex), []);

    return {
        open,
        currentImage,
        nextImage,
        previousImage,
        onClickThumbnail,
        openVisor,
        closeVisor
    }
}