import React from 'react';
import {CircularProgress} from '@mui/material';

const SpinnerCargando = ({posicion, ...props}) => {
    return (  
        <div style={{textAlign:posicion}}>
            <CircularProgress {...props} />
        </div>
    );
}
 
export default SpinnerCargando;