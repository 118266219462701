import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Grid, MenuItem } from "@mui/material";
import { FormSelect } from "app/componentes/formularios/FormSelect";
import { FormTextInput } from "app/componentes/formularios/FormTextInput";
import BotonCircular from "app/componentes/generales/BotonCircular";
import { pintaOpcionesSelect } from "app/helpers/libFunciones";
import { PlantillaTipoResponse } from "app/hooks/api/plantillas/useApiPlantillasTipos";
import { useFormContext, useWatch } from "react-hook-form";

interface CamposPlantillaProps {
	plantillasTipos: PlantillaTipoResponse[];
}
const CamposPlantilla = ({ plantillasTipos }: CamposPlantillaProps) => {
	const idTipoPlantilla = useWatch({ name: "idTipoPlantilla" });

	const tipoSeleccionado = plantillasTipos.find((tipo) => tipo.id === idTipoPlantilla);

	if (!tipoSeleccionado) {
		return null;
	}

	return (
		<Grid container spacing={1}>
			<Grid item>
				<h4>Campos de la plantilla</h4>
			</Grid>

			<Grid item lg={12} xs={12}>
				<Grid container spacing={1}>
					{tipoSeleccionado.campos.map((campo) => (
						<Grid item lg={12} xs={12} key={"campoTipoPlantilla" + campo.idTipoCampo}>
							<Box p={1} boxShadow={1}>
								<CampoTipoPlantilla campo={campo} datosPlantillaTipo={tipoSeleccionado} />
							</Box>
						</Grid>
					))}
				</Grid>
			</Grid>
		</Grid>
	);
};

interface CampoTipoPlantillaProps {
	campo: PlantillaTipoResponse["campos"][number];
	datosPlantillaTipo: PlantillaTipoResponse;
}

const CampoTipoPlantilla = ({ campo, datosPlantillaTipo }: CampoTipoPlantillaProps) => {
	const { setValue, getValues } = useFormContext();

	const campoForm = useWatch({ name: `campos[${campo.idTipoCampo}]` });

	const addCampoExtra = (tipo: any) => {
		const values = getValues();

		const objeto = {
			tipo: tipo,
			valor: "",
		};

		setValue(`campos[${campo.idTipoCampo}].jsonColumnas`, [...values.campos[campo.idTipoCampo].jsonColumnas, objeto]);
	};

	const removeCampoExtra = (indiceCampoExtra: number) => {
		const values = getValues();

		var camposNuevos = [...values.campos[campo.idTipoCampo].jsonColumnas];

		camposNuevos.splice(indiceCampoExtra, 1);

		setValue(`campos[${campo.idTipoCampo}].jsonColumnas`, camposNuevos);
	};

	if (campoForm) {
		return (
			<>
				<Grid container spacing={1}>
					<Grid item lg={12} xs={12}>
						<Grid container spacing={1} alignItems="center">
							<Grid item lg={2} xs={2}>
								<Box fontWeight={500} color="text.primary">
									{campo.campo}
								</Box>
							</Grid>
							<Grid item lg={2} xs={12}>
								<FormSelect hooks label="Funcionalidad" name={`campos[${campo.idTipoCampo}].funcion`}>
									<MenuItem value="columna">Columna</MenuItem>
									<MenuItem value="concatenar">Concatenar</MenuItem>
								</FormSelect>
							</Grid>
							<Grid item lg={2} xs={12}>
								<FormSelect
									hooks
									label="¿Qué hacer al actualizar?"
									disabled={campo.actualizable === "0"}
									name={`campos[${campo.idTipoCampo}].comportamientoEnActualizar`}
								>
									<MenuItem value="defecto">Defecto</MenuItem>
									<MenuItem value="actualizar">Actualizar</MenuItem>
								</FormSelect>
							</Grid>
							{(campoForm.funcion === "columna" || datosPlantillaTipo.tipo === "exportacion") && (
								<>
									<Grid item lg={2} xs={12}>
										<FormTextInput
											hooks
											tooltip="Letra de la columna"
											type="text"
											name={`campos[${campo.idTipoCampo}].columna`}
											label="Columna"
										/>
									</Grid>
									<Grid item lg={2} xs={12}>
										<FormTextInput hooks name={`campos[${campo.idTipoCampo}].valorDefecto`} label="Valor por defecto" />
									</Grid>
								</>
							)}
							{campoForm.funcion === "concatenar" && (
								<>
									{campoForm.jsonColumnas.map((campoJson: any, indiceExtra: number) => (
										<Grid item lg={2} xs={2} key={`camposExtra_${campo.idTipoCampo}_${indiceExtra}`}>
											<Grid container spacing={0} alignItems="center">
												<Grid item lg={10} xs={10}>
													<Box p={1}>
														{
															(datosPlantillaTipo.tipo === "importacion" || campoJson.tipo === "texto")
															?
															(
																<FormTextInput
																	hooks
																	type="text"
																	name={`campos[${campo.idTipoCampo}].jsonColumnas[${indiceExtra}].valor`}
																	label={campoJson.tipo === "columna" ? "Columna" : "Texto"}
																/>
															)
															:
															(
																<FormSelect hooks label="Añadir columna" name={`campos[${campo.idTipoCampo}].jsonColumnas[${indiceExtra}].valor`}>
																	{pintaOpcionesSelect(datosPlantillaTipo.campos, "idTipoCampo", "campo", "select_campos", 1)}
																</FormSelect>
															)
														}
														
													</Box>
												</Grid>
												<Grid item lg={2} xs={2}>
													<BotonCircular
														size="small"
														onClick={() => removeCampoExtra(indiceExtra)}
														color="secondary"
														variant="contained"
														type="button"
													>
														<CloseIcon fontSize="inherit" />
													</BotonCircular>
												</Grid>
											</Grid>
										</Grid>
									))}
								</>
							)}
						</Grid>
					</Grid>
					{campoForm.funcion === "concatenar" && (
						<>
							<Grid item lg={12} xs={12}>
								<Box bgcolor={"grey.100"}>
									<Grid container spacing={1} justifyContent="center">
										<Grid item>
											<Button type="button" color="primary" onClick={() => addCampoExtra("columna")}>
												Añadir columna
											</Button>
										</Grid>
										<Grid item>
											<Button type="button" color="primary" onClick={() => addCampoExtra("texto")}>
												Añadir texto
											</Button>
										</Grid>
									</Grid>
								</Box>
							</Grid>
						</>
					)}
				</Grid>
			</>
		);
	} else {
		return null;
	}
};

export default CamposPlantilla;
