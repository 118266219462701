import { Checkbox } from '@mui/material';
import { isArray } from 'lodash';
import React, { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

export const FormCheckboxRHF = React.memo(({name, value, defaultChecked = false, onChange, ...props}) => {

    const { control, getValues, setValue } = useFormContext();


    const handleChange = useCallback((e, valor) => {

        const valoresForm = getValues();

        const valores = valoresForm[name];

        var nuevoValor = null;

        if (isArray(valores))
        {
            nuevoValor = valores?.includes(valor) 
                ? valores?.filter(id => id !== valor)
                : [...(valores ?? []), valor];
        }
        else
        {
            nuevoValor = e.target.checked ? valor : ''
        }

        setValue(name, nuevoValor);

        if (typeof onChange === "function")
        {
            onChange(e, valor);
        }
        
    }, [getValues, name, onChange, setValue]);


    
    return ( 

        <Controller
            render={({field}) => { 
                return (
                    <Checkbox
                        {...field}
                        name={name}
                        checked={isArray(field.value) ? field.value.includes(value) : field.value === value}
                        onChange={(e) => handleChange(e, value)}
                    />
                )
            }}
            name={name}
            control={control}
        />
     );
})
 