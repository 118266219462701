import React, { useEffect, useReducer } from 'react';
import { Formik } from "formik";
import { ejecutaAPI } from './../../../../helpers/libFunciones';
import FormConfig from './FormConfig';
import { useGlobalLoader } from './../../../../hooks/hooksPropios';
import Swal from 'sweetalert2';
import * as permisos from '../../../../helpers/permisos';
import accessControl from "../../../../helpers/accessControl";
import {
  Portlet,
  PortletBody,
  PortletHeader
} from './../../../../partials/content/Portlet';
import { PaperLine } from "../../../utils";

export const ConfigContext = React.createContext();

const configReducer = (state, action) => {
  switch (action.type) {
    case 'SET_DATOS':
      return { ...action.payload };
    case 'SET_LOGO_TARIFA':
      return { ...state, logo_tarifa: action.payload, idLogoTarifa:action.payload?.id || null };
    case 'SET_LOGO_MANIFIESTO':
      return { ...state, logo_manifiesto: action.payload, idLogoManifiesto: action.payload?.id || null };
    default:
      throw new Error();
  }
}



const ConfigGenerales = (props) => {

  const { setGlobalCargando } = useGlobalLoader();

  const [config, dispatch] = useReducer(configReducer, {
    logo_tarifa: null,
    idLogoTarifa: null,
    logo_manifiesto:null,
    idLogoManifiesto:null,    
    hostSMTP:null,
    portSMTP:null,
    usuarioSMTP:null,
    passSMTP:null,
    replyTo:null,
    nombreRemitente:null

  });

  useEffect(() => {

    (async () => {

      setGlobalCargando(true);

      var { success, respuesta } = await ejecutaAPI('GET', `config`);

      if (success) {
        dispatch({ type: "SET_DATOS", payload: respuesta });
      }

      setGlobalCargando(false);

    })();

  }, [setGlobalCargando]);


  const handleSubmit = (values, { setFieldValue }) => {

    (async () => {
      setGlobalCargando(true);

                 
      const data = { idLogoTarifa:config.logo_tarifa?.id || null,
                   idLogoManifiesto:config.logo_manifiesto?.id || null, 
                   ...values
                  };
      const { success } = await ejecutaAPI('PUT', `config`, data);

      if (success) {

        Swal.fire(
          'Éxito',
          'Se han guardado los datos de configuración',
          'success'
        );


      }

      setGlobalCargando(false);
    })();



  }



  return (
    <ConfigContext.Provider value={[config, dispatch]}>
      <Portlet>
        <PortletHeader
          title={<>
            Variables </>
          }          
        >
        </PortletHeader>
        <PortletBody>

          <PaperLine elevation={0} style={{
            marginTop: "10px", marginBottom: "20px"
          }}>

            <p>
              Variables de configuración
           </p>

          </PaperLine>
          <Formik enableReinitialize initialValues={config} onSubmit={handleSubmit}>
            {
              formik => (
                <form onSubmit={formik.handleSubmit}>
                  <FormConfig></FormConfig>
                </form>
              )
            }
          </Formik>
        </PortletBody>


      </Portlet>
    </ConfigContext.Provider>
  );

}

export default accessControl([permisos.config.ver])(ConfigGenerales);



