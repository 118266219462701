import React from 'react';
import { FormAutocompleteFormik } from './Librerias/Formik/FormAutocompleteFormik';
import FormAutocompleteRHF from './Librerias/ReactHookForm/FormAutocompleteRHF';

export const FormAutocomplete = (props) => {

    const { hooks, ...otherProps } = props;


    return (
        !hooks ? 
        (
            <FormAutocompleteFormik {...otherProps} />
        )
        :
        (
            <FormAutocompleteRHF {...otherProps} />
        )
    )
}
