import axios from "axios";
import jwt_decode from 'jwt-decode';
import Header from "./Header";
import { guardaCookie } from './cookie';

//VERBOS  GET POST DELETE PUT

let dominio = document.domain;

//export const BASE_URL = "http://localhost:3010/";

if(document.domain === "localhost" || document.domain==="apimensaglobal")
{
    dominio="apimensaglobal";
}
else
{
    dominio = document.domain+"/api";
}

export const BASE_URL = window.location.protocol + "//" + dominio;

const cerrarSesion=()=>{

    localStorage.clear();
    sessionStorage.clear();
    window.location = "/home/auth/login";
}

const errorHandler = async (error) => {      

    const originalRequest = error.config;
    const response=error.response;

    let jwt='';


    if(response)
    {  

        if (401 === response.status && response.data) 
        {
  
           if (response.data.msg[0].indexOf("Autorización requerida") > -1) 
           {
            
                var refreshToken = JSON.parse(localStorage.getItem("login"))?.refreshToken;

                if (refreshToken !== undefined && refreshToken.length > 0)
                {
                    //Comprobamos que el refreshToken no haya caducado.
                
                    jwt = jwt_decode(refreshToken);
            
                    let timeActual=(new Date().getTime() / 1000).toFixed(0);

                    if(jwt.exp<timeActual )
                    {

                        //ha expirado el refresh Token
                        cerrarSesion();

                    } 
                    else
                    {                 
                
                        let resp= await axios.post(BASE_URL + "/Auth/getRefreshToken", "refreshToken="+refreshToken);
                
                        if(resp && resp.data.success===true)
                        {                  

                            //Actaulizamos en el localStorage los token
                            const token = resp.data.authToken;
                            const refreshToken=resp.data.refreshToken;

                            var objeto = JSON.parse(localStorage.getItem("login"));
                            
                            objeto.authToken=token;
                            objeto.refreshToken=refreshToken;                                                                    
                            
                            guardaCookie({authToken:token});

                            localStorage.removeItem("login");
                            localStorage.setItem("login",JSON.stringify(objeto));

                            let newRequest={...originalRequest};                                                                      
                            
                            return Api(newRequest.method,newRequest.url,newRequest.data);
                            
                        
                        }
                        else
                        {

                            cerrarSesion();
                        }
                    }
                }
                else 
                {

                    //Si no viene el token, ¿Cerrar sesión?. Habría que comprobar si al no devolver la promise entra en el .then de la petición en vez de en el .catch
                    cerrarSesion();
                }
   
            }
            else 
            {

                //Error 401 con data, pero sin el mensaje que reinicia el token.
                return Promise.reject(error);
            } 
        }
        else 
        {

            //Error != 401 y no viene data.
            return Promise.reject(error);
        }
    }
    else 
    {
        //No viene respuesta en la petición.

        var login = JSON.parse(localStorage.getItem("login"));

        if (login === null)
        {
            cerrarSesion();
        }
        else 
        {
            return Promise.reject(error);
        }

    }
    
}

const successHandler = (response) => {
   
    return response;
}


const  Api = (method, url, data = {}, options = {}) => {

    
    const axiosInstance = axios.create({
        baseURL: BASE_URL,
        headers:Header(),
        withCredentials: true,
    });
    
    axiosInstance.interceptors.response.use(
        response => successHandler(response),
        error => errorHandler(error)
    )

    const httpMethod = method.toLowerCase();

    const hasData = ["post", "put", "patch"].indexOf(httpMethod) >= 0;
    const settings = hasData ? options : data;

    const request = hasData
        ? axiosInstance[httpMethod](url, data, settings)
        : axiosInstance[httpMethod](url, settings);  
    
        return request;
}


export default Api;



