import { useQuery } from "@tanstack/react-query";
import { useDataArray } from "app/hooks/useDataArray";
import { ejecutaAPI } from "../../../helpers/libFunciones";

export interface PaisesResponse {
  id: string;
  pais: string;
  alpha2Code: string;
  alpha3Code: string;
  codigoNumerico: null | string;
  bandera: string;
  prefijoTelefonico: null | string;
}

export const getPaises = async () => {


    const {success, respuesta, msg} = await ejecutaAPI<PaisesResponse[]>('GET', `/localizaciones/paises`);

    if (success)
    {
        return respuesta;
    }
    else 
    {
        throw new Error(msg.join(", "));
    }
}

export const useApiListadoPaises = (optionsExtra = {}) => {


    const options = {
        staleTime: 1000 * 60 * 1440,
        cacheTime: 1000 * 60 * 1440,
        ...optionsExtra
    }   

    const query = useQuery(['paises'], () => getPaises(), options);

    const paises = useDataArray<PaisesResponse[]>(query.data);

    return {
        ...query,
        paises
    }
}