import { Route, Switch } from "react-router-dom";
import Permisos from "./permisos/";
import FichaUsuarioPage from "./users/FichaUsuarioPage";
import ListadoUsuariosPage from "./users/ListadoUsuariosPage";

export default function ConfigPage() {
    return (
        <Switch>
           
            <Route path="/config/users/:id" render={(props) => <FichaUsuarioPage key={props.match.params.id} id={props.match.params.id} />} />
            <Route path="/config/users" exact render={() => <ListadoUsuariosPage />} />

            <Route path="/config/permisos" exact render={() => <Permisos />} />
            
        </Switch>
    );
}
