import { useQuery } from "@tanstack/react-query";
import { ejecutaAPI } from "app/helpers/libFunciones";

export interface ConfigResponse {
  id: string;
  idLogoTarifa: string | null;
  idLogoManifiesto: string | null;
  hostSMTP: string | null;
  portSMTP: string | null;
  usuarioSMTP: string | null;
  passSMTP: string | null;
  replyTo: string | null;
  nombreRemitente: string | null;
  mimeTypeRecogidas: string | null;
  horaMaximaRecogidas: string | null;
  logo_tarifa: LogoConfig | [];
  logo_manifiesto: LogoConfig | [];
}

export interface LogoConfig {
  id: string;
  ruta: string;
  nombre: string;
  idUsuario: string;
  temporal: string;
  fechaCreacion: string;
  fechaActualizacion: null;
  base64: string;
  mimeType: string;
  url: string;
}

const getConfig = async () => {

    const {success, respuesta, msg} = await ejecutaAPI<ConfigResponse>('GET', '/config');

    if (success)
    {
        return respuesta;
    }
    else 
    {
        throw new Error(msg.join(", "));
    }

}


export const useApiGetConfig = (options = {}) => {


    const defaultOptions = { 
        staleTime: 1000 * 3600, cacheTime: 1000 * 86400,
        ...options 
    }

    const data = useQuery(['config'], () => getConfig(), defaultOptions);

    let errorMsg = data.error instanceof Error ? data.error.message : '';

    return {
        ...data,
        errorMsg
    }

}