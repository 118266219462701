export const clientes = {

    ver: 1,
    crear: 2,
    eliminar: 3,
    editar: 4,
    exportar: 5,
    verValoraciones:102
}

export const redes={
  editar:53,
  ver:52
}


export const tarifas={
 editar: 65,
 ver: 61, 
 crear: 62,
 ver_costes: 63,
 eliminar: 64,
 desbloquear: 66
}

export const simulador = {
 ver:55,
 ver_costes:56
}

export const usuarios={
 ver:72,
 crear:73,
 eliminar:74,
 editar:75,
 editar_interno:76,
 editar_mensajero:77,
 editar_cliente:78,
 editar_comercial:79,
 editar_subagencia: 109

}

export const config={
 ver:89

}

export const recogidas = {

  ver: 47,
  crear: 48,
  eliminar: 49,
  editar: 50,
  ver_estadisticas: 51,
  no_imprimir_etiquetas: 82,
  bloquear_origen: 101,
  ver_todos:104,
  ver_programaciones:105,
  recogidasFueraPlaza:106

}

export const envios = {

  ver: 7,
  crear: 8,
  editar:9,
  eliminar:13,
  ver_estadisticas: 10,
  valorar_envios: 11,
  ver_valoracion: 12,
  forzar_envios: 14,
  editar_portes: 98,
  usar_reembolso: 99,
  ver_todos: 103,
  importar_reembolsos: 107,
  editar_pago_reembolsos: 108
}

export const utilidades={
  ver :80
}

export const transmision={
  ver:67,
  transmitir:68,
  enviarsms:69,
  enviaremails:70,
  codigosbarras:71
}

export const importacion = {
  ver: 28
}

export const categorias={
  ver :87,
  editar:88,
  eliminar:88
}

export const facturacion = {
  ver: 15,
  facturar: 19,

}

export const plantillas = {
  ver: 37,
  crear: 40,
  editar: 38,
  borrar: 39
}