import React, { useState,useEffect } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { TextField, Grid, Button } from "@mui/material";
import clsx from "clsx";
import * as auth from "../../store/ducks/auth.duck";
import axios from "axios";
import { login } from "../../crud/auth.crud";
import { BASE_URL } from './../../crud/Api';
import './login.css';
import { useHistory } from "react-router-dom";


function Login(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [roles,setRoles]= useState({});
  const [datos, setDatos] = useState({user: "",password: "",rol:"" });  
  const [logoCliente,setLogoCliente]=useState("");

  const history= useHistory();

  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem",
    backgroundColor:"#000674",
    borderColor:"#000674"
  });

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({
      paddingRight: "3.5rem", backgroundColor: "#000674",
      borderColor: "#000674" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({
      paddingRight: "2.5rem", backgroundColor: "#000674",
      borderColor: "#000674" });
  };

  const onSubmitForm = (values, { setStatus, setSubmitting }) => {
    enableLoading();

    setDatos({...datos,user:values.user,password:values.password});

    login(values.user, values.password,values.rol)
      .then(({ data }) => {
        disableLoading();

        if (data.success === false) {
          setSubmitting(false);
          setStatus(
            data.msg[0]
          );
        } else {

          //Comprobamos
          if (data.authToken !== undefined) {   

            let set=data;

            if(data.data.roles.length===1){
              let rol = data.data.roles[0].id;              
              set.idRol=rol;
              localStorage.setItem("login", JSON.stringify(set));
              props.login({ ...data, idRol: rol });
            }else{

              set.idRol = datos.rol;
              localStorage.setItem("login", JSON.stringify(set));
              props.login({ ...data, idRol: datos.rol });
            }
            
            
          } else {

            setSubmitting(false);
            //Mostramos las opciones de login
            setRoles(data.data.roles);           
          }

        }
      })
      .catch(() => {
        disableLoading();
        setSubmitting(false);
        setStatus(
          intl.formatMessage({
            id: "AUTH.VALIDATION.INVALID_LOGIN"
          })
        );
      });


  }

  useEffect( ()=>{

      (async()=>{

        let param = { "dominio": document.domain };          

        try{
               
        let resp = await axios.post(BASE_URL + "/Auth/dameDatosDominio", param );

      
        if(resp.data.success){       
              setLogoCliente(resp.data.data.logotipo);          
              localStorage["logotipo"] = resp.data.data.logotipo;
              localStorage["logotipo_base64"]=resp.data.data.base64;
              localStorage["logotipo_mimeType"] = resp.data.data.mimeType;
          
        }else{

          if (document.domain=="localhost" || document.domain=="apimensaglobal"){                                        
                        
              let logo ="http://apimensaglobal/uploads/mensaglobal.avanzadi.com/1604656788_df77be886fad9b723929.png";
              setLogoCliente(logo);
              localStorage["logotipo"] = logo;
              localStorage["logotipo_base64"] = "";
              localStorage["logotipo_mimeType"] = "";

          }else{
            history.push("/error/dominio");            
            
          }

        }

      }catch(e){

        
           if (document.domain == "localhost" || document.domain=="apimensaglobal") {

              let logo = "http://apimensaglobal/uploads/mensaglobal.avanzadi.com/1604656788_df77be886fad9b723929.png";
              setLogoCliente(logo);
              localStorage["logotipo"] = logo;
              localStorage["logotipo_base64"] = "";
              localStorage["logotipo_mimeType"] = "";
           }else{

              history.push("/error/dominio");
           }
      }

      })();

  },
  [history]);

  
  return  (
    <>
      <div className="kt-login__body">
        <div className="kt-login__form">
          <Formik
            enableReinitialize
            initialValues={datos}
            validate={values => {
              const errors = {};

              if (!values.user) {
                // https://github.com/formatjs/react-intl/blob/master/docs/API.md#injection-api
                errors.user = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
              } 

              if (!values.password) {
                errors.password = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
              }

              return errors;
            }}
            onSubmit={onSubmitForm}
          >
            {({
              values,
              status,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
              <form
                noValidate={true}
                autoComplete="off"
                className="kt-form"
                onSubmit={handleSubmit}               
              >
                {status ? (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">{status}</div>
                  </div>
                ) : (
                  <></>    
                )}
                
                  <input type="hidden" id="rol" name="rol" value={values.rol}></input>

                  {(logoCliente!="")?
                    (<div style={{margin:"0px auto",width:"100%",textAlign:"center",marginBottom:"20px"}}><img alt="" className="logoCliente" src={logoCliente}></img></div>)
                    :null
                  }          

                <Grid container >
                  <Grid item xs={12}>
                    <TextField
                      type="text"
                      label="Usuario"
                      margin="normal"                  
                      name="user"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.user}
                      helperText={touched.user && errors.user}
                      error={Boolean(touched.user && errors.user)}
                    />
                  </Grid>

                  <Grid item xs={12}>
                      <TextField
                        type="password"
                        margin="normal"
                        label="Password"                     
                        name="password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.password}
                        helperText={touched.password && errors.password}
                        error={Boolean(touched.password && errors.password)}
                      />
                </Grid>

                </Grid>
                  
                <div className="kt-login__actions">
                                 
                  <button
                    id="kt_login_signin_submit"
                    type="submit"
                    disabled={isSubmitting}
                    className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                      {
                        "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                      }
                    )}`}
                    style={loadingButtonStyle}
                  >
                    Entrar
                  </button>

                </div>
                
                  {
                    (roles && roles.length > 0) ?
                      <Grid container spacing={10}>
                        {
                          Object.values(roles).map(item => (
                            <Grid item key={item.id} xs={3} lg={3}>
                              <Button type="submit"
                                onClick={() => {
                                  setDatos({ ...datos, rol: item.id })
                                }} variant="contained" color="secondary"
                                style={{minWidth:"100px"}}
                                >
                                {item.nombre}
                              </Button>
                            </Grid>

                          ))
                        }
                      </Grid>
                      :
                      null


                  } 

              </form>
            )}
          </Formik>  
          </div>
          </div>      
    </>
  );
}

export default injectIntl(
  connect(
    null,
    auth.actions
  )(Login)
);
