import { takeLatest,call } from "redux-saga/effects";


export const actionTypes = {
  LISTADO_REDES:"[Listado Redes]",  
  LISTADO_RUTAS: "[Listado Rutas]",  
  ACTUALIZA_RUTAS: "[Actualiza Rutas]",  
  LISTADO_MAESTROS: "[Listado Maestros]",
  ACTUALIZA_MAESTROS: "[Actualiza Maestros]",  
  LISTADO_DELEGACIONES: "[Listado Delegaciones]",
  ACTUALIZA_DELEGACIONES: "[Actualiza Delegaciones]",  
  LISTADO_SERVICIOS: "[Listado Servicios]",
  ACTUALIZA_SERVICIOS: "[Actualiza Servicios]", 
  LISTADO_VARIABLES: "[Listado Variables]",
  ACTUALIZA_VARIABLES: "[Actualiza Variables]"
};

const initialState = { 
    redes: { data: [] }, 
    rutas: { data: [] }, 
    maestros: { data: [] }, 
    delegaciones: { data: [] }, 
    servicios: {data:[]},
    variables: {data:[]},
    actualiza_rutas:0,
    actualiza_maestros:0,
    actualiza_delegaciones:0,
    actualiza_servicios:0,
    actualiza_variables:0

  } ;

export const reducer = 
    (state = initialState, action) => {
      switch (action.type) {
        case actionTypes.LISTADO_REDES: {
          
          return {...state,redes:action.payload};
        }

        case actionTypes.LISTADO_RUTAS: {

          return { ...state, rutas: action.payload };
        }

        case actionTypes.ACTUALIZA_RUTAS: {

          return { ...state, actualiza_rutas: state.actualiza_rutas+1 };
        }

        case actionTypes.LISTADO_MAESTROS: {

          return { ...state, maestros: action.payload };
        }

        case actionTypes.ACTUALIZA_MAESTROS: {

          return { ...state, actualiza_maestros: state.actualiza_maestros + 1 };
        }

        case actionTypes.LISTADO_DELEGACIONES: {

          return { ...state, delegaciones: action.payload };
        }

        case actionTypes.ACTUALIZA_DELEGACIONES: {

          return { ...state, actualiza_delegaciones: state.actualiza_delegaciones + 1 };
        }

        case actionTypes.LISTADO_SERVICIOS: {

          return { ...state, servicios: action.payload };
        }

        case actionTypes.ACTUALIZA_SERVICIOS: {

          return { ...state, actualiza_servicios: state.actualiza_servicios + 1 };
        }

        case actionTypes.LISTADO_VARIABLES: {

          return { ...state, variables: action.payload };
        }

        case actionTypes.ACTUALIZA_VARIABLES: {

          return { ...state, actualiza_variables: state.actualiza_variables + 1 };
        }

      
        default:

          return state;
      }
}
   

export const actions = {
  listado: (payload) => ({ type: actionTypes.LISTADO_REDES, payload }) , 
  listadoRutas: (payload) => ({ type: actionTypes.LISTADO_RUTAS, payload }),  
  actualizaRutas: (payload) => ({ type: actionTypes.ACTUALIZA_RUTAS, payload }),  
  listadoMaestros: (payload) => ({ type: actionTypes.LISTADO_MAESTROS, payload }),  
  actualizaMaestros: (payload) => ({ type: actionTypes.ACTUALIZA_MAESTROS, payload }) , 
  listadoDelegaciones: (payload) => ({ type: actionTypes.LISTADO_DELEGACIONES, payload }),
  actualizaDelegaciones: (payload) => ({ type: actionTypes.ACTUALIZA_DELEGACIONES, payload }),  
  listadoServicios: (payload) => ({ type: actionTypes.LISTADO_SERVICIOS, payload }),
  actualizaServicios: (payload) => ({ type: actionTypes.ACTUALIZA_SERVICIOS, payload }) , 
  listadoVariables: (payload) => ({ type: actionTypes.LISTADO_VARIABLES, payload }),
  actualizaVariables: (payload) => ({ type: actionTypes.ACTUALIZA_VARIABLES, payload }),  
};


//Watchers
export function* saga() {
  yield takeLatest(actionTypes.LISTADO_REDES, function* listadoSaga(){});
  yield takeLatest(actionTypes.LISTADO_RUTAS, function* listadoSaga(){});  
  yield takeLatest(actionTypes.LISTADO_MAESTROS, function* listadoSaga(){});    
  yield takeLatest(actionTypes.LISTADO_DELEGACIONES, function* listadoSaga(){});    
  yield takeLatest(actionTypes.LISTADO_SERVICIOS, function* listadoSaga() { });    
  yield takeLatest(actionTypes.LISTADO_VARIABLES, function* listadoSaga() { });
}

