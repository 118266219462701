import { Alert, AlertProps } from "@mui/material";
import { ReactNode, forwardRef } from "react";

interface SnackbarAlertProps {
    children: ReactNode;
    props: AlertProps;
}


const SnackbarAlert = forwardRef<HTMLDivElement, SnackbarAlertProps>(({children, ...props} : SnackbarAlertProps, ref) => {

    return (
        <Alert ref={ref} elevation={6} variant="filled"  {...props}>
            {children}
        </Alert>
    )
})

export default SnackbarAlert;