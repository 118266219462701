import { Grid, MenuItem, Paper } from "@mui/material";
import BotonesFormulario from "app/componentes/botones/BotonesFormulario";
import { FormAutocomplete } from "app/componentes/formularios/FormAutocomplete";
import { FormDatepicker } from "app/componentes/formularios/FormDatepicker";
import FormRHF from "app/componentes/formularios/FormRHF";
import { FormSelect } from "app/componentes/formularios/FormSelect";
import { FormTextArea } from "app/componentes/formularios/FormTextArea";
import { FormTextInput } from "app/componentes/formularios/FormTextInput";
import TienePermisos from "app/helpers/TienePermisos";
import { pintaOpcionesSelect } from "app/helpers/libFunciones";
import { usuarios as permisosUsuarios } from "app/helpers/permisos";
import { roles, rolesSelect } from "app/helpers/roles";
import { DatosCliente } from "app/hooks/api/clientes/useClientes";
import { useRoles } from "app/hooks/hooksPropios";
import Yup from "app/librerias/yup";
import { InitialFormUsuarioValues, ValidatedFormUsuarioValues } from "../../models/usuarios.types";
import CamposPassword from "./CamposPassword";
import CamposRoles from "./CamposRoles";

const schema = Yup.object().shape({
	nombre: Yup.string().required("Requerido").nullable(),
	apellido1: Yup.string().required("Requerido").nullable(),
	user: Yup.string().required("Requerido").nullable(),
	email: Yup.string().email("Email no válido").required("Requerido").nullable(),
	telefono: Yup.string().min(6, "Teléfono no válido").required("Requerido").nullable(),
	idRol: Yup.array().of(Yup.string()).required("Requerido").nullable(),
	password: Yup.string().min(6, "La clave debe teber mínimo 6 caracteres").max(16, "La clave debe teber máximo 6 caracteres").nullable(),
	repetir_pass: Yup.string()
		.nullable()
		.test("passwords-match", "El password no coincide", function (this: Yup.TestContext, value: string) {
			return this.parent.password === value;
		}),
	idCliente: Yup.string().when("idRol", (idRol: string[]) => {
		let tieneRolCliente = idRol.includes(roles.CLIENTE.toString());

		return tieneRolCliente ? Yup.string().required("Requerido").nullable() : Yup.string().nullable();
	}),
    idClienteSubagencia: Yup.array().of(Yup.string()).when("idRol", (idRol: string[]) => {
		let tieneRolSubagencia = idRol.includes(roles.SUBAGENCIA.toString());

		return tieneRolSubagencia ? Yup.array().of(Yup.string()).min(1, 'Tienes que seleccionar al menos un cliente').required("Requerido").nullable() : Yup.array().of(Yup.string()).nullable();
	}),
	dni: Yup.string()
		.matches(/^[0-9]{8,8}[A-Z]$/, { message: "Dni no válido ", excludeEmptyString: true })
		.nullable(),
});

interface FormularioFichaUsuarioProps {
    formValues: InitialFormUsuarioValues;
    guardarUsuario: (values: ValidatedFormUsuarioValues) => void;
    paises: any;
    clientes: DatosCliente[];
    id: string;
}

export default function FormularioFichaUsuario({id, formValues, guardarUsuario, paises, clientes} : FormularioFichaUsuarioProps) {

	const { esAdmin } = useRoles();

	//Admin puede crear otros admins
	let rolesSelector = rolesSelect;

	if (!esAdmin) {
		rolesSelector = rolesSelect.filter(rol => rol.id !== roles.ADMIN);
	}

	return (
		<FormRHF defaultValues={formValues} onSubmit={guardarUsuario} schema={schema}>
			<FormTextInput hooks type="hidden" name="id" />
			<Grid container spacing={8}>
				<Grid item xs={12} lg={3}>
					<Paper elevation={3} style={{ marginTop: 15 }}>
						<div style={{ margin: "0px auto", textAlign: "center" }}>
							<img src="/media/users/user-default.png" alt="default" style={{ padding: 20 }} width="200"></img>
						</div>
					</Paper>

					<FormSelect hooks name="activo" label="Activo" variant="standard">
						<MenuItem value="1">Activo</MenuItem>
						<MenuItem value="0">Desactivado</MenuItem>
					</FormSelect>

					<FormSelect
						hooks
						name="idRol"
						multiple
						label="Roles"
						variant="standard"
						disabled={id !== "new" && !TienePermisos([permisosUsuarios.editar])}
					>
						{pintaOpcionesSelect(rolesSelector, "id", "nombre", "rolesFilter", 0)}
					</FormSelect>

					<CamposRoles idUsuario={id} clientes={clientes} />
				</Grid>

				<Grid item xs={12} lg={9}>
					<Grid container spacing={3}>
						<Grid item xs={12} lg={4}>
							<FormTextInput hooks label="Nombre" name="nombre" />
						</Grid>
						<Grid item xs={12} lg={4}>
							<FormTextInput hooks label="Apellido 1" name="apellido1" />
						</Grid>

						<Grid item xs={12} lg={4}>
							<FormTextInput hooks label="Apellido 2" name="apellido2" />
						</Grid>
					</Grid>

					<Grid container spacing={3}>
						<Grid item xs={12} lg={4}>
							<FormTextInput hooks label="Usuario *" name="user" />
						</Grid>
						<CamposPassword id={id} />
					</Grid>

					<Grid container spacing={3}>
						<Grid item xs={12} lg={4}>
							<FormTextInput hooks label="Email *" name="email" />
						</Grid>

						<Grid item xs={12} lg={4}>
							<FormTextInput hooks label="Teléfono" name="telefono" />
						</Grid>

						<Grid item xs={12} lg={4}>
							<FormTextInput hooks label="Dni" name="dni" />
						</Grid>
					</Grid>

					<Grid container spacing={3}>
						<Grid item xs={12} lg={4}>
							<FormAutocomplete
								hooks
								name="idPais"
								label="País"
								opciones={paises.map((pais: any) => ({
									id: pais.id,
									value: `${pais.pais} (${pais.alpha2Code})`,
								}))}
							/>
						</Grid>

						<Grid item xs={12} lg={4}>
							<FormTextInput hooks label="Provincia" name="provincia" />
						</Grid>

						<Grid item xs={12} lg={4}>
							<FormTextInput hooks label="Ciudad" name="ciudad" />
						</Grid>
					</Grid>

					<Grid container spacing={3}>
						<Grid item xs={12} lg={6}>
							<FormTextInput hooks label="Calle" name="calle" />
						</Grid>

						<Grid item xs={12} lg={2}>
							<FormTextInput hooks label="Núm" name="numero" />
						</Grid>

						<Grid item xs={12} lg={4}>
							<FormTextInput hooks label="Código Postal" name="cp" />
						</Grid>
					</Grid>
					<br />
					<br />

					<Grid container spacing={3}>
						<Grid item xs={12} lg={4}>
							<label>Caducidad carnet conducir</label>
							<br />
							<FormDatepicker
								hooks
								variant="outlined"
								name="fecha_cad_carnet_conducir"
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>

						<Grid item xs={12} lg={8}>
							<label>Observaciones</label>
							<FormTextArea variant="outlined" hooks name="observaciones" className="input" placeholder="Observaciones" />
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			{
				TienePermisos([permisosUsuarios.editar]) ? <BotonesFormulario /> : null
			}
		</FormRHF>
	);
}
