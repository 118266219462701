import { Grid, MenuItem } from "@mui/material";
import { FormSelect } from "app/componentes/formularios/FormSelect";
import { FormTextInput } from "app/componentes/formularios/FormTextInput";
import { pintaOpcionesSelect } from "app/helpers/libFunciones";
import { rolesSelect } from "app/helpers/roles";



export default function FiltrosListadoUsuarios() {


    return (
        <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={4} lg={2}>
                <FormTextInput hooks name="usuario" label="Usuario" />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2}>
                <FormTextInput hooks name="nombre" label="Nombre" />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2}>
                <FormTextInput hooks name="apellidos" label="Apellidos" />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2}>
                <FormTextInput hooks name="email" label="Email" />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={1}>
                <FormTextInput hooks name="telefono" label="Teléfono" />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={1}>
                <FormTextInput hooks name="ciudad" label="Ciudad" />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={1}>
                <FormSelect hooks name="roles" multiple label="Rol">
                    {pintaOpcionesSelect(rolesSelect, "id", "nombre", "rolesFilter", 0)}
                </FormSelect>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={1}>
                <FormSelect hooks name="activo" label="Activo">
                    <MenuItem value="">Seleccionar</MenuItem>
                    <MenuItem value="1">Sí</MenuItem>
                    <MenuItem value="0">No</MenuItem>
                </FormSelect>
            </Grid>
        </Grid>
    )

}