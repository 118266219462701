import { gira_fecha } from "app/helpers/libFunciones";
import { FicheroLecturas } from "./FicheroLecturas.class";

export class FicheroLecturasAmazon extends FicheroLecturas {

    generarContenido() {

        let devolver = '';

        let envios = this.envios;


        if (envios.length === 0) 
        {
            throw new Error('No se han filtrado envíos');
        }

        devolver = `order-id\torder-item-id\tquantity\tship-date\tcarrier-code\tcarrier-name\ttracking-number\tship-method\ttransparency_code\n`;

        
        envios.forEach(envio => {

            let referencia = this.rellenaConEspacios(envio.referencia ?? '', 19);

            let codBar = envio.codigoBarras ?? '';

            let fecha = gira_fecha(envio.fecha, '-');

            let line = `${referencia}\t\t\t${fecha}\tOther\t${envio.nombreRed}\t${codBar}\t${envio.nombreServicioMaestro}\t\n`;
        
            devolver += line;

        });

      return devolver;
    }

    rellenaConEspacios(value, numEspacios) {

        return value.padStart(numEspacios);
    }

}