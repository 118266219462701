import ClearIcon from "@mui/icons-material/Clear";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import SearchIcon from "@mui/icons-material/Search";
import ViewColumn from "@mui/icons-material/ViewColumn";
import {
    Box,
    FormControlLabel,
    IconButton,
    LinearProgress,
    Menu,
    MenuItem,
    MenuList,
    Table as MuiTable,
    Paper,
    Switch,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
} from "@mui/material";
import { flexRender } from "@tanstack/react-table";
import { Row, RowData, Table } from "@tanstack/table-core";
import { useMenuContextual } from "app/hooks/hooksPropios";
import { TooltipG } from "app/pages/utils";
import { Fragment, ReactNode, useState } from "react";
import { FieldValues, UseFormReset } from "react-hook-form";
import FormRHF from "../../formularios/FormRHF";
import { DatatableFiltersState } from "./hooks/useDatatableFilters";
import { DatatablePaginationState } from "./hooks/useDatatablePagination";

declare module "@tanstack/table-core"
{
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    interface ColumnMeta<TData extends RowData, TValue>
    {
        maxWidth?: string;
        width?: string;
        minWidth?: string;
    }
}

export const LoadingOverlay = ({ cargando = false }) => {
    return (
        <>
            {cargando && (
                <>
                    <div style={{ position: "absolute", top: 0, width: "100%", zIndex: 3 }}>
                        <LinearProgress />
                    </div>
                </>
            )}
        </>
    );
};

interface DatatableHeaderProps
{
    table: Table<unknown>;
}

export const DatatableHeader = ({ table }: DatatableHeaderProps) => {
    return (
        <TableHead>
            {table.getHeaderGroups().map(headerGroup => (
                <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map(header => (
                        <TableCell
                            key={header.id}
                            sx={{
                                maxWidth: header.column.columnDef?.meta?.maxWidth ?? "100%",
                                minWidth: header.column.columnDef?.meta?.minWidth ?? "auto",
                                width: header.column.columnDef?.meta?.width ?? "auto",
                            }}
                        >
                            {header.column.getCanSort()
                                ? (
                                    <TableSortLabel
                                        active={Boolean(header.column.getIsSorted())}
                                        direction={header.column.getIsSorted() || "asc"}
                                        onClick={header.column.getToggleSortingHandler()}
                                    >
                                        {header.isPlaceholder ? null : flexRender(
                                            header.column.columnDef.header,
                                            header.getContext(),
                                        )}
                                    </TableSortLabel>
                                )
                                : (
                                    header.isPlaceholder ? null : flexRender(
                                        header.column.columnDef.header,
                                        header.getContext(),
                                    )
                                )}
                        </TableCell>
                    ))}
                </TableRow>
            ))}
        </TableHead>
    );
};

interface DatatableBodyProps
{
    table: Table<unknown>;
    subrowComponent: ((row: any) => ReactNode) | null;
    onRowClick?: ((row: any) => void);
}

export const DatatableBody = ({ table, subrowComponent = null, onRowClick = undefined }: DatatableBodyProps) => {
    return (
        <TableBody>
            {table.getRowModel().rows.map(row => <DatatableBodyRow key={row.id} row={row} subrowComponent={subrowComponent} onRowClick={onRowClick} />)}
        </TableBody>
    );
};

interface DatatableBodyRowProps
{
    row: Row<unknown>;
    subrowComponent: ((row: any) => ReactNode) | null;
    onRowClick?: ((row: any) => void);
}

export const DatatableBodyRow = ({ row, subrowComponent = null, onRowClick = undefined }: DatatableBodyRowProps) => {


    const handleRowClick = () => {

        if (onRowClick)
        {
            onRowClick(row);
        }

    }

    return (
        <Fragment>
            <TableRow hover key={row.id} onClick={handleRowClick}>
                {row.getVisibleCells().map(cell => (
                    <TableCell
                        key={cell.id}
                        sx={{
                            maxWidth: cell.column.columnDef?.meta?.maxWidth ?? "100%",
                            minWidth: cell.column.columnDef?.meta?.minWidth ?? "auto",
                            width: cell.column.columnDef?.meta?.width ?? "auto",
                        }}
                    >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                ))}
            </TableRow>
            {(row.getIsExpanded() && subrowComponent) && (
                <TableRow>
                    {/* 2nd row is a custom 1 cell row */}
                    <TableCell colSpan={row.getVisibleCells().length}>
                        {subrowComponent({ row })}
                    </TableCell>
                </TableRow>
            )}
        </Fragment>
    );
};

interface DatatableFooterProps
{
    table: Table<unknown>;
}

export const DatatableFooter = ({ table }: DatatableFooterProps) => {
    return (
        <TableFooter>
            {table.getFooterGroups().map(footerGroup => (
                <TableRow key={footerGroup.id}>
                    {footerGroup.headers.map(header => (
                        <TableCell key={header.id}>
                            {header.isPlaceholder
                                ? null
                                : flexRender(
                                    header.column.columnDef.footer,
                                    header.getContext(),
                                )}
                        </TableCell>
                    ))}
                </TableRow>
            ))}
        </TableFooter>
    );
};

interface DatatableProps
{
    table: Table<any>;
    isFetching: boolean;
    data: any;
    paginationState: DatatablePaginationState;
    filtrosState?: DatatableFiltersState;
    filterComponent?: ReactNode;
    defaultFilterOpen?: boolean;
    subrowComponent?: ((row: any) => ReactNode) | null;
    tableSize?: "small" | "medium";
    onRowClick?: ((row: any) => void);
}

export const Datatable = (props: DatatableProps) => {
    const {
        table,
        isFetching,
        data,
        paginationState,
        filtrosState,
        filterComponent,
        defaultFilterOpen = true,
        subrowComponent = null,
        onRowClick = undefined,
        tableSize = "medium",
    } = props;

    return (
        <>
            {(filtrosState && filterComponent) && (
                <DatatableToolbar
                    table={table}
                    filtrosState={filtrosState}
                    filterComponent={filterComponent}
                    defaultFilterOpen={defaultFilterOpen}
                />
            )}
            <DatatablePagination paginationState={paginationState} totalRows={parseInt(data?.total ?? 0)} />
            <TableContainer sx={{ position: "relative", maxHeight: false ? 740 : "none" }}>
                <LoadingOverlay cargando={isFetching} />
                <MuiTable sx={{ minWidth: 750 }} size={tableSize}>
                    <DatatableHeader table={table} />
                    <DatatableBody table={table} subrowComponent={subrowComponent} onRowClick={onRowClick} />
                    <DatatableFooter table={table} />
                </MuiTable>
            </TableContainer>
            <DatatablePagination paginationState={paginationState} totalRows={parseInt(data?.total ?? 0)} />
        </>
    );
};

interface DatatablePaginationProps
{
    paginationState: DatatablePaginationState;
    totalRows: number;
}

export const DatatablePagination = ({ paginationState, totalRows = 0 } : DatatablePaginationProps) => {
    return (
        <TablePagination
            labelRowsPerPage="Filas por página"
            rowsPerPageOptions={[10, 20, 30, 50]}
            component="div"
            count={totalRows}
            rowsPerPage={paginationState.datosPaginacion.pageSize}
            page={paginationState.datosPaginacion.pageIndex}
            onPageChange={paginationState.onChangePage}
            onRowsPerPageChange={paginationState.onChangeRowsPerPage}
            showFirstButton={true}
            showLastButton={true}
            sx={{width: "100%"}}
        />
    );
};

interface DatatableToolbarProps
{
    filtrosState: DatatableFiltersState;
    filterComponent: ReactNode;
    defaultFilterOpen?: boolean;
    table: Table<unknown>;
}

export const DatatableToolbar = ({ table, filtrosState, filterComponent = null, defaultFilterOpen = true }: DatatableToolbarProps) => {
    const [filterOpen, setFilterOpen] = useState(defaultFilterOpen);

    const { openMenu, open, closeMenu, anchorEl } = useMenuContextual();

    return (
        <FormRHF defaultValues={filtrosState.filtros} onSubmit={filtrosState.setFiltrosDatatable} autoReset={false}>
            {({ reset }: { reset: UseFormReset<FieldValues> }) => (
                <>
                    <Box display="flex" justifyContent="flex-end" gap="10px">
                        <IconButton size="small" onClick={openMenu}>
                            <ViewColumn />
                        </IconButton>
                        <TooltipG title="Mostrar/ocultar filtros">
                            <IconButton size="small" onClick={() => setFilterOpen(state => !state)}>
                                <FilterAltIcon />
                            </IconButton>
                        </TooltipG>
                        <IconButton size="small" type="submit">
                            <SearchIcon />
                        </IconButton>
                        <IconButton size="small" onClick={() => filtrosState.resetFiltros(reset)}>
                            <ClearIcon />
                        </IconButton>
                    </Box>
                    <Box display={filterOpen ? "block" : "none"}>
                        {filterComponent}
                    </Box>
                    <Menu anchorEl={anchorEl} open={open} onClose={closeMenu}>
                        <MenuVisualizacionColumnas table={table} />
                    </Menu>
                </>
            )}
        </FormRHF>
    );
};

interface DatatableExpandRowButtonProps
{
    row: Row<unknown>;
}

export const DatatableExpandRowButton = ({ row }: DatatableExpandRowButtonProps) => {
    return (
        <IconButton
            size="small"
            onClick={row.getToggleExpandedHandler()}
        >
            {row.getIsExpanded() ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
        </IconButton>
    );
};

interface MenuVisualizacionColumnasProps
{
    table: Table<unknown>;
}

const MenuVisualizacionColumnas = ({ table }: MenuVisualizacionColumnasProps) => {
    return (
        <Paper sx={{ minWidth: 220 }}>
            <MenuList dense>
                {table.getAllLeafColumns().map(column => {
                    if (!column.getCanHide())
                    {
                        return null;
                    }

                    return (
                        <MenuItem key={column.id}>
                            <FormControlLabel
                                sx={{ gap: "10px" }}
                                control={
                                    <Switch
                                        size="small"
                                        checked={column.getIsVisible()}
                                        onChange={column.getToggleVisibilityHandler()}
                                    />
                                }
                                label={<>{column?.columnDef?.header}</>}
                            />
                        </MenuItem>
                    );
                })}
            </MenuList>
        </Paper>
    );
};

export default MenuVisualizacionColumnas;
