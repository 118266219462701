import { makeStyles } from '@mui/styles';
import moment from 'moment';


export const useStyles = makeStyles(theme => {
    

    return {
        item: {
            width: "262px",
            //padding: "15px",
            boxShadow: "2px 2px 8px #00000024",
            display: "flex",
            margin: "9px",
            marginLeft: "17px",
            flexDirection: "column",
            fontSize: "12px",
            borderLeft: ({datos, codigo}) => {

                var color = datos.tipo === "recogida" ? theme.palette.trafico.recogidas.main : (datos.tipo === "recogidaFueraPlaza") ? theme.palette.trafico.recogidasFueraPlaza.main  : theme.palette.trafico.envios.main;
                
                var desde = moment(datos.horaHasta, "HH:mm");

                var actual = moment();

                if (actual.isAfter(desde))
                {

                    color = "#f44336";
                }


                if ((datos.tipo.indexOf("recogida")>=0 && (codigo === "terminado" || codigo === "recogido")) || (datos.tipo === "envio" && codigo === "entregado"))
                {
                    color = theme.palette.success.main;
                }

                if (datos.anulado === "1" )
                {
                    color = theme.palette.red.main;
                } else if (!(datos.valorado > 0) && datos.tipo == "envio"){
                    color = theme.palette.orange.main;
                }

                return `5px solid ${color}`;
            },
            backgroundColor: ({datos, codigo}) => {
                
                var color = "white";

                if(datos.tipo=="recogida" && (datos.observacionesRecogida!=null || datos.notasMensajero!=null)){
                    color = "#efefef";
                }

                if ((datos.tipo.indexOf("recogida")>=0 && (codigo === "terminado" || codigo === "recogido")) || (datos.tipo === "envio" && codigo === "entregado"))
                {
                    color = theme.palette.success.light;
                }

                if (datos.anulado === "1" )
                {
                    color = theme.palette.red[100];
                } else if (!(datos.valorado > 0) && datos.tipo == "envio") {
                    color = theme.palette.orange[100];
                }

                return color;
            },
            "&:hover": {
                backgroundColor: ({ datos, codigo }) => {

                    var color = "#f7f7f7";

                    if (datos.tipo == "recogida" && (datos.observacionesRecogida != null || datos.notasMensajero != null)) {                        
                        color = "#e4e4e4";
                    }

                    if ((datos.tipo === "recogida" && (codigo === "terminado" || codigo === "recogido")) || (datos.tipo === "envio" && codigo === "entregado")) {
                        color = theme.palette.success.darkLight;
                    }

                    if (datos.anulado === "1") {
                        color = theme.palette.red[200];
                    } else if (!(datos.valorado > 0) && datos.tipo == "envio") {
                        color = theme.palette.orange[200];
                    }

                    return color;
                },                
            }
        },
        caja: {
            
        },
        cabeceraCaja: {
            textAlign: "center",
            background: theme.palette.trafico.envios.extraLight,
            fontWeight: "500",
            fontSize: "10px"
        },
        bodyCaja: {
            padding: "11px",
            paddingTop: "4px",
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
        },
        filaTitulo: {
            display: "flex",
            alignItems: "center",
            marginBottom: "5px"
        },
        tipo: {
            padding: "5px 10px 5px 10px",
            backgroundColor: ({ datos }) => datos.tipo == "recogida" ? theme.palette.trafico.recogidas.main : (datos.tipo === "recogidaFueraPlaza") ? theme.palette.trafico.recogidasFueraPlaza.main : theme.palette.trafico.envios.main,
            color: "white",
            borderRadius: "100%",
            fontWeight: "bold"
        },
        tipo2: {
            padding: "5px 8px 5px 8px",
            backgroundColor: ({ datos }) => datos.tipo == "recogida" ? theme.palette.trafico.recogidas.main : (datos.tipo === "recogidaFueraPlaza") ? theme.palette.trafico.recogidasFueraPlaza.main : theme.palette.trafico.envios.main,
            color: "white",
            borderRadius: "100%",
            fontWeight: "bold"
        },
        textoArriba: {
            paddingLeft: "6px",
            flex: "1",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            paddingRight: "7px",
            fontSize: "10px",
            color: "#848484"
        },
        filaDatosTipo: {
            display: "flex",
            flexDirection: "column",
            flex: 1
        },
        datoPrincipal: {
            fontSize: "14px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            color: ({ datos }) => datos.tipo == "recogida" ? theme.palette.trafico.recogidas.dark : (datos.tipo === "recogidaFueraPlaza") ? theme.palette.trafico.recogidasFueraPlaza.dark:theme.palette.trafico.envios.dark,
            fontWeight: "600"
        },
        datoSecundario: {
            whiteSpace: "nowrap",
            overflow: "hidden",
            fontSize: "10px",
            textOverflow: "ellipsis",
            color: "#848484"
        },
        filaHoras: {
            display: "flex"
        },
        horaInicio: {
            padding: "5px",
            backgroundColor: ({datos,codigo}) => {
                

                var color = theme.palette.trafico.recogidas.light;

                if (datos.tipo === "envio")
                {
                    color = theme.palette.trafico.envios.light;
                }

                var desde = moment(datos.horaDesde, "HH:mm");

                var actual = moment();

                if (actual.isAfter(desde))
                {
                    color = "#f08a24";
                }

                if ((datos.tipo === "recogida" && (codigo === "terminado" || codigo === "recogido")) || (datos.tipo === "envio" && codigo === "entregado"))
                {
                    color = theme.palette.success.main;
                }

                if (datos.anulado === "1")
                {
                    color = theme.palette.red.main;
                }

                return color;
            },
            color: "white",
            margin: "2px"
        },
        horaFin: {
            padding: "5px",
            backgroundColor: ({datos, codigo}) => {
                
                var color = theme.palette.trafico.recogidas.light;

                if (datos.tipo === "envio")
                {
                    color = theme.palette.trafico.envios.light;
                }

                var desde = moment(datos.horaHasta, "HH:mm");

                var actual = moment();

                if (actual.isAfter(desde))
                {

                    color = "#f44336";
                }

                if ((datos.tipo === "recogida" && (codigo === "terminado" || codigo === "recogido")) || (datos.tipo === "envio" && codigo === "entregado"))
                {
                    color = theme.palette.success.main;
                }

                if (datos.anulado === "1")
                {
                    color = theme.palette.red.main;
                }

                return color;
            },
            color: "white",
            margin: "2px"
        },
        filaFooter: {
            display: "flex",
            alignItems: "center"
        },
        footer1: {
            flex: "1",
            '& a': {
                color: 'inherit'
            }
        },
        footer1Cliente: {
            flex: "1",
            paddingTop:"8px",
            '& a': {
                color: 'inherit'
            }
        },
        footer2: {
            alignSelf: "flex-end",
            flex: "1",
            fontSize: "10px",
            textAlign: "right",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            paddingRight: "5px",
            paddingLeft: "5px"
        },
        footer3: {
            alignSelf: "flex-end"
        },
        enlace: {
            color: "inherit",
            '&:hover': {
                color: "inherit"
            }
            
        },
        iconoEnvios: {
            color: ({ datos }) => datos.numEnvios && datos.numEnvios > 0 ? theme.palette.success.main: "#bdbdbd"
        },
        iconoBultos: {
            color: ({ datos }) => datos.numBultos && datos.numBultos > 0 ? theme.palette.success.main : "#bdbdbd"
        },
        iconoTerminar: {
            cursor: "pointer"
        },
        selectorMensajero: {
            marginTop: "0px"
        },
        iconoWarning: {
            color: '#ff8383'
        },
        capaIncidencia: {
            paddingLeft: "11px"
        },
        etiqueta: {
            borderRadius: "5px 5px 5px 5px",
            color: "black",
            backgroundColor: "#f2f2f2",
            float: "left",
            marginRight: "5px",
            padding: "2px 5px 2px 5px",
            fontSize: "12px",
            dsiplay: "inline-block"

        },
        contenedorBotonEtiquetas: {
            flex: '1',
            textAlign: 'right'
        },
        capaAcciones: {
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '10px',
            paddingBottom: '10px'
        },
        '@media (max-width: 768px)': {
            item:{
                width: "95%",
            },
            datoSecundario: {
                whiteSpace: "normal",
                overflow: "hidden",
                fontSize: "10px",
                textOverflow: "ellipsis",
                color: "#848484"
            },

        }

  }
});