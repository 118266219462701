import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import DoneIcon from "@mui/icons-material/Done";
import EditIcon from "@mui/icons-material/Edit";
import EmailIcon from "@mui/icons-material/Email";
import InfoIcon from "@mui/icons-material/Info";
import LabelIcon from "@mui/icons-material/Label";
import SearchIcon from "@mui/icons-material/Search";
import { Badge, Box, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import Mostrar from "app/componentes/datadisplay/Mostrar";
import BotonCircular from "app/componentes/generales/BotonCircular";
import TienePermisos from "app/helpers/TienePermisos";
import { TIPOS_CATEGORIAS } from "app/helpers/constants";
import { envios, recogidas, redes } from "app/helpers/permisos";
import { useMenuContextual, useRoles } from "app/hooks/hooksPropios";
import EtiquetaModal from "app/modules/etiquetas/components/EtiquetaModal/EtiquetaModal";
import { TooltipG } from "app/pages/utils";
import { MouseEventHandler } from "react";
import { Link } from "react-router-dom";

interface AccionesRecogidaFilaTraficoProps {
	datos: any;
	codigoEstado: string;
	handleComprobacion: MouseEventHandler<HTMLButtonElement> | undefined;
	mostrarFinalizarRecogida: boolean;
	handleSetTerminado: MouseEventHandler<HTMLButtonElement> | undefined;
}

export default function AccionesRecogidaFilaTrafico(props: AccionesRecogidaFilaTraficoProps) {
	const { datos, codigoEstado, handleComprobacion, mostrarFinalizarRecogida, handleSetTerminado } = props;

	const { esMensajero, esRolAdministrativo, esCliente } = useRoles();

	const { open, openMenu, closeMenu, anchorEl, datosMenu } = useMenuContextual();

	let enlace = "";

	if (esMensajero) {
		enlace = `/mensajero/recogidas/${datos.id}`;
	} else if (esRolAdministrativo || esCliente) {
		enlace = `/recogidas/${datos.id}`;
	}

	let numEnvios = !isNaN(datos.numEnvios) ? parseInt(datos.numEnvios) : 0;

	return (
		<>
			{esMensajero ? (
				<Box mr={1}>
					{codigoEstado === "aviso" || codigoEstado === "asignado" ? (
						<TooltipG title="Marcar como comprobado">
							<BotonCircular color="primary" onClick={handleComprobacion} variant="contained" type="button">
								<CheckIcon />
							</BotonCircular>
						</TooltipG>
					) : (
						<Link to={enlace}>
							<BotonCircular color="secondary" variant="contained" type="button">
								<SearchIcon />
							</BotonCircular>
						</Link>
					)}
				</Box>
			) : null}
			<Mostrar cuando={esRolAdministrativo} desmontar={true}>
				<EtiquetaModal tipo={TIPOS_CATEGORIAS.RECOGIDAS} id={datos.id} titulo="Categorizar recogida">
					{({ abrirModalEtiquetas }: { abrirModalEtiquetas: () => void }) => (
						<Box mr={1}>
							<TooltipG title="Categorizar">
								<BotonCircular onClick={abrirModalEtiquetas} color="primary" variant="contained" type="button">
									<LabelIcon />
								</BotonCircular>
							</TooltipG>
						</Box>
					)}
				</EtiquetaModal>
			</Mostrar>

			{esRolAdministrativo && mostrarFinalizarRecogida && datos.tipo === "recogida" ? (
				<Box mr={1}>
					<TooltipG title="Marcar la recogida como terminada">
						<BotonCircular onClick={handleSetTerminado} color="primary" variant="contained" type="button">
							<DoneIcon />
						</BotonCircular>
					</TooltipG>
				</Box>
			) : null}
			{(esCliente || esRolAdministrativo || esMensajero) && (
				<>
					{!esMensajero || TienePermisos([], [recogidas.crear, recogidas.editar]) ? (
						<Box mr={1}>
							<TooltipG title="Editar">
								<Link to={`/recogidas/${datos.id}`}>
									<BotonCircular color="primary" variant="contained" type="button">
										<EditIcon />
									</BotonCircular>
								</Link>
							</TooltipG>
						</Box>
					) : null}

					{TienePermisos([envios.ver]) && (esCliente || esRolAdministrativo) && (
						<Box mr={1}>
							<TooltipG title="Envios">
								<BotonCircular color="secondary" onClick={(e) => openMenu(e, datos.id)} variant="contained" type="button">
									<Badge badgeContent={numEnvios} color="error">
										<EmailIcon />
									</Badge>
								</BotonCircular>
							</TooltipG>
						</Box>
					)}
				</>
			)}

			{esMensajero && (
				<Box mr={1}>
					<TooltipG title="Envios">
						<BotonCircular color="secondary" variant="contained" type="button">
							<Badge badgeContent={numEnvios} color="error">
								<EmailIcon />
							</Badge>
						</BotonCircular>
					</TooltipG>
				</Box>
			)}

			{(datos.observacionesRecogida || datos.notasMensajero) && (
				<Box mr={1}>
					<TooltipG
						title={`${datos.observacionesRecogida != null ? datos.observacionesRecogida : ""} ${
							datos.notasMensajero != null ? datos.notasMensajero : ""
						}`}
					>
						<InfoIcon color="secondary" style={{ marginLeft: "5px", fontSize: "40px" }}></InfoIcon>
					</TooltipG>
				</Box>
			)}

			<Menu open={open} anchorEl={anchorEl} onClose={closeMenu}>
				{TienePermisos([envios.ver]) && (
					<Link to={`/recogidas/${datosMenu}/envios`} target="_blank">
						<MenuItem>
							<ListItemIcon>
								<EmailIcon />
							</ListItemIcon>
							<ListItemText
								primary={
									<Box component="span" color="text.primary">
										Ir a los envíos
									</Box>
								}
							/>
						</MenuItem>
					</Link>
				)}
				{TienePermisos([envios.crear, redes.ver]) && codigoEstado !== "recogido" && codigoEstado !== "terminado" && (
					<Link to={{ pathname: `/envios/new`, state: { idRecogida: datosMenu }, search: datosMenu ?? "" }} target="_blank">
						<MenuItem>
							<ListItemIcon>
								<AddIcon />
							</ListItemIcon>
							<ListItemText
								primary={
									<Box component="span" color="text.primary">
										Añadir envío
									</Box>
								}
							/>
						</MenuItem>
					</Link>
				)}
			</Menu>
		</>
	);
}
