import { useMutation } from "@tanstack/react-query";
import { ejecutaAPI } from "../../../helpers/libFunciones";



export const borrarMetodoPago = async (id: string) => {


    const { success, respuesta, msg } = await ejecutaAPI<unknown>('DELETE', `/configuracion/metodospago/${id}`);

    if (success)
    {
        return respuesta;
    }
    else 
    {
        throw new Error(msg.join(","));
    }

}

export const useApiBorrarMetodoPago = () => {


    return useMutation<unknown, Error, string, unknown>((id) => borrarMetodoPago(id));
}