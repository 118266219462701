/* Mensajes de confirmación/aviso por red /servicio */

export const ConfigMensajes = {    
    4: { //gls
      '1.5': {            
            title: 'Revisar envío',
            text: `Confirmar que el código postal indicado es válido para el servicio Entrega Sábado, si no lo fuese se grabará como Entrega 24`,
            icon: 'info',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Continuar'
        },      
    },       


}