import { VISTAS_MODALES } from "app/componentes/modalesComunes/ModalesApp";
import { useModalContextAction } from "app/contexts/useModalContext";
import { useCallback } from "react";

export const useModalEtiquetas = (id, tipo, titulo) => {

    const {openModal} = useModalContextAction();

    const abrirModalEtiquetas = useCallback(() => {

        const data = {
            idOrigen: id,
            tipo: tipo
        }

        openModal(VISTAS_MODALES.ADD_ETIQUETAS, data, {titulo});

    }, [id, openModal, tipo, titulo])

    return {
        abrirModalEtiquetas
    }

}