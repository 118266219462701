import { takeLatest} from "redux-saga/effects";


export const actionTypes = {
  LISTADO_CLIENTES:"[Listado Clientes]",
  CLIENTES_REDES: "[Clientes Redes]",
  CLIENTES_TRAFICO:"SET_CLIENTES_TRAFICO",
  CLIENTES_TRAFICO_INIT:"SET_CLIENTES_TRAFICO_INIT"
};

const initialState = { 
    clientes: { data: [] },
    clientesRedes: {},
    clientesTrafico:{},
    clientesTrafico_time:0
  } ;

export const reducer = 
    (state = initialState, action) => {
      switch (action.type) {
        case actionTypes.LISTADO_CLIENTES: {
          
          return {...state,clientes:action.payload};
        }
        case actionTypes.CLIENTES_REDES: {
          return {...state,clientesRedes:action.payload};
        }

        case actionTypes.CLIENTES_TRAFICO: {
          return { ...state, clientesTrafico: action.payload,clientesTrafico_time:Date.now() };
        }

        case actionTypes.CLIENTES_TRAFICO_INIT: {
          return { ...state, clientesTrafico: {}, clientesTrafico_time:1 };
        }       
       
        default:

          return state;
      }
}
   

export const actions = {
  listado: (payload) => ({ type: actionTypes.LISTADO_CLIENTES, payload })
};


//Watchers
export function* saga() {
  yield takeLatest(actionTypes.LISTADO_CLIENTES, function* listadoSaga(){});
}

