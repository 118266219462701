import { blue, grey, orange, red } from "@mui/material/colors";
import { ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material/styles';

const { palette: { augmentColor } } = createTheme({});

const theme = createTheme(
    /**
     * @see https://material-ui.com/customization/themes/#theme-configuration-variables
     */
    {
        // direction: "rtl",
        typography: {
            fontFamily: ["Poppins"].join(","),
        },

        palette: {
            contrastThreshold: 3,
            backHover: {
                main: "#ecf9fd",
            },
            primary: {
                main: "#000674",
                // dark: will be calculated from palette.primary.main,
                // contrastText: will be calculated to contrast with palette.primary.main
            },
            secondary: {
                // light: will be calculated from palette.primary.main,
                main: "#1ab59f",
                // dark: will be calculated from palette.primary.main,
                contrastText: "#ffffff",
            },
            success: {
                light: "#e0fff2",
                main: "#26d48c",
                darkLight: "#b7fee0",
                // dark: will be calculated from palette.primary.main,
            },
            warning: {
                main: "#ffd383",
                light: "#ffffe9",
            },
            stone: {
                main: grey[300],
                dark: grey[400],
                contrastText: '#000000de'
            },
            default: {

                main: grey[300],
                dark: grey[400],
                contrastText: '#000000de'
                  
            },
            grey: {
                200: grey[200],
                main: grey[500],
            },
            red: {
                100: red[100],
                200: red[200],
                main: red[500]
            },
            orange: {
                100: orange[100],
                200: orange[200],
                main: orange[500]
            },
            blue: {
                main: blue[500]
            },
            error: augmentColor({ color: { main: "#fd397a" } }),
            inputReadOnly: {
                main: "#e8e8e8",
            },
            subtitulos: {
                main: "#b1b1b1",
            },
            trafico: {
                envios: augmentColor({ color: { main: "#4a148c", extraLight: "#fbf3ff" } }),
                recogidas: {
                    main: "#1976d2",
                    light: "#63a4ff",
                    dark: "#004ba0",
                    contrastText: "#ffffff",
                },
                recogidasFueraPlaza: {
                    main: "#dabf7f",
                    light: "#fef2d5",
                    dark: "#a8915b",
                    contrastText: "#ffffff",
                },
            },
        },

        /**
         * @see https://material-ui.com/customization/globals/#default-props
         */
        components: {
            // Name of the component ⚛️
            MuiButtonBase: {
                // The properties to apply
                defaultProps: {
                    // The props to change the default for.
                    disableRipple: true, // No more ripple, on the whole application 💣!
                },
            },
            MuiTextField: {
                defaultProps: {
                    size: "small",
                    InputProps: { autoComplete: "new-password" },
                    inputProps: { autoComplete: "off" },
                    margin: "normal",
                    className: "kt-width-full",
                    variant: "standard",
                },
            },
            MuiSelect: {
                defaultProps: {
                    variant: "standard"
                }
            },
            // Set default elevation to 1 for popovers.
            MuiPopover: {
                defaultProps: {
                    elevation: 1,
                },
            },
            MuiCheckbox: {
                defaultProps: {
                    color: 'secondary'
                }
            }
        },
    },
);


export default function ThemeProvider(props)
{
    const { children } = props;

    return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
}
