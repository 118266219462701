import { Container, Grid, Icon } from "@mui/material";
import { Button, Typography } from '@mui/material/';
import { makeStyles } from "@mui/styles";
import
  {
    Portlet,
    PortletBody,
    PortletHeader,
  } from "../../partials/content/Portlet";

const useStyles = makeStyles((theme)=>(
  {
  bloque:{    
    display:"block",
    marginBottom:"30px"
  }  
}
));



export default function Soporte() {

const classes = useStyles();
 
 
  return (
   
    <Portlet> 
      <PortletHeader
        title={<>
          Atención al cliente </>
        }
        toolbar={null}
      >
      </PortletHeader> 
      <PortletBody>
        <div className="kt-section kt-margin-t-30">
          <div className="kt-section__body">
              <Container maxWidth="xl">
                <Grid container spacing={2}>              
                   <Grid item lg={12}>
                  <Typography><h1>Mensaglobal - Soporte Remoto</h1></Typography>  
                    <p>Guía de instalación del programa de asistencia remota para clientes de Mensaglobal.</p>               
                   </Grid>

                <Grid item lg={12} className={classes.bloque}>                  
                  <h3>1) Descarga la aplicación de soporte remoto</h3>
                  <p>Descarga la aplicación haciendo clic sobre el siguiente botón (tambien se puede descargar haciendo clic derecho sobre el botón y seleccionado "guardar enlace como...")</p>

                  <Grid container spacing={2} >
                      <Grid item xs={12}>                        
                        <a href="/media/soporte/soporte.exe" download="Soporte.exe">
                          <Button style={{fontSize:"16px"}} type="button"  variant="contained" color="primary">
                          <Icon className="fa fa-download"></Icon> &nbsp;&nbsp; Descargar (Windows)
                          </Button>
                        </a>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <a href="/media/soporte/soporte.dmg" download="Soporte.dmg">
                        <Button style={{ fontSize: "16px" }} type="button" variant="contained" color="primary">
                          <Icon className="fa fa-download"></Icon>  &nbsp;&nbsp; Descargar (MacOS)
                        </Button>
                       </a>
                      </Grid>
                     
                  </Grid>
                </Grid>


                <Grid item lg={12} className={classes.bloque}>
                  <h3>2) Ejecutar aplicación</h3>
                  <p>Una vez descargada haz click en el archivo soporte.exe en el icono que aparecerá en la parte inferior izquierda de tu navegador, o ejecutalo desde la carpeta donde lo hayas descargado</p>

                  <Grid container spacing={2} justifyContent="flex-start">
                    <Grid item xs={12}>
                      <img alt="" style={{marginTop:"-10px",marginBottom:"15px"}} src="/media/soporte/descarga.png"></img>
                      <p>Al ser un ejecutable, es probable que su sistema operativo pregunte si deseas ejecutar el archivo. Si es tu caso, permítelo presionando en el botón "Ejecutar"</p>                      
                      <img alt="" style={{ marginTop: "10px"}} src="/media/soporte/descarga.jpg"></img>
                    </Grid>
                   
                  </Grid>
                </Grid>

                <Grid item lg={12} className={classes.bloque}>
                  <h3>3) Obtener el ID de conexión</h3>
                  <p>Una vez que se haya cargado la aplicación se mostrará en pantalla una ventana con el logotipo de Mensaglobal y una zona indicando el ID de conexión que tendrás que facilitar a la persona de soporte para que pueda conectarse de forma remota a tu equipo. El ID aparecerá donde indica la flecha en la imagen.</p>

                  <Grid container spacing={2} justifyContent="flex-start">
                    <Grid item xs={12}>
                      <img alt="" src="/media/soporte/anydesk.png"></img>
                      
                    </Grid>

                  </Grid>
                </Grid>

                <Grid item lg={12} className={classes.bloque}>
                  <h3>4) Aceptar conexión</h3>
                  <p>Por último, cuando la persona encargada en soporte realice la conexión, la aplicación preguntará si deseas permitir el acceso a tu equipo. Acepta haciendo clic en el botón verde "Aceptar" y en unos segundos se iniciará la asistencia técnica en remoto. Esa misma ventana sirve para mantener un chat con soporte o para terminar la conexión remota.</p>

                  <Grid container spacing={2} justifyContent="flex-start">
                    <Grid item xs={12}>
                      <img alt="" src="/media/soporte/anydesk2.png"></img>

                    </Grid>

                  </Grid>
                </Grid>

                </Grid>
            </Container>
          </div> 
        </div>
      </PortletBody>  

    </Portlet> 

    
  );
}

