

const TienePermisos = (req, or = []) => {

    let isAllow = false;

    if (localStorage.getItem("login")) {

        let datos = JSON.parse(localStorage.getItem("login"));

        let user = datos.data;
        let idRol = datos.idRol;

        if (user) {

            let { roles } = user;

            let permisos = roles.filter(item => item.id == idRol)[0].permisos;


            if (permisos.length > 0) {
                permisos = Object.values(permisos);

                for (var i = 0; i < permisos.length; i++) {
                    permisos[i] = parseInt(permisos[i]);
                }
            }

            //Pasamos el array de permisos a cadena           

            if (permisos.length > 0 && req.length > 0) {

                isAllow = req.every(d => permisos.indexOf(d) >= 0);
            }

            //si algun permiso de or lo tiene
            if (or.length > 0) {

                if (req.length > 0 && isAllow === false) {

                } else {
                    isAllow = or.some(d => permisos.indexOf(d) >= 0);
                }

            }

        }



        return isAllow;

    } else {
        return false;
    }

}




export default TienePermisos;