import axios from "axios";
import {BASE_URL} from "./Api";

export const LOGIN_URL = BASE_URL +"/Auth/login"

export const REGISTER_URL = BASE_URL +"/Auth/register";
export const REQUEST_PASSWORD_URL = BASE_URL+"/Auth/forgot-password";

export const ME_URL = BASE_URL+"/me";



export function login(user, password,rol) {
  return axios.post(LOGIN_URL, "user="+user+"&password="+password+"&rol="+rol);

}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}






