import { TextField } from '@mui/material';
import { useField } from 'formik';
import React, { useEffect } from 'react';
import { getValuesFromChildrenOptions } from '../../../../helpers/libFunciones';
import { TooltipG } from '../../../../pages/utils';
import { useStyles } from '../../css/css';



export const FormSelectFormik = ({hooks, name, children, onChange, tooltip, multiple, displayEmpty, placeholder, clase, permitirValoresNoExistentes = true, ...props}) => {

    const [campo, infoCampo, helpers] = useField(name);

    const {setValue} = helpers;

    const classes = useStyles();

    var propsDefault = {

        name:name,
        select: true,
        value: campo.value || '',
        variant:"outlined",
        size:"small",
        InputProps:{autoComplete:'new-password', className: classes[clase] || null},
        margin:"normal",
        className:"kt-width-full",
        onChange:(e) => {

            campo.onChange(e);

            if (typeof onChange === "function")
            {
                onChange(e, campo.value);
            }
        },
        helperText:infoCampo.touched && infoCampo.error,
        error: Boolean(infoCampo.touched && infoCampo.error)
    }

    if (multiple)
    {
        propsDefault.SelectProps={
            multiple: true, 
            displayEmpty: true, 
           /* renderValue: (selected) => {
                return selected.length === 0 ? (placeholder ? placeholder : "Seleccionar") : selected.join(', ')
            } */
        };

        propsDefault.InputLabelProps={shrink:true};

        propsDefault.value = campo.value || [];
    }

    

    useEffect(() => {

        //Determina si se permite setear el estado de un select con un valor que no está en las opciones
        if (!permitirValoresNoExistentes)
        {

            var valoresPosibles = getValuesFromChildrenOptions(children);


            if (valoresPosibles.length > 0 && campo.value && !valoresPosibles.includes(campo.value.toString()))
            {
                setValue("");

            }

        }

        

    },[children, campo.value, permitirValoresNoExistentes, setValue])

    return ( 
        
            tooltip !== undefined 
            ? 
            (
                <TooltipG title={tooltip}>
                    <TextField {...propsDefault} {...props}>
                        {children}
                    </TextField>
                </TooltipG>
            )
            :
            (
                <TextField {...propsDefault} {...props}>
                    {children}
                </TextField>
            )
        

     )
};
 