
export default function PermisosMenu(permisos,idRol,menuConfig) {

    
   let items_filter = menuConfig.header.items;
   let items_aside=menuConfig.aside.items;
  
  
  if(permisos.length>0){

    permisos=Object.values(permisos);
    for(var i=0;i<permisos.length;i++){
      permisos[i]=parseInt(permisos[i]);
    }
  
   }

   
    items_filter=recursiva_submenu(items_filter,permisos,idRol);   
    menuConfig.header.items = items_filter;

    items_aside = recursiva_submenu(items_aside, permisos,idRol);
    menuConfig.aside.items = items_aside;


    return (menuConfig);
}

  function recursiva_submenu(items,permisosUsuario,idRol){

    var new_items=[];
    var item_aux=[];
    var aux;

    

    for(var i=0;i<items.length;i++){
     
       var item=items[i];
       
    
      if ((((item.permisos && item.permisos.length > 0) && permisosUsuario.indexOf(item.permisos[0]) >= 0) || item.permisos == undefined || item.permisos.length == 0) && (item.rol.length == 0 || (item.rol.indexOf(idRol) !== -1 ))){

             item_aux=item;  
                 
                        
             if(item.submenu){
                 aux=recursiva_submenu(item.submenu,permisosUsuario,idRol);

                 if(aux.length>0)
                     item_aux.submenu=aux;
             }

             new_items.push(item_aux);
        }  
      } 
    
      return new_items;

  } 

