import { Snackbar } from '@mui/material';
import SnackbarAlert from 'app/componentes/avisos/SnackbarAlert';
import { format } from 'date-fns';
import { toLower, toPairs, trim } from 'lodash';
import { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { useDelta } from 'react-delta';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import Api from '../crud/Api';
import { redes } from '../helpers/constants';
import { ejecutaAPI, prepararDatosSchema } from '../helpers/libFunciones';
import { roles } from '../helpers/roles';
import { actions as actionsLoader } from '../store/ducks/loader.duck';




export const useTab = ( tabs, tabInicio, rutaBase, idElemento ) => { 

    
    const history = useHistory();

    let controlarRutas = false;

    if (rutaBase !== undefined)
    {
        controlarRutas = true;
    }

    //const tabs = tabsEnviadas.filter(tab => tab.mostrar === true);


    const handleChangeTab = (event, newValue) => {

        setTab(newValue);

        if (controlarRutas)
        {

            var urlTab = tabs[newValue].url;

            var urlIr = `/${rutaBase}/${idElemento}`;

            if (urlTab !== undefined && urlTab !== "")
            {
                urlIr += `/${urlTab}`;
            }

            history.push(urlIr);
        }

        

    }

    var tabDefecto = 0;

    if (controlarRutas)
    {

        var indice = tabs.findIndex((elemento) => {

            return elemento.url === tabInicio;
            
        });

        if (indice >= 0)
        {
            tabDefecto = indice;
        }

    }

    
    const [tab, setTab] = useState(tabDefecto);


    return {
        tab,
        handleChangeTab,      
      }

  }

  export const useDatatable = (url, filtros, paginacionServidor = true) => {

    const [filasDatatable, setFilasDatatable] = useState([]);
    const [filasDatatableFiltradas, setFilasDatatableFiltradas] = useState([]);
    const [cargando, setCargando] = useState(false);
    const [pagina, setPagina] = useState(1);
    const [porPagina, setPorPagina] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [cargaForzada, setCargaForzada] = useState(true); //Puesto esto hasta que sepa como controlar las dependencias del useCallback.
    const paginaRef = useDelta(pagina);
    const porPaginaRef = useDelta(porPagina);

    let valoresInicialesFiltro = {};
 

    filtros.forEach((campo) => {

        var valor = "";
        if(campo?.opcionesExtra?.value){
            valor=campo.opcionesExtra.value;
        } else if (campo?.opcionesExtra?.defaultValue){
            valor = campo.opcionesExtra.defaultValue;
        }
        valoresInicialesFiltro[campo.name] = valor;
    });
    
    const [datosFiltros, setDatosFiltro] = useState(valoresInicialesFiltro);

    
    const handleChangeFiltro = (event, name, type, callback = null) => {
        
        var nuevosDatosFiltros={};
        var value="";

        if(event.target){     
            nuevosDatosFiltros = {...datosFiltros, [event.target.name]: event.target.value}
        }else{

            switch(type){
                case "fecha":
                    value = format(event, 'dd/MM/yyyy', new Date()).toString();                                        
                    break;
                default:
                    value=event;
                    break;
            }

            nuevosDatosFiltros = { ...datosFiltros, [name]: value }
        }




        setDatosFiltro(nuevosDatosFiltros);

        if (!paginacionServidor)
        {

            var filasFiltradas = filasDatatable.filter((fila) => {

                var devolver = true;

                Object.keys(nuevosDatosFiltros).forEach((elemento) => {
                    
                    if (nuevosDatosFiltros[elemento] !== undefined && fila[elemento] !== undefined)
                    {

                        if (nuevosDatosFiltros[elemento] !== "" && nuevosDatosFiltros[elemento] !== null)
                        {
                            
                            if (!fila[elemento].toLowerCase().includes(nuevosDatosFiltros[elemento].toLowerCase()))
                            {
                                devolver = false;
                            }


                        }
                        
                    }
                   
                });

                return devolver;

            });

            setFilasDatatableFiltradas(filasFiltradas);

        }

        if (typeof callback === 'function')
        {
            callback(event, setDatosFiltro);
        
        }

    }

    const handleSearchFiltro = () => {

        setCargaForzada(true);
    }

    const handleClearFiltro = () => {

        setDatosFiltro(valoresInicialesFiltro);

        setCargaForzada(true);

    }



    const realizarPeticion = useCallback(async () => {


        if (paginacionServidor || cargaForzada)
        {

            let filterParams = "";

            let filters_aux = toPairs(datosFiltros);

            if (filters_aux.length > 0) {

                filters_aux.forEach((value) => { 

                    if(value[1] !== "")
                    {
                        filterParams += `${value[0]}=${value[1]}&`;
                    }
                });

            }

            if (porPaginaRef !== null || paginaRef !== null || cargaForzada)
            {

                setCargando(true);
                setCargaForzada(false);

                //Buscamos si en la URL ya se ha metido algún queryString.

                var separador = '?';

                if (url.indexOf('?') !== -1)
                {
                    separador = '&';
                }

                let urlApi = trim(`${url}${separador}page=${pagina}&per_page=${porPagina}&${filterParams}`, '&');

                await Api("get", urlApi).then((respuesta) => {

                    const {data: {data, success}} = respuesta;

                    if (success === true)
                    {

                        var filas = data;

                        var filasTotales = 0;

                        if (!Array.isArray(data))
                        {
                            filas = data.data;

                            filasTotales = data.total;
                        }
                        else 
                        {
                            filasTotales = filas.length;
                        }

                        setFilasDatatable(filas);
                        setFilasDatatableFiltradas(filas);
                        setTotalRows(parseInt(filasTotales));

                    }
                    else
                    {
                        setFilasDatatable([]);
                        setFilasDatatableFiltradas([]);
                        setTotalRows(0);

                    }


                }).catch(respuesta => {


                    setFilasDatatable([]);
                    setFilasDatatableFiltradas([]);
                    setTotalRows(0);
                });

                
                
                
                setCargando(false);
            }

        }



    }, [datosFiltros, porPagina, pagina, porPaginaRef, paginaRef, cargaForzada, paginacionServidor, url])

    const handlePerRowsChange = (porPagina) => {

        setPorPagina(porPagina);

    }

    const handlePageChange = (pagina) => {

        setPagina(pagina);

    }

    const handleSort = (column, sortDirection) => {
        //Por el momento no se usa.
    }

    const recargarDatatable = () => {

        setCargaForzada(true);

    }
    
    useEffect(() => {
        


        realizarPeticion();

        return () => {

        }
    }, [realizarPeticion]);

    return {
        filasDatatableFiltradas,
        setFilasDatatable,
        setFilasDatatableFiltradas,
        cargando,
        handlePerRowsChange,
        handlePageChange,
        handleChangeFiltro,
        handleSearchFiltro,
        handleClearFiltro,
        handleSort,
        recargarDatatable,
        datosFiltros,
        totalRows
    }

  }


  export const useModal = () => {

    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);

    const history = useHistory();

    const handleOpenModal = useCallback(() => {
        setOpen(true);
        setOpen2(true);        

    }, [])

    const handleCloseModal = useCallback(() => {
        setOpen(false);
        setOpen2(false);
    }, [])

    const handleRedireccion = useCallback(() => {
        history.push('/');
    }, [history])

    return {
        open,
        open2,
        handleOpenModal,
        handleCloseModal,
        handleRedireccion
    }

  }

export const useModalArr = () => {

    const [modals, setModals] = useState({});
    
    const history = useHistory();

    const handleOpenModals = useCallback((index) => {        

        setModals({...modals,[index]:true});
        
    }, [modals]);


    const handleCloseModals = useCallback((index) => {
        setModals({ ...modals, [index]: false });

    }, [modals]);

     const handleRedireccion = useCallback(() => {
        history.push('/');
    }, [history])

    const openModal=(index)=>{
        if(modals[index]!=undefined)
           return modals[index];
        else
           return false;
    }

    return {
        openModal,
        handleOpenModals,
        handleCloseModals,
        handleRedireccion
    }

}

  /* Mensajes para modales */
  export const useMsg=()=>{

      const [openMsg, setOpenMsg] = useState(false);
      const [result, setResult] = useState({ msg: "", tipo: "success" });


      const handleClickMsg = useCallback((message, tipo) => {
          setOpenMsg(true);
          setResult({ "msg": message, "tipo": tipo });

      },[]);

      const handleCloseMsg = useCallback((event, reason) => {
          if (reason === 'clickaway') {
              return;
          }
          setOpenMsg(false);
      },[]);

      const Msg=()=>(
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={openMsg} autoHideDuration={30000} onClose={handleCloseMsg}  >
                <SnackbarAlert onClose={handleCloseMsg}  severity={result.tipo} >
                    {result.msg}
                </SnackbarAlert>
            </Snackbar>
      );


      return {
        handleCloseMsg,
        handleClickMsg,
        Msg
      }

}

  export const useAPI = (callbackPeticion) => {

    const {cargando, setGlobalCargando} = useGlobalLoader();


    const lanzarPeticion = (verbo, url) => {


        switch (verbo.toLowerCase())
        {
            case 'delete':

                Swal.fire({
                    title: '¿Estás seguro de eliminarlo?',
                    text: '',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'Cancelar'
                }).then(async (result) => {
        
                    if (result.value) {
        
                        ejecutar(verbo, url);
        
                    }
                });

            break;
            default:
                ejecutar(verbo, url);
            break;
        }

        


    }

    const ejecutar = async (verbo, url, datos = {}) => {


        setGlobalCargando(true);
        


        await Api(verbo, url, datos).then((respuesta)=>{
            
            const {data: {success}} = respuesta;

            if(success)
            {
                Swal.fire(
                    'Exito',
                    'Se ha realizado la acción correctamente',
                    'success'
                );

                if (typeof callbackPeticion === "function")
                {
                    callbackPeticion(false, respuesta);
                }

            }else{

                Swal.fire(
                    'Error',
                    'Se ha producido un error',
                    'error'
                )

                if (typeof callbackPeticion === "function")
                {

                    callbackPeticion(true, respuesta);

                }
            }

        }).catch((respuesta)=>{

            Swal.fire(
                'Error',
                'Se ha producido un error mandando la petición',
                'error'
            )
            if (typeof callbackPeticion === "function")
            {
                callbackPeticion(true, respuesta);
            }

        });

        setGlobalCargando(false);

    }


    return {
        cargando,
        lanzarPeticion
    }



  }

  export const useGlobalLoader = () => {


    const cargando = useSelector((state) => state.loader.appCargando);

    const dispatch = useDispatch();

    const setGlobalCargando = useCallback((estado) => {

        dispatch(actionsLoader.setCargando(estado));
        
    },[dispatch])


    return {
        cargando,
        setGlobalCargando
    }

  }

  export const useControlarErrores = () => {


    const [errores, setErrores] = useState([]);

    const controlarError = (respuesta) => {
  
        if (respuesta !== undefined)
        {
            //Pueden venir de tres formas, con data definido, con response, o sin response y sin data (Petición sin respuesta pero con error).

            var msg = [];

            if (respuesta.data !== undefined)
            {
                msg = respuesta.data.msg;
            }
            else if (respuesta.response !== undefined)
            {
                msg = respuesta.response.data.msg;
            }
                

            if (msg !== undefined)
            {

                if (typeof msg !== "object")
                {
                    msg = [msg];
                }

                if (msg.length === 0)
                {
                    msg.push("Se ha producido un error");
                }

                setErrores(msg);

            }
            else
            {
                setErrores(["Se ha producido un error"]);
            }
        }
        else 
        {
            setErrores(["Se ha producido un error"]);
        }

    }


    return {
        errores,
        controlarError,
        setErrores
    }


  }

  export const useFetch = (urlBase = "") => {

    const reducer = (state, action) => {

        switch (action.type) {
            case 'FETCH_SUCCESS':
                return {...state, success: true, cargando: false, respuesta: action.payload, data: action.payload.data.data};
            case 'FETCH_ERROR':
                return {...state, success: false, cargando: false, respuesta: action.payload};
            case 'FETCH_START':
                return {...state, success:false, cargando: true, respuesta: [], data: []}
            default:
                throw new Error();
        }
    
    }

    const {errores, controlarError} = useControlarErrores([]);

    const [respuesta, dispatch] = useReducer(reducer, {
        success: false,
        cargando: false,
        respuesta: [], 
        data: [],
        errores: []
    });


    const api = {
        post: async (objeto) => {

            await ejecutaAPI('POST', objeto);
    
        },
        put: async (objeto) => {

            await ejecutaAPI('PUT', objeto);
    
        },
        get: async (objeto) => {

            await ejecutaAPI('GET', objeto);
    
        },
        delete: async (objeto) => {

            await ejecutaAPI('DELETE', objeto);
    
        }
    };


    const ejecutaAPI = async (method, objeto) => {


        var objetoBase = {
            method: method,
            url: '',
            data: {},
            options: {},
            schema: null,
            showAlertOnSuccess: false, 
            showAlertOnError: false,
            onSuccess: (respuesta) => {

            },
            onError: (respuesta) => {

            }
        }

        var opcionesFetch = {...objetoBase, ...objeto};

        if (opcionesFetch.schema !== undefined && opcionesFetch.schema !== null)
        {
            opcionesFetch.data = prepararDatosSchema(opcionesFetch.data, opcionesFetch.schema);
        }

        opcionesFetch.url = urlBase !== "" ? urlBase + opcionesFetch.url : opcionesFetch.url;

        dispatch({type: "FETCH_START"});

        await Api(opcionesFetch.method, opcionesFetch.url, opcionesFetch.data, opcionesFetch.options).then((datosLlamada) => {

            let {data: {data, success}} = datosLlamada;
            
            if (success)
            {

                dispatch({type: "FETCH_SUCCESS", payload: datosLlamada});

                if (objeto.showAlertOnSuccess)
                {

                    Swal.fire({
                        title: 'Exito',
                        text: 'Se ha realizado la acción correctamente',
                        icon: 'success',
        
                    }).then(() => {
                  
                        opcionesFetch.onSuccess(data);
                        
                    });

                }
                else 
                {
                    
                    opcionesFetch.onSuccess(data);
                    
                }

            }
            else 
            {

                dispatch({type: "FETCH_ERROR", payload: datosLlamada});

                controlarError(datosLlamada);

                opcionesFetch.onError(datosLlamada);
                
            }
    
        }).catch((datosLlamada) => {
            
            dispatch({type: "FETCH_ERROR", payload: datosLlamada});

            controlarError(datosLlamada);

            opcionesFetch.onError(datosLlamada);
            
        });
        
    }

    return {
        respuesta,
        errores,
        api
    }

  }


/* Para filtrar objeto */
  export const useFiltros=(filtrosInit={})=>{


      const [filtros, setFilters]=useState(filtrosInit);

      const setFiltros=(nombreCampo,valor)=>{

          setFilters({ ...filtros, [nombreCampo]: { ...filtros[nombreCampo], valor: valor } });
      }
      
      const filtrar=useCallback((array)=>{

        let aux=[];
        let filters_aux = toPairs(filtros);

        
         array && array.length>0 && array.forEach(item => {
             
           let cumpleFiltros = true;
             //si algun filtro no lo cumple no lo incluimos en el array
             filters_aux && filters_aux.forEach(item_filter => {
                 if (item_filter[1].valor != "") {

                     switch (item_filter[1].tipo) {

                         case "exact":              
                    

                             if (item[item_filter[0]] != item_filter[1].valor) {
                                 cumpleFiltros = false;
                             }
                             break;

                            case "like":

                             if (toLower(item[item_filter[0]]).indexOf(toLower(item_filter[1].valor)) < 0) {
                                 cumpleFiltros = false;
                             }
                             break;

                         default:

                     }


                 }

                
             });

             if (cumpleFiltros) {
                 aux.push(item);
             }

        })

       
        return aux;

      },[filtros]);


      return {          
          setFiltros,
          filtrar,
          filtros
      }


  } 

  export const useRoles = () => {


    const {idRol} = useUsuarioConectado();

    //Puede recibir un array o un string.
    const esRol = useCallback((roles) => {

        var devolver = false;


        if (typeof roles !== "object")
        {
            if (idRol === roles)
            {
                devolver = true;
            }
        }
        else 
        {
            //Si recibe un array, se comprueba si el rol con el que ha conectado el usuario es alguno de los especificados.
            devolver = roles.indexOf(idRol) !== -1 ? true : false;
        }


        return devolver;

    }, [idRol]);

    var comprobacionesRol = {
        esMensajero: esRol(roles.MENSAJERO),
        esAdmin: esRol(roles.ADMIN),
        esCliente: esRol(roles.CLIENTE),
        esInterno: esRol(roles.INTERNO),
        esMultiLogin: esRol(roles.MULTILOGIN),
        esComercial: esRol(roles.COMERCIAL),
        esRolAdministrativo: esRol([roles.INTERNO, roles.MULTILOGIN, roles.ADMIN, roles.SUBAGENCIA]),
        esSubagencia: esRol(roles.SUBAGENCIA)
    };


    return {
        ...roles,
        ...comprobacionesRol,
        esRol,
        idRol
    }


  }

  export const useUsuarioConectado = () => {

    const datosUser = useSelector((state) => state.auth);

        
    return {
        idRol: datosUser.idRol,
        datosUser: datosUser,
        ...datosUser.user,
    }

  }

  export const useMenuContextual = () => {

    const [open, setOpen] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);

    const [datosMenu, setDatosMenu] = useState(null);

    const openMenu = (e, datosMenu) => {

        e.preventDefault();

        e.stopPropagation();

        setDatosMenu(datosMenu);

        setOpen(true)

        setAnchorEl(e.currentTarget);
    }

    const closeMenu = () => {

        setOpen(false);

        setAnchorEl(null);
    }

    return {
        openMenu,
        closeMenu,
        datosMenu,
        open,
        anchorEl
    }

  }

  export const useCP = (callback) => {

    const [localidades, setLocalidades] = useState([]);

    const [provinciaEncontrada, setProvincia] = useState("");

    const {setGlobalCargando} = useGlobalLoader();

    const cargaLocalizacionesDisponibles = useCallback(async (cp,idPais) => {

        if (typeof cp === "string")
        {

            if (cp.length === 5)
            {
                
                setGlobalCargando(true);                

                var pais_param= (idPais!="" && idPais!=undefined)?`${idPais}`:"214";

                const {success, respuesta} = await ejecutaAPI('GET', `localizaciones/localidades?cp[]=${cp}&idPais=${pais_param}`);

                var localidades = [];
                var provincia="";

                if (success)
                {

               
                    if (respuesta.length > 0) {
                        provincia = respuesta[0].provincia;
                        setProvincia(provincia);
                    }

                    localidades = respuesta.filter(item=>item.id>0).map(localidad => {
                        return {
                            id: localidad.localidad,
                            value: localidad.localidad,
                            idPais: localidad.idPais
                        }
                    });


                }

                setLocalidades(localidades);

               

                setGlobalCargando(false);

                if (typeof callback === "function")
                {
                    callback(localidades,provincia);   
                }

            }
            else 
            {
                if (typeof callback === "function")
                {
                    callback([],"");   
                }
            }

        }


    }, [callback, setGlobalCargando])




    return {
        localidades,
        provinciaEncontrada,
        cargaLocalizacionesDisponibles
    }

  }

  export const useRedesConstant = (idRed) => {

    var objeto = {};

    Object.keys(redes).forEach((red) => {

        objeto['es'+red] = Number(idRed) === redes[red];

    });


    return {
        ...objeto
    }


  }

  export function useMemoCompare(next, compare) {
    // Ref for storing previous value
    const previousRef = useRef();
    const previous = previousRef.current;
    
    // Pass previous and next value to compare function
    // to determine whether to consider them equal.
    const isEqual = compare(previous, next);
  
    // If not equal update previousRef to next value.
    // We only update if not equal so that this hook continues to return
    // the same old value if compare keeps returning true.
    useEffect(() => {
      if (!isEqual) {
        previousRef.current = next;
      }
    });
    
    // Finally, if equal then return the previous value
    return isEqual ? previous : next;
  }

  export const usePaginacion = (data, itemsPorPagina,paginaDefecto=1,callback) => {

    const [pagina, setPaginaActual] = useState(paginaDefecto);

    const ultimaPagina = Math.ceil(data.length / itemsPorPagina);
  
    const inicio = (pagina - 1) * itemsPorPagina;
    
    const fin = inicio + itemsPorPagina;
    
    const datosPaginados = data.slice(inicio, fin);

    const siguiente = () => {
        setPaginaActual(paginaActual => Math.min(paginaActual + 1, ultimaPagina));
    }
  
    const anterior = () => {
        setPaginaActual(paginaActual => Math.max(paginaActual - 1, 1));
    }
  
    const irPagina = useCallback((page) => {

      const pageNumber = Math.max(1, page);

      setPaginaActual(Math.min(pageNumber, ultimaPagina));
        
        if (typeof callback === 'function') {
            callback(Math.min(pageNumber, ultimaPagina));
        }


    }, [ultimaPagina,callback])

    useEffect(() => {

        if (ultimaPagina > 0 && pagina > ultimaPagina)
        {
            irPagina(1);
        }

    }, [pagina, ultimaPagina, irPagina]);

       


  
    return {
        datosPaginados,
        pagina,
        siguiente, 
        anterior, 
        irPagina, 
        setPaginaActual, 
        ultimaPagina
    };
  }