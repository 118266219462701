import { redes } from "app/helpers/constants";
import { replaceAll } from "app/helpers/libFunciones";

export class FicheroLecturas
{

    constructor(datos) {
        this.envios = datos.envios;
        this.red = datos.red;
        this.filtros = datos.filtros;
      }
    
      descargar() {
        
          let devolver = false;

          let datosFichero = this.generarContenido();

          if (datosFichero != "")
          {
              let red = this.red;

              let filtros = this.filtros;

              const file = new Blob([datosFichero], { type: 'text/plain' });

              var blobUrl = URL.createObjectURL(file);

              const link = document.createElement('a');

              link.href = blobUrl;

              link.setAttribute('download', `envios-${red?.nombre || ""}-${filtros.fechaDesde}-${filtros.fechaHasta}.txt`);

              document.body.appendChild(link);

              link.click();

              link.remove();

              devolver = true;
          }
          else 
          {
              throw new Error('Se ha producido un error generando el contenido');
          }
          
          return devolver;
        
      }
    
      generarContenido() {
          throw new Error('Debe implementarse en la subclase');
      }

      procesarCodigoBulto(envio, bulto) {

          let codigoBulto = replaceAll(bulto.codSeguimiento, "-", "");

          let codigoAgencia = envio.codAgencia || "";

          switch (parseInt(envio.idRedMensaglobalDatos)) 
          {

              case redes.TIPSA:
                codigoBulto = codigoAgencia + codigoAgencia + codigoBulto;
                  break;

              case redes.GLS:
                codigoBulto += "43" + codigoAgencia + codigoBulto;
                  break;
              default:
              break;
          }

          return codigoBulto

      }


}