import { ejecutaAPI } from "app/helpers/libFunciones";
import { useDataArray } from "app/hooks/useDataArray";
import { useQuery } from "@tanstack/react-query";

export const getEtiquetasElemento = async (idElemento, tipo) => {


    const {success, respuesta, error} = await ejecutaAPI('GET', `/categorias/elemento/${idElemento}/tipo/${tipo}`);

    if (success)
    {
        return respuesta;
    }
    else 
    {
        throw new Error(error);
    }
}

export const useEtiquetasElementoQuery = (idElemento, tipo, options = {}) => {

    let defaultOptions = {
        staleTime: Infinity,
        ...options
    }

    const query = useQuery(['categorias_elemento', tipo, idElemento], () => getEtiquetasElemento(idElemento, tipo), defaultOptions);

    const etiquetasElemento = useDataArray(query.data);

    return {
        ...query,
        etiquetasElemento
    }
}