import { TIPOS_FICHEROS_LECTURA } from "../../constants";
import { FicheroLecturasAmazon } from "./FicheroLecturasAmazon.class";
import { FicheroLecturasGeneral } from "./FicheroLecturasGeneral.class";

export class FicheroLecturasFactory
{
    static create(datos, tipo)
    {

        switch (tipo)
        {
            case TIPOS_FICHEROS_LECTURA.GENERAL:
                return new FicheroLecturasGeneral(datos);
            case TIPOS_FICHEROS_LECTURA.AMAZON:
                return new FicheroLecturasAmazon(datos);
            default: 
                throw new Error('Tipo no definido');
        }
    }


}