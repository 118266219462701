import React from 'react';
import {useField} from 'formik';
import { Checkbox } from '@mui/material';

export const FormCheckboxFormik = ({name, ...props}) => {

    const [campo] = useField(name);


    return ( 
        <Checkbox
            name={name}
            checked={campo.value || false}
            onChange={campo.onChange}
            {...props}
        />
     );
}
 