import { TextField } from "@mui/material";
import { DesktopTimePicker } from "@mui/x-date-pickers";

function TimepickerP({ ...props })
{

    var inputProps = {
        size: "small",
        margin: "normal",
        className: "kt-width-full",
    };

    if (props.InputProps)
    {
        inputProps = { ...inputProps, ...props.InputProps };
    }

    return (
        <DesktopTimePicker
            ampm={false}
            label="With keyboard"
            slots={{
                textField: TextField,
            }}
            slotProps={{
                textField: {
                    variant: "outlined",
                    autoComplete: "new-password",
                    ...inputProps,
                },
            }}
            {...props}
        />
    );
}

export default TimepickerP;
