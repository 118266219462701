
import { useMutation } from "@tanstack/react-query";
import { ejecutaAPI } from "../../../helpers/libFunciones";

interface GenerarXMLTraficoRequest {
    envios: string[];
}

export const generarXMLEnvios = async (values: GenerarXMLTraficoRequest) => {

    const {success, respuesta, msg} = await ejecutaAPI<any>('POST', `/trafico/exportacion/xml`, values);

    if (success)
    {
        return respuesta;
    }
    else 
    {
        throw new Error(msg.join(', '));
    }

}

export const useApiGenerarXML = () => {



    return useMutation<any, Error, GenerarXMLTraficoRequest, unknown>((values) => generarXMLEnvios(values));
}