import { takeLatest,call } from "redux-saga/effects";


export const actionTypes = {
  LISTADO_TARIFAS:"[Listado Tarifas]",    
  ACTUALIZA_TARIFAS: "[Actualiza Tarifas]"  
};

const initialState = { 
    tarifas: { data: [] },     
    actualiza_tarifas:0    

  } ;

export const reducer = 
    (state = initialState, action) => {
      switch (action.type) {
        case actionTypes.LISTADO_TARIFAS: {
          
          return {...state,tarifas:action.payload};
        }
        case actionTypes.ACTUALIZA_TARIFAS: {

          return { ...state, actualiza_tarifas: state.actualiza_tarifas+1 };
        }

      
        default:

          return state;
      }
}
   

export const actions = {
  listado: (payload) => ({ type: actionTypes.LISTADO_TARIFAS, payload }) , 
  actualizaTarifas: (payload) => ({ type: actionTypes.ACTUALIZA_TARIFAS, payload }),    
};


//Watchers
export function* saga() {
  yield takeLatest(actionTypes.LISTADO_TARIFAS, function* listadoSaga(){});  
}

