import { Route, Switch } from "react-router-dom";
import Configuracion from './configuracion';
import ConfigCategorias from './configuracion/ConfigCategorias/index';
import ConfigGenerales from './configuracion/ConfigGenerales/index';
import ConfigLogs from './configuracion/ConfigLogs/index';
import ConfigMetodosPago from "./configuracion/ConfigMetodosPago/ConfigMetodosPago";

export default function ConfigPage() {
    return (
        <Switch>
            <Route path="/configuracion/categorias" render={(props) => <ConfigCategorias />} />                        
            <Route path="/configuracion/config" render={(props) => <ConfigGenerales />} />
            <Route path="/configuracion/metodospago" render={(props) => <ConfigMetodosPago />} />
            <Route path="/configuracion/logs" render={(props) => <ConfigLogs />} />
            <Route path="/configuracion" render={(props) => <Configuracion />} />                        
            
        </Switch>
    );
}
