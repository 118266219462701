import { ejecutaAPI } from "app/helpers/libFunciones";

interface GetEnvioResponse {
  id: string;
  idRecogida: string;
  idDireccion: string;
  idDireccionRecogida: string;
  idServicio: string;
  idRuta: string;
  portes: string;
  importeDebidos?: any;
  idServicioTipo: string;
  bultos: string;
  peso: string;
  kmAdicionales?: any;
  valorDeclarado?: any;
  descDeclarado?: any;
  contraReembolso: string;
  observaciones?: any;
  referencia?: any;
  idClienteTransmitible: string;
  idDepartamentoTransmitible?: any;
  pesoTransmitible: string;
  margenReduccion: string;
  debidos?: any;
  idDelegacion: string;
  distribucionPropia: string;
  idMensajero?: any;
  fecha: string;
  horaDesde?: any;
  horaHasta?: any;
  nombreFirma?: any;
  DNIFirma?: any;
  idFicheroFirma?: any;
  oficinaRed: any[];
  codExpedicion: string;
  uidExpedicion?: any;
  codExpedicionInternacional?: any;
  valoracionManual: string;
  importeCostesReales?: any;
  fechaTransmitidoRed?: any;
  anulado: string;
  importado: string;
  idFicheroEtiquetas: string;
  numTarifario?: any;
  instrDevolucion?: any;
  incoterm?: any;
  valorNeto?: any;
  codigoTipoMercancia?: any;
  ibanReembolso?: any;
  tipoPesoVol?: any;
  idFicheroEtiquetaRetorno?: any;
  codigoBarras?: any;
  fechaUltImpresion?: any;
  pagadoReembCliente: string;
  pagadoReembRed: string;
  observPagadoCliente: string;
  observPagadoRed: string | null;
  fechaReembPagadoRed?: any;
  fechaReembPagadoCliente?: any;
  papelera: any;
  idUltimoTrazabilidad: string;
  comisionComercial: string;
  comisionMensajero: string;
  comisionMensajeroRecogida: string;
  comisionManual: string;
  idComercial: string;
  urlPOD: string | null;
  fechaCreacion: string;
  fechaActualizacion: string;
  idRed: string;
  idRedMensaglobalDatos: string;
  nombreServicio: string;
  codigoDeServicioEnLaRed: any;
  nombreDeServicioEnLaRed: any;
  codigoServicioGenerico: string;
  nombreDelegacion: string;
  codAgencia: string;
  prefijo?: any;
  codEtiquetador?: any;
  nombreRed: string;
  servicioTipo: string;
  idClienteRedTransporte: string;
  uid?: any;
  nombreDepartamento: string;
  Mensajero: string;
  comercial: string;
  cp_destino: string;
  idPaisDestino: string;
  recogidaFueraPlaza: string;
  idDepartamento: string;
  RedTransmitible: boolean;
  editable: boolean;
  facturaPF: any[];
  facturado: boolean;
  categorias: any[];
  valoraciones: Valoracion[];
  variables: Variable[];
  imagenes: any[];
  log_reembolsos: LogReembolso[];
  estados: Estado[];
  referencias: Referencia[];
  PDFEtiquetas: string;
  PDFEtiquetasBase64: string;
  servicio: Servicio;
  direccion: Direccion;
  direccionRecogida: DireccionRecogida;
  bultosEstadosRed: BultosEstadosRed[];
  enlace_seguimiento_externo: string;
}

interface BultosEstadosRed {
  id: string;
  idEnvioReferencia: string;
  numBulto: string;
  idFicheroEtiqueta: string;
  codSeguimiento: string;
  anulado: string;
  peso: string;
  pesoUnitario: string;
  pesoVolumetricoUnitario?: any;
  ancho: any;
  alto: any;
  largo: any;
  peso_original?: any;
  ancho_original?: any;
  alto_original?: any;
  largo_original?: any;
  pesoVolumetrico?: any;
  factorVolumetrico: string;
  descripcion?: any;
  seguimiento: Seguimiento;
}

interface Seguimiento {
  nombre: string;
  fecha: string;
  usuario: string;
  observaciones?: any;
}

interface DireccionRecogida {
  id: string;
  idClienteDepartamento: string;
  nombre: string;
  personaContacto: string;
  telefonoContacto: string;
  emailContacto: any;
  direccion: string;
  cp: string;
  localidad: string;
  provincia: string;
  idPais: string;
  observaciones: any;
  dni?: any;
  habitual: string;
  fechaCreacion: string;
  fechaActualizacion?: any;
  nombreDepartamento: string;
  razonSocial: string;
  idCliente: string;
}

interface Direccion {
  id: string;
  idClienteDepartamento: string;
  nombre: string;
  personaContacto: any;
  telefonoContacto: any;
  emailContacto: any;
  direccion: string;
  cp: string;
  localidad: string;
  provincia: string;
  idPais: string;
  observaciones?: any;
  dni?: any;
  habitual: string;
  fechaCreacion: string;
  fechaActualizacion?: any;
  nombreDepartamento: string;
  razonSocial: string;
  idCliente: string;
}

interface Servicio {
  id: string;
  idRed: string;
  idServicioMaestro: string;
  nombre: string;
  privado: string;
  idTipo: string;
  factorVolumetrico: string;
  idModalidad: string;
  cuentaContable: string;
  cuentaContableAdicional: string;
  interno: string;
  observaciones: string;
  fechaCreacion: string;
  fechaActualizacion: string;
  nombreServicioMaestro: string;
  nombreModalidad: string;
  nombreTipo: string;
  codigoDeServicioEnLaRed: any;
  codigoServicioGenerico: string;
  idRedMensaglobalDatos: string;
}

interface Referencia {
  id: string;
  idEnvio: string;
  unidades: string;
  idServicioReferencia: string;
  peso: string;
  ancho: any;
  alto: any;
  largo: any;
  factorVolumetrico: string;
  pesoVolumetrico?: any;
  descripcion?: any;
  peso_original?: any;
  ancho_original?: any;
  alto_original?: any;
  largo_original?: any;
  idTipo: string;
  referencia: string;
  bultos: Bulto[];
}

interface Bulto {
  id: string;
  idEnvioReferencia: string;
  numBulto: string;
  idFicheroEtiqueta: string;
  codSeguimiento: string;
  anulado: string;
  etiqueta: Etiqueta;
}

interface Etiqueta {
  id: string;
  ruta: string;
  nombre: string;
  idUsuario: string;
  temporal: string;
  fechaCreacion: string;
  fechaActualizacion?: any;
  url: string;
}

interface Estado {
  id: string;
  tablaOrigen: string;
  idOrigen: string;
  idEstado: string;
  idUsuario: string;
  observaciones?: any;
  fecha: string;
  codigo: string;
  nombre: string;
  icono: string;
  imagenes: any[];
}

export interface LogReembolso {
  id: string;
  tabla: string;
  idRegistro: string;
  accion: string;
  target: string;
  idUsuarioCreacion: string;
  descripcion: string;
  data: any;
  fechaCreacion: string;
  nombre: string;
  user: string;
}

interface Variable {
  id: string;
  idEnvio: string;
  idVariable: string;
  valor: any;
  accionRed: any;
}

interface Valoracion {
  id: string;
  idEnvio: string;
  referencia: string;
  servicio: string;
  coste: string;
  precio: string;
  info: string;
  infoSinCoste: string;
  idRef: any;
  idVariable: string;
  idServicio?: any;
  ruta: any;
  adicional: string;
  tipoIVA: string;
}

export const getEnvio = async (id: string) => {


    const { success, respuesta, msg } = await ejecutaAPI<GetEnvioResponse>("GET", `/envios/${id}`);

    if (success)
    {
        return respuesta;
    }
    else 
    {
        throw new Error(msg.join(","));
    }

}