import { useModalEtiquetas } from 'app/modules/etiquetas/hooks/useModalEtiquetas';

const EtiquetaModal = ({id, tipo, titulo = "", children}) => {

    const {abrirModalEtiquetas} = useModalEtiquetas(id, tipo, titulo);


    return children({abrirModalEtiquetas})
     
}
 
export default EtiquetaModal;