import { ejecutaAPI } from "app/helpers/libFunciones";
import { isEmpty } from "lodash";


export const getDatosEnvios = async (params = {}, datos) => {

    let queryString = '';

    if (!isEmpty(params))
    {
        queryString = '?' + new URLSearchParams(params).toString();
    }

    const {success, respuesta, error} = await ejecutaAPI('PUT', `/simulador/get_datos_envios${queryString}`, datos);

    if (success)
    {
        return respuesta;
    }
    else 
    {
        throw new Error(error);
    }
}