import { Alert, Paper } from "@mui/material";
import Cargando from "app/componentes/avisos/Cargando";
import { useApiSeguimientoIdEnvio } from "app/hooks/api/envios/useApiSeguimientoCodEnvio";
import SeguimientoEnvio from "app/modules/seguimiento/components/SeguimientoEnvio/SeguimientoEnvio";
import { isEmpty } from "lodash";
import { Portlet, PortletBody, PortletHeader } from "../../../../partials/content/Portlet";

const FichaSeguimiento = ({ id }) => {
    
    const {data, isInitialLoading} = useApiSeguimientoIdEnvio(id);

    var titulo = `Seguimiento`;

    if (data?.id !== undefined)
    {
        titulo = `Seguimiento (Bultos: ${data.seguimiento?.length})`;
    }

    return (
        <Cargando activo={isInitialLoading}>
            <Portlet>
                <PortletHeader title={titulo} goBack={`/envios/${data?.id}`} />
                <PortletBody>
                    {
                        !isEmpty(data) 
                        ?
                        (
                            <Paper elevation={0}>
                                <SeguimientoEnvio data={data} />
                            </Paper>
                        )
                        : 
                        (
                            <Alert severity="error">
                                Seguimiento no encontrado
                            </Alert>
                        )
                    }
                    
                </PortletBody>
            </Portlet>
        </Cargando>
    );
};

export default FichaSeguimiento;
