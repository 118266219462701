import { Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import GetAppIcon from '@mui/icons-material/GetApp';
import VisibilityIcon from '@mui/icons-material/Visibility';
import React from 'react';
import ImgsViewer from 'react-images-viewer';
import { iconURL, isImage } from '../../helpers/ficheros';
import { useImageViewer } from '../../hooks/useImageViewer';
import { TooltipG } from '../../pages/utils';
import { styleActionsContainer, styleImage, styleNombreFichero, styleThumb, styleThumbImageContainer, styleThumbsContainer } from './css';

const FicherosTumbsnail = ({ficheros = [], mostrarNombre = false, onDelete = null}) => {

  const {open, currentImage, previousImage, nextImage, onClickThumbnail, closeVisor, openVisor} = useImageViewer();

    const onViewClick = (file) => { 

      if (isImage(file.url)) 
      {

        var index = 0;

        var ficTemp = ficheros.filter(i => isImage(i.url));

        ficTemp.forEach((i, indexImg) => {

          if (i.url === file.url)
          {
            index = indexImg;
          }
  
        });

        openVisor(index)
      } 
    }

    return ( 
        <>
          <Box sx={styleThumbsContainer}>
            {
              ficheros && ficheros.map(file => (              
                <Box key={`fichero_${file.id}`} sx={styleThumb}>
                    <Box sx={styleThumbImageContainer}>
                      { 
                        <TooltipG title={file.nombre}>
                          <img
                            src={file.url}
                            alt="imagen"
                            onError={(e) => { e.target.src = iconURL(file.url) }}
                            style={styleImage}
                          />       
                        </TooltipG>
                      }
                    </Box>
                    <Box sx={styleActionsContainer}>
                      
                        <div className={`action ver`} onClick={() => onViewClick(file)}> 

                          {
                            isImage(file.url)
                            ?
                            (
                              <VisibilityIcon fontSize="small" />   
                            )
                            :
                            (
                              <a rel="noopener noreferrer" target="_blank" href={file.url}>
                                <GetAppIcon fontSize="small" />
                              </a>   
                            )                       
                  
                          }
                          
                        </div>
                        {
                          typeof onDelete === "function" ? 
                            <div className={`action borrar`}>
                                <DeleteIcon fontSize="small" onClick={() => onDelete(file)} />
                            </div>
                          :null
                        }

                    </Box>
                    {
                      mostrarNombre ?
                        <TooltipG title={file.nombre}>
                          <Box sx={styleNombreFichero}>
                            {file.nombre}
                          </Box>
                        </TooltipG>
                      : null
                    }
                </Box> 
                              
              ))
            }
          </Box>
          <ImgsViewer
              showThumbnails={true}
              imgs={ficheros.filter(i =>(isImage(i.url))).map(i =>({src:i.url,thumbnail:i.url}))}
              currImg={currentImage}
              isOpen={open}                 
              onClickPrev={previousImage}
              onClickNext={nextImage}
              onClickThumbnail={onClickThumbnail}
              onClose={closeVisor}
          />
        </>
     );
}
 
export default FicherosTumbsnail;