import { Grid } from '@mui/material';
import { useState } from 'react';
import { SketchPicker } from 'react-color';
import reactCSS from 'reactcss';

export const ColorPicker=(props)=>{
    
     const [displayColorPicker,setDisplayColorPicker]=useState(false);
     const {color,setColor}=props;

    const handleClick = () => {        
        setDisplayColorPicker(!displayColorPicker);
    };

    const handleClose = () => {
        
        setDisplayColorPicker(false);
    };

    const handleChange = (color) => {        
        setColor(color.hex);

    };

    const styles = reactCSS({
        'default': {
            color: {                
                width: '100% !important',
                height: '20px',
                borderRadius: '2px',
                background: `${color}`,                
            },
            swatch: {
                padding: '5px',
                background: '#fff',
                borderRadius: '1px',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                display: 'block',
                width:"100% !important",
                cursor: 'pointer',
            },
            popover: {
                position: 'absolute',
                zIndex: '2',
            },
            cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
            },
        },
    });

   

       

        return (
            <Grid container direction="row" justifyContent="flex-start">
            
                 <Grid item xs={12} >                   
                    <div style={styles.swatch} onClick={handleClick}>
                        <div style={styles.color} />
                    </div>
                 </Grid>
                
                {displayColorPicker ? <div style={styles.popover}>
                    <div style={styles.cover} onClick={handleClose} />
                        <SketchPicker color={color} onChange={handleChange} />
                    </div>
                 : null}
                
            </Grid>
        )
    
}
