import { Backdrop, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useGlobalLoader } from '../../hooks/hooksPropios';

const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: 1000000000000000000,
      color: '#fff',
    },
  }));

const CapaBloqueo = ({loader = <CircularProgress/>}) => {

    const classes = useStyles();

    const {cargando} = useGlobalLoader();

    return ( 
        <Backdrop className={classes.backdrop} open={cargando}>
            {loader}
        </Backdrop>
        
     );
}
 
export default CapaBloqueo;