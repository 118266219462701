import React, {useEffect,useState} from "react";
import axios from 'axios';
import { decode } from 'base-64';
import { BASE_URL } from './../crud/Api';
import * as auth from "../store/ducks/auth.duck";
import { connect } from "react-redux";
import { useHistory } from 'react-router-dom';
import { Grid, Button } from "@mui/material";
import { toAbsoluteUrl } from "../../_metronic";
import { useGlobalLoader } from "../hooks/hooksPropios";
import { Alert } from "@mui/lab";
import { guardaCookie } from './../crud/cookie';


 const Multilogin=(props)=> {

    const [respuesta,setRespuesta]=useState({});
    const [rol,setRol]=useState(0);
    const [roles,setRoles]=useState([]);
    const {json,login}=props;
    const history=useHistory();
     const { cargando, setGlobalCargando } = useGlobalLoader();
    
    //Hacemos la peticion

     useEffect(() => {

         let source = axios.CancelToken.source();

        
         (async () => {

             try {            
                 
                 var data = decode(json);
                 data = JSON.parse(data);
                 
                 if(rol>0){
                    data.rol=rol;
                    setGlobalCargando(true);
                 }

                                                                
                 let resp = await axios.post(BASE_URL + "/multilogin/login", data, { cancelToken: source.token });         
                 
                 if (resp && resp.data.success === true) {            

                     setRespuesta(resp.data);
              
                      if(resp.data.authToken){                                                                               

                          let set = resp.data;                                                    

                          if(resp.data.data.roles.length==1){
                              set.idRol = resp.data.data.roles[0].id;
                          }else if(rol>0){
                            set.idRol=rol;
                          }
                          localStorage.setItem("login", JSON.stringify(set));

                          guardaCookie({authToken:resp.data.authToken});

                          login({ ...resp.data, idRol: set.idRol });                              
                          setGlobalCargando(false);
                          history.push("/dashboard");

                      }else{

                            setGlobalCargando(false);
                            setRoles([...resp.data.data.roles]);
                           
                           //url pruebas
                           //http://localhost:3000/multilogin/ewogICJpZFVzdWFyaW9JbnRlcm5vIjogIjMiLAogICJpZFVzdWFyaW9NdWx0aWxvZ2luIjogIjEyIiwKICAiY29kaWZpY2FkbyI6ICI4OTdmMDE3ZDIyMTJiZjk2NzkyMTdmMzA5MTFjMWRmZDA0OWRmNGE2IiwKICAicHJveWVjdG9zIjogIiIsCiAgInByb3llY3Rvc1VzdWFyaW8iOiAiIiwKICAicmVkaXJlY2Npb24iOiAiIiwKICAibm92ZWRhZGVzIjogIiIKfQ==

                      }
                                                
                 }else{
                   
                     setRespuesta({ error: true, data:resp });
                 } 
                                  

             }catch(e){             
                 setRespuesta({error:true,data:e.response});
             }

            })();

         // Especifica cómo sanear este efecto:
         return function cleanup() {
             source.cancel("Petición cancelada");
         }


     }, [login,json,history,rol,setGlobalCargando]); 
         
    return(            
            (roles && roles.length > 0) ?                
            <Grid container 
             direction="row"
                justifyContent="center"
                alignItems="center">

                    <Grid item xs={12} style={{ margin: "80px auto 20px auto", textAlign: "center" }}
                >
                        <img
                            alt="Logo"
                            width="300"
                            style={{margin:"0px auto",textAlign:"center"}}
                            src={toAbsoluteUrl("/media/logos/logoTodo.png")}
                        /> 

                    </Grid>
                  <Grid item xs={12}><br></br><br></br><br></br></Grid>
                    {
                        Object.values(roles).map(item => (
                            <Grid item key={item.id} xs={12} lg={3} style={{maxWidth:"200px",textAlign:"center"}}>
                                <Button type="submit"
                                    onClick={() => {
                                        setRol(item.id);
                                    }} variant="contained" color="secondary"
                                    style={{ minWidth: "100px" }}
                                >
                                    {item.nombre}
                                </Button>
                            </Grid>

                        ))
                    }
                </Grid>
                :
            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center">

                <Grid item xs={12} style={{ margin: "80px auto 20px auto", textAlign: "center" }}
                >
                    <img
                        alt="Logo"
                        width="300"
                        style={{ margin: "0px auto", textAlign: "center" }}
                        src={toAbsoluteUrl("/media/logos/logoTodo.png")}
                    />

                </Grid>
                <Grid item xs={12}><br></br><br></br><br></br></Grid>
                {
                  (respuesta.data && respuesta.data.data)?                  
                    <Alert severity="error" style={{fontSize:"20px"}}>
                            {respuesta.data.data.msg[0]}
                    </Alert>
                        : <Alert severity="error" style={{ fontSize: "20px" }}>
                            ERROR
                        </Alert>
                }
            </Grid>      

    
    )
    
 }


export default  connect(null,auth.actions)(Multilogin);

