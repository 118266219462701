import Etiqueta from "app/componentes/generales/Etiqueta";
import { muestraPrecio } from "app/helpers/libFunciones";
import { useRoles } from "app/hooks/hooksPropios";
import { PreciosTrafico, ValoresEnvioCambioParcial } from "app/modules/trafico/models/trafico.types";
import EtiquetaPrecioEnvio from "./EtiquetaPrecioEnvio";

interface EtiquetasPreciosProps {
	id: string;
	precios: PreciosTrafico;
	tipoBeneficio: string | null;
	classes: any;
	handleCambioParcial: (datos: ValoresEnvioCambioParcial) => void;
	tieneValoraciones: boolean;
}

export default function EtiquetasPrecios({
	id,
	precios,
	tipoBeneficio,
	classes,
	handleCambioParcial,
	tieneValoraciones,
}: EtiquetasPreciosProps) {
	const { esRolAdministrativo } = useRoles();

	let tooltipCoste = "Coste estimado";

	let tooltipBeneficio = `Beneficios con comisiones de ${muestraPrecio(precios.comisiones_total)} € aplicadas`;

	let margenUsar = precios.margen_est;

	let costeUsar = precios.coste_total;

	let beneficioUsar = precios.beneficio_est;

	let colorUsarPrecio = "#43ac6a";

	let colorUsarBeneficio = "#63c086";

	if (tipoBeneficio == "2" && precios.coste_real > 0) {
		tooltipCoste = "Coste real";

		margenUsar = precios.margen_real;

		costeUsar = precios.coste_real;

		beneficioUsar = precios.beneficio_real;

		if (precios.margen_real && parseFloat(precios.margen_real) < 0) {
			colorUsarPrecio = "#ba3333";
		}
	}

	if (beneficioUsar !== null && beneficioUsar < 0) {
		colorUsarBeneficio = "#ba3333";
	}

	return (
		<>
			{esRolAdministrativo && precios.precio_total > 0 ? (
				<>
					<Etiqueta
						tooltip={tooltipCoste}
						texto={muestraPrecio(costeUsar) + " €"}
						clases={classes.etiqueta}
						estilos={{ backgroundColor: "#63c086", color: "white" }}
					/>
					<EtiquetaPrecioEnvio
						id={id}
						tooltip="Precio total sin IVA"
						precio={precios.precio_total.toString()}
						margen={margenUsar}
						costeReal={precios.coste_real}
						className={classes.etiqueta}
						handleCambioParcial={handleCambioParcial}
						sx={{ backgroundColor: `${colorUsarPrecio} !important`, color: "white !important" }}
						tieneValoraciones={tieneValoraciones}
					/>
					{beneficioUsar !== null && (
						<Etiqueta
							tooltip={tooltipBeneficio}
							texto={muestraPrecio(beneficioUsar) + " €"}
							clases={classes.etiqueta}
							estilos={{ backgroundColor: colorUsarBeneficio, color: "white" }}
						/>
					)}
				</>
			) : (
				<EtiquetaPrecioEnvio
					id={id}
					tooltip="Sin valorar"
					precio={precios.precio_total.toString()}
					costeReal={precios.coste_real}
					className={classes.etiqueta}
					handleCambioParcial={handleCambioParcial}
					sx={{ backgroundColor: "#f08a24 !important", color: "white !important" }}
					tieneValoraciones={tieneValoraciones}
				/>
			)}
		</>
	);
}
