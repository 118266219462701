import { IVA } from "./constants";

export const calculaTotalValoraciones = (valoraciones, impuestos) => {

    let coste_total = 0;
    let precio_total = 0;
    let margen_beneficio = 0;
    let beneficio = 0;
    let precio_iva = 0;


    valoraciones && valoraciones.forEach(item => {            
        
        var porcentajeIVA = impuestos && impuestos.find(i => i.referencia == item.tipoIVA)?.porcentaje;
        if(isNaN(porcentajeIVA)) porcentajeIVA=IVA;

        item.coste=(isNaN(item.coste) || item.coste=="") ?0:item.coste;
        item.precio = (isNaN(item.precio) || item.precio=="" ) ? 0 : item.precio;

        coste_total = parseFloat(coste_total) + parseFloat(item.coste);
        precio_total = parseFloat(precio_total) + parseFloat(item.precio);
        precio_iva = (porcentajeIVA > 0) ? parseFloat(item.precio) * (1 + (parseFloat(porcentajeIVA) / 100)) + parseFloat(precio_iva) : (parseFloat(item.precio) + parseFloat(precio_iva));
    });        

    beneficio = (precio_total - coste_total);

    if (beneficio > 0 && precio_total > 0)
        margen_beneficio = ((beneficio / precio_total) * 100);
    else
        margen_beneficio = 0;
     
    precio_iva=parseFloat(precio_iva);
    
    return { coste_total, precio_total, margen_beneficio, beneficio, precio_iva };

}