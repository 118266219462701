import { useClientes } from "app/hooks/api/clientes/useClientes";
import { useApiListadoPaises } from "app/hooks/api/localizaciones/useApiListadoPaises";
import { useListadoTarifas } from "app/hooks/api/tarifas/useListadoTarifas";
import { useApiGuardarUsuario } from "app/hooks/api/usuarios/useApiGuardarUsuario";
import { useApiUsuario } from "app/hooks/api/usuarios/useApiUsuario";
import { useGlobalLoader } from "app/hooks/hooksPropios";
import { swalError } from "app/librerias/swal";
import { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { UsuarioMapper } from "../helpers/usuarios.mapper";
import { ValidatedFormUsuarioValues } from "../models/usuarios.types";

export const useFichaUsuario = (id: string) => {

    const { isInitialLoading: isLoadingTarifas } = useListadoTarifas({ tipo: "mensajero" });

    const {isInitialLoading: isLoadingPaises, paises } = useApiListadoPaises();

    const {isInitialLoading: isLoadingClientes, clientes} = useClientes({activo: 1, per_page: -1, simplificado: 1});

    const {isInitialLoading: isLoadingUsuario, data: datosUsuario} = useApiUsuario(id);

    const {mutate} = useApiGuardarUsuario(id);

    const usuarioForm = useMemo(() => UsuarioMapper.toForm(datosUsuario), [datosUsuario]);

    const history = useHistory();

    const {setGlobalCargando} = useGlobalLoader();


    const guardarUsuario = (values: ValidatedFormUsuarioValues) => {

        setGlobalCargando(true);

        let valuesEnviar = UsuarioMapper.toRequest(values);

        mutate(valuesEnviar, {
            onSuccess: () => history.push("/config/users"),
            onError: (error) => swalError('Error', error.message ?? 'Se ha producido un error'),
            onSettled: () => setGlobalCargando(false)
        })

    }


    return {
        isLoading: isLoadingPaises || isLoadingTarifas || isLoadingClientes || isLoadingUsuario,
        usuarioForm,
        guardarUsuario,
        paises,
        clientes,
        datosUsuario
    }
}