import { Box } from '@mui/material';

const Mostrar = ({ cuando = false, children, desmontar = false }) => {

    if (desmontar && !cuando) 
    {
        return null;
    }
  
    if (!cuando) 
    {
        return (
            <Box hidden={true}>
                {children}
            </Box>
        );
    }
  
    return children;
}
 
export default Mostrar;