import {Button } from '@mui/material';
import { withStyles } from '@mui/styles';


const BotonCircular = withStyles((theme) => ({
    root: {
        "minWidth": "0px",
        "borderRadius": "100%",
        "padding": "6px"
    },
  }))(Button);

 
export default BotonCircular;