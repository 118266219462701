import { CircularProgress, Grid, MenuItem, TextField } from '@mui/material';
import { useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { ClearButton, SearchButton, customStyles, paginationOptions } from '../../crud/datatables/config';
import { useDatatable } from '../../hooks/hooksPropios';
import DatepickerP from './DatepickerP';



const ListadoPaginado = (props) => {


    const {
        url, 
        filtros = [], 
        paginationServer = true, 
        recargarTabla, 
        setRecargarTabla,
        rows,
        actualizaRows
    } = props;

    const pintaOpciones = (opciones) =>
    {

        let arrayOpciones = [];

        
        arrayOpciones = opciones.map((opcion, indice) => {
            return <MenuItem key={`${opcion.id}_${indice}`} value={opcion.id}>{opcion.value}</MenuItem>
        });

        arrayOpciones.unshift(<MenuItem key={"vacio"} value="">Selecciona</MenuItem>)

        return arrayOpciones;

    }

 
    const {
            filasDatatableFiltradas,
            setFilasDatatable,
            setFilasDatatableFiltradas,
            cargando,
            totalRows, 
            handlePerRowsChange, 
            handlePageChange, 
            handleSort,
            handleSearchFiltro,
            handleClearFiltro,
            handleChangeFiltro,
            recargarDatatable,
            resetPaginationToggle,
            datosFiltros
        } = useDatatable(url, filtros, paginationServer);


    

    const generaComponenteFiltros = (filtros) => {

        var devolver = [];

        const columnas = {lg:4,xs:12};

        if (filtros !== undefined)
        {

            if (filtros.length > 0)
            {

                devolver = (

                    <Grid container spacing={2} style={{width:"100%"}}>
                        
                        {filtros.map((campo) => {

                            if (campo.mostrar === undefined || campo.mostrar)
                            {

                                let grid = {...columnas, ...campo.grid};

                                let defaultProps = {
                                    type: campo.type,
                                    variant:"outlined",
                                    label: campo.label,
                                    margin:"normal",
                                    className:"kt-width-full",
                                    name: campo.name,
                                    InputLabelProps:{},
                                    value: datosFiltros[campo.name]
                                };

                                let propsData = defaultProps;

                                //Aplicar atributos específicos (si los hubiera).
                                if (campo.opcionesExtra !== undefined)
                                {
                    
                                    propsData = {...propsData, ...campo.opcionesExtra};                                

                                }

                                var componente = null;

                                switch(campo.type)
                                {
                                    case "select":
                                        componente = <TextField onChange={(event) => handleChangeFiltro(event, null, null, campo.onChange)} select {...propsData}>{pintaOpciones(campo.opciones)}</TextField>;
                                    break;
                                    case "textarea":
                                        componente = <TextField onChange={(event) => handleChangeFiltro(event, null, null, campo.onChange)}  multiline {...propsData}   />;
                                    break;
                                    case "fecha":
                                        componente = <DatepickerP onChange={(event) => { handleChangeFiltro(event, campo.name, campo.type, campo.onChange) }} {...propsData}  />
                                    break;
                                    default:
                                        componente = <TextField onChange={(event) => handleChangeFiltro(event, null, null, campo.onChange)}  {...propsData} />;
                                    break;

                                }

                                return (
                                    <Grid key={`Filtro_${campo.label}`} {...grid} item>
                                    
                                        {componente}
                                        
                                    </Grid>
                                )
                            }

                            return null;
                        })}
                        <ClearButton handleClear={handleClearFiltro}></ClearButton>
                        <SearchButton handleSearch={handleSearchFiltro}></SearchButton>
                    </Grid>


                )

            }


        }

        return devolver;
    }

    var filtrosListado = generaComponenteFiltros(filtros);
    

    var defaultProps = {
        title:"",
        columns:[],
        data: filasDatatableFiltradas,
        progressPending: cargando,
        pagination: true,
        paginationServer: paginationServer,
        paginationTotalRows: totalRows,
        onChangeRowsPerPage: handlePerRowsChange,
        onChangePage: handlePageChange,
        paginationComponentOptions:paginationOptions,
        onSort:handleSort,
        sortServer: true,
        defaultSortField: "title",
        selectableRows:false,
        selectableRowsNoSelectAll:false,
        selectableRowsHighlight:false,
        expandableRows:false,
        //expandableRowExpanded:isExpanded,
        highlightOnHover: true,
        striped:true,
        pointerOnHover: true,
        dense:false,
        noTableHead: false,
        persistTableHead: false,
        noHeader:true,
        subHeader: true,
        progressComponent: <CircularProgress/>,
        paginationResetDefaultPage: resetPaginationToggle,
        subHeaderComponent: filtrosListado,
        subHeaderAlign:"left",
        fixedHeader:false,
        fixedHeaderScrollHeight:"300px",
        customStyles:customStyles
        /*onRowClicked: (row)=> {                               
            history.push(`/clientes/${row.id}`);
        }}*/
    };

    var propsDatatable = {...defaultProps, ...props};

    useEffect(() => {

        if (recargarTabla)
        {
            recargarDatatable();

            setRecargarTabla(false);
        }

    }, [recargarTabla, recargarDatatable, setRecargarTabla]);


    useEffect(() => {

        if (actualizaRows) {
                        
            setFilasDatatable(rows);
            setFilasDatatableFiltradas(rows)
        }

    }, [actualizaRows,rows,setFilasDatatable,setFilasDatatableFiltradas]);


    return ( 

            <DataTable
                {...propsDatatable}                 
            />
     
     );
}

export default ListadoPaginado;