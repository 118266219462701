import Yup, { emptyStringToNull } from "app/librerias/yup";
import { esHoraValidaRecogidas } from "app/modules/shared/helpers/shared.helper";
import { isBefore, isEqual, parse, startOfDay } from "date-fns";

const SchemaVal = Yup.object().shape({
	ref: Yup.string().nullable(),
	servicio: Yup.string().required("El servicio de la valoración no puede estar vacío").nullable(),
	coste: Yup.number().nullable(),
	precio: Yup.number().nullable(),
	info: Yup.string().nullable(),
});

export const schemaTabValoraciones = Yup.object().shape({
	comisionComercial: Yup.number().transform(emptyStringToNull).nullable(),
	comisionMensajero: Yup.number().transform(emptyStringToNull).nullable(),
	valoraciones: Yup.array().of(SchemaVal),
});

export const sacarAvisoRecogidaEnFechaAnterior = (fechaRecogida: string) => {
	let fechaActual = startOfDay(new Date());

	let fechaRecogidaDate = startOfDay(parse(fechaRecogida, "dd/MM/yyyy", new Date()));

	return isBefore(fechaRecogidaDate, fechaActual);
};

export const sacarAvisoHoraMaximaSobrepasada = (fechaRecogida: string, horaMaximaRecogidas: string | null) => {

    const horaRecogidaValida = esHoraValidaRecogidas(horaMaximaRecogidas);

    const fechaRecogidaDate = startOfDay(parse(fechaRecogida, "dd/MM/yyyy", new Date()));

    const fechaActual = startOfDay(new Date());

    return !horaRecogidaValida && isEqual(fechaRecogidaDate, fechaActual);

};
