import React from 'react';
import { FormProvider } from "react-hook-form";

//@Deprecated Se dejará de ir usando en favor de FormRHF.
export const FormRHFOld = ({onSubmit, children, ...metodosForm}) => {
    return (
        <>
            <FormProvider {...metodosForm}>
                <form style={{width:"100%"}} onSubmit={metodosForm.handleSubmit(onSubmit)}>
                    {children}
                </form>
            </FormProvider>
        </>
      );
}
 
