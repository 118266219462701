import { TextField } from '@mui/material';
import { get, isArray } from 'lodash';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { getValuesFromChildrenOptions } from '../../../../helpers/libFunciones';
import { TooltipG } from '../../../../pages/utils';
import { useStyles } from '../../css/css';

const FormSelectRHF = ({name, children, onChange, tooltip, multiple, displayEmpty, placeholder, clase, permitirValoresNoExistentes = true, options = null, optionIdKey = 'id', optionValueKey = 'value', ...props}) => {
    
    const { control, formState, setValue, getValues } = useFormContext();

    const { errors} = formState;

    const errores = get(errors, name);

    const classes = useStyles();

    var propsDefault = {

        name:name,
        select: true,
        variant:"outlined",
        size:"small",
        InputProps:{autoComplete:'new-password', className: classes[clase] || null},
        margin:"normal",
        className:"kt-width-full",
        helperText: (errores && errores.message) || '',
        defaultValue: multiple ? [] : "",
        error: Boolean(errores),
    }

    if (multiple)
    {
        propsDefault.SelectProps={
            multiple: true, 
            displayEmpty: true,
        };

        if (isArray(options))
        {
            propsDefault.SelectProps.renderValue = (selected) => {
                    
                if (selected.length === 0)
                {
                    return placeholder ? placeholder : "Seleccionar";
                }
                
                var valores = options.filter(item => selected.includes(item[optionIdKey])).map(item => item[optionValueKey]);

                return valores.join(", ");
                
            }
        }

        propsDefault.InputLabelProps={shrink:true};

        propsDefault.defaultValue = [];
    }

    const handleChange = (e, onChangeRHF) => {

        onChangeRHF(e);

        if (typeof onChange === "function")
        {
            onChange(e);
        }
        
    }

    useEffect(() => {


        if (!permitirValoresNoExistentes)
        {

            var valoresPosibles = getValuesFromChildrenOptions(children);

            var valor = getValues(name);


            if (valoresPosibles.length > 0 && valor && !valoresPosibles.includes(valor.toString()))
            {
                setValue(name, "");

            }

        }

        

    },[children, name, permitirValoresNoExistentes, getValues, setValue])


    return ( 
        
            tooltip !== undefined 
            ? 
            (
                <TooltipG title={tooltip}>
                    <Controller
                        render={({field}) => (
                            <TextField value={field.value} {...propsDefault} {...props} onChange={(e) => handleChange(e, field.onChange)}>
                                {children}
                            </TextField>
                            )
                        }
                        control={control}
                        defaultValue={multiple ? [] : ""}
                        name={name}
                    />
                </TooltipG>
            )
            :
            (
                <Controller
                    render={({field}) => (
                        <TextField value={field.value} {...propsDefault} {...props} onChange={(e) => handleChange(e, field.onChange)}>
                            {children}
                        </TextField>
                        )
                    }
                    control={control}
                    defaultValue={multiple ? [] : ""}
                    name={name}
                />
                
            )
        

     )
}
 
export default FormSelectRHF;