import Yup from '../../../librerias/yup';


const objetoDireccion = Yup.object({
    id: Yup.string().nullable(),
    nombre: Yup.string().required('Requerido').nullable(),
    personaContacto: Yup.string().nullable(),
    telefonoContacto: Yup.string().nullable(),
    emailContacto: Yup.string().email('El email no es válido').nullable(),
    direccion: Yup.string().required('Requerido').nullable(),
    cp: Yup.string().required('Requerido').nullable(),
    localidad: Yup.string().required('Requerido').nullable(),
    provincia: Yup.string().nullable(),
    idPais: Yup.string().required('Requerido').nullable(),
    dni: Yup.string().dni('El dni no es válido').nullable(),
    habitual: Yup.boolean().nullable(),
    observaciones: Yup.string().nullable()
});

export const validador = Yup.object({
    id: Yup.string().nullable(),
    idDepartamento: Yup.string().required('Requerido'),
    direccion: objetoDireccion,
    direccionDestino:objetoDireccion.optional(),
    fecha: Yup.string().required('Requerido').nullable(),
    bultos: Yup.number().positive('Tiene que ser un número positivo'),
    envios: Yup.number().positive('Tiene que ser un número positivo'),
    preparado: Yup.string().nullable(),
    horaDesde: Yup.string().required('Hora desde requerida').nullable(),
    horaHasta: Yup.string().required('Hora hasta requerida').nullable(),
    idMensajero: Yup.string().nullable(),
    notasMensajero: Yup.string().nullable(),
    observacionesRecogida: Yup.string().nullable(),
    recogidaFueraPlaza: Yup.boolean().nullable().default(false),
    codRecogida:Yup.string().nullable()
});

export const importacionEnvios = Yup.object({
    idPlantilla: Yup.string().required('Requerido'),
    idServicio: Yup.string().nullable()
});


export const traspasoEnvios = Yup.object({
    idCliente: Yup.string().required('Debes seleccionar un cliente').nullable(),
    idDepartamento: Yup.string().required('Debes seleccionar un departamento').nullable()
});

