import EditIcon from "@mui/icons-material/Edit";
import LabelIcon from "@mui/icons-material/Label";
import { Box, Button, Icon } from "@mui/material";
import Mostrar from "app/componentes/datadisplay/Mostrar";
import BotonCircular from "app/componentes/generales/BotonCircular";
import TienePermisos from "app/helpers/TienePermisos";
import { TIPOS_CATEGORIAS } from "app/helpers/constants";
import { envios } from "app/helpers/permisos";
import { useRoles } from "app/hooks/hooksPropios";
import EtiquetaModal from "app/modules/etiquetas/components/EtiquetaModal/EtiquetaModal";
import { TooltipG } from "app/pages/utils";
import { Link } from "react-router-dom";

interface AccionesEnvioFilaTraficoProps {
	datos: any;
	tipo: string;
	classes: any;
}
export default function AccionesEnvioFilaTrafico({ datos, tipo, classes }: AccionesEnvioFilaTraficoProps) {
	const { esMensajero, esRolAdministrativo, esCliente } = useRoles();

    let enlaceEditar = tipo == "E" ? `/envios/${datos.id}` : `/recogidas/${datos.idRecogida}`;

	return (
		<>
			{esMensajero && (
				<Link to={`/mensajero/entregas/${datos.id}`}>
					<Button color="primary" className={classes.botonEstado}>
						<Icon>{datos.estado.icono}</Icon> {datos.estado.estado}
					</Button>
				</Link>
			)}
			<Mostrar cuando={esRolAdministrativo} desmontar={true}>
				<EtiquetaModal
					tipo={tipo === "E" ? TIPOS_CATEGORIAS.ENVIOS : TIPOS_CATEGORIAS.RECOGIDAS}
					id={datos.id}
					titulo="Categorizar envío"
				>
					{({ abrirModalEtiquetas }: { abrirModalEtiquetas: () => void }) => (
						<Box mr={1}>
							<TooltipG title="Categorizar">
								<BotonCircular onClick={abrirModalEtiquetas} color="primary" variant="contained" type="button">
									<LabelIcon />
								</BotonCircular>
							</TooltipG>
						</Box>
					)}
				</EtiquetaModal>
			</Mostrar>
			{(esCliente || esRolAdministrativo || esMensajero) && TienePermisos([], [envios.editar, envios.crear]) && (
				<Box mr={1}>
					<TooltipG title="Editar">
						<Link to={enlaceEditar}>
							<BotonCircular color="primary" variant="contained" type="button">
								<EditIcon />
							</BotonCircular>
						</Link>
					</TooltipG>
				</Box>
			)}
		</>
	);
}
