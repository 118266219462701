import { useQuery } from "@tanstack/react-query";
import { ejecutaAPI } from "app/helpers/libFunciones";

export interface SeguimientoEnvioCodResponse
{
    id: string;
    codExpedicion: string;
    codExpedicionInternacional?: any;
    codExpedicionUniversal: string;
    fecha: string;
    localidadDestino: string;
    idPaisDestino: string;
    cpDestino: string;
    idRedMensaglobalDatos: string;
    codAgencia: string;
    codigoBarras?: any;
    distribucionPropia: string;
    ultimoEstado: string;
    nombreFirma: string;
    DNIFirma: string;
    urlFirma: string;
    datosPaisDestino: DatosPaisDestino;
    seguimiento: Seguimiento[];
    imagenes: Imagen[];
    codigoSeguimientoExterno: string;
    urlSeguimientoRed: string;
    urlPOD: string;
}

interface Imagen
{
    id: string;
    ruta: string;
    nombre: string;
    idUsuario: string;
    temporal: string;
    fechaCreacion: string;
    fechaActualizacion?: any;
    url: string;
}

interface Seguimiento
{
    numBulto: string;
    seguimiento: DatosSeguimiento[];
}

interface DatosSeguimiento
{
    fecha: string;
    codigo: string;
    nombre: string;
    descripcion: string;
    datos: string;
}

interface DatosPaisDestino
{
    id: string;
    pais: string;
    alpha2Code: string;
    alpha3Code: string;
    codigoNumerico: string;
    bandera: string;
    prefijoTelefonico: string;
}

type typeBusqueda = "id" | "codigoExpedicionUniversal";

export const getSeguimientoEnvio = async (codigoEnvio: string, busqueda: typeBusqueda = "id") => {
    const urls = {
        id: `/envios/localizar/id/${codigoEnvio}`,
        codigoExpedicionUniversal: `/envios/localizar/cod/${codigoEnvio}`,
    };

    const url = urls[busqueda];

    if (!url)
    {
        throw new Error(`No se ha encontrado la url para la busqueda ${busqueda}`);
    }

    const { success, respuesta, msg } = await ejecutaAPI<SeguimientoEnvioCodResponse>("GET", url);

    if (success)
    {
        return respuesta;
    }
    else
    {
        throw new Error(msg.join(","));
    }
};

type OptionsExtra = {
    refetchOnMount?: boolean | "always";
};

export const useApiSeguimientoCodEnvio = (codigoEnvio: string, optionsExtra: OptionsExtra = {}) => {
    const options = {
        ...optionsExtra,
    };

    const data = useQuery(
        ["seguimiento_envio_cod", codigoEnvio],
        () => getSeguimientoEnvio(codigoEnvio, "codigoExpedicionUniversal"),
        options,
    );

    let errorMsg = data.error instanceof Error ? data.error.message : "";

    return {
        ...data,
        errorMsg,
    };
};

export const useApiSeguimientoIdEnvio = (idEnvio: string, optionsExtra: OptionsExtra = {}) => {
    const options = {
        ...optionsExtra,
    };

    const data = useQuery(["seguimiento_envio_id", idEnvio], () => getSeguimientoEnvio(idEnvio, "id"), options);

    let errorMsg = data.error instanceof Error ? data.error.message : "";

    return {
        ...data,
        errorMsg,
    };
};
