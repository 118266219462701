/**
 * Entry application component used to compose providers and render Routes.
 * */

import { StyledEngineProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import ModalesApp from "app/componentes/modalesComunes/ModalesApp";
import { ModalContextProvider } from "app/contexts/useModalContext";
import { es } from "date-fns/locale";
import React, { useRef } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { LastLocationProvider } from "react-router-last-location";
import { PersistGate } from "redux-persist/integration/react";
import { Toaster } from "sonner/dist";
import { I18nProvider, LayoutSplashScreen, ThemeProvider } from "./_metronic";
import { WebSocketProvider } from "./app/hooks/useWS";
import { config } from "./app/librerias/react-query.config";
import { Routes } from "./app/router/Routes";

export default function App({ store, persistor, basename }) {
	const queryClientRef = useRef();

	if (!queryClientRef.current) {
		queryClientRef.current = new QueryClient(config);
	}

	return (
		/* Provide Redux store */
		<StyledEngineProvider injectFirst>
			<Provider store={store} loading={<LayoutSplashScreen />}>
				<QueryClientProvider client={queryClientRef.current}>
					{/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
					<PersistGate persistor={persistor}>
						{/* Add high level `Suspense` in case if was not handled inside the React tree. */}
						<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
							<React.Suspense fallback={<LayoutSplashScreen />}>
								{/* Override `basename` (e.g: `homepage` in `package.json`) */}
								<BrowserRouter basename={basename}>
									{/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
									<LastLocationProvider>
										{/* Provide Metronic theme overrides. */}
										<ThemeProvider>
											{/* Provide `react-intl` context synchronized with Redux state.  */}
											<I18nProvider>
												<ModalContextProvider>
													<WebSocketProvider>
														{/* Render routes with provided `Layout`. */}
														<Routes />
														<ModalesApp />
														<Toaster position="top-right" />
													</WebSocketProvider>
												</ModalContextProvider>
											</I18nProvider>
										</ThemeProvider>
									</LastLocationProvider>
								</BrowserRouter>
							</React.Suspense>
						</LocalizationProvider>
					</PersistGate>
					<ReactQueryDevtools initialIsOpen={false} />
				</QueryClientProvider>
			</Provider>
		</StyledEngineProvider>
	);
}
