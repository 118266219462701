
import { setCookie } from 'nookies';

 const EXPIRE_COOKIE=3600;

export const guardaCookie=(data)=>{            
    
    setCookie(null, 'user', JSON.stringify(data), {
        maxAge: EXPIRE_COOKIE,
        path: '/'
    }); 

};