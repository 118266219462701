import { Box, SxProps } from "@mui/material";
import { FormTextInput } from "app/componentes/formularios/FormTextInput";
import { Form } from "app/componentes/formularios/form/form";
import BotonCircular from "app/componentes/generales/BotonCircular";
import TienePermisos from "app/helpers/TienePermisos";
import { muestraPrecio } from "app/helpers/libFunciones";
import { envios } from "app/helpers/permisos";
import { useRoles } from "app/hooks/hooksPropios";
import { swalError } from "app/librerias/swal";
import { InitialFormCambiarPesoValues, ValidatedFormCambiarPesoValues } from "app/modules/trafico/models/trafico.types";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { MdEdit, MdOutlineSave } from "react-icons/md";

interface EtiquetaPesoProps {
	id: string;
	peso: string;
	className?: string;
	handleCambioParcial: (datos: any) => void;
}

export default function EtiquetaPeso({ id, peso, className, handleCambioParcial }: EtiquetaPesoProps) {
	const styleInput = {
		padding: "0px 5px",
		fontSize: "12px",
		width: "60px",
	};

	const [modoEditar, setModoEditar] = useState(false);

	const { esCliente, esRolAdministrativo, esMensajero } = useRoles();

	const puedeEditar = (esCliente || esRolAdministrativo || esMensajero) && TienePermisos([], [envios.editar, envios.crear]);

	const form = useForm<InitialFormCambiarPesoValues>({
		defaultValues: {
			id,
			peso: parseFloat(peso).toFixed(2),
		},
	});

	const onSubmit = (values: ValidatedFormCambiarPesoValues) => {
		setModoEditar(false);

		if (values.peso !== peso) {
			if (parseFloat(values.peso) > 0) {
				handleCambioParcial(values);
			} else {
				swalError("Error", "El peso debe ser mayor que 0");
			}
		}
	};

	const activarEdicion = () => {
		if (puedeEditar) {
			setModoEditar(true);
		}
	};

	let sxBox: SxProps = {
		cursor: "pointer",
		display: "flex !important",
	};

	if (modoEditar) {
		sxBox.padding = "0px";
	}

	return (
		<Box className={className} alignItems="center" gap="7px" px="5px" sx={sxBox} onClick={() => activarEdicion()}>
			{!modoEditar ? (
				<>
					<span>Peso: {muestraPrecio(peso)}</span>
					{puedeEditar && <MdEdit />}
				</>
			) : (
				<Form {...form} onSubmit={onSubmit}>
					<FormTextInput hooks type="hidden" name="id" />
					<Box display="flex" alignItems="center" gap="5px">
						<FormTextInput
							inputProps={{ style: styleInput, step: ".01" }}
							hooks
							type="number"
							name="peso"
							clase="sinEstilo"
							margin="none"
						/>
						<BotonCircular type="submit" variant="contained" color="primary">
							<MdOutlineSave />
						</BotonCircular>
					</Box>
				</Form>
			)}
		</Box>
	);
}
