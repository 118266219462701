import { TabContext, TabList, TabListProps, TabPanel, TabPanelProps } from "@mui/lab";
import { Tab } from "@mui/material";
import { ChangeEvent, ReactNode, useCallback, useState } from "react";
import { TabMaterialType } from "./models/tab.interfaces";

interface TabMaterialProps extends TabListProps {
    children: ReactNode;
    initialValue?: string;
    tabs: TabMaterialType[]
}

const TabMaterial = ({children, initialValue = '', tabs = [], ...props} : TabMaterialProps) => {

    if (!initialValue && tabs.length > 0)
    {
        initialValue = tabs[0].value;
    }

    const [tab, setTab] = useState(initialValue);

    const handleChangeTab = useCallback((event: ChangeEvent<{}>, newValue: any) => {

        setTab(newValue)

    }, []);

    return (
        <TabContext value={tab}>
            <TabList
                onChange={handleChangeTab}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                {...props}
            > 
            {
                tabs.map(tab => (
                    <Tab key={`tab_${tab.value}`} label={tab.label} value={tab.value} />
                ))
            }                
            </TabList>
            {children}
        </TabContext>
    )

}


export const TabPanelMaterial = ({children, ...props} : TabPanelProps) => {

    return (
        <TabPanel style={{padding: 0, paddingTop: '20px'}} {...props}>
            {children}
        </TabPanel>
    )

}


export default TabMaterial;