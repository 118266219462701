import DashboardIcon from "@mui/icons-material/Dashboard";
import DoneIcon from "@mui/icons-material/Done";
import EditIcon from "@mui/icons-material/Edit";
import EmailIcon from "@mui/icons-material/Email";
import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Warning";
import { Badge, Checkbox, TextField } from "@mui/material";
import Mostrar from "app/componentes/datadisplay/Mostrar";
import BotonCircular from "app/componentes/generales/BotonCircular";
import Etiqueta from "app/componentes/generales/Etiqueta";
import TienePermisos from "app/helpers/TienePermisos";
import { TIPOS_CATEGORIAS } from "app/helpers/constants";
import { direccionString, pintaOpcionesSelect } from "app/helpers/libFunciones";
import { envios, recogidas } from "app/helpers/permisos";
import { useRoles } from "app/hooks/hooksPropios";
import CargadorEtiquetas from "app/modules/etiquetas/components/CargadorEtiquetas/CargadorEtiquetas";
import IndicadorPuntoControl from "app/modules/trafico/components/listado/IndicadorPuntoControl";
import { useDatosTrafico } from "app/pages/home/trafico/hooks/useDatosTrafico";
import { TooltipG } from "app/pages/utils";
import { Link } from "react-router-dom";
import BotonTooltipEtiquetas from "../BotonTooltipEtiquetas/BotonTooltipEtiquetas";
import { useStyles } from "./css";

const TarjetaTrafico = ({
	datos,
	mensajeros,
	estados,
	setMensajero,
	setTerminado,
	setComprobacion,
	tipoEditable,
	seleccionados,
	seleccionarElemento,
}) => {
	const { esRolAdministrativo, esMensajero, esCliente } = useRoles();

	const data = {
		estados: estados,
		datos: datos,
		seleccionados: seleccionados,
	};

	const {
		tipo,
		datosTratados,
		codigoEstado,
		datosIncidencia,
		desactivarMensajero,
		mostrarFinalizarRecogida,
		elementoSeleccionado,
		estadoTooltip,
	} = useDatosTrafico(data);

	datos = datosTratados;

	const propsParaStyles = {
		datos: datos,
		codigo: codigoEstado,
		datosIncidencia: datosIncidencia,
	};

	const classes = useStyles(propsParaStyles);

	var textoArriba = "";

	var textoFooter1 = "";

	var numEnvios = 0;

	var numBultos = 0;

	var enlaceFichaPrincipal = "";

	var enlaceBotonEditar = "";

	var puedeVerBotonEditar = false;

	if (tipo === "R" || tipo === "RP") {
		enlaceBotonEditar = `/recogidas/${datos.id}`;

		puedeVerBotonEditar =
			tipo === "R" ? TienePermisos([], [recogidas.crear, recogidas.editar]) : TienePermisos([recogidas.recogidasFueraPlaza]);

		if (esMensajero) {
			enlaceFichaPrincipal = `/mensajero/recogidas/${datos.id}`;
		} else if (esCliente || esRolAdministrativo) {
			enlaceFichaPrincipal = `/recogidas/${datos.id}`;
		}

		if (!isNaN(datos.numEnvios)) {
			numEnvios = parseInt(datos.numEnvios);
		}

		if (!isNaN(datos.numBultos)) {
			numBultos = parseInt(datos.numBultos);
		}

		textoArriba = datos.cliente;

		textoFooter1 = datos.id;
	} else {
		enlaceBotonEditar = `/envios/${datos.id}`;

		puedeVerBotonEditar = TienePermisos([], [envios.editar, envios.crear]);

		if (esMensajero) {
			enlaceFichaPrincipal = `/mensajero/entregas/${datos.id}`;
		} else if (esCliente || esRolAdministrativo) {
			enlaceFichaPrincipal = `/envios/${datos.id}`;
		}

		textoArriba = `${datos.nombreServicioMaestro} (${datos.bultos} bultos)`;

		textoFooter1 = (
			<a target="_blank" rel="noopener noreferrer" href={`/recogidas/${datos.idRecogida}`}>
				R: {datos.idRecogida}
			</a>
		);
	}

	var direccionObjeto = {
		nombre: datos.direccion.nombre,
		direccion: datos.direccion.direccion,
		cp: datos.direccion.cp,
		localidad: datos.direccion.localidad,
	};

	const handleSubmitMensajero = (e) => {
		setMensajero(e.target.value, datos);
	};

	const handleComprobacion = () => {
		setComprobacion(datos);
	};

	const handleSetTerminado = (e) => {
		setTerminado(datos);
	};

	return (
		<div className={classes.item}>
			{tipo === "E" && datos.distribucionPropia === "1" && <div className={classes.cabeceraCaja}>Distribución Propia</div>}

			<div className={classes.bodyCaja}>
				<div className={classes.filaTitulo}>
					{datos.tipo === tipoEditable &&
						(esRolAdministrativo || esCliente) &&
						(tipoEditable !== "envio" || tipoEditable === "envio") && (
							<div>
								<Checkbox
									checked={elementoSeleccionado}
									onChange={seleccionarElemento}
									value={datos.id}
									name="elementoSeleccionado"
									color="primary"
								/>
							</div>
						)}

					<div className={tipo == "RP" ? classes.tipo2 : classes.tipo}>{tipo}</div>
					<div className={classes.textoArriba}>{textoArriba}</div>
					{datos.estadoPuntoControl && (
						<IndicadorPuntoControl
							sx={{ width: "20px", height: "20px", fontSize: "8px" }}
							estadoPuntoControl={datos.estadoPuntoControl}
						/>
					)}
					{(tipo === "R" || tipo === "RP") && (esRolAdministrativo || esCliente || esMensajero) && (
						<>
							<div>
								<Link to={enlaceFichaPrincipal}>
									<TooltipG title="Envios">
										<Badge badgeContent={numEnvios} color="error">
											<EmailIcon className={classes.iconoEnvios} />
										</Badge>
									</TooltipG>
								</Link>
							</div>
							{numBultos > 0 && (
								<div>
									<TooltipG title="Bultos">
										<Badge badgeContent={numBultos} color="primary" style={{ marginLeft: "10px" }}>
											<DashboardIcon className={classes.iconoBultos} />
										</Badge>
									</TooltipG>
								</div>
							)}
							{(datos.observacionesRecogida || datos.notasMensajero) && (
								<div style={{ marginLeft: "10px" }}>
									<TooltipG
										title={`${datos.observacionesRecogida != null ? datos.observacionesRecogida : ""} ${
											datos.notasMensajero != null ? datos.notasMensajero : ""
										}`}
									>
										<InfoIcon color="secondary" style={{ marginLeft: "5px", fontSize: "25px" }}></InfoIcon>
									</TooltipG>
								</div>
							)}
						</>
					)}
					{(esRolAdministrativo || esCliente) && datosIncidencia.incidentado && (
						<div className={classes.capaIncidencia}>
							<TooltipG title={datosIncidencia.textoIncidencia || "Incidentado"}>
								<WarningIcon className={classes.iconoWarning} />
							</TooltipG>
						</div>
					)}
				</div>
				<div className={classes.filaDatosTipo}>
					<div className={classes.datoPrincipal}>
						<TooltipG title={datos.direccion.nombre}>
							<Link to={enlaceFichaPrincipal} className={classes.enlace}>
								{datos.direccion.nombre}
							</Link>
						</TooltipG>
					</div>
					<div className={classes.datoSecundario}>{direccionString(direccionObjeto, true)}</div>
				</div>
				<div className={classes.filaHoras}>
					<div className={classes.horaInicio}>{datos.fecha}</div>
					<div className={classes.horaInicio}>{datos.horaDesde}</div>
					<div className={classes.horaFin}>{datos.horaHasta}</div>
					<div className={classes.contenedorBotonEtiquetas}>
						<CargadorEtiquetas
							categoriasIniciales={datos.categorias}
							id={datos.id}
							tipo={tipo === "E" ? TIPOS_CATEGORIAS.ENVIOS : TIPOS_CATEGORIAS.RECOGIDAS}
						>
							{({ etiquetasElemento }) => (
								<BotonTooltipEtiquetas idElemento={datos.id} tipo={tipo} etiquetasElemento={etiquetasElemento} />
							)}
						</CargadorEtiquetas>
					</div>
				</div>

				{esRolAdministrativo ||
				(esMensajero &&
					((tipo == "E" && TienePermisos([envios.ver_todos])) || (tipo == "R" && TienePermisos([recogidas.ver_todos])))) ? (
					<div>
						<div>
							<TextField
								style={{ visibility: tipo == "RP" ? "hidden" : "true" }}
								disabled={desactivarMensajero}
								classes={{ root: classes.selectorMensajero }}
								select
								name="idMensajero"
								onChange={handleSubmitMensajero}
								label="Selecciona mensajero"
								value={datos.idMensajero || ""}
							>
								{pintaOpcionesSelect(mensajeros, "id", "nombreCompleto", "selectMensajerosTrafico")}
							</TextField>
						</div>
					</div>
				) : datos.idMensajero > 0 && esMensajero ? (
					<Etiqueta estilos={{ marginTop: "5px", marginBottom: "5px" }} texto={datos.nombreMensajero} clases={classes.etiqueta} />
				) : null}

				<div className={classes.filaFooter}>
					{(esRolAdministrativo || esCliente) && (
						<div className={esCliente ? classes.footer1Cliente : classes.footer1}>{textoFooter1}</div>
					)}

					<div className={classes.footer2}>
						<TooltipG title={estadoTooltip}>
							<span>{estadoTooltip}</span>
						</TooltipG>
					</div>
					{esRolAdministrativo && mostrarFinalizarRecogida && tipo === "R" && (
						<div className={classes.footer3}>
							<TooltipG title="Marcar la recogida como terminada">
								<DoneIcon onClick={handleSetTerminado} className={classes.iconoTerminar} />
							</TooltipG>
						</div>
					)}

					{tipo === "R" && esMensajero && (codigoEstado === "aviso" || codigoEstado === "asignado") && (
						<TooltipG title="Marcar como comprobado">
							<DoneIcon onClick={handleComprobacion} className={classes.iconoTerminar} />
						</TooltipG>
					)}
				</div>
				<Mostrar cuando={esMensajero && puedeVerBotonEditar} desmontar={true}>
					<div className={classes.capaAcciones}>
						<TooltipG title="Editar">
							<Link to={enlaceBotonEditar}>
								<BotonCircular color="primary" size="small" variant="contained" type="button">
									<EditIcon />
								</BotonCircular>
							</Link>
						</TooltipG>
					</div>
				</Mostrar>
			</div>
		</div>
	);
};

export default TarjetaTrafico;
