import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Box, ButtonBase, Grid } from "@mui/material";
import FicherosTumbsnail from "app/componentes/galeria/FicherosThumbsnail";
import { SeguimientoEnvioCodResponse } from "app/hooks/api/envios/useApiSeguimientoCodEnvio";
import BultoEnvio from "app/modules/seguimiento/components/BultoEnvio/BultoEnvio";
import { TooltipG } from "app/pages/utils";
import { useCopyToClipboard } from "react-use";

const sxTitles = {
    color: "primary.main",
    fontWeight: "600",
};

const sxContenedorInfoPrincipal = {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    marginBottom: "20px",
    fontWeight: "500",
    marginTop: "15px",
};

const sxCampo = {
    padding: "7px 10px",
    background: "#f5f5f7",
    minHeight: "33.5px",
};

interface SeguimientoEnvioProps
{
    data: SeguimientoEnvioCodResponse;
}

export default function SeguimientoEnvio({ data }: SeguimientoEnvioProps)
{
    const [copiado, copyToClipboard] = useCopyToClipboard();

    return (
        <>
            <Box sx={sxContenedorInfoPrincipal}>
                <Box display="flex" flexDirection="column" gap="10px">
                    <Box sx={sxTitles} component="h5">Nº de seguimiento</Box>
                    <Box sx={sxCampo}>{data.codExpedicionUniversal}</Box>
                </Box>
                <Box display="flex" flexDirection="column" gap="10px">
                    <Box sx={sxTitles} component="h5">Estado</Box>
                    <Box sx={sxCampo}>{data.ultimoEstado}</Box>
                </Box>
            </Box>
            <Box sx={sxTitles} component="h5">
                Detalles
            </Box>
            <Box mt="20px">
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <Box fontWeight="500" component="label">Destino</Box>
                        <Box sx={sxCampo}>
                            {`${data.localidadDestino}, ${data.cpDestino}, ${data.datosPaisDestino?.pais}`}
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box fontWeight="500" component="label">Fecha</Box>
                        <Box sx={sxCampo}>{data.fecha}</Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box fontWeight="500" component="label">Seguimiento red</Box>
                        <Box>
                            <Box sx={sxCampo} display="flex" alignItems="center" justifyContent="space-between" gap="10px">
                                {data.codigoSeguimientoExterno && (
                                    <>
                                        <span>{data.codigoSeguimientoExterno}</span>
                                        <TooltipG title={!copiado.value ? "Copiar código de seguimiento" : "Copiado"}>
                                            <span>
                                                <ButtonBase
                                                    type="button"
                                                    onClick={() => copyToClipboard(data.codigoSeguimientoExterno)}
                                                >
                                                    <ContentCopyIcon sx={{ fontSize: "14px" }} />
                                                </ButtonBase>
                                            </span>
                                        </TooltipG>
                                    </>
                                )}
                            </Box>
                            <Box>
                                {data.urlSeguimientoRed && (
                                    <a href={data.urlSeguimientoRed} target="_blank" rel="noreferrer">Ver seguimiento</a>
                                )}
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box fontWeight="500" component="label">Justificante de entrega red</Box>
                        <Box sx={sxCampo}>
                            {data.urlPOD
                                ? <a href={data.urlPOD} target="_blank" rel="noreferrer">Ver justificante</a>
                                : "No disponible"}
                        </Box>
                    </Grid>
                    {(data.nombreFirma || data.DNIFirma || data.urlFirma) && (
                        <Grid item xs={12} sm={12}>
                            <Box sx={sxTitles} component="h5" mb="20px">
                                Justificante de entrega
                            </Box>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <Box fontWeight="500" component="label">Nombre</Box>
                                    <Box sx={sxCampo}>{data.nombreFirma}</Box>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Box fontWeight="500" component="label">NIF</Box>
                                    <Box sx={sxCampo}>{data.DNIFirma}</Box>
                                </Grid>
                                {data.urlFirma && (
                                    <Grid item xs={12} sm={12}>
                                        <Box fontWeight="500" component="label">Firma</Box>
                                        <Box>
                                            <img style={{ height: "50px" }} src={data.urlFirma} alt="Firma" />
                                        </Box>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    )}

                    {data.distribucionPropia === "1" && data.imagenes.length > 0 && (
                        <Grid item xs={12} sm={12}>
                            <Box fontWeight="500" component="label">Imagenes</Box>
                            <FicherosTumbsnail ficheros={data.imagenes} />
                        </Grid>
                    )}
                </Grid>
                <Box sx={sxTitles} mt={"40px"} component="h5">
                    Seguimiento
                </Box>
                <Grid mt={"20px"} container spacing={1}>
                    {data.seguimiento.map((datosSeguimiento, indice) => {
                        return (
                            <Grid item lg={12} xs={12} key={`Bulto_${datosSeguimiento.numBulto}`}>
                                <Box mb={2} m={1}>
                                    <BultoEnvio indice={indice} datosSeguimiento={datosSeguimiento} />
                                </Box>
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>
        </>
    );
}
