import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getPermisos } from './../store/selectors';


 function AlertDialog() {
    const [open, setOpen] = React.useState(true);

    const history=useHistory();

    function handleClose() {
        setOpen(false);
        history.goBack();
    }


   

    return (
        <div>          
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Acceso restringido"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        No tienes permisos para acceder a esta sección
                        
                    </DialogContentText>
                </DialogContent>
                <DialogActions>                    
                    <Button onClick={handleClose} color="primary" autoFocus>
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

 const accessControl = permisosRequired => WrappedComponent => {

    const SecuredControl = class extends Component {

        render() {


            let permisos = this.props.permisos;     
                                           
            permisos=Object.values(permisos);
            for(var i=0;i<permisos.length;i++){
                permisos[i]=parseInt(permisos[i]);
            }

            const isAllow = permisosRequired.every(d => permisos.indexOf(d) >= 0);

            if (!isAllow) {
                return (
                    <AlertDialog></AlertDialog>   
                );
            }

            return <WrappedComponent  {...this.props} />;
        }

        componentWillUnmount(){
            return () => {
            }
        }
    }

            

    const mapStateToProps = (store) =>
        ({ permisos: getPermisos(store)});
    

    return connect(mapStateToProps)(SecuredControl);

}

export default accessControl;



