import { ColumnDef, getCoreRowModel, getExpandedRowModel, getSortedRowModel, SortingState, TableOptions, useReactTable } from "@tanstack/react-table";
import { useMemo, useState } from "react";
import { useDatatableFilters } from "./useDatatableFilters";
import { useDatatablePagination } from "./useDatatablePagination";
import { useDatatableQuery } from "./useDatatableQuery";

interface useServerSideDatatableProps<TData>
{
    url: string;
    queryKey: string[];
    columns: ColumnDef<TData>[];
    initialPage?: number;
    initialRowsPerPage?: number;
    tableProps?: Partial<TableOptions<TData>>;
    defaultFilterValues?: any;
    keyFiltersLocalStorage?: string;
}

export function useDatatable<TData extends unknown>(
    { url, queryKey, columns, initialPage = 0, initialRowsPerPage = 10, tableProps = {}, defaultFilterValues = {}, keyFiltersLocalStorage = "" }: useServerSideDatatableProps<TData>,
)
{
    const paginationState = useDatatablePagination(initialPage, initialRowsPerPage);

    const { datosPaginacion } = paginationState;

    const [sorting, setSorting] = useState<SortingState>([]);

    const [columnVisibility, setColumnVisibility] = useState({})

    const filtrosState = useDatatableFilters(defaultFilterValues, paginationState.goToFirstPage, keyFiltersLocalStorage);

    const { data, isFetching } = useDatatableQuery({
        url,
        queryKey,
        datosPaginacion,
        sorting,
        filtros: filtrosState.filtros,
    });

    const pagination = useMemo(() => ({
        pageIndex: datosPaginacion.pageIndex,
        pageSize: datosPaginacion.pageSize,
    }), [datosPaginacion]);

    const tableData = useMemo(() => data?.data ?? [], [data]);

    const table = useReactTable({
        data: tableData,
        pageCount: data?.total_pages ?? -1,
        state: {
            pagination,
            sorting,
            columnVisibility
        },
        onColumnVisibilityChange: setColumnVisibility,
        onSortingChange: setSorting,
        onPaginationChange: paginationState.setPagination,
        manualPagination: true,
        manualSorting: true,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        ...tableProps
    });

    return {
        paginationState,
        filtrosState,
        table,
        data,
        isFetching,
    };
}
