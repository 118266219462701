import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { call, put, takeLatest } from "redux-saga/effects";
import Api from "../../crud/Api";
import { guardaCookie } from './../../crud/cookie';


export const actionTypes = {
  Login: "[Login] Action",
  ActualizaPermisos: "Actualiza permisos",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  Modulos: "[Modulos] Action",  
  Roles: "[Roles] Action",  
  setPermisos:"[setPermisosUser] Action",
  setPermisosUserRol: "[setPermisosUserRol] Action"
};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  refreshToken:undefined,
  idRol:0,  
  modulos:[],
  roles:[]
};


export const reducer = persistReducer(
    { storage, key: "user_auth", whitelist: ["user", "authToken","refreshToken","idRol","roles","modulos"] },
    (state = initialAuthState, action) => {
      switch (action.type) {
        case actionTypes.Login: {         

          const { authToken,refreshToken,idRol} = action.payload;
          const user=action.payload.data;     

          guardaCookie({authToken:authToken});                            
    
          return { ...state, authToken, refreshToken,user,idRol:parseInt(idRol) };
        } 

        case actionTypes.ActualizaPermisos: {

          return { ...state };
        } 

        case actionTypes.refreshToken:{

           const {authToken} = action.payload;

           return {...state,authToken};
        }

        case actionTypes.Logout: {
          //routerHelpers.forgotLastLocation();
          localStorage.clear();
          sessionStorage.clear();
          return {...initialAuthState};
        }

        case actionTypes.Modulos:{
           let {modulos}=action.payload;
        
          return { ...state, modulos };
            
        }

        case actionTypes.Roles: {
          let { roles } = action.payload;

          return { ...state, roles };
        }   

        case actionTypes.setPermisos: {
          let permisos = action.payload;    
          
          return {...state,user:{...state.user,permisos:permisos}};
          
        }

        case actionTypes.setPermisosUserRol: {
          let roles = action.payload;

          return { ...state, user: { ...state.user, roles: roles } };

        }

          

        default:
          return state;
      }
    }
);

export const actions = {
  login: data => ({ type: actionTypes.Login, payload: data }),
  ActualizaPermisos: data => ({ type: actionTypes.ActualizaPermisos, payload: data }),
  setModulos: data => ({type: actionTypes.Modulos, payload: data }),
  setRoles: data => ({ type: actionTypes.Roles, payload: data }),
  setPermisosUser: data => ({ type: actionTypes.setPermisos, payload: data }),   
  setPermisosUserRol: data => ({ type: actionTypes.setPermisosUserRol, payload: data }),   
  register: authToken => ({
    type: actionTypes.Register,
    payload: { authToken }
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: user => ({ type: actionTypes.UserRequested, payload: { user } }),  
};

export function* saga() {

  yield takeLatest(actionTypes.setPermisos, function* setPermisos() {      
  });


  yield takeLatest(actionTypes.ActualizaPermisos, function* pidePermisos() {

    
     var ret = yield call(getPermisos);

    var roles = [];
    var modulos = [];

    if(ret.data){

      if (ret.data.success !== false) {
        modulos = ret.data.data.modulos;
      }

      ret = yield call(getPermisosRoles);

      if (ret.data.success !== false) {
        roles = ret.data.data;
      }

      yield put(actions.setModulos({ modulos }));
      yield put(actions.setRoles({ roles }));

     
  }


  });

  
  yield takeLatest(actionTypes.Login, function* login(){

     //yield put(actions.ActualizaPermisos());

  });
  
}



async function getPermisos(){

  var permisos={};

   try{
   
    permisos=await Api("get", `users/permisos`);
     return permisos;
   }catch(e){
    permisos=e.response;
    return permisos;

   }
    
}


async function getPermisosRoles() {
  
  var permisos = {};

  try {
    permisos = await Api("get", `users/permisosRol`);
    return permisos;

  } catch (e) {
    permisos = e.response;
    return permisos;

  }

}

