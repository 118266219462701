import { Badge } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useRoles } from "app/hooks/hooksPropios";
import { useWSContext } from "app/hooks/useWS";
import ListadoAlertasTrafico from "app/pages/home/trafico/components/ListadoAlertasTrafico/ListadoAlertasTrafico";
import clsx from "clsx";
import React from "react";
import { Dropdown } from "react-bootstrap";
import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";



 const AlertsUser=()=> {

   const { numAlerts, setOpen} = useWSContext();
   
   const { MENSAJERO, esRol, esRolAdministrativo } = useRoles();
   const esMensajero = esRol(MENSAJERO);
          
 return (
   (esMensajero || esRolAdministrativo) && <Dropdown
        className="kt-header__topbar-item kt-header__topbar-item--langs"
        drop="down" alignRight
      >
        <Dropdown.Toggle as={HeaderDropdownToggle} id="dropdown-toggle-my-cart">
          <span
            className={clsx("kt-header__topbar-icon")}
          >
            <Badge badgeContent={numAlerts()} onClick={()=>{setOpen(true)}} color="error">
               <NotificationsIcon  style={{color:"white"}}/>
            </Badge>

          </span>
        </Dropdown.Toggle>
        
       <ListadoAlertasTrafico />
     
      </Dropdown>
    );
  
};

export default AlertsUser;

