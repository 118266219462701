import { Button, Container, Grid, Typography } from '@mui/material';
import SnackbarAlert from "app/componentes/avisos/SnackbarAlert";
import axios from 'axios';
import { Formik } from "formik";
import { isEmpty } from 'lodash';
import { useEffect, useState } from "react";
import { FormSelect, FormTextInput } from '../../../../componentes/formularios/core';
import accessControl from "../../../../helpers/accessControl";
import { pintaOpcionesSelect } from "../../../../helpers/libFunciones";
import * as permisos from '../../../../helpers/permisos';
import { SelectTiposCategorias } from '../../../../helpers/selects';
import { PaperLine } from "../../../utils";
import Api from './../../../../crud/Api';
import TienePermisos from './../../../../helpers/TienePermisos';
import { useFiltros, useGlobalLoader } from './../../../../hooks/hooksPropios';
import Yup from './../../../../librerias/yup';
import
  {
    Portlet,
    PortletBody,
    PortletHeader
  } from './../../../../partials/content/Portlet';
import { BotonAdd2 } from './../../../utils/index';
import ElementoCategoria from './ElementoCategoria';


const ConfigCategorias = () => {

  const [categorias, setCategorias] = useState({ categorias: [] });
  const [categorias_filter, setCategoriasFilter] = useState([]);
  const [tiposCategorias, setTiposCategorias] = useState([]);
  const { setGlobalCargando } = useGlobalLoader();

  const categoriaVacia = {
    "id": "",
    "idTipo": "",
    "icono": "star",
    "color": "#000000",
    "nombre": "",
    "descripcion": null,
    "referencia": ""
  }

  const { setFiltros, filtrar, filtros } = useFiltros({ nombre: { valor: "", tipo: "like" }, idTipo: { valor: "", tipo: "exact" } });


  useEffect(() => {

    let source = axios.CancelToken.source();

    (async () => {

      try {

        setGlobalCargando(true);

        SelectTiposCategorias().then(data => {
          setTiposCategorias(data);
        });

        let response = await Api("get", `categorias?per_page=-1`, { cancelToken: source.token });

        if (response.data) {
          let data = response.data;

          if (data.success === true) {
            setCategorias({ categorias: data.data });

          }

        }

        setGlobalCargando(false);
      } catch (e) {

      }

    })();

    return function cleanup() {

      source.cancel("Petición cancelada");
    }

  }, [setGlobalCargando]);


  //Filtros
  useEffect(() => {

    let aux = filtrar(categorias.categorias)
    setCategoriasFilter([...aux]);

  }, [filtrar, categorias]);


  const onSubmitForm = (values, { setStatus, setSubmitting }) => {

    setSubmitting(true);
    setGlobalCargando(true);

    //Por cada categoria guardamos
    var msg = [];

    values.categorias && values.categorias.forEach(async (item, index) => {

      let response = {};

      if (values.categorias[index].fechaCreacion)
        delete values.categorias[index].fechaCreacion;

      if (values.categorias[index].fechaActualizacion)
        delete values.categorias[index].fechaActualizacion;

      try{
      if (item.id > 0) {
        
        response = await Api("put", `/categorias/${item.id}`, values.categorias[index]); 
        
        if (response && response.data) {
          msg.push(response.data.msg[0]);
        }

      } else {
        response = await Api("post", `/categorias`, values.categorias[index])
        if (response.data) {
          if (response.data.success === true) {
            setCategorias({ categorias: Object.values({ ...categorias.categorias, [index]: { ...response.data.data } }) })
          } else {
            if (response.data.msg) {
              if(response.data.msg.length>0)
              msg.push(response.data.msg[0]);
            }
          }
        }
      }
    }catch(e){
        if (index === values.categorias.length - 1) {
          if (e.response.data.msg.length>0)
           msg.push(e.response.data.msg);
        }
    }


      if (index === values.categorias.length - 1) {
        setSubmitting(false);
        setGlobalCargando(false);     
        if (msg.length > 0) {
          let aux=msg.join(" ");
          if(aux!=" ")
            setStatus(aux);
        }else{
          setStatus(false);
        }
      }
    });



  }


  const nuevaCategoria = () => {

    setCategorias({ categorias: [...categorias.categorias, { ...categoriaVacia }] });
  }

  const SchemaCat = Yup.object().shape({
    nombre: Yup.string().required('Requerido'),
    referencia: Yup.string().nullable(),
    idTipo: Yup.string().required('Requerido'),
    descripcion: Yup.string().nullable()
  });


  const SchemaCategorias = Yup.object().shape({
    categorias: Yup.array().of(
      SchemaCat
    )
  });



  return (
    <Portlet>
      <PortletHeader
        title={<>
          Categorias </>
        }
        toolbar={(TienePermisos([permisos.categorias.ver,permisos.categorias.editar]))?<BotonAdd2 name="Nueva Categoria" onClick={(e) => { nuevaCategoria() }}></BotonAdd2>:null}
      >
      </PortletHeader>
      <PortletBody>

        <PaperLine elevation={0} style={{
          marginTop: "10px", marginBottom: "20px"
        }}>

          <p>
            Las categorías permiten marcar o agrupar datos y filtrar búsquedas, según el tipo de categoría.
           </p>

        </PaperLine>

        <Formik
          enableReinitialize
          initialValues={categorias}
          onSubmit={onSubmitForm}
          validateOnChange={true} validateOnBlur={false}
          validationSchema={SchemaCategorias}
        >
          {(formik) => (
            <form
              autoComplete="new-password"
              className="kt-form"
              onSubmit={formik.handleSubmit}
              name="fichaVariable"
            >
              {(formik.status && !isEmpty(formik.status)) ? (
                <>
                  <SnackbarAlert variant="outlined" elevation={0} severity={"warning"}  >
                    {formik.status} 
                  </SnackbarAlert>
                  <br></br>
                </>
              ) : null}

              <Grid container spacing={2}>

                <Grid item xs={12} >
                  <Typography variant="h6">Filtros</Typography>
                </Grid>
                <Grid item xs={12} lg={3}>
                  <FormTextInput name="nombre" label="Nombre *" value={filtros.nombre.valor} onChange={(e) => { setFiltros("nombre", e.target.value) }}></FormTextInput>
                </Grid>

                <Grid item xs={12} lg={3}>
                  <FormSelect name={`tipoCategoria`} value={filtros.idTipo.valor} label="Tipo" onChange={(e) => { setFiltros("idTipo", e.target.value) }}>
                    {pintaOpcionesSelect(tiposCategorias, "id", "nombre", `tiposCategoria_`)}
                  </FormSelect>
                </Grid>


              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} >
                  &nbsp;
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} >
                  <Typography variant="h6">Categorías</Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Container maxWidth="lg">
                    {
                      formik.values.categorias && formik.values.categorias.map((item, index) => {
                        if (categorias_filter.filter(cat => cat.id === item.id).length > 0) {
                          return (<ElementoCategoria key={`cat_${index}`} tiposCategorias={tiposCategorias} setCategorias={setCategorias} index={index}></ElementoCategoria>);
                        }
                      })
                    }
                  </Container>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={12}>
                  {(TienePermisos([permisos.categorias.editar]))?
                    <Button type="submit" disabled={(!formik.isSubmitting && TienePermisos([permisos.categorias.editar])) ? false : true} style={{ float: "right" }} variant="contained" color="primary">
                    Guardar
                    </Button>
                    :null
                  }
                </Grid>
              </Grid>


            </form>

          )}
        </Formik>

      </PortletBody>
      <br></br>

    </Portlet>




  );



}

export default accessControl([permisos.categorias.ver])(ConfigCategorias);