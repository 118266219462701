import Cargando from "app/componentes/avisos/Cargando";
import { usePlantillas } from "../../../../hooks/usePlantillas";
import FormularioPlantilla from "../../../../modules/plantillas/components/FormularioPlantilla";
import { Portlet, PortletBody, PortletHeader } from "../../../../partials/content/Portlet";

const FichaPlantilla = ({ id }) => {
	const { isLoading, redes, plantillasTipos, plantilla } = usePlantillas(id);

	return (
		<>
			<Portlet>
				<PortletHeader title={"Plantilla"} goBack={"/plantillas"} />
				<PortletBody>
					<Cargando activo={isLoading}>
						<FormularioPlantilla redes={redes} plantillasTipos={plantillasTipos} plantilla={plantilla} />
					</Cargando>
				</PortletBody>
			</Portlet>
		</>
	);
};

export default FichaPlantilla;
