import { ejecutaAPI } from "app/helpers/libFunciones";

interface GetDatosComisionesRequest {
  id: string;
  tipo: string;
}

export interface GetDatosComisionesResponse {
  datos: DatosComision[];
  tarifas: Tarifa[];
}

interface Tarifa {
  id: string;
  idRed: string;
  tipo: string;
  nombre: string;
  margen: string;
  factorVolumetrico: string;
  bloqueada: string;
  cabeceraArribaFondo: string;
  cabeceraArribaTexto: string;
  cabeceraIzquierdaFondo: string;
  cabeceraIzquierdaTexto: string;
  cabeceraEsquinaFondo: string;
  cabeceraEsquinaTexto: string;
  filasParesFondo: string;
  celdasVaciasFondo: string;
  fullLogo: string;
  idFichero: string | null;
  textoInicial: string;
  textoFinal: string;
  idCustom: string | null;
  importado: string;
  fechaCreacion: string;
  fechaActualizacion: string;
  nombreRed: string;
  margenRed: string;
  variables: VariableTarifa[];
  tarifas_servicios: ServicioTarifa[];
}

interface ServicioTarifa {
  id: string;
  idTarifa: string;
  idServicio: string;
  imprimir: string;
  noRotar: string;
  nombre: null;
  margen: null | string;
  factorVolumetrico: null | string;
  interno: string;
  observaciones: null;
  activo: string;
  nombreServicio: string;
  referencias: ReferenciaTarifaServicio[];
}

interface ReferenciaTarifaServicio {
  id: string;
  idTarifaServicio: string;
  idReferencia: string;
  estado: string;
  nombreServicioReferencia: string;
  franjas: FranjaTarifaServicio[];
  rutas: RutaTarifaServicio[];
  valores: ValorTarifaServicio[];
}

interface ValorTarifaServicio {
  id: string;
  idTarifaRuta: string;
  idTarifaFranja: string;
  tipoModificacion: "valor" | "margen" | "inactivo" | null;
  margen: string | null;
  valor: string | null;
  idServicioReferenciaFranja: string;
  idServicioReferenciaRuta: string;
  idTarifaReferenciaRuta: string;
  idTarifaReferenciaFranja: string;
}

interface RutaTarifaServicio {
  id: string;
  idTarifaReferencia: string;
  idServicioReferenciaRuta: string;
  margen: string | null;
  factorVolumetrico: string | null;
}

interface FranjaTarifaServicio {
  id: string;
  idTarifaReferencia: string;
  idServicioReferenciaFranja: string;
  margen: null | string;
}

interface VariableTarifa {
  id: string;
  idTarifa: string;
  idVariable: string;
  activa: string;
  tipoModificacion: null;
  margen: null;
  valor: null;
  minimoTipoModificacion: null;
  minimoMargen: null;
  minimoValor: null;
  nombreVariable: string;
  valorVariable: string;
  valorMinimoVariable: string;
  accionRed: null | string;
  idCustom: null;
}

export interface DatosComision {
  tipo: string;
  id: string;
  idComercial: string;
  porcComisionMensajero: null | string;
  porcComisionMensajeroRecogida: string;
  porcComisionComercial: string;
  valoracion: string;
  idTarifa: null | string;
  idTarifaMensajeroRecogida: string;
}

export const getDatosComisiones = async (values: GetDatosComisionesRequest[]) => {

    const {success, respuesta, msg} = await ejecutaAPI<GetDatosComisionesResponse>('POST', `/trafico/comisiones/datos`, values);

    if (success)
    {
        return respuesta;
    }
    else 
    {
        throw new Error(msg.join(", "));
    }

}