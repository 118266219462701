import React,{useState,useMemo,useEffect,useCallback} from 'react';
import { Grid,Icon } from '@mui/material';
import { ejecutaAPI } from '../../../../helpers/libFunciones';
import { useGlobalLoader } from '../../../../hooks/hooksPropios';
import * as permisos from '../../../../helpers/permisos';
import accessControl from "../../../../helpers/accessControl";
import {
  Portlet,
  PortletBody,
  PortletHeader
} from '../../../../partials/content/Portlet';
import ListadoPaginado from '../../../../componentes/generales/ListadoPaginado';
import { useModal } from './../../../../hooks/hooksPropios';
import Modal from '../../../utils/Modal';
import FichaLog from './FichaLog';
import moment from 'moment';

const ConfigLogs= (props) => {

 

  const [recargarTabla, setRecargarTabla] = useState(false);
  const [idLog,setIdLog]=useState(0);
  
  const [acciones,setAcciones]=useState([]);

  const {setGlobalCargando}=useGlobalLoader();

  const { handleOpenModal, open, handleCloseModal } = useModal();

  

  useEffect(()=>{

    setGlobalCargando(true);

    (async()=>{

      const peticionAcciones = ejecutaAPI('GET', `acciones_logs`);

      const [get_acciones] = await Promise.all([peticionAcciones]);


      if (get_acciones.success) {
  
        const arrayAcciones = get_acciones.respuesta.map((accion) => {
          return { id: accion, value: accion }
        });

         setAcciones(arrayAcciones);        
      }

    })();


    setGlobalCargando(false);

  },[setGlobalCargando]);

  const abrirLog = useCallback((idLog = "") => {

    handleOpenModal();

    setIdLog(idLog);

  }, [handleOpenModal])



  const columns = useMemo(() => (
    [
    
      {
        name: 'Acción',
        selector: 'accion',
        sortable: true,
        grid: {
          lg: 1
        }
      },
      {
        name: 'Usuario',
        selector: 'usuario',
        sortable: true,
        grid: {
          lg: 1
        }
      },
       {
        name: 'Info',
        selector: 'info',
        sortable: true,
        tooltip:true,
         grid: {
           lg: 5
         }
      }
      ,{
        name: 'Url',
        selector: 'url',
        sortable: true,        
        grid: {
          lg: 3
        }
      }    
      ,{
        name: 'Ip',
        selector: 'ip',
        sortable: true,         
        grid: {
          lg: 1
        }
        ,cell: (row) => <>           
          {<>{row.ip}&nbsp;&nbsp;&nbsp;<Icon className="fa fa-globe" onClick={()=>{window.open(`https://www.geolocation.com/es?ip=${row.ip}`,'_blank')}} color="action"></Icon></> }
          </>,
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        }       
      ,
      {
        name: 'Fecha',
        selector: 'fecha',
        sortable: true,
        grid: {
          lg: 1
        }
      },
      {
        name: '',        
        sortable: false,
        cell: (row) => <>
          {<Icon className="fa fa-eye" onClick={() => { abrirLog(row.id) }} color="action"></Icon>}
        </>,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      }
  
    ]
  ), [abrirLog]);



  const filtros = [

    {
      label: "Fecha desde",
      type: "fecha",
      name: "fechaDesde",
      opcionesExtra: { defaultValue: moment().format("DD/MM/YYYY")},
      grid: {
        lg: 2
      }
    },
    {
      label: "Fecha hasta",
      type: "fecha",
      name: "fechaHasta",
      opcionesExtra: { defaultValue: moment().format("DD/MM/YYYY") },
      grid: {
        lg: 2
      }
    },
    {
      label: "Usuario",
      type: "text",
      name: "usuario",            
      grid: {
        lg: 2
      }
    },
      {
        label: "Acciones",
        type: "select",
        opciones: acciones,
        name: "accion",           
        opcionesExtra: { defaultValue:"LOGIN", InputLabelProps:{ shrink: true } },
        grid: {
          lg: 2
        }
      },
    {
      label: "Info",
      type: "text",      
      name: "info",      
      grid: {
        lg: 2
      }
    }
  
  ];



  return (
   
      <Portlet>
        <PortletHeader
          title={<>
            Logs </>
          }          
        >
        </PortletHeader>
        <PortletBody>

          <Grid container>
              
              <Grid item xs={12}>                
                <ListadoPaginado
                  url={`logs`}
                  columns={columns}
                  title="Logs"
                  filtros={filtros}                  
                  recargarTabla={recargarTabla}
                  setRecargarTabla={setRecargarTabla}
                 />
        
              </Grid>

            <Modal maxWidth="md" titulo={`Log ${idLog}`} open={open} handleClose={handleCloseModal}>
              <FichaLog id={idLog}></FichaLog>              
            </Modal>


          </Grid>

            
        </PortletBody>

      </Portlet>
  );

}

export default accessControl([permisos.config.ver])(ConfigLogs);



