import { Box, Icon } from "@mui/material";
import { ListadoPaginadoColumns } from "app/componentes/generales/models/types";
import { useApiBorrarMetodoPago } from "app/hooks/api/metodosPago/useApiBorrarMetodoPago";
import { ListadoMetodosPagoResponse } from "app/hooks/api/metodosPago/useApiListadoMetodosPago";
import { useGlobalLoader } from "app/hooks/hooksPropios";
import Swal, { swalAviso } from "app/librerias/swal";
import { useCallback, useMemo, useState } from "react";
import { useModalAddMetodoPago } from "./useModalAddMetodoPago";

interface useListadoMetodosPagoProps {
    idDepartamento?: string;
}

export const useListadoMetodosPago = (props : useListadoMetodosPagoProps = {}) => {

    const [recargarTabla, setRecargarTabla] = useState(false);

    let propsModal = {
        ...props,
        recargarDatatable: () => setRecargarTabla(true)
    };

    const {abrirModalMetodoPago} = useModalAddMetodoPago(propsModal);

    const {setGlobalCargando} = useGlobalLoader();

    const { mutate } = useApiBorrarMetodoPago();

    const borrarMetodoPago = useCallback((id: string) => {

        swalAviso('Aviso', '¿Estás seguro de que quieres borrar este método de pago?', () => {

            setGlobalCargando(true);

            mutate(id, {
                onSuccess: () => setRecargarTabla(true),
                onError: (error) => {

                    Swal.fire('Error', error?.message ?? 'Se ha producido un error borrando el método de pago', 'error');
                },
                onSettled: () => setGlobalCargando(false)
            })
        })

    }, [mutate, setGlobalCargando])

    const columns: ListadoPaginadoColumns<ListadoMetodosPagoResponse>[] = useMemo(() => (
            [
                {
                    name: 'Nombre',
                    selector: 'nombre',
                    sortable: true,
                },
                {
                    name: 'Tipo',
                    selector: 'nombreTipo'
                },
                {
                    name: 'Activo',
                    cell: (row) => row.activo === "1" ? 'Sí' : 'No'
                },
                {
                    name: 'Fecha creación',
                    selector: 'fechaCreacion'
                },
                {
                    name: '',
                    cell: (row) => {

                        return (
                            <Box display="flex" alignItems="center" style={{gap: '8px'}}>
                                <Icon className="fa fa-edit" onClick={() => abrirModalMetodoPago(row.id)} color="action" />
                                <Icon className="fa fa-trash-alt" onClick={() => borrarMetodoPago(row.id)} color="action" />
                            </Box>
                        )
                    },
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                },
            ]
    ), [abrirModalMetodoPago, borrarMetodoPago]);

    return {
        columns,
        abrirModalMetodoPago,
        recargarTabla,
        setRecargarTabla
    }

}