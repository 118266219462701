import InfoIcon from "@mui/icons-material/Info";
import { FormControl, InputLabel } from "@mui/material";
import { FormAutocomplete } from "app/componentes/formularios/FormAutocomplete";
import { FormSelect } from "app/componentes/formularios/FormSelect";
import { FormTextInput } from "app/componentes/formularios/FormTextInput";
import TienePermisos from "app/helpers/TienePermisos";
import { pintaOpcionesSelect } from "app/helpers/libFunciones";
import { usuarios as permisosUsuarios } from "app/helpers/permisos";
import { roles } from "app/helpers/roles";
import { DatosCliente } from "app/hooks/api/clientes/useClientes";
import OpcionesUserMensajero from "app/pages/home/config/users/OpcionesUserMensajero/OpcionesUserMensajero";
import { TooltipG } from "app/pages/utils";
import { useFormContext, useWatch } from "react-hook-form";

interface CamposRolesProps {
	idUsuario: string;
	clientes: DatosCliente[];
}

export default function CamposRoles({ idUsuario, clientes = [] }: CamposRolesProps) {
	

	const {setValue} = useFormContext();

    const rolesUsuario = useWatch({name: 'idRol'});

	const idClienteSeleccionado = useWatch({name: 'idCliente'});

	const departamentosCliente = clientes.find(cliente => cliente.id === idClienteSeleccionado)?.departamentos ?? [];

	const onChangeCliente = () => setValue('idDepartamento', []);
	
	return (
		<>
			{rolesUsuario?.includes(roles.CLIENTE) && (
				<>
					<FormAutocomplete hooks name="idCliente" label="Cliente" opciones={clientes.map(cliente => ({id: cliente.id, value: cliente.razonSocial}))} onChangeCallback={onChangeCliente} />
					<FormControl sx={{width: '100%'}}>
						<div>
							<TooltipG
								title={"Si no se selecciona ningúna, el usuario podrá ver y crear envíos y recogidas de todas las sedes"}
							>
								<InfoIcon style={{ cursor: "pointer" }} />
							</TooltipG>
							<InputLabel variant="standard" style={{ marginLeft: "30px" }} id="idDepartamento" shrink={true}>
								Sedes
							</InputLabel>
						</div>
						<FormSelect
							hooks
							name="idDepartamento"
							variant="standard"
							multiple
							disabled={!TienePermisos([permisosUsuarios.editar]) && idUsuario !== "new"}
						>
							{pintaOpcionesSelect(departamentosCliente, "id", "nombre", "departamentos", 0)}
						</FormSelect>
					</FormControl>
				</>
			)}

			{
				rolesUsuario?.includes(roles.SUBAGENCIA) && (

					<FormAutocomplete hooks name="idClienteSubagencia" multiple label="Clientes de la agencia" opciones={clientes.map(cliente => ({id: cliente.id, value: cliente.razonSocial}))}  />

				)
			}

			{rolesUsuario?.includes(roles.COMERCIAL) && (
				<FormTextInput
					hooks
					type="number"
					inputProps={{ min: 0, max: 100, step: "0.01" }}
					name="comisionComercial"
					label="Comisión comercial (%)"
					variant="standard"
				/>
			)}

			<OpcionesUserMensajero  />
		</>
	);
}
