import { Box, Icon } from '@mui/material';
import { getContrastText } from 'app/helpers/color_helper';
import React from 'react';

const CategoriaElemento = ({data, width = "100%"}) => {

    const style = {
        display: 'flex',
        gap: '0.5rem',
        alignItems: 'center',
        bgcolor: data.color,
        justifyContent: 'center',
        minWidth: 'fit-content',
        width: width,
        padding: '0.25rem 0.8rem',
        borderRadius: '3px',
        fontSize: '0.8rem',
        color: getContrastText(data.color, '#1e1d22')
    }

    return ( 
        <Box sx={style}>
            <Icon style={{fontSize: '1.1rem'}}>
                {data.icono || null}
            </Icon>
        
            <Box sx={{ fontWeight: "500" }}>
                {data.nombre}
            </Box>
        </Box> 
     );
}
 
export default CategoriaElemento;