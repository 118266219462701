import React from 'react';
import { FormCheckboxFormik } from './Librerias/Formik/FormCheckboxFormik';
import { FormCheckboxRHF } from './Librerias/ReactHookForm/FormCheckboxRHF';

export const FormCheckbox = (props) => {

    const { hooks, ...otherProps } = props;


    return (
        !hooks ? 
        (
            <FormCheckboxFormik {...otherProps} />
        )
        :
        (
            <FormCheckboxRHF {...otherProps} />
        )
    )
};
 