import { Box, Icon } from "@mui/material";
import { ColumnDef } from "@tanstack/react-table";
import BotonDatatableBorrar from "app/componentes/botones/BotonDatatableBorrar";
import { useDatatable } from "app/componentes/tablas/TanstackTable/hooks/useDatatable";
import TienePermisos from "app/helpers/TienePermisos";
import { usuarios } from "app/helpers/permisos";
import { UsuarioListadoResponse } from "app/hooks/api/usuarios/useApiUsuarios";
import { Link } from "react-router-dom";

export const useDatatableUsuarios = () => {
	const columns: ColumnDef<UsuarioListadoResponse, any>[] = [
		{
			accessorKey: "user",
			header: "Usuario",
			enableSorting: true,
		},
		{
			accessorKey: "nombre",
			header: "Nombre",
			enableSorting: true,
		},
		{
			accessorKey: "apellidos",
			header: "Apellidos",
			enableSorting: true,
		},
		{
			accessorKey: "email",
			header: "Email",
			enableSorting: true,
		},
		{
			accessorKey: "telefono",
			header: "Teléfono",
			enableSorting: true,
		},
		{
			accessorKey: "ciudad",
			header: "Ciudad",
			enableSorting: true,
		},
		{
			accessorKey: "activo",
			header: "Activo",
			enableSorting: true,
			cell: ({ row }) => (row.original.activo === "1" ? "Sí" : "No"),
		},
		{
			accessorKey: "actions",
			header: "",
			enableSorting: false,
			enableHiding: false,
			meta: {
				width: "85px",
			},
			cell: ({ row }) => {
				let fila = row.original;

				return (
					<Box sx={{ display: "flex", gap: "10px", alignItems: 'center' }}>
						{
							<Link to={`/config/users/${fila.id}`}>
								<Icon className="fa fa-edit" color="action" />
							</Link>
						}
						{TienePermisos([usuarios.eliminar]) && (
							<BotonDatatableBorrar to={`/users/${fila.id}`} invalidationKeys={["usuarios"]}>
								<Icon className="fa fa-trash-alt" color="action" />
							</BotonDatatableBorrar>
						)}
					</Box>
				);
			},
		},
	];

	const { table, data, isFetching, paginationState, filtrosState } = useDatatable({
		url: "users",
		columns,
		queryKey: ["usuarios"],
	});

	return {
		table,
		data,
		isFetching,
		paginationState,
		filtrosState,
	};
};
