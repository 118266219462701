import { Grid } from "@mui/material";
import { FormTextInput } from "app/componentes/formularios/FormTextInput";
import { useRoles } from "app/hooks/hooksPropios";
import { useWatch } from "react-hook-form";

interface CamposPasswordProps {
    id: string;
}

export default function CamposPassword({id} : CamposPasswordProps) {

    const { ADMIN, MULTILOGIN, esAdmin, esMultiLogin } = useRoles();

    const rolesUsuario = useWatch({name: 'idRol'}) as string[];

    let mostrarPassword = true;
    //Evitar que un usuario que no es admin pueda editar la contraseña de un admin/multilogin
    if (rolesUsuario && rolesUsuario.length > 0 && id !== "new" && !esAdmin && !esMultiLogin) {
        mostrarPassword = !rolesUsuario.some((valorRol) => parseInt(valorRol) === ADMIN || parseInt(valorRol) === MULTILOGIN);
    }

    return (
        mostrarPassword ? (
            <>
                <Grid item xs={12} lg={4}>
                    <FormTextInput
                        hooks
                        label="Password"
                        type="password"
                        name="password"
                    />
                </Grid>

                <Grid item xs={12} lg={4}>
                    <FormTextInput
                        hooks
                        label="Repetir Password"
                        type="password"
                        name="repetir_pass"
                    />
                </Grid>
            </>
        ) : null
    )

}