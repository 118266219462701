import { Pagination } from '@mui/material';
import { useCallback } from 'react';

const PaginationWrapper = ({pagina, ultimaPagina, irPagina, setPaginaActual, siguiente, anterior, ...props}) => {

    const handleChangePagina = useCallback((e, pagina) => {
        irPagina(pagina);
    }, [irPagina])

    const stylePag={marginTop:"10px",marginBottom:"10px"};

    return ( 
        <>
            <Pagination 
                style={stylePag}
                count={ultimaPagina} 
                size="medium" 
                page={pagina} 
                variant="text" 
                shape="circular"
                onChange={handleChangePagina}
                {...props}
            />
        </>
     );
}
 
export default PaginationWrapper;