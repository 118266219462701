import { FormTimepickerFormik } from "./Librerias/Formik/FormTimepickerFormik";
import { FormTimepickerRHF } from "./Librerias/ReactHookForm/FormTimepickerRHF";

export const FormTimepicker = (props) => {

    
    const { hooks, ...otherProps } = props;


    return (
        !hooks ? 
        (
            <FormTimepickerFormik {...otherProps} />
        )
        :
        (
            <FormTimepickerRHF {...otherProps} />
        )
    )

}
 
