import { Datatable } from "app/componentes/tablas/TanstackTable/Datatable";
import TienePermisos from "app/helpers/TienePermisos";
import { usuarios as permisosUsuarios } from "app/helpers/permisos";
import FiltrosListadoUsuarios from "app/modules/usuarios/components/listado/FiltrosListadoUsuarios/FiltrosListadoUsuarios";
import { useDatatableUsuarios } from "app/modules/usuarios/hooks/useDatatableUsuarios";
import { BotonAdd } from "app/pages/utils";
import { Portlet, PortletBody, PortletHeader } from "app/partials/content/Portlet";

export default function ListadoUsuariosPage() {

    const {data, table, isFetching, paginationState, filtrosState} = useDatatableUsuarios();

	return (
		<Portlet>
			<PortletHeader
				title={"Usuarios"}
				toolbar={TienePermisos([permisosUsuarios.crear]) ? <BotonAdd link="/config/users/new" name="Nuevo Usuario" /> : null}
			/>
			<PortletBody>
				<Datatable  
                    data={data}
                    table={table}
                    isFetching={isFetching}
                    paginationState={paginationState}
					filtrosState={filtrosState}
					filterComponent={<FiltrosListadoUsuarios />}
                />
			</PortletBody>
		</Portlet>
	);
}
