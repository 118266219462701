import { useQuery } from "@tanstack/react-query";
import { ejecutaAPI } from "../../../helpers/libFunciones";
import { useDataArray } from "../../useDataArray";


export const getTarifas = async (filtros = {}) => {


    let parametros = new URLSearchParams(filtros).toString();

    if (parametros)
    {
        parametros = `&${parametros}`;
    }

    const {success, respuesta, error} = await ejecutaAPI('GET', `/tarifas?per_page=-1${parametros}`);

    if (success)
    {
        return respuesta;
    }
    else 
    {
        throw new Error(error);
    }
}

export const useListadoTarifas = (filtros = {}, opts = {}) => {


    const query = useQuery(['tarifas', filtros], () => getTarifas(filtros), opts);

    const listadoTarifas = useDataArray(query.data);



    return {
        ...query,
        listadoTarifas
    }
}