import { makeStyles } from "@mui/styles";


export const useStyles = makeStyles(theme => {
    

    return {
        
        estadoEnvio: {
            fontSize: '17px',
            color: theme.palette.primary.main,
            fontWeight: '500'
        }

  }
});