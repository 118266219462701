import { Box, TextField } from "@mui/material";
import { useFormikContext } from "formik";
import { capitalize, isFunction } from "lodash";
import moment from "moment";
import "moment/locale/es";
import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { pintaOpcionesSelect } from "../../helpers/libFunciones";
import DatepickerP from "../generales/DatepickerP";

const options_FECHAS = () => {
    moment.locale("es");
    let hoy = moment();
    let opc = [];

    let mes = capitalize(moment().format("MMMM"));
    let mes_pasado = capitalize(moment().subtract(1, "month").format("MMMM"));

    opc.push({ nombre: `Hoy (${hoy.format("L")})`, value: "hoy" });
    opc.push({ nombre: `Ayer (${hoy.subtract(1, "days").format("L")})`, value: `ayer` });
    opc.push({ nombre: `Esta semana`, value: `semana` });
    opc.push({ nombre: `Semana pasada`, value: `semana_pasada` });
    opc.push({ nombre: `Este mes (${mes})`, value: `mes` });
    opc.push({ nombre: `Mes pasado (${mes_pasado})`, value: `mes_pasado` });

    return opc;
};

export const calculaFechas = (opcion) => {
    let fec = moment();
    let fechaDesde = "";
    let fechaHasta = "";

    switch (opcion)
    {
        default:
        case "hoy":
            fechaDesde = fec.format("YYYY-MM-DD");
            fechaHasta = fechaDesde;
            break;

        case "ayer":
            fechaDesde = fec.subtract(1, "days").format("YYYY-MM-DD");
            fechaHasta = fechaDesde;
            break;

        case "semana":
            fechaDesde = fec.startOf("week").format("YYYY-MM-DD");
            fechaHasta = fec.endOf("week").format("YYYY-MM-DD");
            break;

        case "semana_pasada":
            fechaDesde = fec.subtract(1, "weeks").startOf("week").format("YYYY-MM-DD");
            fechaHasta = fec.endOf("week").format("YYYY-MM-DD");
            break;

        case "mes":
            fechaDesde = fec.format("YYYY-MM-01");
            fechaHasta = fec.endOf("month").format("YYYY-MM-DD");
            break;

        case "mes_pasado":
            fechaDesde = fec.subtract(1, "months").format("YYYY-MM-01");
            fechaHasta = fec.endOf("month").format("YYYY-MM-DD");
            break;
    }

    return { fechaDesde, fechaHasta };
};

export const FormBusquedaFechas = (props) => {
    const { name, label, InputProps, p, init, mostrarOpciones, onChange, title, sx } = props;

    const { values, setFieldValue } = useFormikContext();
    const opciones = options_FECHAS();

    useEffect(() => {
        if (init)
        {
            setFieldValue(`${name}Desde`, moment().format("DD/MM/YYYY"));
            setFieldValue(`${name}Hasta`, moment().format("DD/MM/YYYY"));
            setFieldValue(`${name}_opcion`, "hoy");
        }
    }, [name, init, setFieldValue]);

    return (
        <Box display="flex" flexWrap={isMobile ? "wrap" : "nowrap"} sx={{ width: "100%", ...sx }}>
            {(mostrarOpciones == undefined || mostrarOpciones == true)
                && (
                    <Box p={p}>
                        <TextField
                            variant="outlined"
                            InputProps={InputProps}
                            select
                            style={{ width: "190px" }}
                            label={label}
                            name={`${name}_opcion`}
                            value={values[`${name}_opcion`]}
                            defaultValue="hoy"
                            onChange={(e) => {
                                let { fechaDesde, fechaHasta } = calculaFechas(e.target.value);
                                setFieldValue(`${name}_opcion`, e.target.value);
                                setFieldValue(`${name}Desde`, moment(fechaDesde).format("DD/MM/YYYY"));
                                setFieldValue(`${name}Hasta`, moment(fechaHasta).format("DD/MM/YYYY"));
                                isFunction(onChange) && onChange();
                            }}
                        >
                            {pintaOpcionesSelect(opciones, "value", "nombre")}
                        </TextField>
                    </Box>
                )}

            <Box p={p}>
                <DatepickerP
                    onAccept={e => {
                        setFieldValue(`${name}Desde`, moment(e).format("DD/MM/YYYY"));
                        isFunction(onChange) && onChange();
                    }}
                    style={{ minWidth: "100px" }}
                    InputProps={InputProps}
                    value={values[`${name}Desde`]}
                    label={`Desde ${(title != undefined) ? title : ""}`}
                >
                </DatepickerP>
            </Box>
            <Box p={p}>
                <DatepickerP
                    onAccept={e => {
                        setFieldValue(`${name}Hasta`, moment(e).format("DD/MM/YYYY"));
                        isFunction(onChange) && onChange();
                    }}
                    style={{ minWidth: "100px" }}
                    InputProps={InputProps}
                    value={values[`${name}Hasta`]}
                    label={`Hasta ${(title != undefined) ? title : ""}`}
                >
                </DatepickerP>
            </Box>
        </Box>
    );
};
