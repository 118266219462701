import { Box } from '@mui/material';
import { Children, ReactNode } from 'react';

interface AccionesPaginaProps {
    children: ReactNode;
    mb?: string | number;
    justifyContent?: string;
}

const AccionesPagina = ({children, mb = "15px", justifyContent = "flex-end"} : AccionesPaginaProps) => {


    return ( 
        <Box width={1} mb={mb}>
            <Box display="flex" justifyContent={justifyContent}>
                {
                    Children.map(children, child => (
                        <Box mr="2.5px" ml="2.5px">
                            {child}
                        </Box>
                        )
                    )
                }
            </Box>
        </Box>
     );
}
 
export default AccionesPagina;