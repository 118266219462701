export const styleThumbsContainer = {
    display:"flex",
    marginTop: "15px",
    flexWrap: "wrap"
}

export const styleThumb = {
    "display": "flex",
    "flexDirection": "column",
    "width": "125px",
    "minWidth": "125px",
    "margin": "5px",
    "boxShadow": "1px 0 3px #00000040"
}

export const styleThumbImageContainer = {
    "width": "100%",
    "height": "100px",
    "flex": "1",
    "padding": "5px"
}
export const styleImage = {
    width: "100%",
    height: "100%"
}

export const styleActionsContainer = {
    display: "flex",
    '& .action': {
        "flex": "1",
        "display": "flex",
        "alignItems": "center",
        "justifyContent": "center",
        "padding": "3px",
        "background": "#444444",
        cursor: "pointer",
        "color": "white",
        transition: "0.2s",
        "&.ver": {
            
            "&:hover": {
                background: "#3b96e4",
                transition: "0.2s",
            },
            "& a": {
                "width": "100%",
                "display": "flex",
                "justifyContent": "center",
                "color": "white",
                "&:hover": {
                    color:"white"
                }
            } 
            
        },
        "&.borrar:hover": {
            background: "#e43b3b",
            transition: "0.2s"
        }
    }
}

export const styleNombreFichero = {
    float: "left", 
    whiteSpace: "nowrap",
    textOverflow: "ellipsis", 
    overflow: "hidden",
    padding:"5px"
}