import TimepickerP from "app/componentes/generales/TimepickerP";
import { format, parse } from "date-fns";
import { get } from "lodash";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useStyles } from "../../css/css";

export const FormTimepickerRHF = ({ name, onChange = null, clase, margin = "normal", ...props }) => {
	const [error, setError] = useState(false);

	const { control, setValue, formState } = useFormContext();

	const { errors } = formState;

	const errores = get(errors, name);

	const classes = useStyles();

	const handleError = () => {
		if (error) {
			return true;
		} else {
			return Boolean((errores && errores.message) || "");
		}
	};

	const handleHelperText = () => {
		if (error) {
			return "Formato incorrecto";
		} else {
			return (errores && errores.message) || "";
		}
	};

	return (
		<Controller
			name={name}
			control={control}
			defaultValue={null}
			render={({ field }) => {
				var value = null;

				if (field.value) {
					var valorParsear = field.value.split(":");

					valorParsear = `${valorParsear[0]}:${valorParsear[1]}`;

					value = parse(valorParsear, "HH:mm", new Date());
				}

				var propsDefault = {
					name: name,
					value: value,
					onChange: (date, error) => {
						if (error.validationError === null) {
							setError(false);

							let string = null;

							if (date !== null) {
								string = format(date, "HH:mm");
							}

							setValue(name, string);

							if (typeof onChange === "function") {
								onChange(date, field.value);
							}
						} else {
							setError(true);
						}
					},
					InputProps: {
						helperText: handleHelperText(),
						error: handleError(),
						className: classes[clase] || null,
						margin: margin,
					},
				};

				return <TimepickerP {...propsDefault} {...props} />;
			}}
		/>
	);
};
