
import ListadoMetodosPago from "app/modules/metodospago/components/ListadoMetodosPago/ListadoMetodosPago";
import { Portlet, PortletBody, PortletHeader } from "app/partials/content/Portlet";



export default function ConfigMetodosPago() {

    
    return (
        <Portlet>
            <PortletHeader goBack={"/configuracion"} title={"Configuración de métodos de pago"} />
            <PortletBody>
                <ListadoMetodosPago />
            </PortletBody>
        </Portlet>
    )

}