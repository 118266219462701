import { TextField } from '@mui/material';
import { useField } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { useDelta } from 'react-delta';
import { TooltipG } from '../../../../pages/utils';
import { useStyles } from '../../css/css';

export const FormTextInputFormik = ({hooks, name, tooltip, onChange, onBlur, fast = false, clase = null, ...props}) => {


    const classes = useStyles();
    
    const [campo, infoCampo] = useField(name);

    const [local, setEstadoLocal] = useState(campo.value || '');

    const valorFormik = useDelta(campo.value);

    var valueUsar = (campo.value === 0 || campo.value) ? campo.value : '';

    if (fast)
    {
        valueUsar = local;
    }

    const formikChange = campo.onChange;

    const formikBlur = campo.onBlur;

    var propsDefault = useMemo(() => ({
        name:name,
        value: valueUsar,
        variant:"outlined",
        size:"small",
        margin: "normal",
        InputProps:{autoComplete:'new-password', className: classes[clase] || null},
        className:"kt-width-full",
        onChange:(e) => {

            if (fast)
            {
                setEstadoLocal(e.target.value); //Actualiza en local.
            }
            else 
            {
                formikChange(e); //Actualiza el estado en el formik
            }

            if (typeof onChange === "function")
            {
                onChange(e, valueUsar);
            }
        },
        onBlur:(e) => {

            if (fast)
            {
                formikChange(e);
            }

            formikBlur(e);

            if (typeof onBlur === "function")
            {
                onBlur(e);
            }
        },
        helperText:infoCampo.touched && infoCampo.error,
        error: Boolean(infoCampo.touched && infoCampo.error)
    }), [valueUsar, clase, classes, fast, name, onBlur, onChange, formikChange, formikBlur, infoCampo.error, infoCampo.touched]);

    useEffect(() => {

        if (fast)
        {
            
            if (valorFormik !== null)
            {
                if (valorFormik.curr !== local)
                {
                    
                    setEstadoLocal(valorFormik.curr || "");
                }
            }

        }

    }, [valorFormik, fast, local]);

    return ( 

        tooltip !== undefined 
            ? 
            (
                <TooltipG title={tooltip}>
                    <TextField {...propsDefault}  {...props} />
                </TooltipG>
            )
            :
            (
                <TextField {...propsDefault} {...props} />
            )
     )
};