import React from 'react';
import { Redirect } from 'react-router-dom';
import { useRoles } from '../../hooks/hooksPropios';

const ControlAcceso = ({roles, redirect = true, children}) => {

    const {esRol} = useRoles();

    var permitirAcceso = esRol(roles);


    return (  
        permitirAcceso 
        ?
        (
            <>
                {children}
            </>
        )
        :
        (
            redirect
            ?
            (
                <Redirect to="/dashboard"/>
            )
            :
            (
                null
            )
        )
    );
}
 
export default ControlAcceso;