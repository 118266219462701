import AppsIcon from '@mui/icons-material/Apps';
import ListIcon from '@mui/icons-material/List';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import TextRotateUpIcon from '@mui/icons-material/TextRotateUp';
import TextRotationDownIcon from '@mui/icons-material/TextRotationDown';
import { Box, Button, Grid } from '@mui/material';
import PaginationWrapper from 'app/componentes/generales/PaginationWrapper/PaginationWrapper';
import { usePaginacion } from 'app/hooks/hooksPropios';
import { useWS } from 'app/hooks/useWS';
import FilaTrafico from 'app/modules/trafico/components/listado/FilaTrafico/FilaTrafico';
import TarjetaTrafico from 'app/pages/home/trafico/components/TarjetaTrafico/TarjetaTrafico';
import { TooltipG } from 'app/pages/utils';
import Modal from 'app/pages/utils/Modal';
import { isMobile } from 'react-device-detect';
import { useEffectOnce } from 'react-use';




const ListadoAlertasTrafico=()=>{

    
    const {connect,open,setOpen,listadoTrafico,filtrosTrafico,pag,mensajeros,estados,handleSetMensajero,handleSetTerminado,handleSetComprobado,dispatch,handleSetVista,handleSetOrden,handleReset} = useWS();
            
    const { datosPaginados, ...propsPaginacion } = usePaginacion(listadoTrafico, 12, pag, (pag) => { dispatch({ type: "SET_PAGINA", payload: pag }) });

    //Conectamos con el socket

    useEffectOnce(() => {
        connect();
    })
       
    return(
        
        <Modal fullscreen={(isMobile) ? true : false} maxWidth="lg" titulo="Nuevos Envíos/Recogidas" open={open} handleClose={() => { setOpen(false); }}>

            {open && <>

                <Grid container>

                    <Grid container direction="row" justifyContent="flex-end" alignItems="baseline">

                        
                        <Grid item lg={8} xs={12} style={{ textAlign: "right" }} >
                      
                            <Button color="primary" onClick={() => handleReset()} startIcon={<RotateLeftIcon />}>Resetear alertas</Button>
                        
                        </Grid>

                        <Grid item lg={4} xs={12} style={{ textAlign: "right", maxWidth: "300px" }}>
                            <Button size="small" style={{ marginLeft: "20px", marginRight: "5px" }} variant="outlined" color={filtrosTrafico.vista == "recogidaEnvios" ? "secondary" : "primary"} onClick={() => { handleSetVista("recogidaEnvios") }}>
                                <AppsIcon />
                            </Button>

                            <Button style={{ marginRight: "5px" }} size="small" variant="outlined" color={filtrosTrafico.vista == "recogidaEnvios_lista" ? "secondary" : "primary"} onClick={() => { handleSetVista("recogidaEnvios_lista") }}>
                                <ListIcon />
                            </Button>
                       
                            <TooltipG title={filtrosTrafico.orden == "fecha" ? "Orden por fecha" : "Orden alfabético agrupado recogida/envios"}>
                                <Button style={{ marginRight: "5px" }} size="small" variant="outlined" color={filtrosTrafico.orden == "fecha" ? "secondary" : "primary"} onClick={() => { handleSetOrden((filtrosTrafico.orden == "fecha") ? "envio_recogida" : "fecha") }}>
                                    {filtrosTrafico.orden == "fecha" ? <TextRotationDownIcon /> : <TextRotateUpIcon />}
                                </Button>
                            </TooltipG>

                        </Grid>

                    </Grid>


                    <Grid item xs={12}>
                    <Box component="div" style={{ marginLeft: "12px" }} fontWeight={500}>
                            Mostrando {datosPaginados.length} registro de {listadoTrafico.length} registros totales </Box>
                    </Grid>
                    
                    <Grid item xs={12}>
                        <PaginationWrapper {...propsPaginacion} />
                    </Grid>
                </Grid>                
          
                <Grid container>
                {
                    datosPaginados.map((fila) => {

                        switch (filtrosTrafico.vista) {

                            case "estadisticas":
                            case "recogidaEnvios":
                                return (
                                    <TarjetaTrafico
                                        key={`fila_${fila.tipo}_${fila.id}`}
                                        mensajeros={mensajeros}
                                        estados={estados}
                                        datos={fila}                                                                                
                                        setMensajero={handleSetMensajero}
                                        setTerminado={handleSetTerminado}
                                        setComprobacion={handleSetComprobado}
                                    />
                                );
                            case "recogidaEnvios_lista":
                                return (
                                    <FilaTrafico
                                        key={`fila_${fila.tipo}_${fila.id}`}
                                        estados={estados}
                                        mensajeros={mensajeros}
                                        setMensajero={handleSetMensajero}
                                        setComprobacion={handleSetComprobado}
                                        setTerminado={handleSetTerminado}                                                                                
                                        datos={fila} />

                                );
                            default:

                                return null;

                        }

                    })
                }
                </Grid>
                <Grid container>
                    <PaginationWrapper {...propsPaginacion} />
                </Grid>
            </> }
            
        </Modal>           

    );


}

export default ListadoAlertasTrafico;