import { useEtiquetasElementoQuery } from "app/hooks/api/etiquetas/useEtiquetasElementoQuery";
import { isArray } from 'lodash';

const CargadorEtiquetas = ({id, tipo, categoriasIniciales = undefined, enabled = false, children}) => {

    const {etiquetasElemento, isInitialLoading: isLoading} = useEtiquetasElementoQuery(id, tipo, {
        initialData: isArray(categoriasIniciales) ? categoriasIniciales : undefined,
        enabled: enabled
    });


    return children({etiquetasElemento, isLoading})
     
}
 
export default CargadorEtiquetas;