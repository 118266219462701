import { VISTAS_MODALES } from "app/componentes/modalesComunes/ModalesApp";
import { useModalContextAction } from "app/contexts/useModalContext";
import { useCallback } from "react";

export interface useModalAddMetodoPagoProps {
    idDepartamento?: string;
    recargarDatatable?: () => void;
}

export const useModalAddMetodoPago = (props : useModalAddMetodoPagoProps) => {

    const {openModal} = useModalContextAction();

    const abrirModalMetodoPago = useCallback((id?: string) => {

        let propModal = {...props, id};

        openModal(VISTAS_MODALES.ADD_METODO_PAGO, propModal, {titulo: id ? 'Editar método de pago' :'Crear método de pago', maxWidth: 'sm'});

    }, [openModal, props])

    return {
        abrirModalMetodoPago
    }

}