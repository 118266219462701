import React from "react";
//import SearchDropdown from "../../../app/partials/layout/SearchDropdown";
/*import UserNotifications from "../../../app/partials/layout/UserNotifications";*/
/*import MyCart from "../../../app/partials/layout/MyCart";
import QuickActionsPanel from "../../../app/partials/layout/QuickActionsPanel";
import QuickPanelToggler from "./QuickPanelToggle";
import LanguageSelector from "../../../app/partials/layout/LanguageSelector";*/
import UserProfile from "../../../app/partials/layout/UserProfile";
import AlertsUser from "../../../app/partials/layout/AlertsUser";
//import { toAbsoluteUrl } from "../../utils/utils";


export const Topbar=()=>{

 
    return (
      <div className="kt-header__topbar kt-grid__item">
        
        {/* //Buscar <SearchDropdown useSVG="true"  /> */}

        {/*<UserNotifications
          bgImage={toAbsoluteUrl("/media/misc/bg-1.jpg")}
          pulse="true"
          pulseLight="false"
          skin="dark"
          iconType=""
          isLabel={false}
          type="success"
          useSVG="true"
          dot="false"
    />*/}

        {/* <QuickActionsPanel
          bgImage={toAbsoluteUrl("/media/misc/bg-2.jpg")}
          skin="light"
          iconType=""
          useSVG="true"
          gridNavSkin="light"
        /> */}

    
         {/*  <QuickPanelToggler /> */}

        {/* <LanguageSelector iconType="" /> */}

        <AlertsUser></AlertsUser>

        <UserProfile showHi={true} showBadge={true} />
      </div>
    );
  
}
