import { put, takeLatest,call } from "redux-saga/effects";




export const actionTypes = {
  LISTADO:"[Listado Users]",  
};

const initialState = {
  users: [],  
};

export const reducer = 
    (state = initialState, action) => {
      switch (action.type) {
        case actionTypes.LISTADO: {
          
          return {...action.payload};
        }
       
        default:
          return state;
      }
    }


export const actions = {
  listado: (payload) => ({ type: actionTypes.LISTADO, payload })  
};

//Watchers
export function* saga() {
  yield takeLatest(actionTypes.LISTADO, function* listadoSaga() {});
  
}

