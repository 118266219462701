
import { useEffect } from 'react';
import { useFormState, useWatch } from 'react-hook-form';

const AutoSubmit = ({formRef}) => {

    const {isValid, isDirty} = useFormState();

    const values = useWatch();


    useEffect(() => {

        if (isValid && isDirty)
        {
            formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
        }

    },[isValid, isDirty, values, formRef])

    return null;

}

export default AutoSubmit;