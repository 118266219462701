import { Button, CircularProgress } from '@mui/material';


const BotonGuardar = ({children, loading = false, ...props}) => {
    return ( 

        <Button type="submit" color="primary" disabled={loading} variant="contained" {...props}>
            { loading && <CircularProgress style={{marginRight: '5px'}} size={12} color="inherit" />}
            {children}
        </Button>

     );
}
 
export default BotonGuardar;