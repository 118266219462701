
import { obtenerDatosValorarEnvios } from 'app/modules/envios/helper/envios.helper';
import { cloneDeep, isEmpty, union } from 'lodash';
import { useCallback } from 'react';
import { IVA, IVA_REF, TIPOS_REFERENCIAS } from '../../../helpers/constants';
import { ejecutaAPI, muestraPrecio, transformaObjectToArray } from '../../../helpers/libFunciones';
import { calculaPrecios } from '../../../pages/home/tarifas/FuncionesTarifas';
import { FuncionesVariables, filtraServiciosVinculados } from './../VariablesRed/FuncionesVariables';


const { calculaVariables } = FuncionesVariables();


export const FuncionesValoraciones = ( idCliente,  manual,  handleClickMsg, setSubmit,setManual,impuestos) => {
     
    const calculosValoraciones = useCallback((referencias_filtradas, objRed, tarifa, idRuta, idServicio, portes, valoraciones) => {

        var nuevasValoraciones = [];

        referencias_filtradas && referencias_filtradas.forEach(referencia => {

          
            let margenRed = 0;            

            if (objRed) {

                margenRed = objRed.red.margen_defecto;

                var clienteRed = objRed.clienteRed;

                var impuestoCliente = impuestos?.find(i => i.referencia == clienteRed.idTipoImpuesto);

                var tipoIVA = impuestoCliente !== undefined ? impuestoCliente.referencia : IVA_REF;                

                //Sacamos el servicio              
                let datosServicio = false;

                let servicios = objRed.servicios_tarifa_asociada || [];

                if (portes !== "pagados" && objRed.tarifa_debidos && objRed.tarifa_debidos.id !== undefined) {

                    servicios = objRed.servicios_tarifa_debidos;
                    
                }

                datosServicio = servicios?.find(i => i.id === idServicio) || false;

                if (datosServicio)
                {

                    //Mirar si la ruta tiene IVA.                              
                    let servicioRef = datosServicio.referencias?.find(ref => ref.id === referencia.idReferencia);

                    if (servicioRef)
                    {

                        servicioRef.rutas.forEach(i => {

                            if (i.idRuta === idRuta && i.tipoIVA != "") {     

                                let impuestoRuta = impuestos?.find(im => im.referencia == i.tipoIVA)?.referencia;
                                
                                tipoIVA = impuestoRuta != undefined ? impuestoRuta : tipoIVA;

                            }
                        });
                    }

                    //Sacamos de la tarifa, los datos del servicio de la tarifa a usar

                    if (tarifa?.tarifas_servicios) {

                        let tarifaServicio = tarifa.tarifas_servicios.find(tariServ => tariServ.idServicio === idServicio && tariServ.activo !== "0") || false;

                        if (tarifaServicio)
                        {
                            if (tarifaServicio.margen <= 0)
                            {
                                tarifaServicio.margen = tarifa.margen;
                            }

                            //Tenemos que ver si pasamos los bultos o los kilos segun el tipo de referencia.
                            let cantidad = 0;
                            let tipo_calculo = "";
                            let resumen_cantidad = "";
                            let unidad = "";

                            if (parseInt(referencia.idTipo) === TIPOS_REFERENCIAS.KILO) {

                                cantidad = parseFloat(referencia.peso);

                                resumen_cantidad = `${muestraPrecio(cantidad)} Kg`;

                                unidad = "Kg";

                            } else {

                                cantidad = parseInt(referencia.idTipo) === TIPOS_REFERENCIAS.VARIABLE ? parseInt(referencia.variable) : parseInt(referencia.bultos);
  
                                resumen_cantidad = `${cantidad} Ud`;

                                unidad = "Ud";

                            }

                            let valoracion = calculaPrecios(referencia.idReferencia, margenRed, idRuta, datosServicio, tarifaServicio, cantidad);

                            //insertamos valoraciones
                            if (valoracion) {
                                //Insertamos la primera valoracion
                                
                                if (valoracion.tipoCalculo === "valor") {
                                    tipo_calculo = `(Calc. por valor ${valoracion.valorCalculado})`;
                                }

                                //Creamos la info
                                let infoValoracion = `(${resumen_cantidad}) &nbsp; <b>Franja:</b> ${valoracion.franja} (${valoracion.margenCalculado}%) ${tipo_calculo} `;

                                //Buscamos en valoraciones la que habia antes, por si han cambiado campos de texto.                        
                                let val_anterior = valoraciones.find(i => i.idRef === referencia.idReferencia && i.idServicio === datosServicio.id && (i.idVariable == "0" || i.idVariable == null) && (i.adicional == "0" || i.adicional == null));

                                let datoReferencia = referencia.referencia;

                                if (val_anterior)
                                {
                                    
                                    if (val_anterior.ref != undefined && val_anterior.ref != "")
                                    {
                                        datoReferencia = val_anterior.ref;
                                    }
                                    else if (val_anterior.referencia != undefined)
                                    {
                                        datoReferencia = val_anterior.referencia;
                                    }
                                }


                                let valoracionInsertar = {
                                    
                                    idRef: referencia.idReferencia,
                                    idServicio: datosServicio.id,
                                    servicio: val_anterior ? val_anterior.servicio : datosServicio.servicio,
                                    ruta: valoracion.ruta,
                                    ref: datoReferencia,
                                    coste: muestraPrecio(valoracion.valorServicio),
                                    precio: muestraPrecio(valoracion.valorCalculado),
                                    info: infoValoracion,
                                    infoSinCoste: infoValoracion,
                                    idVariable: "0",
                                    adicional: "0",
                                    error: valoracion.error,
                                    tipoIVA: tipoIVA                             
                                    
                                };

                                //Si es de tipo variable, en servicio, en vez del nombre, pondremos la ref
                                if (parseInt(referencia.idTipo) === TIPOS_REFERENCIAS.VARIABLE)
                                {
                                    valoracionInsertar.servicio = valoracionInsertar.ref;
                                }

                                if (!valoracion.mostrarSoloAdc)
                                {
                                    nuevasValoraciones.push(valoracionInsertar);
                                }

                                tipo_calculo = "";

                                if (valoracion.tipoCalculoAdc === "valor") {
                                    tipo_calculo = `(Calc. por valor ${valoracion.valorCalculadoAdc})`;
                                }

                                //Creamos la info
                                let peso_adc = parseFloat(cantidad - parseInt(valoracion.franja));

                                if (!Number.isSafeInteger(peso_adc)) {
                                    peso_adc = parseInt(peso_adc) + 1;
                                }

                                infoValoracion = `${muestraPrecio(peso_adc)} ${unidad} a ${valoracion.valorCalculadoAdc}€ &nbsp;&nbsp;${tipo_calculo} `;

                                //Si tiene valores de franjaAdicional.
                            
                                if (valoracion.valorCalculadoAdc > 0 || valoracion.errorAdc != "") {

                                    let val_anterior_adc = valoraciones.find(i => i.idRef === referencia.idReferencia && i.idServicio === datosServicio.id && (i.idVariable == "0" || i.idVariable == null) && i.adicional == "1");

                                    let datoReferenciaAdc = referencia.referencia;

                                    if (!datoReferenciaAdc)
                                    {
                                        let valoracionRelacionadaExistente = nuevasValoraciones.filter(i => i.idRef === referencia.idReferencia && i.idServicio === datosServicio.id && (i.idVariable == "0" || i.idVariable == null));
                                        
                                        if (valoracionRelacionadaExistente.length == 1)
                                        {
                                            datoReferenciaAdc = valoracionRelacionadaExistente[0].ref;
                                        }
                                        
                                    }

                                    let valoracionInsertarAdc = {
                                        idRef: referencia.idReferencia,
                                        idServicio: datosServicio.id,
                                        servicio: val_anterior_adc ? val_anterior_adc.servicio : `${datosServicio.servicio} (Adicional)`,
                                        ruta: valoracion.ruta,
                                        ref: val_anterior_adc && val_anterior_adc?.ref != "" ? val_anterior_adc.ref : `${datoReferenciaAdc} Adc.)`,
                                        coste: muestraPrecio(parseFloat(peso_adc * valoracion.valorServicioAdc)),
                                        precio: muestraPrecio(parseFloat(peso_adc * valoracion.valorCalculadoAdc)),
                                        info: infoValoracion,
                                        infoSinCoste: infoValoracion,
                                        idVariable: "0",
                                        adicional: "0",
                                        error: valoracion.errorAdc,
                                        tipoIVA: tipoIVA                             
                                        
                                    };

                                    if (parseInt(referencia.idTipo) === TIPOS_REFERENCIAS.VARIABLE)
                                    {
                                        valoracionInsertarAdc.servicio = valoracionInsertarAdc.ref;
                                    }

                                    nuevasValoraciones.push(valoracionInsertarAdc);

                                }
                            }
                        }
                    }
                }
            }




        });

        return nuevasValoraciones;
    },[impuestos]);



    /* Calcula los precios segun el pesp/bultos servicio y tarifa*/
    const calculaValoraciones = useCallback((valoraciones, referencias, redes, idRed, idServicio, idRuta, portes, variables, referenciasVariables,manual, tarifaForzada = null) => {
   

        var valoraciones_referencias = [];

        var valoraciones_variables_referencias = [];

        var valoraciones_new = [];

        var variablesEnvio = [];

        //Unificamos referencias con el mismo idReferencia
        var referencias_filtradas = [];
        var referencias_variables_filtradas = [];

        var redes_arr = redes.redes ? redes.redes : redes;

        var tarifa = {};

        var objRed = redes_arr?.find(item => item.red.id === idRed);       

        if (!objRed)
        {
            objRed = redes_arr?.find(item => item.red.idRed === idRed); 
        }

        if (objRed)
        {

            if (tarifaForzada && !isEmpty(tarifaForzada))
            {
                tarifa = tarifaForzada;
            }
            else 
            {
                if (portes === "pagados") {
                
                    tarifa = objRed.tarifa_asociada;
    
                } 
                else {
    
                    tarifa = objRed.tarifa_debidos;
    
                    if (!tarifa || (tarifa && tarifa.id === undefined))
                    {
                        tarifa = objRed.tarifa_asociada;
                    }
    
                }
            }

            //Modificamos el objeto de las variables de la red para añadirle una posición tarifa con los datos de la variable para esa tarifa.

            variables = cloneDeep(variables);
            
            variables.variablesRed = variables.variablesRed.map(variable => {

                let variableEnTarifa = tarifa.variables.find(variableTarifa => variableTarifa.idVariable === variable.id);
                
                return {...variable, tarifa: variableEnTarifa || {}};
            })

            if (referencias?.length > 0) {

                referencias_filtradas = filtraReferencias(referencias, "referencias");

                referencias_variables_filtradas = filtraReferencias(referenciasVariables, "referencias_variables");

                //Calculamos por cada referencia.           
                valoraciones_referencias = calculosValoraciones(referencias_filtradas, objRed, tarifa, idRuta, idServicio, portes, valoraciones);

                //Calculamos por cada variable de referencia.
                if (referencias_variables_filtradas.length > 0) {
                    valoraciones_variables_referencias = calculosValoraciones(referencias_variables_filtradas, objRed, tarifa, idRuta, idServicio, portes, valoraciones);
                }

            }

            //Creamos el objeto final valoraciones

            //Valoraciones manuales
            var valoracionesManuales = valoraciones?.filter(i => (i.idRef == "0" || i.idRef == null) && (i.idVariable == "0" || i.idVariable == null)) || [];

            //Filtramos decimales

            valoracionesManuales = manual == "1" ? valoracionesManuales.map(item => {
                return ({ ...item, precio: muestraPrecio(item.precio), coste: muestraPrecio(item.coste) });
            }) : [];


            var valoraciones_variables = [];

            //Calcuamos valoraciones de variables
            if (variables && variables.variablesRed) {


                //Aplicamos las variables , las automáticas y las opcionales marcadas
                
                variables.variablesRed.forEach(var_item => {

                    if (var_item.idRed == idRed && (var_item.opcional == "0" || variables.variablesOpcionales.filter(i => i.idVariable == var_item.id).length === 1)) {
                        
                        

                        var aux = calculaVariables(var_item, variables, (tarifa.margen > 0) ? tarifa.margen : tarifa.margenRed, valoraciones, impuestos, false);
                        
                        if (aux) {

                            if (var_item.tipo == "%" && var_item.descuento != "1") {
                                
                                if (aux?.valor_variable) {                                    
                                    
                                    var val = {
                                        ...aux, 
                                        precio: parseFloat(aux.valor_variable).toFixed(2),
                                        coste: parseFloat(aux.valor_variable).toFixed(2),
                                        info: '',
                                        infoSinCoste: ''
                                    };                                    
                                    
                                    delete val.valor_variable;
                                    
                                    valoraciones_variables.push(val);

                                    variablesEnvio.push({
                                        idVariable: val.idVariable,
                                        valor: aux.valor_variable
                                    })

                                    aux = {
                                        ...aux,
                                        servicio:`Comisión (%) ${aux.servicio}`,
                                        coste: (parseFloat(aux.coste) - parseFloat(aux.valor_variable)).toFixed(2),
                                        precio: (parseFloat(aux.precio)-parseFloat(aux.valor_variable)).toFixed(2)
                                    };

                                    delete aux.valor_variable;
                                    
                                    valoraciones_variables.push(aux);

                                }
                                else {

                                    valoraciones_variables.push(aux);

                                    variablesEnvio.push({
                                        idVariable: aux.idVariable,
                                        valor: null
                                    })
                                }

                            }
                            else {

                                valoraciones_variables.push(aux);

                                variablesEnvio.push({
                                    idVariable: aux.idVariable,
                                    valor: null
                                })
                            }

                            
                        }
                    }

                });

            }

            valoraciones_new = union(valoraciones_referencias, valoraciones_variables_referencias, valoraciones_variables, valoracionesManuales);
            
            //Para calcular ciertas variables (total,portes) necesitamos todas las valoraciones
            variables.variablesRed && variables.variablesRed.forEach(var_item => {

                if (var_item.idRed == idRed && (var_item.opcional == "0" || variables.variablesOpcionales.filter(i => i.idVariable == var_item.id).length === 1) ) {                    

                    let aux = calculaVariables(var_item, variables, (tarifa.margen > 0) ? tarifa.margen : tarifa.margenRed, valoraciones_new,impuestos, true);
                    
                    if (aux) {

                        if (var_item.tipo == "%" && var_item.descuento != "1") {

                            if (aux?.valor_variable) {                                
                                
                                var val = { 
                                    ...aux, 
                                    precio: parseFloat(aux.valor_variable).toFixed(2), 
                                    coste: parseFloat(aux.valor_variable_coste).toFixed(2), 
                                    info: '',
                                    infoSinCoste: '' 
                                };
                                
                                delete val.valor_variable;
                                
                                delete val.valor_variable_coste;
                                
                                valoraciones_new.push(val);

                                variablesEnvio.push({
                                    idVariable: aux.idVariable,
                                    valor: val.valor_variable
                                })

                                aux = { 
                                    ...aux, 
                                    servicio: `Comisión (%) ${aux.servicio}`, 
                                    coste: (parseFloat(aux.coste) - parseFloat(aux.valor_variable_coste)).toFixed(2), 
                                    precio: (parseFloat(aux.precio) - parseFloat(aux.valor_variable)).toFixed(2) 
                                };

                                delete aux.valor_variable;
                                
                                delete aux.valor_variable_coste;
                                
                                valoraciones_new.push(aux);

                            } else {

                                valoraciones_new.push(aux);

                                variablesEnvio.push({
                                    idVariable: aux.idVariable,
                                    valor: null
                                })
                            }

                        } 
                        else {
                            valoraciones_new.push(aux);

                            variablesEnvio.push({
                                idVariable: aux.idVariable,
                                valor: null
                            })
                        }


                    }
                }

            });

        }

        return {
            valoraciones_new,
            variablesEnvio
        }

    }, [impuestos,calculosValoraciones]);


    /* Calcula el total de las valoraciones para mostrar en el componente */
    const calculaTotal = useCallback((valoraciones) => {

        let coste_total = 0;
        let precio_total = 0;
        let margen_beneficio = 0;
        let beneficio = 0;
        let precio_iva = 0;


        valoraciones && valoraciones.forEach(item => {            
            
            var porcentajeIVA = impuestos && impuestos.find(i => i.referencia == item.tipoIVA)?.porcentaje;
            if(isNaN(porcentajeIVA)) porcentajeIVA=IVA;

            item.coste=(isNaN(item.coste) || item.coste=="") ?0:item.coste;
            item.precio = (isNaN(item.precio) || item.precio=="" ) ? 0 : item.precio;

            coste_total = parseFloat(coste_total) + parseFloat(item.coste);
            precio_total = parseFloat(precio_total) + parseFloat(item.precio);
            precio_iva = (porcentajeIVA > 0) ? parseFloat(item.precio) * (1 + (parseFloat(porcentajeIVA) / 100)) + parseFloat(precio_iva) : (parseFloat(item.precio) + parseFloat(precio_iva));
        });        

        beneficio = (precio_total - coste_total);

        if (beneficio > 0 && precio_total > 0)
            margen_beneficio = ((beneficio / precio_total) * 100);
        else
            margen_beneficio = 0;
         
        precio_iva=parseFloat(precio_iva);
        
        return { coste_total, precio_total, margen_beneficio, beneficio, precio_iva };

    }, [impuestos]);



    /*Elimina una ref */
    const eliminaVal = useCallback((formik, indexVal) => {

        var valoraciones = formik.values.valoraciones;

        if (valoraciones.length > 0) {
            let valoraciones_new = [];
 
            valoraciones.forEach((item, index) => {
                if (indexVal !== index) {
                    valoraciones_new.push(item);
                } else {                    
                    if(manual!="1"){
                        if (item && parseInt(item.idRef) > 0) {
                            handleClickMsg("Esta valoración esta vinculada con una referencia", "warning");
                            valoraciones_new.push(item);
                        } else {
                            if (item && parseInt(item.idVariable)>0) {
                                handleClickMsg("Esta valoración esta vinculada con una variable", "warning");
                                valoraciones_new.push(item);
                            }
                        }
                   }
                }
            });

            if(valoraciones_new.length!==valoraciones.length){
                setManual('1');
            }
        
          
            formik.setFieldValue("valoraciones", valoraciones_new);            
                                           
            setSubmit(true);
          
        }

    }, [handleClickMsg, setSubmit,manual,setManual]);


    /*Inserta una valoracion */
    const insertaVal = useCallback((formik) => {

        if (idCliente !== "") {

            //Sacamos el iva del cliente
            var redes_arr = [];
            
            if (formik.values.redes.redes) {
                redes_arr = formik.values.redes.redes
            } else {
                redes_arr = formik.values.redes;
            }
            let objRed = redes_arr && redes_arr.filter(item => item.clienteRed.idRed === formik.values.idRed);            

            if (objRed && objRed.length > 0) {
                objRed = objRed[0];                
                var clienteRed = objRed.clienteRed;
                var impuestoCliente = impuestos && impuestos.find(i => i.referencia == clienteRed.idTipoImpuesto);
                var tipoIVA = impuestoCliente !== undefined ? impuestoCliente.referencia : IVA_REF;
            }

            let valoraciones_new = Object.values({ ...formik.values.valoraciones, [formik.values.valoraciones.length]: { idRef: 0, idVariable: 0, idServicio: 0, ref: "", servicio: "VARIOS", ruta: "", coste: "1", precio: "1", info: "...",infoSinCoste:"...", adicional: "1",tipoIVA } });

            formik.setFieldValue("valoraciones", valoraciones_new);
            setSubmit(true);
            setManual('1');


        } else {
            handleClickMsg("Debes seleccionar cliente", "warning");
        }


    }, [idCliente, handleClickMsg, setSubmit,setManual,impuestos]);

    const cambiaInfo = (formik, index, valor) => {

        formik.setFieldValue(`valoraciones[${index}].info`, valor);
        setSubmit(true);
    }

    const cambiaInfoSinCoste = (formik, index, valor) => {

        formik.setFieldValue(`valoraciones[${index}].infoSinCoste`, valor);
        setSubmit(true);
    }


    const filtraReferencias = (referencias, tipo = "referencias") => {

        var referencias_filtradas = [];

        referencias && referencias.forEach(item => {
            let aux = referencias_filtradas.filter(i => i.idReferencia === item.idReferencia);

            if (aux.length > 0) {
                referencias_filtradas.forEach((item2, index) => {
                    if (item2.idReferencia === item.idReferencia) {

                        switch (tipo) {

                            case "referencias":
                                referencias_filtradas[index] = {
                                    ...referencias_filtradas[index],
                                    peso: parseFloat(referencias_filtradas[index].peso) + parseFloat(item.peso),
                                    bultos: parseInt(referencias_filtradas[index].bultos) + parseInt(item.bultos),
                                    variable: parseInt(referencias_filtradas[index].variable) + parseInt(item.variable),
                                    idTipo: item.idTipo
                                }
                                break;

                            case "referencias_variables":

                                referencias_filtradas[index] = {
                                    ...referencias_filtradas[index],
                                    valor: parseFloat(referencias_filtradas[index].valor) + parseFloat(item.valor),
                                    idTipo: item.idTipo
                                }
                                break;

                            default:

                        }


                    }
                });

            } else {
                referencias_filtradas.push(item);
            }

        });

        return referencias_filtradas;
    }

    const creaObjetoVariables=useCallback((item,idRuta,respuesta,esRolAdministrativo)=>{

        var aux = transformaObjectToArray(respuesta.redes);
        var datosRed = aux.find(i => i.red.id === item.idRed);
        var variablesRed = datosRed.variables;

        //Filtramos variablesRed 
        //Esto quizás también esté mal, ya que si valora un rol cliente y el envío tiene una variable de interno, se le va a quitar.       
        /*if (!esRolAdministrativo) {
            variablesRed= variablesRed.filter(i => i.interno === "0");
        }*/

        variablesRed = filtraServiciosVinculados(variablesRed,item.idServicio,idRuta);

        var variablesCampos = {
            tipoEnvio: Number(item.idServicioTipo),
            es_recogida: (Number(item.idServicioTipo) === 2) ? true : false,
            es_intercity: (Number(item.idServicioTipo) === 3) ? true : false,
            tiene_dni:item.direccion.dni ? true : false,
            es_debidos: item.portes === "debidos" && item.importeDebidos && item.importeDebidos > 0 ? true : false,
            exceso_medidas: false
        };

        var envio = respuesta.envios.find(env => env.params.idEnvio == item.id);

        if (envio)
        {

            var redCliente = envio.redes.find(r => r.idRed == item.idRed);

            if (redCliente)
            {
            
                //Creamos el objeto variables
                let reglaMedidasUsar = datosRed?.red?.reglaMedidas ? parseInt(datosRed.red.reglaMedidas) : null;

                if (redCliente?.clienteRed?.reglaMedidas)
                {
                    reglaMedidasUsar = parseInt(redCliente.clienteRed.reglaMedidas);
                }

                if (reglaMedidasUsar !== null)
                {
                    //Sumamos las medidas de las referencias y miramos si alguna excede la regla de medidas
                    item.referencias.forEach(referencia => {

                        if (referencia.alto !== null && referencia.ancho !== null && referencia.largo !== null) {
                            
                            let sumaMedidas = parseFloat(referencia.alto) + parseFloat(referencia.ancho) + parseFloat(referencia.largo);

                            if (sumaMedidas > reglaMedidasUsar) {
                                variablesCampos.exceso_medidas = true;
                                
                            }
                        }
                    })
                }


                if (item.kmAdicionales != null && item.kmAdicionales != "") variablesCampos.kilometros = Number(item.kmAdicionales);
                if (item.valorDeclarado != null && item.valorDeclarado != "") variablesCampos.declarado = Number(item.valorDeclarado);
                if (item.contraReembolso != null && item.contraReembolso != "") variablesCampos.reembolso = Number(item.contraReembolso);
                if (item.peso != null && item.peso != "") variablesCampos.kg = Number(item.peso);
                if (item.bultos != null && item.bultos != "") variablesCampos.bultos = Number(item.bultos);


                //A las variablesRed le añadimos el item de tarifa

                var varAux=[];
                variablesRed.forEach(i=>{

                    
                    var variables = [];
                    var variable_tarifa = {};
                    var idTarifa = 0;

                    if(redCliente && redCliente.clienteRed){
                        //Detectamos la tarifa
                        if (item.portes == "pagados") {
                            idTarifa=redCliente.clienteRed.idTarifaAsociada;
                        } else {

                            if(redCliente.clienteRed.idTarifaDebidos>0){
                                idTarifa = redCliente.clienteRed.idTarifaDebidos;
                            }else{
                                idTarifa = redCliente.clienteRed.idTarifaAsociada;
                            }
                            
                        }
                    }            
                                
                    if(idTarifa>0){

                        var tarifas=transformaObjectToArray(datosRed.tarifas);

                        variables=tarifas.filter(j=>j.id===idTarifa)[0].variables;
                    }
                    
                    variable_tarifa=variables && variables.filter(j=>j.idVariable===i.id && j.activa=="1");

                    if(variable_tarifa.length===1){                
                        varAux.push({...i,tarifa:variable_tarifa[0]})
                    }
                    
                });

                variablesRed=varAux;
            }
        }

        var variables = {
            variablesRed: variablesRed,
            variablesCampos: variablesCampos,
            variablesOpcionales: item.variables_envio.map(i=>({"idVariable":i.idVariable,"valor":i.valor}))
        }

        return variables;

    },[]);

    //a partir de un envio y el objeto redes global, genera el objeto redes para calcular valoraciones
    const convierteObjetoRedes = useCallback((envio,indexEnvio, datos,idServicio,portes) => {

        var retorno=envio.redes.map(i=>{

            var redes = transformaObjectToArray(datos.redes);
            
            var red=redes.filter(j=>j.red.id==i.idRed)[0];            
            var tarifas=transformaObjectToArray(red.tarifas);
            var servicios_tarifas = transformaObjectToArray(red.servicios_tarifas);
            
            var tarifa_asociada = tarifas && tarifas.filter(z => z.id == i.clienteRed.idTarifaAsociada)[0];
            var tarifa_debidos = (i.clienteRed.idTarifaDebidos > 0 && i.clienteRed.idTarifaDebidos != i.clienteRed.idTarifaAsociada) ? tarifas && tarifas.filter(z => z.id == i.clienteRed.idTarifaDebidos)[0] : null;
                      
            var servicios_tarifa_asociada= servicios_tarifas.filter(z=>z.idTarifa==tarifa_asociada.id && i.servicios_asociados.indexOf(z.id)>=0);
            var servicios_tarifa_debidos = (tarifa_debidos!=null)?servicios_tarifas.filter(z => z.idTarifa == tarifa_debidos.id && i.servicios_debidos.indexOf(z.id) >= 0):[];

            //Buscamos la ruta correspondiente            
         
            var servicios = [];
            if (portes == "pagados" || servicios_tarifa_debidos.length == 0) {
                servicios = servicios_tarifa_asociada;
            } else {
                servicios = servicios_tarifa_debidos;
            }

            var idRuta=0;           
            if (servicios.filter(i => i.id === idServicio).length > 0){
                idRuta = servicios.filter(i => i.id === idServicio)[0].idRuta;

                //Si en arrEnvios encuentra el indexEnvio, cambiamos el idRuta
                var rutaEnvio=servicios.filter(i => i.id === idServicio)[0].arrEnvios.filter(i=>i.indexEnvio==indexEnvio);
                if(rutaEnvio.length>0){
                    idRuta=rutaEnvio[0].idRuta;
                }

            } 
        

            //Los servicios los devolvemos con el factorVolumetrico correspondiente a la ruta.
            if(idRuta>0){
                servicios_tarifa_asociada=servicios_tarifa_asociada.map(item=>{
                    //Buscamos ruta
                   
                    var info=red.info_rutas_servicios_tarifa.filter(z=>z.idServicio==item.id && z.idRuta==idRuta && z.idTarifa==tarifa_asociada.id);

                    if(info.length>0){
                        info=info[0];
                        return ({...item,idRuta:info.idRuta,factorVolumetrico:info.factorVolumetrico,ruta:info.ruta});
                    }else{
                        return item;
                    }
                    
                });

                if(tarifa_debidos!=null && servicios_tarifa_debidos.length>0){
                        servicios_tarifa_debidos = servicios_tarifa_debidos.map(item => {

                            //Buscamos ruta
                            var info = red.info_rutas_servicios_tarifa.filter(z => z.idServicio == item.id && z.idRuta == idRuta && z.idTarifa == tarifa_debidos.id);

                            if (info.length > 0) {
                                info = info[0];
                                return ({ ...item, idRuta: info.idRuta, factorVolumetrico: info.factorVolumetrico, ruta: info.ruta });
                            } else {
                                return item;
                            }

                        });
                    }
          }

            return({
                "clienteRed":i.clienteRed,
                "red":red.red,
                "tarifa_asociada":tarifa_asociada,
                "tarifa_debidos": tarifa_debidos ,
                "servicios_tarifa_asociada": servicios_tarifa_asociada,
                "servicios_tarifa_debidos": servicios_tarifa_debidos,
                "idRuta":idRuta
            })


        })

        

        return retorno;
    }, []);


    const valorarEnvios = useCallback(async (listadoEnvios,esRolAdministrativo) => {

        //De la lista de envios, pedimos los datos al simulador

        let datosDevolver = {
            valoraciones_envios: [],
            infoParaValoracion: []
        }


        var resp = await obtenerDatosValorarEnvios(listadoEnvios);
    
        listadoEnvios && listadoEnvios.forEach((item) => {

            //por cada envio, montamos los datos necesarios para pasarle a la funcion de valoraciones                    
            
            var envio = resp.envios.filter(i=>i.params.idEnvio==item.id)[0];

            var indexEnvio = envio.params.indice;

            if (envio) 
            {                                                    
                
                //Crea el objeto redes a partir del envio y la respuesta agrupada
                var redes = convierteObjetoRedes(envio,indexEnvio,resp,item.idServicio,item.portes);

                //Comprobamos la red y el servicio del envio
                var red = redes.filter(i => i.red.id === item.idRed);
                                    
                var valoraciones_new=[];

                var variablesEnvio = [];
                
                if (red.length > 0) 
                {
                    
                    red = red[0];

                    //del objeto envio y la respuesta, crea el objeto variables
                    var variables = creaObjetoVariables(item, red.idRuta, resp, esRolAdministrativo);

                    //Añadimos el valor del peso al atributo "variable" para los casos donde el tipo de referencia sea "variable".
                    let referencias = item.referencias.map(referencia => ({...referencia, variable: referencia.peso}));

                    //comprobamos servicio
                    var servicios=[];
                    var servicio_tarifa=[];

                    if(item.portes=="pagados" || red.servicios_tarifa_debidos.length==0){
                        servicios=red.servicios_tarifa_asociada;
                        servicio_tarifa=red.tarifa_asociada.tarifas_servicios.find(i => i.idServicio==item.idServicio);
                    }else{
                        servicios = red.servicios_tarifa_debidos;
                        servicio_tarifa = red.tarifa_debidos.tarifas_servicios.find(i => i.idServicio == item.idServicio);
                    }

                    if(servicios.filter(i=>i.id===item.idServicio).length>0 ){   
                        
                        //Comprobamos los ids de referencia, si estan activos en la tarifa
                        item.referencias = item.referencias.map(j => {

                        
                            //Buscamos la referencia en la tarifa
                            let ref = servicio_tarifa.referencias.find(i=>i.idReferencia==j.idReferencia);                                    
                            
                            if (ref != undefined)
                            {
                                    if(ref.estado != "desactivado")
                                    {
                                        return j;    
                                    }
                            }

                            if (ref == undefined || ref?.estado=="desactivado")
                            {  
                                    //Asignamos otra idServicioReferencia que esté activo en la tarifa, por si ha cambiado                                           
                                    ref=servicio_tarifa.referencias.find(i => i.estado=="defecto");
                                    if(ref==undefined){
                                        ref = servicio_tarifa.referencias.find(i => i.estado != "desactivado");
                                    }   
                                    if(ref){
                                        return {...j,idServicioReferencia:ref.idReferencia,idReferencia:ref.idReferencia}
                                    }else{
                                        return j;
                                    }
                            }                                    

                        });

                        

                        let valoracionesCalculadas = calculaValoraciones(item.valoraciones, referencias, redes, item.idRed, item.idServicio, red.idRuta, item.portes, variables, [], 0);

                        valoraciones_new = valoracionesCalculadas.valoraciones_new;

                        variablesEnvio = valoracionesCalculadas.variablesEnvio;
                    }

                    //Datos para valorar posteriormente
                    datosDevolver.infoParaValoracion.push({
                        idEnvio: item.id,
                        valoraciones: item.valoraciones,
                        referencias: referencias,
                        redes,
                        idRed: item.idRed,
                        idServicio: item.idServicio,
                        idRuta: red.idRuta,
                        portes: item.portes,
                        variables
                    })

                    datosDevolver.valoraciones_envios.push({ 
                        idEnvio: item.id, 
                        idRuta: red.idRuta, 
                        referencias: referencias, 
                        valoraciones: valoraciones_new, 
                        variables: variablesEnvio 
                    });
                }                                                
                
            }
        });


        return new Promise((resolve) => {
            resolve(datosDevolver)

        });

      


    }, [calculaValoraciones,convierteObjetoRedes,creaObjetoVariables]);


    const actualizaValoracionesEnvios = useCallback(async (valoraciones_envios) => {


        if (valoraciones_envios.length > 0) {

                //Agrupar datos para limitar datos enviados.

                var valoraciones_envios_enviar=valoraciones_envios.map(item=>{

                    var ref=[];
                    var val=[];

                    ref=item.referencias.map(ref_item=>([ref_item.id,ref_item.idServicioReferencia]));
        
                    val=item.valoraciones.map(val_item=>{
                        var arr=[];

                        arr.push(val_item.adicional);
                        arr.push(val_item.coste);
                        arr.push(val_item.final);
                        arr.push(val_item.idRef);
                        arr.push(val_item.idServicio);
                        arr.push(val_item.idVariable);
                        arr.push(val_item.info);
                        arr.push(val_item.infoSinCoste);
                        arr.push(val_item.precio);
                        arr.push(val_item.ref);
                        arr.push(val_item.ruta);
                        arr.push(val_item.servicio);
                        arr.push(val_item.tipoIVA);
                        arr.push(val_item.valor_variable);
                        arr.push(val_item.valor_variable_coste);

                        return arr;

                    });
                     
                     return({...item,referencias:ref,valoraciones:val});
                    

                });


                //Guarda valoraciones
                const { respuesta } = await ejecutaAPI('PUT', `envios/actualiza_valoraciones_envios`, valoraciones_envios_enviar);

                return new Promise((resolve, reject) => {

                   resolve(respuesta);

                });


        } else {

            return new Promise((resolve, reject) => {

                reject([]);

            });
        }


    },[]);




    return {
        eliminaVal,
        insertaVal,
        calculaValoraciones,
        cambiaInfo,
        cambiaInfoSinCoste,
        calculaTotal,
        valorarEnvios,
        actualizaValoracionesEnvios        
    }

}
