import { Autocomplete, Checkbox, TextField } from '@mui/material';
import { get } from 'lodash';
import { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import uuid4 from 'uuid4';
import { getValueAutocomplete } from '../../../../helpers/libFunciones';
import { useStyles } from '../../css/css';

const FormAutocompleteRHF = ({name, opciones, label, placeholder, clase, onChangeCallback, margin, propsTextField = {}, ...props}) => {

    const classes = useStyles();

    const { control, setValue, formState } = useFormContext();

    const { errors} = formState;

    const errores = get(errors, name);

    var propMultiple = {};

    var propChange = {

        getOptionLabel: (option) => option?.value ? option.value : '',
        onChange: (e, item) => {

            if (item !== undefined && item !== null)
            {
                
                if (props.multiple)
                {

                    var arrayItems = item.map(i => i.id);

                    setValue(name, arrayItems);
                }
                else 
                {
                    setValue(name, item.id);

                }

            }
            else
            {

                props.multiple ? setValue(name, []) : setValue(name, '');
            }

            if (typeof onChangeCallback === "function")
            {

                onChangeCallback(e, item);
            }

        }
    }

    if (props.freeSolo)
    {
        propChange = {
            onInputChange:(e, item) =>  {
                if (e !== null)
                {
                    setValue(item);
                }
            },
            value: null,
            inputValue: ''
        }

    }

    if (props.multiple)
    {
        propMultiple = {
            renderOption: (props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                style={{ marginRight: 4 }}
                checked={selected}
              />
              {option.value}
            </li>
          ),
          disableCloseOnSelect: true,

        }
    }

    const getOptionKey = useCallback((option) => {
        
        return `option-${option?.id || uuid4()}`;
    }, [])

 
    return (  
        <Controller
        control={control}
        defaultValue={getValueAutocomplete(opciones, '', props.multiple)}
        name={name}
        render={({ field }) => (
            <Autocomplete
            getOptionLabel={(option) => option}
            options={opciones}
            value={getValueAutocomplete(opciones, field.value || '', props.multiple)}
            renderInput={(params) => (
                <TextField
                    {...params}
                    inputProps={{
                    ...params.inputProps,
                    autoComplete:'new-password',
                    }}
                    className={classes[clase] || null}
                    InputLabelProps={{shrink: true}}
                    margin={margin || "normal"}
                    label={label}
                    variant={"outlined"}
                    /*placeholder={props.multiple ? (campo.value.length > 0 ? null : (placeholder ? placeholder : null)) : null}*/
                    helperText={(errores && errores.message) || ''}
                    error={Boolean(errores)}
                    {...propsTextField}
                    
                />
            )}
            getOptionKey={getOptionKey}
            {...props}
            {...propChange}
            {...propMultiple}
            />
        )}
        />
    );
}
 
export default FormAutocompleteRHF;