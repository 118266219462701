import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Box, Button } from '@mui/material';

const BotonAdd = ({text = "Añadir", ...props}) => {


    return (
        <Button variant="outlined" color="primary" {...props}>
            <Box display="flex" sx={{gap: "2px"}} alignItems="center">
                <AddCircleOutlineIcon />
                <span className='xs-hidden'>
                    {text}
                </span>
            </Box>
        </Button>
    )
}

export default BotonAdd;