import { TIPOS_PLANTILLA_IMPORTACION_ENVIOS } from 'app/helpers/constants';
import Yup from '../../../librerias/yup';


export const schemaPlantillas = Yup.object({
    id: Yup.string().nullable().default(""),
    nombre: Yup.string().required('Requerido').default(""),
    idTipoPlantilla: Yup.string().required('Requerido').default(""),
    idRed: Yup.string().when('idTipoPlantilla', (idTipoPlantilla, schema) => {
        if (idTipoPlantilla === "1")
        {
            return schema.required('Requerido').default("");
        }
        else 
        {
            return schema.nullable().default("");
        }
    }),
    inicioDatos: Yup.number().positive("Tiene que ser positivo").required('Requerido').default("1"),
    formato: Yup.string().required('Requerido').default("excel"),
    descripcion: Yup.string().nullable().default(""),
    tipoImportacionEnvios: Yup.string().nullable().default(TIPOS_PLANTILLA_IMPORTACION_ENVIOS.CREAR.id),
    campos: Yup.object().default({})
});

Yup.string().when('activo', (activo, schema) => activo ? schema.required('Requerido') : schema).nullable()