import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Checkbox, FormControlLabel, Grid, Icon, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { capitalize } from 'lodash';
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, TooltipG, useStylesTable } from "../../../utils";


const PanelPermiso = (props)=>{

    const { checkAll, row,checksPermisos, handleChangeCheck,rol,buscar,expandedPanel,setExpandedPanel}=props;
    const classes = useStylesTable();

    //Creamos el array con las distintas secciones
    row.secciones = [null];
    row.permisos.forEach(n => {
        if (n.seccion != null && n.seccion != "") {
            if (row.secciones.indexOf(n.seccion) === -1) {
                row.secciones.push(n.seccion);

            }
        }
    })

    let permisosRol = rol.permisos.map(permiso => permiso.idPermiso);

    return(
        <ExpansionPanel defaultExpanded={expandedPanel} expanded={expandedPanel} onChange={(e,expanded)=>{setExpandedPanel(expanded)}}  key={row.id}>
        <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={row.nombre}
            id={row.id}
        >
            <Typography className={classes.heading} variant="h5">
                <Icon style={{ width: 30, cursor: "pointer" }} className={row.ico} />
                {row.nombre}
            </Typography> 

        </ExpansionPanelSummary>


        <ExpansionPanelDetails>
            <Grid container spacing={3} justifyContent="flex-end" >
                <Grid item xs={1} align="right" style={{ marginTop: -65 }}  >
                    <TooltipG title={"Select/Deselect All"} style={{ fontSize: 25}}>
                            <FormControlLabel style={{ marginRight: 40}}
                            control={<Checkbox onChange={e => checkAll(e,rol.id)} name={row.id} color="primary" />}
                            label=""
                        />
                    </TooltipG>
                </Grid>
                <Grid item xs={12} >
                    {row.secciones.map(seccionP => (

                        <TableContainer component={Paper} style={{ marginBottom: 20 }} >
                            <Table size="small" aria-label="dense table" className={classes.table}>
                                <TableHead>
                                    {(seccionP !== null) ?
                                        <TableRow>
                                            <TableCell className={classes.cell_titulo} colSpan={4} >
                                                <TooltipG title={"Select/Deselect All"} style={{ fontSize: 25 }}>
                                                    <FormControlLabel
                                                        control={<Checkbox onChange={e => checkAll(e, rol.id,seccionP)} name={row.id} color="primary" />}
                                                        label=""
                                                    />
                                                </TooltipG>
                                                {seccionP}
                                            </TableCell>
                                        </TableRow>
                                        :
                                        null
                                    }
                                    <TableRow>
                                <TableCell className={classes.cell_short}></TableCell>
                                        <TableCell className={classes.cell_long}>Permiso</TableCell>
                                        {                                            
                                        <TableCell  className={classes.cell_short}>{capitalize(rol.rol)}</TableCell>                                         
                                        }
                                        <TableCell className={classes.cell_short}>Usuario</TableCell>
                                        
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        Object.values(row.permisos).filter(item => item.seccion === seccionP).filter(item=> (buscar!==""?item.nombre.toLowerCase().indexOf(buscar.toLowerCase())>=0:true)).map(item => (
                                            (permisosRol.indexOf(item.id)>-1)?
                                            <TableRow key={`permiso_${item.id}`}>
                                                <TableCell className={classes.cell_short}>
                                                    <TooltipG title={item.comentario} style={{ fontSize: 25 }}>
                                                        <Icon style={{ width: 30,cursor:'pointer' }} className="fa fa-info-circle" color="primary" />
                                                    </TooltipG>
                                                </TableCell>
                                                <TableCell className={classes.cell_long}>                                                    
                                                    {item.nombre}                                                       
                                                    
                                                </TableCell>
                                                {                                                   
                                                    <TableCell className={classes.cell_short}>                                                            
                                                        {<Checkbox checked={(permisosRol.indexOf(item.id)===-1?false:true)} disabled  name={item.id} color="primary" /> }
                                                    </TableCell>
                                                    
                                                }                                             
                                                <TableCell align="right" className={classes.cell_short}>
                                                    <Checkbox checked={checksPermisos && checksPermisos[item.id]} onChange={handleChangeCheck} name={item.id} color="primary" />                                                    
                                                </TableCell>

                                            </TableRow>
                                            :null
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>

                    ))}


                </Grid>

            </Grid>

        </ExpansionPanelDetails>
    </ExpansionPanel> );
};

export default PanelPermiso;