/* eslint-disable no-mixed-operators */

export const getContrastText = (color, returnedBlack = '#000000') => {
  
    let isRGB = typeof color === "object" && color.r !== undefined ? true : false;
  
    var rgb = !isRGB ? hexToRgb(color) : color;
  
    var yiq = (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000;
  
    let colorDevolver = yiq >= 128 ? returnedBlack : "#FFFFFF";
  
    //Lo devuelve en RGB o hexadecimal según el formato del color enviado.
    return isRGB ? hexToRgb(colorDevolver) : colorDevolver;
  }
  
  export function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }
  
  export function RGBToHex(r,g,b) {
  
    r = r.toString(16);
    g = g.toString(16);
    b = b.toString(16);
  
    if (r.length == 1)
      r = "0" + r;
    if (g.length == 1)
      g = "0" + g;
    if (b.length == 1)
      b = "0" + b;
  
    return "#" + r + g + b;
  }
  
  export function RGBAToHexA(r,g,b,a) {
    r = r.toString(16);
    g = g.toString(16);
    b = b.toString(16);
    a = Math.round(a * 255).toString(16);
  
    if (r.length == 1)
      r = "0" + r;
    if (g.length == 1)
      g = "0" + g;
    if (b.length == 1)
      b = "0" + b;
    if (a.length == 1)
      a = "0" + a;
  
    return "#" + r + g + b + a;
  }
  
  
  export const getRandomColor = (name) => {
  
    const firstAlphabet = name.charAt(0).toLowerCase();
  
    // get the ASCII code of the character
    const asciiCode = firstAlphabet.charCodeAt(0);
  
    // number that contains 3 times ASCII value of character -- unique for every alphabet
    const colorNum = asciiCode.toString() + asciiCode.toString() + asciiCode.toString();
  
    var num = Math.round(0xffffff * parseInt(colorNum));
    var r = num >> 16 & 255;
    var g = num >> 8 & 255;
    var b = num & 255;
  
    return RGBToHex(r, g, b);
  }
  
  export const generatePaletteFromColor = (hex) => {
  
    const rgb = hexToRgb(hex);
  
    const shades = {};
  
  
    var key = 900;
  
    for (let i = 6; i <= 15; i++)
    {
        let red = Math.floor(Math.min(i * rgb.r / 10, 255));
  
        let green = Math.floor(Math.min(i * rgb.g / 10, 255));
  
        let blue = Math.floor(Math.min(i * rgb.b / 10, 255));
  
        shades[key] = {
          r: red,
          g: green,
          b: blue
        }
  
        key = Math.max(Math.max(key - 100, 0), 50);
  
    }
  
    return shades;
  
  }
  
  export const getCSSVariableStringColor = (rgbObject, tipo) => {
  
  
    return `--color-${tipo}: ${rgbObject.r} ${rgbObject.g} ${rgbObject.b};`
  }
  