import { isEmpty } from "lodash";
import Yup, { emptyStringToNull } from "../../../../librerias/yup";

const nombreUPS =  Yup.string().when('$valoresActuales', {
    is: (valoresActuales) => ((valoresActuales.recogidaFueraPlaza === true || valoresActuales.recogidaFueraPlaza === "1")),
    then: Yup.string().required('El nombre debe estar relleno').default("").max(27, 'Máximo 27 caracteres'),
    otherwise: Yup.string().required('El nombre debe estar relleno').default("").max(35, 'Máximo 35 caracteres')
});

const validacionesEstandarUPS = {
    validaciones: Yup.object({
        peso: Yup.number().default(1).required("Requerido"),
        bultos: Yup.number().positive('Los bultos deben ser mayor que cero').min("1", "Mínimo un bulto").default(1).required('Requerido'),
        direccion: Yup.object().shape({
            nombre:nombreUPS,
            direccion: Yup.string().required('La dirección debe estar rellena').default(""),
            localidad: Yup.string().required('La ciudad debe estar rellena').default("").max(30, 'Máximo 30 caracteres'),
            cp: Yup.string().required('El código postal debe estar relleno').default("").max(8, 'Máximo 8 caracteres'), 
            telefonoContacto: Yup.string().required('El teléfono debe estar relleno').default(""),

        }, ['nombre', 'direccion', 'localidad', 'cp']),
        direccionRecogida: Yup.object().shape({
            nombre: nombreUPS,
            direccion: Yup.string().required('La dirección debe estar rellena').default(""),
            localidad: Yup.string().required('La ciudad debe estar rellena').default("").max(30, 'Máximo 30 caracteres'),
            cp: Yup.string().required('El código postal debe estar relleno').default("").max(8, 'Máximo 8 caracteres'),
            personaContacto: Yup.string().when('$valoresActuales', {
                is: (valoresActuales) => ((valoresActuales.recogidaFueraPlaza === true || valoresActuales.recogidaFueraPlaza === "1")),
                then: Yup.string().required('El nombre de la persona de contacto del remitente debe estar relleno').default(""),
                otherwise: Yup.string().nullable().default("")
            }),
            telefonoContacto: Yup.string().when('$valoresActuales', {
                is: (valoresActuales) => ((valoresActuales.recogidaFueraPlaza === true || valoresActuales.recogidaFueraPlaza === "1")),
                then: Yup.string().required('El telefono de la persona de contacto del remitente debe estar relleno').default(""),
                otherwise: Yup.string().nullable().default("")
            }),
        }, ['nombre', 'direccion', 'localidad', 'cp']),
        tipoPesoVol: Yup.string().required('El tipo de paquete es obligatorio').default("02")

    }),
    canalizacion: 'oficina',
};



const validacionesIntUPS = {
    validaciones: Yup.object({
        peso: Yup.number().default(1).required("Requerido"),
        bultos: Yup.number().positive('Los bultos deben ser mayor que cero').min("1", "Mínimo un bulto").default(1).required('Requerido'),
        direccion: Yup.object().shape({
            nombre: nombreUPS,
            direccion: Yup.string().required('La dirección debe estar rellena').default(""),
            localidad: Yup.string().required('La ciudad debe estar rellena').default("").max(30, 'Máximo 30 caracteres'),
            cp: Yup.string().required('El código postal debe estar relleno').default("").max(8, 'Máximo 8 caracteres'),
            telefonoContacto: Yup.string().required('El teléfono debe estar relleno').default(""),
            personaContacto: Yup.string().required('El nombre de la persona de contacto debe estar relleno').default(""),

        }, ['nombre', 'direccion', 'localidad', 'cp']),
        direccionRecogida: Yup.object().shape({
            nombre: nombreUPS,
            direccion: Yup.string().required('La dirección debe estar rellena').default(""),
            localidad: Yup.string().required('La ciudad debe estar rellena').default("").max(30, 'Máximo 30 caracteres'),
            cp: Yup.string().required('El código postal debe estar relleno').default("").max(8, 'Máximo 8 caracteres'),
            personaContacto: Yup.string().when('$valoresActuales', {
                is: (valoresActuales) => ((valoresActuales.recogidaFueraPlaza === true || valoresActuales.recogidaFueraPlaza === "1")),
                then: Yup.string().required('El nombre de la persona de contacto del remitente debe estar relleno').default(""),
                otherwise: Yup.string().nullable().default("")
            }),
            telefonoContacto: Yup.string().when('$valoresActuales', {
                is: (valoresActuales) => ((valoresActuales.recogidaFueraPlaza === true || valoresActuales.recogidaFueraPlaza === "1")),
                then: Yup.string().required('El telefono de la persona de contacto del remitente debe estar relleno').default(""),
                otherwise: Yup.string().nullable().default("")
            }),
        }, ['nombre', 'direccion', 'localidad', 'cp']),
        tipoPesoVol: Yup.string().required('El tipo de paquete es obligatorio').default("02")

    }),
    canalizacion: 'oficina',
};


const validacionesMailUPS = {
    validaciones: Yup.object({
        referencia: Yup.string().required('Debes incluir una referencia').default(""),
        peso: Yup.number().default(1).required("Requerido"),
        bultos: Yup.number().positive('Los bultos deben ser mayor que cero').min("1", "Mínimo un bulto").default(1).required('Requerido'),
        direccion: Yup.object().shape({
            nombre: nombreUPS, 
            direccion: Yup.string().required('La dirección debe estar rellena').default("").max(73, 'Dir Destino. Máximo 73 caracteres'),
            localidad: Yup.string().required('La ciudad debe estar rellena').default("").max(30, 'Máximo 30 caracteres'),
            cp: Yup.string().required('El código postal debe estar relleno').default("").max(9, 'Máximo 9 caracteres'),
            telefonoContacto: Yup.string().required('El teléfono debe estar relleno').default(""),

        }, ['nombre', 'direccion', 'localidad', 'cp']),
        direccionRecogida: Yup.object().shape({
            nombre: Yup.string().required('El nombre del remitente debe estar relleno').default(""),
            direccion: Yup.string().required('La dirección debe estar rellena').default("").max(73, 'Dir Origen. Máximo 73 caracteres'),
            localidad: Yup.string().required('La ciudad debe estar rellena').default("").max(30, 'Máximo 30 caracteres'),
            cp: Yup.string().required('El código postal debe estar relleno').default("").max(8, 'Máximo 8 caracteres'),

        }, ['nombre', 'direccion', 'localidad', 'cp']),
        tipoPesoVol: Yup.string().required('El tipo de paquete es obligatorio').default("02")

    }),
    canalizacion: 'oficina',
};




const validacionesEstandarTipsa = {
    validaciones: Yup.object({        
        peso: Yup.number().default(1).required("Requerido"),
        bultos: Yup.number().positive('Los bultos deben ser mayor que cero').min("1", "Mínimo un bulto").default(1).required('Requerido'),
        direccion: Yup.object().shape({
            nombre: Yup.string().required('El nombre debe estar relleno').default(""),                        
            direccion: Yup.string().required('La dirección debe estar rellena').default(""),
            localidad: Yup.string().required('La ciudad debe estar rellena').default(""),
            cp: Yup.string().required('El código postal debe estar relleno').default(""),

        }, ['nombre',  'direccion', 'localidad', 'cp']),
        direccionRecogida: Yup.object().shape({            
            nombre: Yup.string().required('El nombre del remitente debe estar relleno').default(""),
            direccion: Yup.string().required('La dirección del remitente debe estar rellena').default(""),
            localidad: Yup.string().required('La ciudad del remitente debe estar rellena').default(""),
            cp: Yup.string().required('El código postal del remitente debe estar relleno').default(""),

        }, ['nombre',  'direccion', 'localidad', 'cp']),        

    }),
    canalizacion: 'oficina',  
};


const validacionesEstandarTipsaRecogida = {
    validaciones: Yup.object({
        peso: Yup.number().default(1).required("Requerido"),
        bultos: Yup.number().positive('Los bultos deben ser mayor que cero').min("1", "Mínimo un bulto").default(1).required('Requerido'),
        direccion: Yup.object().shape({
            nombre: Yup.string().required('El nombre debe estar relleno').default(""),
            direccion: Yup.string().required('La dirección debe estar rellena').default(""),
            localidad: Yup.string().required('La ciudad debe estar rellena').default(""),
            cp: Yup.string().required('El código postal debe estar relleno').default(""),
            personaContacto: Yup.string().required('El nombre de la persona de contacto debe estar relleno').default(""),
            telefonoContacto: Yup.string().required('El teléfono debe estar relleno').default(""),

        }, ['nombre', 'direccion', 'localidad', 'cp']),
        direccionRecogida: Yup.object().shape({
            nombre: Yup.string().required('El nombre del remitente debe estar relleno').default(""),
            direccion: Yup.string().required('La dirección del remitente debe estar rellena').default(""),
            localidad: Yup.string().required('La ciudad del remitente debe estar rellena').default(""),
            cp: Yup.string().required('El código postal del remitente debe estar relleno').default(""),

        }, ['nombre', 'direccion', 'localidad', 'cp']),
        oficinaRed: Yup.object().required('La oficina es obligatoria').test('notEmptyObject', 'La oficina es obligatoria', (value) => !isEmpty(value)).default({})
    }),
    canalizacion: 'puntorecogida',
    textos: {
        canalizacion: {
            titulo: 'PUNTOS DE RECOGIDA',
            nombreOficina: 'PUNTO CONVENIENCIA'
        }
    }
};

const validacionesEstandarTipsaInternacional = {
    validaciones: Yup.object({
        peso: Yup.number().default(1).required("Requerido"),
        bultos: Yup.number().positive('Los bultos deben ser mayor que cero').min("1", "Mínimo un bulto").default(1).required('Requerido'),
        direccion: Yup.object().shape({
            nombre: Yup.string().required('El nombre debe estar relleno').default(""),
            personaContacto: Yup.string().required('El nombre de la persona de contacto debe estar relleno').default(""),
            telefonoContacto: Yup.string().required('El teléfono debe estar relleno').default(""),
            direccion: Yup.string().required('La dirección debe estar rellena').default(""),
            localidad: Yup.string().required('La ciudad debe estar rellena').default(""),
            cp: Yup.string().required('El código postal debe estar relleno').default(""),
            observaciones: Yup.string().required('Es necesario indicar el contenido en observaciones').default(""),


        }, ['nombre', 'personaContacto', 'telefonoContacto', 'direccion', 'localidad', 'cp']),
        direccionRecogida: Yup.object().shape({
            nombre: Yup.string().required('El nombre del remitente debe estar relleno').default(""),
            direccion: Yup.string().required('La dirección del remitente debe estar rellena').default(""),
            localidad: Yup.string().required('La ciudad del remitente debe estar rellena').default(""),
            cp: Yup.string().required('El código postal del remitente debe estar relleno').default(""),

        }, ['nombre', 'personaContacto', 'telefonoContacto', 'direccion', 'localidad', 'cp']),

    }),
    canalizacion: 'oficina'
};

const validacionesEstandarTipsaDelegacion = {
    validaciones: Yup.object({        
        peso: Yup.number().default(1).required("Requerido"),
        bultos: Yup.number().positive('Los bultos deben ser mayor que cero').min("1", "Mínimo un bulto").default(1).required('Requerido'),
        direccion: Yup.object().shape({
            nombre: Yup.string().required('El nombre debe estar relleno').default(""),            
            direccion: Yup.string().required('La dirección debe estar rellena').default(""),
            localidad: Yup.string().required('La ciudad debe estar rellena').default(""),
            cp: Yup.string().required('El código postal debe estar relleno').default(""),

        }, ['nombre',  'direccion', 'localidad', 'cp']),
        direccionRecogida: Yup.object().shape({
            nombre: Yup.string().required('El nombre del remitente debe estar relleno').default(""),
            direccion: Yup.string().required('La dirección del remitente debe estar rellena').default(""),
            localidad: Yup.string().required('La ciudad del remitente debe estar rellena').default(""),
            cp: Yup.string().required('El código postal del remitente debe estar relleno').default(""),

        }, ['nombre',  'direccion', 'localidad', 'cp']),
        oficinaRed: Yup.object().required('La oficina es obligatoria').test('notEmptyObject', 'La oficina es obligatoria', (value) => !isEmpty(value)).default({})

    }),
    canalizacion: 'oficina'
};

const validacionesEstandarCorreosExpress = {
    validaciones: Yup.object({
        valorDeclarado: Yup.number().transform(emptyStringToNull).nullable().max("3000", "Máximo 3000 €").default(null),
        contraReembolso: Yup.number().transform(emptyStringToNull).nullable().max("2499", "Máximo 2499 €").default(null),
        peso: Yup.number().max("2000", "Máximo 2000 KG").default(1).required("Requerido"),
        bultos: Yup.number().positive('Los bultos deben ser mayor que cero').min("1", "Mínimo un bulto").default(1).required('Requerido'),
        direccion: Yup.object().shape({
            nombre: Yup.string().required('El nombre debe estar relleno').default(""),
            personaContacto: Yup.string().required('El nombre de la persona de contacto debe estar relleno').default(""),
            telefonoContacto: Yup.string().required('El teléfono debe estar relleno').default(""),
            direccion: Yup.string().required('La dirección debe estar rellena').default(""),
            localidad: Yup.string().required('La ciudad debe estar rellena').default(""),
            cp: Yup.string().required('El código postal debe estar relleno').default(""),

        }, ['nombre', 'personaContacto', 'telefonoContacto', 'direccion', 'localidad', 'cp']),
        direccionRecogida: Yup.object().shape({
            nombre: Yup.string().required('El nombre del remitente debe estar relleno').default(""),            
            direccion: Yup.string().required('La dirección del remitente debe estar rellena').default(""),
            localidad: Yup.string().required('La ciudad del remitente debe estar rellena').default(""),
            cp: Yup.string().required('El código postal del remitente debe estar relleno').default(""),
            personaContacto: Yup.string().when('$valoresActuales', {
                is: (valoresActuales) => ((valoresActuales.recogidaFueraPlaza === true || valoresActuales.recogidaFueraPlaza === "1" )),
                then: Yup.string().required('El nombre de la persona de contacto del remitente debe estar relleno').default(""),
                otherwise: Yup.string().nullable().default("")
            }),
            telefonoContacto: Yup.string().when('$valoresActuales', {
                is: (valoresActuales) => ((valoresActuales.recogidaFueraPlaza === true || valoresActuales.recogidaFueraPlaza === "1")),
                then: Yup.string().required('El teléfono debe estar relleno').default(""),
                otherwise: Yup.string().nullable().default("")
            })
        }, ['nombre', 'personaContacto', 'telefonoContacto', 'direccion', 'localidad', 'cp']),

    }),
    canalizacion: 'oficina'};

const validacionesInternacionalCorreosExpress = {
    validaciones: Yup.object({
        valorDeclarado: Yup.number().transform(emptyStringToNull).nullable().max("3000", "Máximo 3000 €").default(null),
        contraReembolso: Yup.number().transform(emptyStringToNull).nullable().max("2499", "Máximo 2499 €").default(null),
        peso: Yup.number().max("2000", "Máximo 2000 KG").default(1).required("Requerido"),
        bultos: Yup.number().positive('Los bultos deben ser mayor que cero').min("1", "Mínimo un bulto").default(1).required('Requerido'),
        direccion: Yup.object().shape({
            nombre: Yup.string().required('El nombre debe estar relleno').default(""),
            personaContacto: Yup.string().required('El nombre de la persona de contacto debe estar relleno').default(""),
            telefonoContacto: Yup.string().required('El teléfono debe estar relleno').default(""),
            direccion: Yup.string().required('La dirección debe estar rellena').default(""),
            localidad: Yup.string().required('La ciudad debe estar rellena').default(""),
            cp: Yup.string().required('El código postal debe estar relleno').default(""),

        }, ['nombre', 'personaContacto', 'telefonoContacto', 'direccion', 'localidad', 'cp']),        
        direccionRecogida: Yup.object().shape({
            nombre: Yup.string().required('El nombre del remitente debe estar relleno').default(""),            
            direccion: Yup.string().required('La dirección del remitente debe estar rellena').default(""),
            localidad: Yup.string().required('La ciudad del remitente debe estar rellena').default(""),
            cp: Yup.string().required('El código postal del remitente debe estar relleno').default(""),
            personaContacto: Yup.string().when('$valoresActuales', {
                is: (valoresActuales) => ((valoresActuales.recogidaFueraPlaza === true || valoresActuales.recogidaFueraPlaza === "1")),
                then: Yup.string().required('El nombre de la persona de contacto del remitente debe estar relleno').default(""),
                otherwise: Yup.string().nullable().default("")
            }),
            telefonoContacto: Yup.string().when('$valoresActuales', {
                is: (valoresActuales) => ((valoresActuales.recogidaFueraPlaza === true || valoresActuales.recogidaFueraPlaza === "1")),
                then: Yup.string().required('El teléfono debe estar relleno').default(""),
                otherwise: Yup.string().nullable().default("")
            })
        }, ['nombre', 'personaContacto', 'telefonoContacto', 'direccion', 'localidad', 'cp']),

    }),
    canalizacion: 'oficina'
};

const validacionesInternacionalMonobultoCorreosExpress = {
    validaciones: Yup.object({
        valorDeclarado: Yup.number().transform(emptyStringToNull).nullable().max("3000", "Máximo 3000 €").default(null),
        contraReembolso: Yup.number().transform(emptyStringToNull).nullable().max("2499", "Máximo 2499 €").default(null),
        peso: Yup.number().max("2000", "Máximo 2000 KG").default(1).required("Requerido"),
        bultos: Yup.number().positive('Los bultos deben ser mayor que cero').min("1", "Mínimo un bulto").max("1", "Solo se permite 1 bulto").default(1).required('Requerido'),
        direccion: Yup.object().shape({
            nombre: Yup.string().required('El nombre debe estar relleno').default(""),
            personaContacto: Yup.string().required('El nombre de la persona de contacto debe estar relleno').default(""),
            telefonoContacto: Yup.string().required('El teléfono debe estar relleno').default(""),
            direccion: Yup.string().required('La dirección debe estar rellena').default(""),
            localidad: Yup.string().required('La ciudad debe estar rellena').default(""),
            cp: Yup.string().required('El código postal debe estar relleno').default(""),

        }, ['nombre', 'personaContacto', 'telefonoContacto', 'direccion', 'localidad', 'cp']),
        direccionRecogida: Yup.object().shape({
            nombre: Yup.string().required('El nombre del remitente debe estar relleno').default(""),
            direccion: Yup.string().required('La dirección del remitente debe estar rellena').default(""),
            localidad: Yup.string().required('La ciudad del remitente debe estar rellena').default(""),
            cp: Yup.string().required('El código postal del remitente debe estar relleno').default(""),
            personaContacto: Yup.string().when('$valoresActuales', {
                is: (valoresActuales) => ((valoresActuales.recogidaFueraPlaza === true || valoresActuales.recogidaFueraPlaza === "1")),
                then: Yup.string().required('El nombre de la persona de contacto del remitente debe estar relleno').default(""),
                otherwise: Yup.string().nullable().default("")
            }),
            telefonoContacto: Yup.string().when('$valoresActuales', {
                is: (valoresActuales) => ((valoresActuales.recogidaFueraPlaza === true || valoresActuales.recogidaFueraPlaza === "1")),
                then: Yup.string().required('El teléfono debe estar relleno').default(""),
                otherwise: Yup.string().nullable().default("")
            })
        }, ['nombre', 'personaContacto', 'telefonoContacto', 'direccion', 'localidad', 'cp']),

    }),
    canalizacion: 'oficina'
};


const ValidacionDireccionCorreos = (paises,valoresActuales)=>{

    var devolver=false;

    var paisDestino = paises.find(pais => pais.id === valoresActuales.direccion.idPais);
    var paisOrigen = paises.find(pais => pais.id === valoresActuales.direccionRecogida.idPais);

    if ((paisDestino && paisDestino.alpha2Code === "AD") || (paisOrigen && paisDestino && paisOrigen.alpha2Code==="AD" && paisDestino.alpha2Code==="ES")) {
        devolver = true;
    }

    //si es origen en peninsula, baleares a destino (ceuta,melilla,canarias) o viceversa
    if (!devolver) {
        var cp_destino = valoresActuales.direccion.cp.substring(0, 2);
        var cp_origen = valoresActuales.direccionRecogida.cp.substring(0, 2);
        var cps_arr = ["51", "52", "38", "35"];
        if ((cps_arr.indexOf(cp_destino) >= 0 && cps_arr.indexOf(cp_origen) == -1) || (cps_arr.indexOf(cp_destino) == -1 && cps_arr.indexOf(cp_origen) >= 0)) {
            devolver = true;
        }
    }

    return devolver;
}

const valTipoPesoVol = Yup.string().when(['$valoresActuales'], {
    is: (valoresActuales) => {

        var devolver = false;
        if (valoresActuales.recogidaFueraPlaza === true || valoresActuales.recogidaFueraPlaza === "1") {

            devolver = true;
        }

        return devolver;
    },
    then: Yup.string().required('El peso volumétrico de la recogida debe ser informado'),
    otherwise: Yup.string().nullable().default("")
});

const validacionEstandarInternacionalCorreos = {
    validaciones: Yup.object({
        bultos: Yup.number().positive('Los bultos deben ser mayor que cero').min("1", "Mínimo un bulto").max("10", "Solo se permiten 10 bultos").default(1).required('Requerido'),
        direccion: Yup.object().shape({
            telefonoContacto: Yup.string().when('$valoresActuales', {
                is: (valoresActuales) => (!valoresActuales.codExpedicion && !valoresActuales.direccion.emailContacto),
                then: Yup.string().required('El email o el teléfono deben estar rellenados').default(""),
                otherwise: Yup.string().nullable().default("")
            }),
            emailContacto: Yup.string().when('$valoresActuales', {
                is: (valoresActuales) => (!valoresActuales.codExpedicion && !valoresActuales.direccion.telefonoContacto),
                then: Yup.string().required('El email o el teléfono deben estar rellenados').default(""),
                otherwise: Yup.string().nullable().default("")
            }),

        }),
        numTarifario: Yup.string().when('$valoresActuales', {
            is: (valoresActuales) => !valoresActuales.codExpedicion,
            then: Yup.string().required('El número arancelario es obligatorio').default(""),
            otherwise: Yup.string().nullable().default("")
        }),
        instrDevolucion : Yup.string().when('$valoresActuales', {
            is: (valoresActuales) => !valoresActuales.codExpedicion,
            then: Yup.string().required('Las instrucciones de devolución son obligatorias').default(""),
            otherwise: Yup.string().nullable().default("")
        }),
        ibanReembolso: Yup.string().when('contraReembolso', {
            is: (contraReembolso) => contraReembolso > 0,
            then: Yup.string().transform(emptyStringToNull).required("La cuenta de reembolso es obligatoria").iban("El iban no es válido"),
            otherwise: Yup.string().nullable().default("")
        }),
        tipoPesoVol: valTipoPesoVol
    }),
    canalizacion: 'oficina',
}

const validacionEstandarInternacionalMonobultoCorreos = {
    validaciones: Yup.object({
        bultos: Yup.number().positive('Los bultos deben ser mayor que cero').min("1", "Solo se permite 1 bulto").max("1", "Solo se permite 1 bulto").default(1).required('Requerido'),
        direccion: Yup.object().shape({
            telefonoContacto: Yup.string().when('$valoresActuales', {
                is: (valoresActuales) => (!valoresActuales.codExpedicion && !valoresActuales.direccion.emailContacto),
                then: Yup.string().required('El email o el teléfono deben estar rellenados').default(""),
                otherwise: Yup.string().nullable().default("")
            }),
            emailContacto: Yup.string().when('$valoresActuales', {
                is: (valoresActuales) => (!valoresActuales.codExpedicion && !valoresActuales.direccion.telefonoContacto),
                then: Yup.string().required('El email o el teléfono deben estar rellenados').default(""),
                otherwise: Yup.string().nullable().default("")
            }),

        }),
        numTarifario: Yup.string().when('$valoresActuales', {
            is: (valoresActuales) => !valoresActuales.codExpedicion,
            then: Yup.string().required('El número arancelario es obligatorio').default(""),
            otherwise: Yup.string().nullable().default("")
        }),
        instrDevolucion : Yup.string().when('$valoresActuales', {
            is: (valoresActuales) => !valoresActuales.codExpedicion,
            then: Yup.string().required('Las instrucciones de devolución son obligatorias').default(""),
            otherwise: Yup.string().nullable().default("")
        }),
        valorNeto: Yup.number().when('$valoresActuales', {
            is: (valoresActuales) => !valoresActuales.codExpedicion,
            then: Yup.number().transform(emptyStringToNull).positive("Debe ser un valor positivo").required('El valor neto es obligatorio').default(null).nullable(),
            otherwise: Yup.number().transform(emptyStringToNull).positive("Debe ser un valor positivo").nullable().default(null)
        }),
        ibanReembolso: Yup.string().when('contraReembolso', {
            is: (contraReembolso) => (contraReembolso > 0),
            then: Yup.string().required("La cuenta de reembolso es obligatoria").iban("El iban no es válido"),
            otherwise: Yup.string().nullable().default("")
        }),
        tipoPesoVol: valTipoPesoVol
    }),
    canalizacion: 'oficina',
}


const valDireccionCorreosOrigen = Yup.object().shape({
    personaContacto: Yup.string().when(['$valoresActuales', '$paises'], {
        is: (valoresActuales, paises) => {
            var devolver = false;
            if (!valoresActuales.codExpedicion) {
                devolver = ValidacionDireccionCorreos(paises, valoresActuales);
            }
            return devolver;
        },
        then: Yup.string().required('La persona de contacto en origen es obligatoria').default(""),
        otherwise: Yup.string().nullable().default("")
    }),
    direccion: Yup.string().when(['$valoresActuales', '$paises'], {
        is: (valoresActuales, paises) => {
            var devolver = false;
            if (!valoresActuales.codExpedicion) {
                devolver = ValidacionDireccionCorreos(paises, valoresActuales);
            }
            return devolver;
        },
        then: Yup.string().required('La dirección de origen es obligatoria').default(""),
        otherwise: Yup.string().nullable().default("")
    }),
    dni: Yup.string().when(['$valoresActuales', '$paises'], {
        is: (valoresActuales, paises) => {
            var devolver = false;
            if (!valoresActuales.codExpedicion) {
                devolver = ValidacionDireccionCorreos(paises, valoresActuales);
            }
            return devolver;
        },
        then: Yup.string().required('El dni en origen es obligatorio').default(""),
        otherwise: Yup.string().nullable().default("")
    }),
    telefonoContacto: Yup.string().when(['$valoresActuales', '$paises','emailContacto'], {
        is: (valoresActuales, paises,emailContacto) => {
            var devolver = false;
            if (!valoresActuales.codExpedicion) {
                devolver = ValidacionDireccionCorreos(paises, valoresActuales);
            }
            return devolver && !emailContacto;
        },
        then: Yup.string().required('El email o el teléfono en origen deben estar rellenados').default(""),
        otherwise: Yup.string().nullable().default("")
    }),
    emailContacto: Yup.string().when(['$valoresActuales', '$paises', 'telefonoContacto'], {
        is: (valoresActuales, paises, telefonoContacto) => {
            var devolver = false;
            if (!valoresActuales.codExpedicion) {
                devolver = ValidacionDireccionCorreos(paises, valoresActuales);
            }
            return devolver && !telefonoContacto;
        },
        then: Yup.string().required('El email o el teléfono en origen deben estar rellenados').default(""),
        otherwise: Yup.string().nullable().default("")
    }),

}, ['telefonoContacto', 'emailContacto', 'personaContacto', 'dni']);


const valDireccionCorreos = Yup.object().shape({
    personaContacto: Yup.string().when(['$valoresActuales', '$paises'], {
        is: (valoresActuales, paises) => {
            var devolver = false;
            if (!valoresActuales.codExpedicion) {
                devolver = ValidacionDireccionCorreos(paises, valoresActuales);
            }
            return devolver;
        },
        then: Yup.string().required('La persona de contacto es obligatoria').default(""),
        otherwise: Yup.string().nullable().default("")
    }),
    direccion: Yup.string().when(['$valoresActuales', '$paises'], {
        is: (valoresActuales, paises) => {
            var devolver = false;
            if (!valoresActuales.codExpedicion) {
                devolver = ValidacionDireccionCorreos(paises, valoresActuales);
            }
            return devolver;
        },
        then: Yup.string().required('La dirección es obligatoria').default(""),
        otherwise: Yup.string().nullable().default("")
    }),
    dni: Yup.string().when(['$valoresActuales', '$paises'], {
        is: (valoresActuales, paises) => {
            var devolver = false;
            if (!valoresActuales.codExpedicion) {
                devolver = ValidacionDireccionCorreos(paises, valoresActuales);
            }
            return devolver;
        },
        then: Yup.string().required('El dni es obligatorio').default(""),
        otherwise: Yup.string().nullable().default("")
    }),
    telefonoContacto: Yup.string().when('emailContacto', {
        is: (emailContacto) => !emailContacto,
        then: Yup.string().required('El email o el teléfono deben estar rellenados').default(""),
        otherwise: Yup.string().nullable().default("")
    }),
    emailContacto: Yup.string().when('telefonoContacto', {
        is: (telefonoContacto) => !telefonoContacto,
        then: Yup.string().required('El email o el teléfono deben estar rellenados').default(""),
        otherwise: Yup.string().nullable().default("")
    }),

}, ['telefonoContacto', 'emailContacto', 'personaContacto', 'dni']);

const valTarifario = Yup.string().when(['$valoresActuales', '$paises'], {
    is: (valoresActuales, paises) => {
        var devolver = false;
        if (!valoresActuales.codExpedicion) {
            devolver = ValidacionDireccionCorreos(paises, valoresActuales);
        }

        return devolver;
    },
    then: Yup.string().required('El número arancelario es obligatorio').default(""),
    otherwise: Yup.string().nullable().default("")
});

const valNeto = Yup.number().when(['$valoresActuales', '$paises'], {
    is: (valoresActuales, paises) => {

        var devolver = false;

        if (!valoresActuales.codExpedicion) {

            devolver = ValidacionDireccionCorreos(paises, valoresActuales);
        }

        return devolver;
    },
    then: Yup.number().transform(emptyStringToNull).positive("Debe ser un valor positivo").required('El valor neto es obligatorio').default(null).nullable(),
    otherwise: Yup.number().transform(emptyStringToNull).positive("Debe ser un valor positivo").nullable().default(null)
});

const valReembolso = Yup.string().when('contraReembolso', {
    is: (contraReembolso) => (contraReembolso > 0),
    then: Yup.string().required("La cuenta de reembolso es obligatoria").iban("El iban no es válido"),
    otherwise: Yup.string().nullable().default("")
});



const validacionEstandarCorreos = {
    validaciones: Yup.object({        
        direccionRecogida:valDireccionCorreosOrigen,
        direccion: valDireccionCorreos,
        numTarifario: valTarifario,
        valorNeto:valNeto,
        ibanReembolso:valReembolso,
        tipoPesoVol:valTipoPesoVol
    })
}

const validacionEstandarMultibultoCorreosOficina = {
    validaciones: Yup.object({
        bultos: Yup.number().positive('Los bultos deben ser mayor que cero').min("1", "Mínimo un bulto").max("10", "Solo se permiten 10 bultos").default(1).required('Requerido'),
        direccion: valDireccionCorreos,
        direccionRecogida:valDireccionCorreosOrigen,
        numTarifario: valTarifario,
        valorNeto: valNeto,
        ibanReembolso: valReembolso,
        tipoPesoVol: valTipoPesoVol,
        oficinaRed: Yup.object().required('La oficina es obligatoria').test('notEmptyObject', 'La oficina es obligatoria', (value) => !isEmpty(value)).default({})
    }),
    canalizacion: 'oficina',
}



const valDireccionRecogidaFueraPlaza = Yup.object().shape({ 
    telefonoContacto: Yup.string().when(['$valoresActuales', '$paises', 'emailContacto'], {
        is: (valoresActuales, paises, emailContacto) => {
            var devolver = false;
            if (!valoresActuales.codExpedicion && (valoresActuales.recogidaFueraPlaza===true || valoresActuales.recogidaFueraPlaza==="1") ) {
                devolver = true;
            }
            return devolver && !emailContacto;
        },
        then: Yup.string().required('El email o el teléfono en origen deben estar rellenados').default(""),
        otherwise: Yup.string().nullable().default("")
    }),
    emailContacto: Yup.string().when(['$valoresActuales', '$paises', 'telefonoContacto'], {
        is: (valoresActuales, paises, telefonoContacto) => {
            var devolver = false;
            if (!valoresActuales.codExpedicion && (valoresActuales.recogidaFueraPlaza === true || valoresActuales.recogidaFueraPlaza === "1")) {
                devolver = true;
            }
            return devolver && !telefonoContacto;
        },
        then: Yup.string().required('El email o el teléfono en origen deben estar rellenados').default(""),
        otherwise: Yup.string().nullable().default("")
    }),

}, ['telefonoContacto', 'emailContacto']);


const validacionEstandarGLS = {
    validaciones: Yup.object({
        descDeclarado: Yup.string().when('valorDeclarado', {
            is: (valorDeclarado) => (valorDeclarado > 0),
            then: Yup.string().required("Debes indicar la mercancía del valor declarado"),
            otherwise: Yup.string().nullable().default("")
        }),
        direccionRecogida: valDireccionRecogidaFueraPlaza, 
    }),
    canalizacion: 'oficina'
};

//Comentado todo lo de Parcel Shop por el momento
const validacionParcelShopGLS = {
    validaciones: Yup.object({
        descDeclarado: Yup.string().when('valorDeclarado', {
            is: (valorDeclarado) => (valorDeclarado > 0),
            then: Yup.string().required("Debes indicar la mercancía del valor declarado"),
            otherwise: Yup.string().nullable().default("")
        }),
        direccionRecogida: valDireccionRecogidaFueraPlaza,
    }),
    canalizacion: 'puntorecogida',
    textos: {
        canalizacion: {
            titulo: 'PUNTOS DE RECOGIDA',
            nombreOficina: 'PARCEL SHOP'
        }
    }
};

const validacionInternacionalGLS = {
    validaciones: Yup.object({
        incoterm: Yup.string().nullable(),
        descDeclarado: Yup.string().when('valorDeclarado', {
            is: (valorDeclarado) => (valorDeclarado > 0),
            then: Yup.string().required("Debes indicar la mercancía del valor declarado"),
            otherwise: Yup.string().nullable().default("")
        }),
        direccionRecogida: valDireccionRecogidaFueraPlaza,  
    }),
    canalizacion: 'oficina'
};


const validacionesEstandarCTT = {
    validaciones: Yup.object({        
        direccion: Yup.object().shape({
            telefonoContacto: Yup.string().required('El teléfono debe estar rellenado').default("")

        })
    }),
    canalizacion: 'oficina'
};

const validacionesInternacionalesCTT = {
    validaciones: Yup.object({        
        direccion: Yup.object().shape({
            telefonoContacto: Yup.string().required('El teléfono debe estar rellenado').default(""),
            personaContacto: Yup.string().required('El contacto del destinatario debe estar rellenado').default("")
        }),
        direccionRecogida: Yup.object().shape({
            telefonoContacto: Yup.string().required('El teléfono del remitente debe estar rellenado').default(""),
            personaContacto: Yup.string().required('El contacto del remitente debe estar rellenado').default("")
        }),
        codigoTipoMercancia: Yup.string().when('$valoresActuales', {
            is: (valoresActuales) => !valoresActuales.codExpedicion,
            then: Yup.string().required('El tipo de mercancía es obligatorio').default("").nullable(),
            otherwise: Yup.string().nullable().default("")
        })
    }),
    canalizacion: 'oficina'
};

const validacionesEstandarONTIME = {
    validaciones: Yup.object({        
        peso: Yup.number().default(1).required("Requerido"),
        bultos: Yup.number().positive('Los bultos deben ser mayor que cero').min("1", "Mínimo un bulto").default(1).required('Requerido'),
        direccion: Yup.object().shape({
            nombre: Yup.string().required('El nombre debe estar relleno').default(""),                        
            direccion: Yup.string().required('La dirección debe estar rellena').default(""),
            localidad: Yup.string().required('La ciudad debe estar rellena').default(""),
            cp: Yup.string().required('El código postal debe estar relleno').default(""),

        }, ['nombre',  'direccion', 'localidad', 'cp']),
        direccionRecogida: Yup.object().shape({            
            nombre: Yup.string().required('El nombre del remitente debe estar relleno').default(""),
            direccion: Yup.string().required('La dirección del remitente debe estar rellena').default(""),
            localidad: Yup.string().required('La ciudad del remitente debe estar rellena').default(""),
            cp: Yup.string().required('El código postal del remitente debe estar relleno').default(""),

        }, ['nombre',  'direccion', 'localidad', 'cp']),        

    }),
    canalizacion: 'oficina'
}

const validacionesEstandarDYNAMIC = {
    validaciones: Yup.object({        
        peso: Yup.number().default(1).required("Requerido"),
        bultos: Yup.number().positive('Los bultos deben ser mayor que cero').min("1", "Mínimo un bulto").default(1).required('Requerido'),
        direccion: Yup.object().shape({
            nombre: Yup.string().required('El nombre debe estar relleno').default(""),                        
            direccion: Yup.string().required('La dirección debe estar rellena').default(""),
            localidad: Yup.string().required('La ciudad debe estar rellena').default(""),
            cp: Yup.string().required('El código postal debe estar relleno').default("")
        }, ['nombre',  'direccion', 'localidad', 'cp', 'telefonoContacto', 'personaContacto']),
        direccionRecogida: Yup.object().shape({            
            nombre: Yup.string().required('El nombre del remitente debe estar relleno').default(""),
            direccion: Yup.string().required('La dirección del remitente debe estar rellena').default(""),
            localidad: Yup.string().required('La ciudad del remitente debe estar rellena').default(""),
            cp: Yup.string().required('El código postal del remitente debe estar relleno').default("")
        }, ['nombre',  'direccion', 'localidad', 'cp']),        

    }),
    canalizacion: 'oficina'
}

const validacionesEstandarCAINIAO = {
    validaciones: Yup.object({        
        peso: Yup.number().default(1).required("Requerido"),
        bultos: Yup.number().positive('Los bultos deben ser mayor que cero').min("1", "Mínimo un bulto").max("1", "Esta red solo permite un bulto").default(1).required('Requerido'),
        direccion: Yup.object().shape({
            nombre: Yup.string().required('El nombre debe estar relleno').default(""),                        
            direccion: Yup.string().required('La dirección debe estar rellena').default(""),
            localidad: Yup.string().required('La ciudad debe estar rellena').default(""),
            cp: Yup.string().required('El código postal debe estar relleno').default(""),
            telefonoContacto: Yup.string().required('El teléfono debe estar rellenado').default(""),
            personaContacto: Yup.string().required('El contacto del destinatario debe estar rellenado').default(""),
            emailContacto: Yup.string().required('El email del destinatario debe estar rellenado').default("")
        }, ['nombre',  'direccion', 'localidad', 'cp','telefonoContacto', 'personaContacto']),
        direccionRecogida: Yup.object().shape({            
            nombre: Yup.string().required('El nombre del remitente debe estar relleno').default(""),
            direccion: Yup.string().required('La dirección del remitente debe estar rellena').default(""),
            localidad: Yup.string().required('La ciudad del remitente debe estar rellena').default(""),
            cp: Yup.string().required('El código postal del remitente debe estar relleno').default(""),
            telefonoContacto: Yup.string().required('El teléfono debe estar rellenado').default(""),
            personaContacto: Yup.string().required('El contacto de origen debe estar rellenado').default("")
        }, ['nombre',  'direccion', 'localidad', 'cp','telefonoContacto', 'personaContacto']),        

    }),
    canalizacion: 'oficina'
}


const validacionesEstandarMRW = {
    validaciones: Yup.object({
        peso: Yup.number().default(1).required("Requerido"),
        bultos: Yup.number().positive('Los bultos deben ser mayor que cero').min("1", "Mínimo un bulto").default(1).required('Requerido'),
        direccion: Yup.object().shape({
            nombre: Yup.string().required('El nombre debe estar relleno').default(""),
            direccion: Yup.string().required('La dirección debe estar rellena').default(""),
            localidad: Yup.string().required('La ciudad debe estar rellena').default(""),
            cp: Yup.string().required('El código postal debe estar relleno').default(""),
            telefonoContacto: Yup.string().required('El teléfono debe estar rellenado').default(""),

        }, ['nombre', 'direccion', 'localidad', 'cp']),
        direccionRecogida: Yup.object().shape({
            nombre: Yup.string().required('El nombre del remitente debe estar relleno').default(""),
            direccion: Yup.string().required('La dirección del remitente debe estar rellena').default(""),
            localidad: Yup.string().required('La ciudad del remitente debe estar rellena').default(""),
            cp: Yup.string().required('El código postal del remitente debe estar relleno').default(""),

        }, ['nombre', 'direccion', 'localidad', 'cp']),

    }),
    canalizacion: 'oficina'
};



export const ConfigServicios = {
    //CORREOS
    2: { 
      S0031: validacionEstandarInternacionalCorreos,
      S0159: validacionEstandarInternacionalCorreos,
      S0360: validacionEstandarInternacionalMonobultoCorreos,
      S0410: validacionEstandarInternacionalMonobultoCorreos,
      S0411: validacionEstandarInternacionalMonobultoCorreos, 
      S0133: validacionEstandarMultibultoCorreosOficina,
      S0132: validacionEstandarCorreos,
      S0236: validacionEstandarMultibultoCorreosOficina,
      S0235: validacionEstandarCorreos,
      S0179: { //PAQ LIGERO
            validaciones: Yup.object({
                bultos: Yup.number().positive('Los bultos deben ser mayor que cero').min("1", "Solo se permite 1 bulto").max("1", "Solo se permite 1 bulto").default(1).required('Requerido'),
                referencias: Yup.array().of(
                    Yup.object().shape({
                        pesoVolumetrico: Yup.number().positive('El P.Volumétrico de la referencia tiene que ser positivo').required('El P.Volumétrico de la referencia no puede estar vacio').nullable(),
                        ancho: Yup.number().positive('El ancho de la referencia tiene que ser positivo').required('El ancho de la referencia no puede estar vacio').nullable(),
                        alto: Yup.number().positive('El alto de la referencia tiene que ser positivo').required('El alto de la referencia no puede estar vacio').nullable(),
                        largo: Yup.number().positive('El largo de la referencia tiene que ser positivo').required('El largo de la referencia no puede estar vacio').nullable(),
                    })
                ),
                direccion: valDireccionCorreos,
                direccionRecogida: valDireccionCorreosOrigen,
                numTarifario: valTarifario,
                valorNeto: valNeto,
                ibanReembolso: valReembolso,
                tipoPesoVol: valTipoPesoVol,
            }),
            canalizacion: 'oficina'
        },
        S0176: { //PAQ PREMIUM CITIPAQ
            validaciones: Yup.object({
                bultos: Yup.number().positive('Los bultos deben ser mayor que cero').min("1", "Solo se permite 1 bulto").max("1", "Solo se permite 1 bulto").default(1).required('Requerido'),
                referencias: Yup.array().of(
                    Yup.object().shape({
                        pesoVolumetrico: Yup.number().positive('El P.Volumétrico de la referencia tiene que ser positivo').required('El P.Volumétrico de la referencia no puede estar vacio').nullable(),
                        ancho: Yup.number().positive('El ancho de la referencia tiene que ser positivo').required('El ancho de la referencia no puede estar vacio').nullable(),
                        alto: Yup.number().positive('El alto de la referencia tiene que ser positivo').required('El alto de la referencia no puede estar vacio').nullable(),
                        largo: Yup.number().positive('El largo de la referencia tiene que ser positivo').required('El largo de la referencia no puede estar vacio').nullable(),
                    }),
                ),                
                numTarifario: valTarifario,
                valorNeto: valNeto,
                ibanReembolso: valReembolso,
                tipoPesoVol: valTipoPesoVol,
                oficinaRed: Yup.object().required('La oficina es obligatoria').test('notEmptyObject', 'La oficina es obligatoria', (value) => !isEmpty(value)).default({})
            }),
            canalizacion: 'citypaq',
            textos: {
                canalizacion: {
                    titulo: 'DATOS DE LA TAQUILLA',
                    nombreOficina: 'Taquilla'
                }
            }
        },
        S0178: { //PAQ ESTANDAR CITIPAQ
            validaciones: Yup.object({
                bultos: Yup.number().positive('Los bultos deben ser mayor que cero').min("1", "Solo se permite 1 bulto").max("1", "Solo se permite 1 bulto").default(1).required('Requerido'),
                referencias: Yup.array().of(
                    Yup.object().shape({
                        pesoVolumetrico: Yup.number().positive('El P.Volumétrico de la referencia tiene que ser positivo').required('El P.Volumétrico de la referencia no puede estar vacio').nullable(),
                        ancho: Yup.number().positive('El ancho de la referencia tiene que ser positivo').required('El ancho de la referencia no puede estar vacio').nullable(),
                        alto: Yup.number().positive('El alto de la referencia tiene que ser positivo').required('El alto de la referencia no puede estar vacio').nullable(),
                        largo: Yup.number().positive('El largo de la referencia tiene que ser positivo').required('El largo de la referencia no puede estar vacio').nullable(),
                    })
                ),
                numTarifario: valTarifario,
                valorNeto: valNeto,
                ibanReembolso: valReembolso,
                tipoPesoVol: valTipoPesoVol,
                oficinaRed: Yup.object().required('La oficina es obligatoria').test('notEmptyObject', 'La oficina es obligatoria', (value) => !isEmpty(value)).default({})
            }),
            canalizacion: 'citypaq',
            textos: {
                canalizacion: {
                    titulo: 'DATOS DE LA TAQUILLA',
                    nombreOficina: 'Taquilla'
                }
            }
        }

    },
    3:  { //CORREOS EXPRESS
        54: validacionesEstandarCorreosExpress,
        61: validacionesEstandarCorreosExpress,
        62: validacionesEstandarCorreosExpress,
        63: validacionesEstandarCorreosExpress,
        66: validacionesEstandarCorreosExpress,
        67: validacionesEstandarCorreosExpress,
        68: validacionesEstandarCorreosExpress,
        69: validacionesEstandarCorreosExpress,
        90: validacionesInternacionalMonobultoCorreosExpress ,
        91: validacionesInternacionalCorreosExpress ,        
        92: validacionesEstandarCorreosExpress,        
        93: validacionesEstandarCorreosExpress,        
        44:{
            validaciones: Yup.object({
                valorDeclarado:Yup.number().max("3000", "Máximo 3000 €"),
                contraReembolso: Yup.number().max("2499", "Máximo 2499 €"),
                peso: Yup.number().max("2000", "Máximo 2000 KG").default(1).required("Requerido"),
                bultos: Yup.number().positive('Los bultos deben ser mayor que cero').min("1", "Mínimo un bulto").default(1).required('Requerido'),
                direccion: Yup.object().shape({                    
                    personaContacto: Yup.string().required('El nombre de la persona de contacto debe estar relleno').default(""),
                    telefonoContacto: Yup.string().required('El teléfono debe estar relleno').default("")                    
                }, ['personaContacto', 'telefonoContacto']),
                direccionRecogida: Yup.object().shape({
                    nombre: Yup.string().required('El nombre del remitente debe estar relleno').default(""),            
                    direccion: Yup.string().required('La dirección del remitente debe estar rellena').default(""),
                    localidad: Yup.string().required('La ciudad del remitente debe estar rellena').default(""),
                    cp: Yup.string().required('El código postal del remitente debe estar relleno').default(""),
                    personaContacto: Yup.string().when('$valoresActuales', {
                        is: (valoresActuales) => ((valoresActuales.recogidaFueraPlaza === true || valoresActuales.recogidaFueraPlaza === "1")),
                        then: Yup.string().required('El nombre de la persona de contacto del remitente debe estar relleno').default(""),
                        otherwise: Yup.string().nullable().default("")
                    }),
                    telefonoContacto: Yup.string().when('$valoresActuales', {
                        is: (valoresActuales) => ((valoresActuales.recogidaFueraPlaza === true || valoresActuales.recogidaFueraPlaza === "1")),
                        then: Yup.string().required('El teléfono debe estar relleno').default(""),
                        otherwise: Yup.string().nullable().default("")
                    })
                }, ['nombre', 'personaContacto', 'telefonoContacto', 'direccion', 'localidad', 'cp']),
                oficinaRed: Yup.object().required('La oficina es obligatoria').test('notEmptyObject', 'La oficina es obligatoria', (value) => !isEmpty(value)).default({})

            }),
            canalizacion: 'oficina'}
    },
    5:{  //tipsa
        '06': validacionesEstandarTipsa,
        '07': validacionesEstandarTipsa,
        '10': validacionesEstandarTipsa,
        '14': validacionesEstandarTipsa,        
        '17': validacionesEstandarTipsa,
        '19': validacionesEstandarTipsa,
        '20': validacionesEstandarTipsaDelegacion,
        '24': validacionesEstandarTipsa,
        '25': validacionesEstandarTipsa,
        '27': validacionesEstandarTipsa,
        '28': validacionesEstandarTipsa,
        '48': validacionesEstandarTipsa,
        '49': validacionesEstandarTipsa,
        '90': validacionesEstandarTipsaInternacional,
        '91': validacionesEstandarTipsaInternacional,
        '92': validacionesEstandarTipsaInternacional,
        '96': validacionesEstandarTipsa,
        '50': validacionesEstandarTipsaRecogida,
        'MV': validacionesEstandarTipsa
    },
    4: { //gls
      '74.3': validacionInternacionalGLS,
      '76.3': validacionInternacionalGLS,
        '1.0': validacionEstandarGLS,
        '1.11': validacionEstandarGLS,
        '1.2': validacionEstandarGLS,
        '1.3': validacionEstandarGLS,
        '1.5': validacionEstandarGLS,
        '10.3': validacionEstandarGLS,
        '14.0': validacionEstandarGLS,
        '14.2': validacionEstandarGLS,
        '14.3': validacionEstandarGLS,
        '17.3': validacionEstandarGLS,
        '18.2': validacionEstandarGLS,
        '18.3': validacionEstandarGLS,
        '2.0': validacionEstandarGLS,
        '2.2': validacionEstandarGLS,
        '21.2': validacionEstandarGLS,
        '21.3': validacionEstandarGLS,
        '30.18': validacionEstandarGLS,
        '32.3': validacionEstandarGLS,
        '36.17': validacionEstandarGLS,
        '37.18': validacionEstandarGLS,
        '52.3':validacionEstandarGLS,
        '56.18': validacionEstandarGLS,
        '57.18': validacionEstandarGLS,
        '6.10': validacionEstandarGLS,
        '60.18': validacionEstandarGLS,
        '7.0': validacionEstandarGLS,
        '7.2': validacionEstandarGLS,
        '7.3': validacionEstandarGLS,
        '72.3': validacionEstandarGLS,      
        '78.3': validacionEstandarGLS,
        '79.3': validacionEstandarGLS,
        '8.0': validacionEstandarGLS,
        '8.2': validacionEstandarGLS,
        '8.3': validacionEstandarGLS,
        '9.4': validacionEstandarGLS,
        '1.19': validacionParcelShopGLS,
        '74.19': validacionParcelShopGLS
    },
    11: {
        '10': validacionesEstandarONTIME,
        '14': validacionesEstandarONTIME,
        '24': validacionesEstandarONTIME,
        '72': validacionesEstandarONTIME,
        'CM': validacionesEstandarONTIME,
        'E72': validacionesEstandarONTIME,
        'E24': validacionesEstandarONTIME,
        'RED': validacionesEstandarONTIME,
        '101': validacionesEstandarONTIME, //Internacional
        '100': validacionesEstandarONTIME  //Internacional
    },
    12: { //CTT
        '10H': validacionesEstandarCTT,
        '13H': validacionesEstandarCTT,
        '19H': validacionesEstandarCTT,
        '63R': validacionesEstandarCTT,
        '48H': validacionesEstandarCTT,
        '48C': validacionesEstandarCTT,
        '96M': validacionesEstandarCTT,
        '48B': validacionesEstandarCTT,
        '80I': validacionesInternacionalesCTT,
        '81I': validacionesInternacionalesCTT,
        '90L': validacionesInternacionalesCTT,
        '83S': validacionesEstandarCTT,
        '10S': validacionesEstandarCTT,
        '13S': validacionesEstandarCTT,
        '19S': validacionesEstandarCTT,
        '19B': validacionesEstandarCTT,
        '61N': validacionesEstandarCTT,
        '30N': validacionesEstandarCTT,
        '30S': validacionesEstandarCTT,
        '75N': validacionesEstandarCTT,
        '91H': validacionesEstandarCTT,
        '67D': validacionesEstandarCTT,
        '48A': validacionesEstandarCTT,
        '19T': validacionesEstandarCTT,
        '96S': validacionesEstandarCTT,
        '91S': validacionesEstandarCTT,
        '63S': validacionesEstandarCTT,
        '48S': validacionesEstandarCTT,
        '67C': validacionesEstandarCTT,
        '48M': validacionesEstandarCTT,
        '48N': validacionesEstandarCTT
    },
    13: {   //Dynamic Express
        '10': validacionesEstandarDYNAMIC,
        '13': validacionesEstandarDYNAMIC,
        '19': validacionesEstandarDYNAMIC,
        'ECO': validacionesEstandarDYNAMIC,
        '01V': validacionesEstandarDYNAMIC,
        '29': validacionesEstandarDYNAMIC,
        '65': validacionesEstandarDYNAMIC,
        '70': validacionesEstandarDYNAMIC,
        '78': validacionesEstandarDYNAMIC,
        'SEC': validacionesEstandarDYNAMIC
    },
    7:{ //MRW
        '0000':validacionesEstandarMRW,
        '00051':validacionesEstandarMRW,
        '0010':validacionesEstandarMRW,
        '0015': validacionesEstandarMRW,
        '0100': validacionesEstandarMRW,
        '0105': validacionesEstandarMRW,
        '0110': validacionesEstandarMRW,
        '0115': validacionesEstandarMRW,
        '0200': validacionesEstandarMRW,
        '0205': validacionesEstandarMRW,
        '0220': validacionesEstandarMRW,
        '0230': validacionesEstandarMRW,
        '0235': validacionesEstandarMRW,
        '0300': validacionesEstandarMRW,
        '0350': validacionesEstandarMRW,
        '0370': validacionesEstandarMRW,
        '0385': validacionesEstandarMRW,
        '0390': validacionesEstandarMRW,
        '0400': validacionesEstandarMRW,
        '0450': validacionesEstandarMRW,
        '0480': validacionesEstandarMRW,
        '0490': validacionesEstandarMRW,
        '0800': validacionesEstandarMRW,
        '08001': validacionesEstandarMRW,
        '810': validacionesEstandarMRW,
    },
    9: { //UPS
        '07': validacionesEstandarUPS,
        '08': validacionesIntUPS,
        '11': validacionesEstandarUPS,
        '17': validacionesIntUPS,
        '54': validacionesIntUPS,
        '65': validacionesIntUPS,
        '72': validacionesIntUPS,
        '96': validacionesIntUPS,
        'M5': validacionesMailUPS,
        'M6': validacionesMailUPS,        
    },
    14: { //CAINIAO
        'ESL2L_4PL_ECONOMY': validacionesEstandarCAINIAO
    }

}