import { cloneDeep, isEmpty } from "lodash";
import { useCallback, useState } from "react";
import { FieldValues, UseFormReset } from "react-hook-form";
import { useLocalStorage } from "react-use";


export interface DatatableFiltersState {
    filtros: any;
    setFiltrosDatatable: (values: any) => void;
    resetFiltros: (reset: UseFormReset<FieldValues>) => void;
}


export function useDatatableFilters(defaultFilterValues: any = {}, goToFirstPage : () => void, keyFiltersLocalStorage?: string) : DatatableFiltersState {

    const [savedFilters, setSavedFilters] = useLocalStorage(keyFiltersLocalStorage || "default-filters", defaultFilterValues);

    const [filtros, setFiltros] = useState(keyFiltersLocalStorage ? savedFilters : defaultFilterValues);

    const establecerFiltros = useCallback((values: any) => {

        setFiltros(values);

        if (keyFiltersLocalStorage) {
            setSavedFilters(values);
        }
    }, [keyFiltersLocalStorage, setSavedFilters]);

    const setFiltrosDatatable = useCallback((values: any) => {
        let filtrosSetear = cloneDeep(values);

        Object.keys(filtrosSetear).forEach((key) => {
            if (filtrosSetear[key] === "" || filtrosSetear[key] === null || filtrosSetear[key] === undefined || (typeof filtrosSetear[key] !== "number" && isEmpty(filtrosSetear[key])))
            {
                delete filtrosSetear[key];
            }
        });

        goToFirstPage();

        establecerFiltros(filtrosSetear);

    }, [goToFirstPage, establecerFiltros]);

    const resetFiltros = useCallback((reset: UseFormReset<FieldValues>) => {

        const emptyValues = Object.keys(defaultFilterValues).reduce((acc, key) => {
            acc[key] = "";
            return acc;
        }, {} as Record<string, string>);

        goToFirstPage();

        establecerFiltros(emptyValues);
        
        reset(emptyValues);
    
    }, [goToFirstPage, establecerFiltros, defaultFilterValues]);

    return {
        resetFiltros,
        setFiltrosDatatable,
        filtros
    }

}