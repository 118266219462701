import React from 'react';
import FormDatepickerFormik from './Librerias/Formik/FormDatepickerFormik';
import FormDatepickerRHF from './Librerias/ReactHookForm/FormDatepickerRHF';



export const FormDatepicker = (props) => {

    
    const { hooks, ...otherProps } = props;


    return (
        !hooks ? 
        (
            <FormDatepickerFormik {...otherProps} />
        )
        :
        (
            <FormDatepickerRHF {...otherProps} />
        )
    )

}
 
