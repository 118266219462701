import React, { useCallback, useEffect, useState } from 'react';
import { useDeltaObject } from 'react-delta';
import { FormSelect } from '../../../../componentes/formularios/FormSelect';
import { ejecutaAPI, pintaOpcionesSelect } from '../../../../helpers/libFunciones';

const TipoMercancia = ({name, label, idClienteTransmitible, idDelegacion, ...props}) => {

    const [opciones, setOpciones] = useState([]);

    const ref = useDeltaObject({
        idClienteTransmitible: idClienteTransmitible,
        idDelegacion: idDelegacion
    })

    const recargarTiposMercancia = useCallback(async () => {

        if (idDelegacion && idClienteTransmitible)
        {
            var tiposMercancia = [];

            const {success, respuesta} = await ejecutaAPI('GET', `tiposMercancia?idDelegacion=${idDelegacion}&idClienteRedTransporte=${idClienteTransmitible}`);

            if (success)
            {
                tiposMercancia = respuesta;   
            }

            setOpciones(tiposMercancia);

        }


    }, [idDelegacion, idClienteTransmitible]);

    useEffect(() => {

        if (ref.idClienteTransmitible || ref.idDelegacion)
        {

            recargarTiposMercancia();
        }

    }, [ref, recargarTiposMercancia]);

    return ( 
        <>
            <FormSelect name={name} permitirValoresNoExistentes={false} label={label} {...props}>
                {pintaOpcionesSelect(opciones, "id", "nombre", "codigoTipoMercancia", 0)}
            </FormSelect>
        </>
     );
}
 
export default TipoMercancia;